<template>
  <div class="fixed inset-x-0 bottom-0 pb-2 sm:pb-5">
    <div class="mx-auto max-w-5xl px-2 sm:px-6 lg:px-8">
      <div class="rounded-lg bg-indigo-600/95 backdrop-blur-sm p-2 shadow-lg sm:p-3">
        <div class="flex flex-wrap items-center justify-between">
          <div class="flex w-0 flex-1 items-center">
            <span class="flex rounded-lg bg-indigo-800 p-2">
              <IdentificationIcon
                class="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
            <p
              class="ml-3 truncate font-medium text-white"
              v-html="__(`You are logged in as <b>:company</b>.`, {company})"
            />
          </div>
          <div class="mt-2 w-full flex-shrink-0 order-2 sm:mt-0 sm:w-auto">
            <a
              href="#"
              class="flex gap-3 items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50"
              @click.prevent="stop"
            >
              <ArrowUturnLeftIcon
                class="h-6 w-6"
                aria-hidden="true"
              />
              {{ __("Back to your account") }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { IdentificationIcon, ArrowUturnLeftIcon } from '@heroicons/vue/24/outline';
import { router } from "@inertiajs/vue3";
const props = defineProps({
    'company': String
});

const stop = () => router.post(route('companies.stop_impersonate'));

</script>
