<template>
  <!-- Narrow sidebar -->
  <aside
    id="narrow-menu"
    class="hidden w-28 overflow-y-auto md:block"
  >
    <div class="flex w-full h-full flex-col items-center py-6">
      <Link
        href="/"
        class="flex flex-shrink-0 items-center"
      >
        <Logo
          class="h-16 w-auto"
          :title="__('NO Ransomware')"
        />
      </Link>
      <div class="mt-6 w-full flex-1 space-y-1 px-2">
        <NavLink
          v-for="item in menu"
          :key="item.name"
          :href="item.href"
          :active="item.current"
          :icon="item.icon"
        >
          {{ __(item.name) }}
        </NavLink>
      </div>
      <div class="flex-grow" />
      <div class="flex-shrink-0 p-4 pb-0">
        <a
          href="#"
          class="user flex-shrink-0 group block text-center"
          @click.prevent="toggleProfile"
        >
          <div
            class="avatar-wrapper inline-block h-12 w-12"
            :class="{'avatar-wrapper--open': showProfileNavigation}"
          >
            <Avatar
              :user="user"
              size="w-full h-full"
            />
          </div>
          <p class="text-sm leading-4 dark:text-white font-medium group-hover:text-gray-700 dark:group-hover:text-zinc-300 mt-2">
            {{ user.name }}
          </p>
        </a>
      </div>
      <Collapsible>
        <div
          v-if="showProfileNavigation"
          id="profile-nav"
          class="mt-6 w-full px-2 space-y-1"
        >
          <NavLink
            v-for="item in profileNavigation"
            :key="item.name"
            :href="item.href"
            :as="item.method ? 'button' : 'a'"
            :method="item.method || 'get'"
            :active="item.current"
            :icon="item.icon"
            @mouseup="showProfileNavigation = false"
          >
            {{ item.name }}
          </NavLink>
        </div>
      </Collapsible>
    </div>
  </aside>

  <!-- Mobile menu -->
  <TransitionRoot
    as="template"
    :show="mobileMenuOpened"
  >
    <Dialog
      as="div"
      class="relative z-40 md:hidden"
      @close="close"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 z-40 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-indigo-700 pt-5 pb-4">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-1 right-0 -mr-14 p-1">
                <button
                  type="button"
                  class="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                  @click="close"
                >
                  <XMarkIcon
                    class="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                  <span class="sr-only">{{ __("Close sidebar") }}</span>
                </button>
              </div>
            </TransitionChild>
            <div class="flex flex-shrink-0 items-center px-4">
              <img
                class="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=white"
                alt="NO Ransomware"
              >
            </div>
            <div class="mt-5 h-0 flex-1 overflow-y-auto px-2">
              <nav class="flex h-full flex-col">
                <div class="space-y-1">
                  <a
                    v-for="item in menu"
                    :key="item.name"
                    :href="item.href"
                    :class="[item.current ? 'bg-indigo-800 text-white' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white', 'group py-2 px-3 rounded-md flex items-center text-sm font-medium']"
                    :aria-current="item.current ? 'page' : undefined"
                  >
                    <component
                      :is="item.icon"
                      :class="[item.current ? 'text-white' : 'text-indigo-300 group-hover:text-white', 'mr-3 h-6 w-6']"
                      aria-hidden="true"
                    />
                    <span>{{ item.name }}</span>
                  </a>
                </div>
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
        <div
          class="w-14 flex-shrink-0"
          aria-hidden="true"
        >
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {computed, ref} from "vue";

import Avatar from "@/Shared/Ui/Avatar.vue";
import Collapsible from "@/Shared/Ui/Components/Collapsible.vue";
import NavLink from "@/Shared/Ui/Components/NavLink.vue";
import Logo from "@/Shared/Ui/Logo.vue";

import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';

import {
    AdjustmentsVerticalIcon,
    ArrowRightOnRectangleIcon,
    BookOpenIcon,
    BriefcaseIcon,
    BuildingOfficeIcon,
    CalendarIcon,
    ChartPieIcon,
    HomeIcon,
    UserIcon,
    TicketIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/vue/24/outline';

import User from "@/Models/User.js";
import { router } from "@inertiajs/vue3";

let showProfileNavigation = ref(false);

const props = defineProps({
    user: User,
    mobileMenuOpened: Boolean
});

const emit = defineEmits(['close']);
const close = () => emit('close');

const isUrl = (...urls) => {
    let currentUrl = router.page.url.substr(1);
    if (urls[0] === '') {
        return currentUrl === ''
    }
    return urls.filter((url) => currentUrl.startsWith(url)).length
};

const menu = computed(() => {
    let menu = [];
    if (props.user.isRoot) {
        menu = [...menu,
            { name: __("Home"), href: route('home'), icon: HomeIcon, current: isUrl('') },
            { name: __("Partners"), href: route('partners.index'), icon: BriefcaseIcon, current: isUrl('partners') },
            { name: __("Clients"), href: route('clients.index'), icon: BuildingOfficeIcon, current: isUrl('clients') },
            { name: __("Orders"), href: route('orders.index'), icon: TicketIcon, current: isUrl('orders') },
            { name: __("Students"), href: route('students.index'), icon: UsersIcon, current: isUrl('students') },
            // { name: __("Calendar"), href: route('calendar.index'), icon: CalendarIcon, current: isUrl('calendar') },
            { name: __("Lessons"), href: route('lessons.index'), icon: BookOpenIcon, current: isUrl('lessons') },
        ];
    }
    if (props.user.isPartner) {
        menu = [...menu,
            { name: __("Home"), href: route('home'), icon: HomeIcon, current: isUrl('') },
            { name: __("Partners"), href: route('partners.index'), icon: BriefcaseIcon, current: isUrl('partners') },
            { name: __("Clients"), href: route('clients.index'), icon: BuildingOfficeIcon, current: isUrl('clients') },
            { name: __("Orders"), href: route('orders.index'), icon: TicketIcon, current: isUrl('orders') },
            { name: __("Lessons"), href: route('lessons.index'), icon: BookOpenIcon, current: isUrl('lessons') },
        ];
    }
    if (props.user.isManager) {
        menu = [...menu,
            { name: __("Home"), href: route('home'), icon: HomeIcon, current: isUrl('') },
            { name: __("Students"), href: route('students.index'), icon: UsersIcon, current: isUrl('students') },
            { name: __("Reports"), href: route('reports.index'), icon: ChartPieIcon, current: isUrl('reports') },
            { name: __("Calendar"), href: route('calendar.index'), icon: CalendarIcon, current: isUrl('calendar') },
            { name: __("Lessons"), href: route('lessons.index'), icon: BookOpenIcon, current: isUrl('lessons') },
        ];
    }

    if (props.user.isStudent) {
        menu = [...menu,
            { name: __("Lessons"), href: route('lessons.index'), icon: BookOpenIcon, current: isUrl('lessons') },
            { name: __("Profile"), href: route('profile.index'), icon: UserIcon, current: isUrl('profile') },
        ];
    }
    return menu;
});

let profileNavigation = [];

if (!props.user.isStudent) {
    profileNavigation = [{ name: __("Profile"), href: route('profile.index'), icon: UserIcon, current: isUrl('profile') }];
}

profileNavigation = [ ...profileNavigation,
    { name: __("Settings"), href: route('settings.index'), icon: AdjustmentsVerticalIcon, current: isUrl('settings') },
    { name: __("Log out"), href: route('logout'), icon:  ArrowRightOnRectangleIcon, method: "post"},
];

const toggleProfile = () => {
    showProfileNavigation.value = !showProfileNavigation.value;
    if (showProfileNavigation.value) {
        setTimeout(() => {
            document.querySelector("#profile-nav").scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }, 320);
    }
}

</script>

<style lang="scss" scoped>
aside {
    #secondary-column {
        transition: width .4s ease-in-out;
        will-change: width;
        &:empty {
            width: 0;
        }
    }
}
#narrow-menu {
    .user {
        .avatar-wrapper {
            position: relative;
            overflow: hidden;

            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 50%;
            }

            &::before {
                background: rgba(255, 255, 255, 0);
                backdrop-filter: blur(0);
                transition: background .4s, backdrop-filter .4s;
            }

            &::after {
                color: white;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 15l7-7 7 7' /%3E%3C/svg%3E");
                background-size: 70%;
                background-repeat: no-repeat;
                background-position: center;
                transform: translateY(50px);
                transition: transform .4s;
            }

            &.avatar-wrapper--open {
                &::after {
                    transform: translateY(-50px);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
                }
            }
        }
        &:hover {
            .avatar-wrapper {
                &::before {
                    background: rgba(255, 255, 255, .5);
                    backdrop-filter: blur(2px);
                }

                &::after {
                    transform: translateY(0);
                }
            }
        }
    }
}
</style>
