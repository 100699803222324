<template>
    <transition
        enter-active-class="enter-active"
        leave-active-class="leave-active"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
    >
        <slot />
    </transition>
</template>

<script setup>
const beforeEnter = (element) => {
    requestAnimationFrame(() => {
        if (!element.style.height) {
            element.style.height = "0px";
        }
        element.style.opacity = "0";
        element.style.display = null;
    });
};

const enter = (element) => {
    requestAnimationFrame(() => {
        requestAnimationFrame(() => {
            element.style.height = `${element.scrollHeight}px`;
            element.style.opacity = "1";
        });
    });
};

const afterEnter = (element) => {
    element.style.height = null;
    element.style.opacity = null;
};
/**
 * @param {HTMLElement} element
 */
const beforeLeave = (element) => {
    requestAnimationFrame(() => {
        if (!element.style.height) {
            element.style.height = `${element.scrollHeight}px`;
            element.style.opacity = "1";
        }
    });
};
/**
 * @param {HTMLElement} element
 */
const leave = (element) => {
    requestAnimationFrame(() => {
        requestAnimationFrame(() => {
            element.style.height = "0px";
            element.style.opacity = "0";
        });
    });
};
/**
 * @param {HTMLElement} element
 */
const afterLeave = (element) => {
    element.style.height = null;
    element.style.opacity = null;
};

</script>

<style scoped>
.enter-active,
.leave-active {
    overflow: hidden;
    transition: height .3s ease-in-out, opacity .3s ease-in-out;
    will-change: height;
}
</style>
