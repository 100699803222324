<template>
  <Modal />
  <Popup />
  <Notification />
  <Impersonation
    v-if="user.is_impersonating"
    :company="company.name"
  />
  <div
    class="flex h-full"
  >
    <Navigation
      :mobile-menu-opened="mobileMenuOpen"
      :user="user"
      @close="mobileMenuOpen = false"
    />
    <div class="flex flex-1 flex-col">
      <div class="sticky flex items-center justify-between top-0 z-10 bg-gray-100 dark:bg-zinc-900 pt-1 sm:pt-3 md:hidden">
        <Link
          href="/"
          class="flex flex-shrink-0 items-center px-2"
        >
          <Logo
            class="h-8 w-auto"
            :title="__('NO Ransomware')"
          />
        </Link>
        <button
          type="button"
          class="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 dark:hover:text-zinc-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          @click="mobileMenuOpen = true"
        >
          <span class="sr-only">{{ __("Open sidebar") }}</span>
          <Bars3Icon
            class="h-6 w-6"
            aria-hidden="true"
          />
        </button>
      </div>
      <main class="flex-1 overflow-auto">
        <header
          id="page-header"
        />
        <div
          class="my-6 mx-auto max-w-7xl px-4 sm:px-6 md:px-8"
          :class="{'mb-48': user.is_impersonating}"
        >
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import Notification from "@/Shared/Ui/Notification.vue";
import Navigation from "@/Shared/Ui/Navigation.vue";
import { Bars3Icon } from '@heroicons/vue/24/outline'

import {computed, ref, watchEffect} from 'vue'
import User from "@/Models/User.js";
import Modal from "@/Shared/Ui/Modal.vue";
import Notifications from "@/Support/Notifications";
import Impersonation from "@/Shared/Ui/Impersonation.vue";
import Popup from "@/Shared/Ui/Popup.vue";
import Logo from "@/Shared/Ui/Logo.vue";

const mobileMenuOpen = ref(false)

const props = defineProps({
    auth: Object,
    flash: Object,
})

const wait = timeout => new Promise(res => setTimeout(() => res(), timeout));

watchEffect(async () => {
    if (!props.flash.message) { return; }

    for (let message of props.flash.message) {
        await wait(200);
        Notifications.add(message);
    }
});

const user = computed(() => new User(props.auth.user));
const company = computed(() => props.auth.company);

</script>
