import { usePage } from '@inertiajs/vue3';

/**
 * Translate the given key.
 */
function translate(key, replace = {}) {
    let translation = usePage().props.language[key]
        ? usePage().props.language[key]
        : key

    Object.keys(replace).forEach(key => {
        translation = translation.replace(':' + key, replace[key])
    });

    return translation;
}

export default function (app) {
    app.mixin({
        methods: {
            "__": translate,
        },
    });
    window.__ = translate;
};
