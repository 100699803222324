import Model from "./Model";

export default class User extends Model {
    get isRoot() {
        return this.role === 'root';
    }
    get isPartner() {
        return this.role === 'partner';
    }

    get isManager() {
        return this.role === 'manager';
    }

    get isStudent() {
        return this.role === 'student';
    }
}
