import {reactive} from "vue";

const state = reactive({
    type: 'alert',
    active: false,
    message: '',
    title: '',
    image: '',
    animation: null,
    okText: '',
    cancelText: '',
    inputPlaceholder: '',
    iconType: 'success',
    inputType: 'text',
    userInput: '',
    inputRequired: false,
    inputAttributes: {},
    component: null,
    componentValues: {},
    componentProps: {},
});

let close;
let resetTimeout = null;
const modalPromise = () => new Promise((resolve) => close = resolve);
const open = (message) => {
    translate();
    state.message = message;
    state.active = true;
    clearTimeout(resetTimeout)
    return modalPromise();
}

const reset = (full = false) => {
    state.active = false;
    translate();
    resetTimeout = setTimeout(() => {
        state.message = '';
        state.image = '';
        state.animation = null;
        state.title = '';
        state.type = 'alert';
        state.iconType = 'success';
        state.inputType = 'text';
        state.userInput = '';
        state.inputRequired = false;
        state.inputAttributes = {};
        state.component = null;
        state.componentValues = {};
        state.componentProps = {};
    }, 500);
};

const translate = () => {
    state.okText = state.okText || __('Ok');
    state.cancelText = state.cancelText ||  __('Cancel');
    state.inputPlaceholder = state.inputPlaceholder || __('Please type here...');
}

export default {
    get state() {
        return state;
    },
    get isAlert() {
        return this.state.type === 'alert';
    },
    get isPrompt() {
        return this.state.type === 'prompt';
    },
    get isSuccess() {
        return state.iconType === 'success';
    },
    get isWarning() {
        return state.iconType === 'warning';
    },
    get isDanger() {
        return state.iconType === 'danger';
    },
    alert(message) {
        state.type = 'alert';
        return open(message);
    },
    alertWarning(message) {
        state.iconType = 'warning';
        return this.alert(message);
    },
    alertDanger(message) {
        state.iconType = 'danger';
        return this.alert(message);
    },
    confirm(message) {
        state.type = 'confirm';
        return open(message);
    },
    confirmWarning(message) {
        state.iconType = 'warning';
        return this.confirm(message)
    },
    confirmDanger(message) {
        state.iconType = 'danger';
        return this.confirm(message);
    },
    prompt(message) {
        state.type = 'prompt';
        return open(message);
    },
    promptWarning(message) {
        state.iconType = 'warning';
        return this.prompt(message);
    },
    promptDanger(message) {
        state.iconType = 'danger';
        return this.prompt(message);
    },
    cancel() {
        close(false);
        reset();
    },
    ok(input= true){
        input = state.type === 'prompt' ? input: true;
        close(input);
        reset();
    },
    component(component) {
        state.component = component;
        return this;
    },
    componentProps(values) {
        state.componentProps = values;
        return this;
    },
    title(title) {
        state.title = title;
        return this;
    },
    okText(text) {
        state.okText = text;
        return this;
    },
    cancelText(text) {
        state.cancelText = text;
        return this;
    },
    inputType(type){
        state.inputType = type
        return this
    },
    inputPlaceholder(text) {
        state.inputPlaceholder = text;
        return this;
    },
    inputAttributes(attributes) {
        state.inputAttributes = attributes;
        return this;
    },
    required() {
        state.inputRequired = true;
        return this;
    },
    image(image) {
        state.image = image;
        return this;
    },
    animation(file) {
        state.animation = file;
        return this;
    }
}
