import i18n from "@/i18n";

import { createApp, h } from 'vue';
import { createInertiaApp, Head, Link } from '@inertiajs/vue3';

import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import AuthLayout from "@/Shared/Layouts/AuthLayout.vue";
import AdminLayout from "@/Shared/Layouts/AdminLayout.vue";
import LearningLayout from "@/Shared/Layouts/LearningLayout.vue";

import { ZiggyVue } from 'ziggy-js';

import mitt from 'mitt';

window.emitter = mitt();

import.meta.glob([
    '../img/**',
    '../fonts/**',
]);

createInertiaApp({
    title: title => title ? `${title} - NO Ransomware` : 'NO Ransomware',
    progress: {
        color: '#29d',
    },
    resolve: (name) => {
        let page = resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'));

        page.then(p => {
            if (name.startsWith('Auth/')) p.default.layout = AuthLayout;
            else if (name.startsWith('Learning/')) p.default.layout = LearningLayout;
            else p.default.layout = [AdminLayout]
        });

        return page;
    },
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .component("Link", Link)
            .component("Head", Head)
            .use(i18n)
            .use(Ziggy)
            .mount(el)
    },
});
