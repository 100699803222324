import {reactive} from "vue";

const state = reactive({
    type: 'alert',
    active: false,
    title: String,
    icon: null,
});

const open = (title = "", icon = null) => {
    state.title = title;
    state.icon = icon;
    state.active = true;
}

const close = () => state.active = false;


export default {
    get state() {
        return state;
    },
    open(title = "", icon = null) {
        return open(title, icon);
    },
    close() {
        return close();
    }
}
