<template>
  <Notification :messages="props.flash.message" />
  <slot />
</template>

<script setup>
import Notification from "@/Shared/Ui/Notification.vue";
const props = defineProps({
    flash: Object
});
</script>
