<template>
  <svg
    data-v-2469ac3b=""
    width="100%"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="h-14 w-auto"
    title="NO Ransomware"
  ><mask
    id="mask0_108_2"
    style="mask-type:alpha;"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="120"
    height="120"
  ><path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120ZM68 79V38H52V79H68Z"
    fill="#D31C1C"
  /></mask><g mask="url(#mask0_108_2)"><g id="binary"><path
    d="M16.22 17.5C16.22 17.2733 16.3033 17.0767 16.47 16.91C16.6367 16.7433 16.8333 16.66 17.06 16.66C17.2867 16.66 17.4833 16.7433 17.65 16.91C17.8167 17.0767 17.9 17.2733 17.9 17.5C17.9 17.7267 17.8167 17.9233 17.65 18.09C17.4833 18.2567 17.2867 18.34 17.06 18.34C16.8333 18.34 16.6367 18.2567 16.47 18.09C16.3033 17.9233 16.22 17.7267 16.22 17.5ZM14.48 16.72C14.48 15.8133 14.7067 15.11 15.16 14.61C15.62 14.11 16.2533 13.86 17.06 13.86C17.8667 13.86 18.4967 14.11 18.95 14.61C19.41 15.11 19.64 15.8133 19.64 16.72V18.28C19.64 19.2133 19.41 19.9233 18.95 20.41C18.4967 20.8967 17.8667 21.14 17.06 21.14C16.2533 21.14 15.62 20.8967 15.16 20.41C14.7067 19.9233 14.48 19.2133 14.48 18.28V16.72ZM17.06 19.94C17.3 19.94 17.5 19.9033 17.66 19.83C17.82 19.75 17.95 19.64 18.05 19.5C18.15 19.36 18.22 19.1933 18.26 19C18.3 18.8067 18.32 18.5933 18.32 18.36V16.64C18.32 16.42 18.2967 16.2133 18.25 16.02C18.2033 15.8267 18.13 15.66 18.03 15.52C17.93 15.38 17.8 15.27 17.64 15.19C17.48 15.1033 17.2867 15.06 17.06 15.06C16.8333 15.06 16.64 15.1033 16.48 15.19C16.32 15.27 16.19 15.38 16.09 15.52C15.99 15.66 15.9167 15.8267 15.87 16.02C15.8233 16.2133 15.8 16.42 15.8 16.64V18.36C15.8 18.5933 15.82 18.8067 15.86 19C15.9 19.1933 15.97 19.36 16.07 19.5C16.17 19.64 16.3 19.75 16.46 19.83C16.62 19.9033 16.82 19.94 17.06 19.94Z"
    fill="#5078E1"
  /><path
    d="M22.763 19.74V14.66H22.583L21.923 17.06H20.663V16.94L21.503 14H24.083V19.74H25.823V21H20.783V19.74H22.763Z"
    fill="#5078E1"
  /><path
    d="M28.4661 17.5C28.4661 17.2733 28.5494 17.0767 28.7161 16.91C28.8828 16.7433 29.0794 16.66 29.3061 16.66C29.5328 16.66 29.7294 16.7433 29.8961 16.91C30.0628 17.0767 30.1461 17.2733 30.1461 17.5C30.1461 17.7267 30.0628 17.9233 29.8961 18.09C29.7294 18.2567 29.5328 18.34 29.3061 18.34C29.0794 18.34 28.8828 18.2567 28.7161 18.09C28.5494 17.9233 28.4661 17.7267 28.4661 17.5ZM26.7261 16.72C26.7261 15.8133 26.9528 15.11 27.4061 14.61C27.8661 14.11 28.4994 13.86 29.3061 13.86C30.1128 13.86 30.7428 14.11 31.1961 14.61C31.6561 15.11 31.8861 15.8133 31.8861 16.72V18.28C31.8861 19.2133 31.6561 19.9233 31.1961 20.41C30.7428 20.8967 30.1128 21.14 29.3061 21.14C28.4994 21.14 27.8661 20.8967 27.4061 20.41C26.9528 19.9233 26.7261 19.2133 26.7261 18.28V16.72ZM29.3061 19.94C29.5461 19.94 29.7461 19.9033 29.9061 19.83C30.0661 19.75 30.1961 19.64 30.2961 19.5C30.3961 19.36 30.4661 19.1933 30.5061 19C30.5461 18.8067 30.5661 18.5933 30.5661 18.36V16.64C30.5661 16.42 30.5428 16.2133 30.4961 16.02C30.4494 15.8267 30.3761 15.66 30.2761 15.52C30.1761 15.38 30.0461 15.27 29.8861 15.19C29.7261 15.1033 29.5328 15.06 29.3061 15.06C29.0794 15.06 28.8861 15.1033 28.7261 15.19C28.5661 15.27 28.4361 15.38 28.3361 15.52C28.2361 15.66 28.1628 15.8267 28.1161 16.02C28.0694 16.2133 28.0461 16.42 28.0461 16.64V18.36C28.0461 18.5933 28.0661 18.8067 28.1061 19C28.1461 19.1933 28.2161 19.36 28.3161 19.5C28.4161 19.64 28.5461 19.75 28.7061 19.83C28.8661 19.9033 29.0661 19.94 29.3061 19.94Z"
    fill="#5078E1"
  /><path
    d="M34.5891 17.5C34.5891 17.2733 34.6725 17.0767 34.8391 16.91C35.0058 16.7433 35.2025 16.66 35.4291 16.66C35.6558 16.66 35.8525 16.7433 36.0191 16.91C36.1858 17.0767 36.2691 17.2733 36.2691 17.5C36.2691 17.7267 36.1858 17.9233 36.0191 18.09C35.8525 18.2567 35.6558 18.34 35.4291 18.34C35.2025 18.34 35.0058 18.2567 34.8391 18.09C34.6725 17.9233 34.5891 17.7267 34.5891 17.5ZM32.8491 16.72C32.8491 15.8133 33.0758 15.11 33.5291 14.61C33.9891 14.11 34.6225 13.86 35.4291 13.86C36.2358 13.86 36.8658 14.11 37.3191 14.61C37.7791 15.11 38.0091 15.8133 38.0091 16.72V18.28C38.0091 19.2133 37.7791 19.9233 37.3191 20.41C36.8658 20.8967 36.2358 21.14 35.4291 21.14C34.6225 21.14 33.9891 20.8967 33.5291 20.41C33.0758 19.9233 32.8491 19.2133 32.8491 18.28V16.72ZM35.4291 19.94C35.6691 19.94 35.8691 19.9033 36.0291 19.83C36.1891 19.75 36.3191 19.64 36.4191 19.5C36.5191 19.36 36.5891 19.1933 36.6291 19C36.6691 18.8067 36.6891 18.5933 36.6891 18.36V16.64C36.6891 16.42 36.6658 16.2133 36.6191 16.02C36.5725 15.8267 36.4991 15.66 36.3991 15.52C36.2991 15.38 36.1691 15.27 36.0091 15.19C35.8491 15.1033 35.6558 15.06 35.4291 15.06C35.2025 15.06 35.0091 15.1033 34.8491 15.19C34.6891 15.27 34.5591 15.38 34.4591 15.52C34.3591 15.66 34.2858 15.8267 34.2391 16.02C34.1925 16.2133 34.1691 16.42 34.1691 16.64V18.36C34.1691 18.5933 34.1891 18.8067 34.2291 19C34.2691 19.1933 34.3391 19.36 34.4391 19.5C34.5391 19.64 34.6691 19.75 34.8291 19.83C34.9891 19.9033 35.1891 19.94 35.4291 19.94Z"
    fill="#5078E1"
  /><path
    d="M41.1322 19.74V14.66H40.9522L40.2922 17.06H39.0322V16.94L39.8722 14H42.4522V19.74H44.1922V21H39.1522V19.74H41.1322Z"
    fill="#5078E1"
  /><path
    d="M47.2552 19.74V14.66H47.0752L46.4152 17.06H45.1552V16.94L45.9952 14H48.5752V19.74H50.3152V21H45.2752V19.74H47.2552Z"
    fill="#5078E1"
  /><path
    d="M53.3783 19.74V14.66H53.1983L52.5383 17.06H51.2783V16.94L52.1183 14H54.6983V19.74H56.4383V21H51.3983V19.74H53.3783Z"
    fill="#5078E1"
  /><path
    d="M59.0813 17.5C59.0813 17.2733 59.1647 17.0767 59.3313 16.91C59.498 16.7433 59.6947 16.66 59.9213 16.66C60.148 16.66 60.3447 16.7433 60.5113 16.91C60.678 17.0767 60.7613 17.2733 60.7613 17.5C60.7613 17.7267 60.678 17.9233 60.5113 18.09C60.3447 18.2567 60.148 18.34 59.9213 18.34C59.6947 18.34 59.498 18.2567 59.3313 18.09C59.1647 17.9233 59.0813 17.7267 59.0813 17.5ZM57.3413 16.72C57.3413 15.8133 57.568 15.11 58.0213 14.61C58.4813 14.11 59.1147 13.86 59.9213 13.86C60.728 13.86 61.358 14.11 61.8113 14.61C62.2713 15.11 62.5013 15.8133 62.5013 16.72V18.28C62.5013 19.2133 62.2713 19.9233 61.8113 20.41C61.358 20.8967 60.728 21.14 59.9213 21.14C59.1147 21.14 58.4813 20.8967 58.0213 20.41C57.568 19.9233 57.3413 19.2133 57.3413 18.28V16.72ZM59.9213 19.94C60.1613 19.94 60.3613 19.9033 60.5213 19.83C60.6813 19.75 60.8113 19.64 60.9113 19.5C61.0113 19.36 61.0813 19.1933 61.1213 19C61.1613 18.8067 61.1813 18.5933 61.1813 18.36V16.64C61.1813 16.42 61.158 16.2133 61.1113 16.02C61.0647 15.8267 60.9913 15.66 60.8913 15.52C60.7913 15.38 60.6613 15.27 60.5013 15.19C60.3413 15.1033 60.148 15.06 59.9213 15.06C59.6947 15.06 59.5013 15.1033 59.3413 15.19C59.1813 15.27 59.0513 15.38 58.9513 15.52C58.8513 15.66 58.778 15.8267 58.7313 16.02C58.6847 16.2133 58.6613 16.42 58.6613 16.64V18.36C58.6613 18.5933 58.6813 18.8067 58.7213 19C58.7613 19.1933 58.8313 19.36 58.9313 19.5C59.0313 19.64 59.1613 19.75 59.3213 19.83C59.4813 19.9033 59.6813 19.94 59.9213 19.94Z"
    fill="#5078E1"
  /><path
    d="M65.2044 17.5C65.2044 17.2733 65.2877 17.0767 65.4544 16.91C65.621 16.7433 65.8177 16.66 66.0444 16.66C66.271 16.66 66.4677 16.7433 66.6344 16.91C66.801 17.0767 66.8844 17.2733 66.8844 17.5C66.8844 17.7267 66.801 17.9233 66.6344 18.09C66.4677 18.2567 66.271 18.34 66.0444 18.34C65.8177 18.34 65.621 18.2567 65.4544 18.09C65.2877 17.9233 65.2044 17.7267 65.2044 17.5ZM63.4644 16.72C63.4644 15.8133 63.691 15.11 64.1444 14.61C64.6044 14.11 65.2377 13.86 66.0444 13.86C66.851 13.86 67.481 14.11 67.9344 14.61C68.3944 15.11 68.6244 15.8133 68.6244 16.72V18.28C68.6244 19.2133 68.3944 19.9233 67.9344 20.41C67.481 20.8967 66.851 21.14 66.0444 21.14C65.2377 21.14 64.6044 20.8967 64.1444 20.41C63.691 19.9233 63.4644 19.2133 63.4644 18.28V16.72ZM66.0444 19.94C66.2844 19.94 66.4844 19.9033 66.6444 19.83C66.8044 19.75 66.9344 19.64 67.0344 19.5C67.1344 19.36 67.2044 19.1933 67.2444 19C67.2844 18.8067 67.3044 18.5933 67.3044 18.36V16.64C67.3044 16.42 67.281 16.2133 67.2344 16.02C67.1877 15.8267 67.1144 15.66 67.0144 15.52C66.9144 15.38 66.7844 15.27 66.6244 15.19C66.4644 15.1033 66.271 15.06 66.0444 15.06C65.8177 15.06 65.6244 15.1033 65.4644 15.19C65.3044 15.27 65.1744 15.38 65.0744 15.52C64.9744 15.66 64.901 15.8267 64.8544 16.02C64.8077 16.2133 64.7844 16.42 64.7844 16.64V18.36C64.7844 18.5933 64.8044 18.8067 64.8444 19C64.8844 19.1933 64.9544 19.36 65.0544 19.5C65.1544 19.64 65.2844 19.75 65.4444 19.83C65.6044 19.9033 65.8044 19.94 66.0444 19.94Z"
    fill="#5078E1"
  /><path
    d="M71.7474 19.74V14.66H71.5674L70.9074 17.06H69.6474V16.94L70.4874 14H73.0674V19.74H74.8074V21H69.7674V19.74H71.7474Z"
    fill="#5078E1"
  /><path
    d="M77.4505 17.5C77.4505 17.2733 77.5338 17.0767 77.7005 16.91C77.8671 16.7433 78.0638 16.66 78.2905 16.66C78.5171 16.66 78.7138 16.7433 78.8805 16.91C79.0471 17.0767 79.1305 17.2733 79.1305 17.5C79.1305 17.7267 79.0471 17.9233 78.8805 18.09C78.7138 18.2567 78.5171 18.34 78.2905 18.34C78.0638 18.34 77.8671 18.2567 77.7005 18.09C77.5338 17.9233 77.4505 17.7267 77.4505 17.5ZM75.7105 16.72C75.7105 15.8133 75.9371 15.11 76.3905 14.61C76.8505 14.11 77.4838 13.86 78.2905 13.86C79.0971 13.86 79.7271 14.11 80.1805 14.61C80.6405 15.11 80.8705 15.8133 80.8705 16.72V18.28C80.8705 19.2133 80.6405 19.9233 80.1805 20.41C79.7271 20.8967 79.0971 21.14 78.2905 21.14C77.4838 21.14 76.8505 20.8967 76.3905 20.41C75.9371 19.9233 75.7105 19.2133 75.7105 18.28V16.72ZM78.2905 19.94C78.5305 19.94 78.7305 19.9033 78.8905 19.83C79.0505 19.75 79.1805 19.64 79.2805 19.5C79.3805 19.36 79.4505 19.1933 79.4905 19C79.5305 18.8067 79.5505 18.5933 79.5505 18.36V16.64C79.5505 16.42 79.5271 16.2133 79.4805 16.02C79.4338 15.8267 79.3605 15.66 79.2605 15.52C79.1605 15.38 79.0305 15.27 78.8705 15.19C78.7105 15.1033 78.5171 15.06 78.2905 15.06C78.0638 15.06 77.8705 15.1033 77.7105 15.19C77.5505 15.27 77.4205 15.38 77.3205 15.52C77.2205 15.66 77.1471 15.8267 77.1005 16.02C77.0538 16.2133 77.0305 16.42 77.0305 16.64V18.36C77.0305 18.5933 77.0505 18.8067 77.0905 19C77.1305 19.1933 77.2005 19.36 77.3005 19.5C77.4005 19.64 77.5305 19.75 77.6905 19.83C77.8505 19.9033 78.0505 19.94 78.2905 19.94Z"
    fill="#5078E1"
  /><path
    d="M83.5735 17.5C83.5735 17.2733 83.6568 17.0767 83.8235 16.91C83.9902 16.7433 84.1868 16.66 84.4135 16.66C84.6402 16.66 84.8368 16.7433 85.0035 16.91C85.1702 17.0767 85.2535 17.2733 85.2535 17.5C85.2535 17.7267 85.1702 17.9233 85.0035 18.09C84.8368 18.2567 84.6402 18.34 84.4135 18.34C84.1868 18.34 83.9902 18.2567 83.8235 18.09C83.6568 17.9233 83.5735 17.7267 83.5735 17.5ZM81.8335 16.72C81.8335 15.8133 82.0602 15.11 82.5135 14.61C82.9735 14.11 83.6068 13.86 84.4135 13.86C85.2202 13.86 85.8502 14.11 86.3035 14.61C86.7635 15.11 86.9935 15.8133 86.9935 16.72V18.28C86.9935 19.2133 86.7635 19.9233 86.3035 20.41C85.8502 20.8967 85.2202 21.14 84.4135 21.14C83.6068 21.14 82.9735 20.8967 82.5135 20.41C82.0602 19.9233 81.8335 19.2133 81.8335 18.28V16.72ZM84.4135 19.94C84.6535 19.94 84.8535 19.9033 85.0135 19.83C85.1735 19.75 85.3035 19.64 85.4035 19.5C85.5035 19.36 85.5735 19.1933 85.6135 19C85.6535 18.8067 85.6735 18.5933 85.6735 18.36V16.64C85.6735 16.42 85.6502 16.2133 85.6035 16.02C85.5568 15.8267 85.4835 15.66 85.3835 15.52C85.2835 15.38 85.1535 15.27 84.9935 15.19C84.8335 15.1033 84.6402 15.06 84.4135 15.06C84.1868 15.06 83.9935 15.1033 83.8335 15.19C83.6735 15.27 83.5435 15.38 83.4435 15.52C83.3435 15.66 83.2702 15.8267 83.2235 16.02C83.1768 16.2133 83.1535 16.42 83.1535 16.64V18.36C83.1535 18.5933 83.1735 18.8067 83.2135 19C83.2535 19.1933 83.3235 19.36 83.4235 19.5C83.5235 19.64 83.6535 19.75 83.8135 19.83C83.9735 19.9033 84.1735 19.94 84.4135 19.94Z"
    fill="#5078E1"
  /><path
    d="M90.1166 19.74V14.66H89.9366L89.2766 17.06H88.0166V16.94L88.8566 14H91.4366V19.74H93.1766V21H88.1366V19.74H90.1166Z"
    fill="#5078E1"
  /><path
    d="M96.2396 19.74V14.66H96.0596L95.3996 17.06H94.1396V16.94L94.9796 14H97.5596V19.74H99.2996V21H94.2596V19.74H96.2396Z"
    fill="#5078E1"
  /><path
    d="M102.363 19.74V14.66H102.183L101.523 17.06H100.263V16.94L101.103 14H103.683V19.74H105.423V21H100.383V19.74H102.363Z"
    fill="#5078E1"
  /><path
    d="M108.486 19.74V14.66H108.306L107.646 17.06H106.386V16.94L107.226 14H109.806V19.74H111.546V21H106.506V19.74H108.486Z"
    fill="#5078E1"
  /><path
    d="M114.189 17.5C114.189 17.2733 114.272 17.0767 114.439 16.91C114.605 16.7433 114.802 16.66 115.029 16.66C115.255 16.66 115.452 16.7433 115.619 16.91C115.785 17.0767 115.869 17.2733 115.869 17.5C115.869 17.7267 115.785 17.9233 115.619 18.09C115.452 18.2567 115.255 18.34 115.029 18.34C114.802 18.34 114.605 18.2567 114.439 18.09C114.272 17.9233 114.189 17.7267 114.189 17.5ZM112.449 16.72C112.449 15.8133 112.675 15.11 113.129 14.61C113.589 14.11 114.222 13.86 115.029 13.86C115.835 13.86 116.465 14.11 116.919 14.61C117.379 15.11 117.609 15.8133 117.609 16.72V18.28C117.609 19.2133 117.379 19.9233 116.919 20.41C116.465 20.8967 115.835 21.14 115.029 21.14C114.222 21.14 113.589 20.8967 113.129 20.41C112.675 19.9233 112.449 19.2133 112.449 18.28V16.72ZM115.029 19.94C115.269 19.94 115.469 19.9033 115.629 19.83C115.789 19.75 115.919 19.64 116.019 19.5C116.119 19.36 116.189 19.1933 116.229 19C116.269 18.8067 116.289 18.5933 116.289 18.36V16.64C116.289 16.42 116.265 16.2133 116.219 16.02C116.172 15.8267 116.099 15.66 115.999 15.52C115.899 15.38 115.769 15.27 115.609 15.19C115.449 15.1033 115.255 15.06 115.029 15.06C114.802 15.06 114.609 15.1033 114.449 15.19C114.289 15.27 114.159 15.38 114.059 15.52C113.959 15.66 113.885 15.8267 113.839 16.02C113.792 16.2133 113.769 16.42 113.769 16.64V18.36C113.769 18.5933 113.789 18.8067 113.829 19C113.869 19.1933 113.939 19.36 114.039 19.5C114.139 19.64 114.269 19.75 114.429 19.83C114.589 19.9033 114.789 19.94 115.029 19.94Z"
    fill="#5078E1"
  /><path
    d="M120.312 17.5C120.312 17.2733 120.395 17.0767 120.562 16.91C120.728 16.7433 120.925 16.66 121.152 16.66C121.378 16.66 121.575 16.7433 121.742 16.91C121.908 17.0767 121.992 17.2733 121.992 17.5C121.992 17.7267 121.908 17.9233 121.742 18.09C121.575 18.2567 121.378 18.34 121.152 18.34C120.925 18.34 120.728 18.2567 120.562 18.09C120.395 17.9233 120.312 17.7267 120.312 17.5ZM118.572 16.72C118.572 15.8133 118.798 15.11 119.252 14.61C119.712 14.11 120.345 13.86 121.152 13.86C121.958 13.86 122.588 14.11 123.042 14.61C123.502 15.11 123.732 15.8133 123.732 16.72V18.28C123.732 19.2133 123.502 19.9233 123.042 20.41C122.588 20.8967 121.958 21.14 121.152 21.14C120.345 21.14 119.712 20.8967 119.252 20.41C118.798 19.9233 118.572 19.2133 118.572 18.28V16.72ZM121.152 19.94C121.392 19.94 121.592 19.9033 121.752 19.83C121.912 19.75 122.042 19.64 122.142 19.5C122.242 19.36 122.312 19.1933 122.352 19C122.392 18.8067 122.412 18.5933 122.412 18.36V16.64C122.412 16.42 122.388 16.2133 122.342 16.02C122.295 15.8267 122.222 15.66 122.122 15.52C122.022 15.38 121.892 15.27 121.732 15.19C121.572 15.1033 121.378 15.06 121.152 15.06C120.925 15.06 120.732 15.1033 120.572 15.19C120.412 15.27 120.282 15.38 120.182 15.52C120.082 15.66 120.008 15.8267 119.962 16.02C119.915 16.2133 119.892 16.42 119.892 16.64V18.36C119.892 18.5933 119.912 18.8067 119.952 19C119.992 19.1933 120.062 19.36 120.162 19.5C120.262 19.64 120.392 19.75 120.552 19.83C120.712 19.9033 120.912 19.94 121.152 19.94Z"
    fill="#5078E1"
  /><path
    d="M126.855 19.74V14.66H126.675L126.015 17.06H124.755V16.94L125.595 14H128.175V19.74H129.915V21H124.875V19.74H126.855Z"
    fill="#5078E1"
  /><path
    d="M132.558 17.5C132.558 17.2733 132.641 17.0767 132.808 16.91C132.975 16.7433 133.171 16.66 133.398 16.66C133.625 16.66 133.821 16.7433 133.988 16.91C134.155 17.0767 134.238 17.2733 134.238 17.5C134.238 17.7267 134.155 17.9233 133.988 18.09C133.821 18.2567 133.625 18.34 133.398 18.34C133.171 18.34 132.975 18.2567 132.808 18.09C132.641 17.9233 132.558 17.7267 132.558 17.5ZM130.818 16.72C130.818 15.8133 131.045 15.11 131.498 14.61C131.958 14.11 132.591 13.86 133.398 13.86C134.205 13.86 134.835 14.11 135.288 14.61C135.748 15.11 135.978 15.8133 135.978 16.72V18.28C135.978 19.2133 135.748 19.9233 135.288 20.41C134.835 20.8967 134.205 21.14 133.398 21.14C132.591 21.14 131.958 20.8967 131.498 20.41C131.045 19.9233 130.818 19.2133 130.818 18.28V16.72ZM133.398 19.94C133.638 19.94 133.838 19.9033 133.998 19.83C134.158 19.75 134.288 19.64 134.388 19.5C134.488 19.36 134.558 19.1933 134.598 19C134.638 18.8067 134.658 18.5933 134.658 18.36V16.64C134.658 16.42 134.635 16.2133 134.588 16.02C134.541 15.8267 134.468 15.66 134.368 15.52C134.268 15.38 134.138 15.27 133.978 15.19C133.818 15.1033 133.625 15.06 133.398 15.06C133.171 15.06 132.978 15.1033 132.818 15.19C132.658 15.27 132.528 15.38 132.428 15.52C132.328 15.66 132.255 15.8267 132.208 16.02C132.161 16.2133 132.138 16.42 132.138 16.64V18.36C132.138 18.5933 132.158 18.8067 132.198 19C132.238 19.1933 132.308 19.36 132.408 19.5C132.508 19.64 132.638 19.75 132.798 19.83C132.958 19.9033 133.158 19.94 133.398 19.94Z"
    fill="#5078E1"
  /><path
    d="M138.681 17.5C138.681 17.2733 138.764 17.0767 138.931 16.91C139.098 16.7433 139.294 16.66 139.521 16.66C139.748 16.66 139.944 16.7433 140.111 16.91C140.278 17.0767 140.361 17.2733 140.361 17.5C140.361 17.7267 140.278 17.9233 140.111 18.09C139.944 18.2567 139.748 18.34 139.521 18.34C139.294 18.34 139.098 18.2567 138.931 18.09C138.764 17.9233 138.681 17.7267 138.681 17.5ZM136.941 16.72C136.941 15.8133 137.168 15.11 137.621 14.61C138.081 14.11 138.714 13.86 139.521 13.86C140.328 13.86 140.958 14.11 141.411 14.61C141.871 15.11 142.101 15.8133 142.101 16.72V18.28C142.101 19.2133 141.871 19.9233 141.411 20.41C140.958 20.8967 140.328 21.14 139.521 21.14C138.714 21.14 138.081 20.8967 137.621 20.41C137.168 19.9233 136.941 19.2133 136.941 18.28V16.72ZM139.521 19.94C139.761 19.94 139.961 19.9033 140.121 19.83C140.281 19.75 140.411 19.64 140.511 19.5C140.611 19.36 140.681 19.1933 140.721 19C140.761 18.8067 140.781 18.5933 140.781 18.36V16.64C140.781 16.42 140.758 16.2133 140.711 16.02C140.664 15.8267 140.591 15.66 140.491 15.52C140.391 15.38 140.261 15.27 140.101 15.19C139.941 15.1033 139.748 15.06 139.521 15.06C139.294 15.06 139.101 15.1033 138.941 15.19C138.781 15.27 138.651 15.38 138.551 15.52C138.451 15.66 138.378 15.8267 138.331 16.02C138.284 16.2133 138.261 16.42 138.261 16.64V18.36C138.261 18.5933 138.281 18.8067 138.321 19C138.361 19.1933 138.431 19.36 138.531 19.5C138.631 19.64 138.761 19.75 138.921 19.83C139.081 19.9033 139.281 19.94 139.521 19.94Z"
    fill="#5078E1"
  /><path
    d="M144.804 17.5C144.804 17.2733 144.887 17.0767 145.054 16.91C145.221 16.7433 145.417 16.66 145.644 16.66C145.871 16.66 146.067 16.7433 146.234 16.91C146.401 17.0767 146.484 17.2733 146.484 17.5C146.484 17.7267 146.401 17.9233 146.234 18.09C146.067 18.2567 145.871 18.34 145.644 18.34C145.417 18.34 145.221 18.2567 145.054 18.09C144.887 17.9233 144.804 17.7267 144.804 17.5ZM143.064 16.72C143.064 15.8133 143.291 15.11 143.744 14.61C144.204 14.11 144.837 13.86 145.644 13.86C146.451 13.86 147.081 14.11 147.534 14.61C147.994 15.11 148.224 15.8133 148.224 16.72V18.28C148.224 19.2133 147.994 19.9233 147.534 20.41C147.081 20.8967 146.451 21.14 145.644 21.14C144.837 21.14 144.204 20.8967 143.744 20.41C143.291 19.9233 143.064 19.2133 143.064 18.28V16.72ZM145.644 19.94C145.884 19.94 146.084 19.9033 146.244 19.83C146.404 19.75 146.534 19.64 146.634 19.5C146.734 19.36 146.804 19.1933 146.844 19C146.884 18.8067 146.904 18.5933 146.904 18.36V16.64C146.904 16.42 146.881 16.2133 146.834 16.02C146.787 15.8267 146.714 15.66 146.614 15.52C146.514 15.38 146.384 15.27 146.224 15.19C146.064 15.1033 145.871 15.06 145.644 15.06C145.417 15.06 145.224 15.1033 145.064 15.19C144.904 15.27 144.774 15.38 144.674 15.52C144.574 15.66 144.501 15.8267 144.454 16.02C144.407 16.2133 144.384 16.42 144.384 16.64V18.36C144.384 18.5933 144.404 18.8067 144.444 19C144.484 19.1933 144.554 19.36 144.654 19.5C144.754 19.64 144.884 19.75 145.044 19.83C145.204 19.9033 145.404 19.94 145.644 19.94Z"
    fill="#5078E1"
  /><path
    d="M150.927 17.5C150.927 17.2733 151.01 17.0767 151.177 16.91C151.344 16.7433 151.54 16.66 151.767 16.66C151.994 16.66 152.19 16.7433 152.357 16.91C152.524 17.0767 152.607 17.2733 152.607 17.5C152.607 17.7267 152.524 17.9233 152.357 18.09C152.19 18.2567 151.994 18.34 151.767 18.34C151.54 18.34 151.344 18.2567 151.177 18.09C151.01 17.9233 150.927 17.7267 150.927 17.5ZM149.187 16.72C149.187 15.8133 149.414 15.11 149.867 14.61C150.327 14.11 150.96 13.86 151.767 13.86C152.574 13.86 153.204 14.11 153.657 14.61C154.117 15.11 154.347 15.8133 154.347 16.72V18.28C154.347 19.2133 154.117 19.9233 153.657 20.41C153.204 20.8967 152.574 21.14 151.767 21.14C150.96 21.14 150.327 20.8967 149.867 20.41C149.414 19.9233 149.187 19.2133 149.187 18.28V16.72ZM151.767 19.94C152.007 19.94 152.207 19.9033 152.367 19.83C152.527 19.75 152.657 19.64 152.757 19.5C152.857 19.36 152.927 19.1933 152.967 19C153.007 18.8067 153.027 18.5933 153.027 18.36V16.64C153.027 16.42 153.004 16.2133 152.957 16.02C152.91 15.8267 152.837 15.66 152.737 15.52C152.637 15.38 152.507 15.27 152.347 15.19C152.187 15.1033 151.994 15.06 151.767 15.06C151.54 15.06 151.347 15.1033 151.187 15.19C151.027 15.27 150.897 15.38 150.797 15.52C150.697 15.66 150.624 15.8267 150.577 16.02C150.53 16.2133 150.507 16.42 150.507 16.64V18.36C150.507 18.5933 150.527 18.8067 150.567 19C150.607 19.1933 150.677 19.36 150.777 19.5C150.877 19.64 151.007 19.75 151.167 19.83C151.327 19.9033 151.527 19.94 151.767 19.94Z"
    fill="#5078E1"
  /><path
    d="M157.05 17.5C157.05 17.2733 157.133 17.0767 157.3 16.91C157.467 16.7433 157.663 16.66 157.89 16.66C158.117 16.66 158.313 16.7433 158.48 16.91C158.647 17.0767 158.73 17.2733 158.73 17.5C158.73 17.7267 158.647 17.9233 158.48 18.09C158.313 18.2567 158.117 18.34 157.89 18.34C157.663 18.34 157.467 18.2567 157.3 18.09C157.133 17.9233 157.05 17.7267 157.05 17.5ZM155.31 16.72C155.31 15.8133 155.537 15.11 155.99 14.61C156.45 14.11 157.083 13.86 157.89 13.86C158.697 13.86 159.327 14.11 159.78 14.61C160.24 15.11 160.47 15.8133 160.47 16.72V18.28C160.47 19.2133 160.24 19.9233 159.78 20.41C159.327 20.8967 158.697 21.14 157.89 21.14C157.083 21.14 156.45 20.8967 155.99 20.41C155.537 19.9233 155.31 19.2133 155.31 18.28V16.72ZM157.89 19.94C158.13 19.94 158.33 19.9033 158.49 19.83C158.65 19.75 158.78 19.64 158.88 19.5C158.98 19.36 159.05 19.1933 159.09 19C159.13 18.8067 159.15 18.5933 159.15 18.36V16.64C159.15 16.42 159.127 16.2133 159.08 16.02C159.033 15.8267 158.96 15.66 158.86 15.52C158.76 15.38 158.63 15.27 158.47 15.19C158.31 15.1033 158.117 15.06 157.89 15.06C157.663 15.06 157.47 15.1033 157.31 15.19C157.15 15.27 157.02 15.38 156.92 15.52C156.82 15.66 156.747 15.8267 156.7 16.02C156.653 16.2133 156.63 16.42 156.63 16.64V18.36C156.63 18.5933 156.65 18.8067 156.69 19C156.73 19.1933 156.8 19.36 156.9 19.5C157 19.64 157.13 19.75 157.29 19.83C157.45 19.9033 157.65 19.94 157.89 19.94Z"
    fill="#5078E1"
  /><path
    d="M163.173 17.5C163.173 17.2733 163.256 17.0767 163.423 16.91C163.59 16.7433 163.786 16.66 164.013 16.66C164.24 16.66 164.436 16.7433 164.603 16.91C164.77 17.0767 164.853 17.2733 164.853 17.5C164.853 17.7267 164.77 17.9233 164.603 18.09C164.436 18.2567 164.24 18.34 164.013 18.34C163.786 18.34 163.59 18.2567 163.423 18.09C163.256 17.9233 163.173 17.7267 163.173 17.5ZM161.433 16.72C161.433 15.8133 161.66 15.11 162.113 14.61C162.573 14.11 163.206 13.86 164.013 13.86C164.82 13.86 165.45 14.11 165.903 14.61C166.363 15.11 166.593 15.8133 166.593 16.72V18.28C166.593 19.2133 166.363 19.9233 165.903 20.41C165.45 20.8967 164.82 21.14 164.013 21.14C163.206 21.14 162.573 20.8967 162.113 20.41C161.66 19.9233 161.433 19.2133 161.433 18.28V16.72ZM164.013 19.94C164.253 19.94 164.453 19.9033 164.613 19.83C164.773 19.75 164.903 19.64 165.003 19.5C165.103 19.36 165.173 19.1933 165.213 19C165.253 18.8067 165.273 18.5933 165.273 18.36V16.64C165.273 16.42 165.25 16.2133 165.203 16.02C165.156 15.8267 165.083 15.66 164.983 15.52C164.883 15.38 164.753 15.27 164.593 15.19C164.433 15.1033 164.24 15.06 164.013 15.06C163.786 15.06 163.593 15.1033 163.433 15.19C163.273 15.27 163.143 15.38 163.043 15.52C162.943 15.66 162.87 15.8267 162.823 16.02C162.776 16.2133 162.753 16.42 162.753 16.64V18.36C162.753 18.5933 162.773 18.8067 162.813 19C162.853 19.1933 162.923 19.36 163.023 19.5C163.123 19.64 163.253 19.75 163.413 19.83C163.573 19.9033 163.773 19.94 164.013 19.94Z"
    fill="#5078E1"
  /><path
    d="M169.716 19.74V14.66H169.536L168.876 17.06H167.616V16.94L168.456 14H171.036V19.74H172.776V21H167.736V19.74H169.716Z"
    fill="#5078E1"
  /><path
    d="M175.419 17.5C175.419 17.2733 175.503 17.0767 175.669 16.91C175.836 16.7433 176.033 16.66 176.259 16.66C176.486 16.66 176.683 16.7433 176.849 16.91C177.016 17.0767 177.099 17.2733 177.099 17.5C177.099 17.7267 177.016 17.9233 176.849 18.09C176.683 18.2567 176.486 18.34 176.259 18.34C176.033 18.34 175.836 18.2567 175.669 18.09C175.503 17.9233 175.419 17.7267 175.419 17.5ZM173.679 16.72C173.679 15.8133 173.906 15.11 174.359 14.61C174.819 14.11 175.453 13.86 176.259 13.86C177.066 13.86 177.696 14.11 178.149 14.61C178.609 15.11 178.839 15.8133 178.839 16.72V18.28C178.839 19.2133 178.609 19.9233 178.149 20.41C177.696 20.8967 177.066 21.14 176.259 21.14C175.453 21.14 174.819 20.8967 174.359 20.41C173.906 19.9233 173.679 19.2133 173.679 18.28V16.72ZM176.259 19.94C176.499 19.94 176.699 19.9033 176.859 19.83C177.019 19.75 177.149 19.64 177.249 19.5C177.349 19.36 177.419 19.1933 177.459 19C177.499 18.8067 177.519 18.5933 177.519 18.36V16.64C177.519 16.42 177.496 16.2133 177.449 16.02C177.403 15.8267 177.329 15.66 177.229 15.52C177.129 15.38 176.999 15.27 176.839 15.19C176.679 15.1033 176.486 15.06 176.259 15.06C176.033 15.06 175.839 15.1033 175.679 15.19C175.519 15.27 175.389 15.38 175.289 15.52C175.189 15.66 175.116 15.8267 175.069 16.02C175.023 16.2133 174.999 16.42 174.999 16.64V18.36C174.999 18.5933 175.019 18.8067 175.059 19C175.099 19.1933 175.169 19.36 175.269 19.5C175.369 19.64 175.499 19.75 175.659 19.83C175.819 19.9033 176.019 19.94 176.259 19.94Z"
    fill="#5078E1"
  /><path
    d="M181.962 19.74V14.66H181.782L181.122 17.06H179.862V16.94L180.702 14H183.282V19.74H185.022V21H179.982V19.74H181.962Z"
    fill="#5078E1"
  /><path
    d="M187.665 17.5C187.665 17.2733 187.749 17.0767 187.915 16.91C188.082 16.7433 188.279 16.66 188.505 16.66C188.732 16.66 188.929 16.7433 189.095 16.91C189.262 17.0767 189.345 17.2733 189.345 17.5C189.345 17.7267 189.262 17.9233 189.095 18.09C188.929 18.2567 188.732 18.34 188.505 18.34C188.279 18.34 188.082 18.2567 187.915 18.09C187.749 17.9233 187.665 17.7267 187.665 17.5ZM185.925 16.72C185.925 15.8133 186.152 15.11 186.605 14.61C187.065 14.11 187.699 13.86 188.505 13.86C189.312 13.86 189.942 14.11 190.395 14.61C190.855 15.11 191.085 15.8133 191.085 16.72V18.28C191.085 19.2133 190.855 19.9233 190.395 20.41C189.942 20.8967 189.312 21.14 188.505 21.14C187.699 21.14 187.065 20.8967 186.605 20.41C186.152 19.9233 185.925 19.2133 185.925 18.28V16.72ZM188.505 19.94C188.745 19.94 188.945 19.9033 189.105 19.83C189.265 19.75 189.395 19.64 189.495 19.5C189.595 19.36 189.665 19.1933 189.705 19C189.745 18.8067 189.765 18.5933 189.765 18.36V16.64C189.765 16.42 189.742 16.2133 189.695 16.02C189.649 15.8267 189.575 15.66 189.475 15.52C189.375 15.38 189.245 15.27 189.085 15.19C188.925 15.1033 188.732 15.06 188.505 15.06C188.279 15.06 188.085 15.1033 187.925 15.19C187.765 15.27 187.635 15.38 187.535 15.52C187.435 15.66 187.362 15.8267 187.315 16.02C187.269 16.2133 187.245 16.42 187.245 16.64V18.36C187.245 18.5933 187.265 18.8067 187.305 19C187.345 19.1933 187.415 19.36 187.515 19.5C187.615 19.64 187.745 19.75 187.905 19.83C188.065 19.9033 188.265 19.94 188.505 19.94Z"
    fill="#5078E1"
  /><path
    d="M193.788 17.5C193.788 17.2733 193.872 17.0767 194.038 16.91C194.205 16.7433 194.402 16.66 194.628 16.66C194.855 16.66 195.052 16.7433 195.218 16.91C195.385 17.0767 195.468 17.2733 195.468 17.5C195.468 17.7267 195.385 17.9233 195.218 18.09C195.052 18.2567 194.855 18.34 194.628 18.34C194.402 18.34 194.205 18.2567 194.038 18.09C193.872 17.9233 193.788 17.7267 193.788 17.5ZM192.048 16.72C192.048 15.8133 192.275 15.11 192.728 14.61C193.188 14.11 193.822 13.86 194.628 13.86C195.435 13.86 196.065 14.11 196.518 14.61C196.978 15.11 197.208 15.8133 197.208 16.72V18.28C197.208 19.2133 196.978 19.9233 196.518 20.41C196.065 20.8967 195.435 21.14 194.628 21.14C193.822 21.14 193.188 20.8967 192.728 20.41C192.275 19.9233 192.048 19.2133 192.048 18.28V16.72ZM194.628 19.94C194.868 19.94 195.068 19.9033 195.228 19.83C195.388 19.75 195.518 19.64 195.618 19.5C195.718 19.36 195.788 19.1933 195.828 19C195.868 18.8067 195.888 18.5933 195.888 18.36V16.64C195.888 16.42 195.865 16.2133 195.818 16.02C195.772 15.8267 195.698 15.66 195.598 15.52C195.498 15.38 195.368 15.27 195.208 15.19C195.048 15.1033 194.855 15.06 194.628 15.06C194.402 15.06 194.208 15.1033 194.048 15.19C193.888 15.27 193.758 15.38 193.658 15.52C193.558 15.66 193.485 15.8267 193.438 16.02C193.392 16.2133 193.368 16.42 193.368 16.64V18.36C193.368 18.5933 193.388 18.8067 193.428 19C193.468 19.1933 193.538 19.36 193.638 19.5C193.738 19.64 193.868 19.75 194.028 19.83C194.188 19.9033 194.388 19.94 194.628 19.94Z"
    fill="#5078E1"
  /><path
    d="M200.331 19.74V14.66H200.151L199.491 17.06H198.231V16.94L199.071 14H201.651V19.74H203.391V21H198.351V19.74H200.331Z"
    fill="#5078E1"
  /><path
    d="M206.034 17.5C206.034 17.2733 206.118 17.0767 206.284 16.91C206.451 16.7433 206.648 16.66 206.874 16.66C207.101 16.66 207.298 16.7433 207.464 16.91C207.631 17.0767 207.714 17.2733 207.714 17.5C207.714 17.7267 207.631 17.9233 207.464 18.09C207.298 18.2567 207.101 18.34 206.874 18.34C206.648 18.34 206.451 18.2567 206.284 18.09C206.118 17.9233 206.034 17.7267 206.034 17.5ZM204.294 16.72C204.294 15.8133 204.521 15.11 204.974 14.61C205.434 14.11 206.068 13.86 206.874 13.86C207.681 13.86 208.311 14.11 208.764 14.61C209.224 15.11 209.454 15.8133 209.454 16.72V18.28C209.454 19.2133 209.224 19.9233 208.764 20.41C208.311 20.8967 207.681 21.14 206.874 21.14C206.068 21.14 205.434 20.8967 204.974 20.41C204.521 19.9233 204.294 19.2133 204.294 18.28V16.72ZM206.874 19.94C207.114 19.94 207.314 19.9033 207.474 19.83C207.634 19.75 207.764 19.64 207.864 19.5C207.964 19.36 208.034 19.1933 208.074 19C208.114 18.8067 208.134 18.5933 208.134 18.36V16.64C208.134 16.42 208.111 16.2133 208.064 16.02C208.018 15.8267 207.944 15.66 207.844 15.52C207.744 15.38 207.614 15.27 207.454 15.19C207.294 15.1033 207.101 15.06 206.874 15.06C206.648 15.06 206.454 15.1033 206.294 15.19C206.134 15.27 206.004 15.38 205.904 15.52C205.804 15.66 205.731 15.8267 205.684 16.02C205.638 16.2133 205.614 16.42 205.614 16.64V18.36C205.614 18.5933 205.634 18.8067 205.674 19C205.714 19.1933 205.784 19.36 205.884 19.5C205.984 19.64 206.114 19.75 206.274 19.83C206.434 19.9033 206.634 19.94 206.874 19.94Z"
    fill="#5078E1"
  /><path
    d="M212.158 17.5C212.158 17.2733 212.241 17.0767 212.408 16.91C212.574 16.7433 212.771 16.66 212.997 16.66C213.224 16.66 213.421 16.7433 213.587 16.91C213.754 17.0767 213.837 17.2733 213.837 17.5C213.837 17.7267 213.754 17.9233 213.587 18.09C213.421 18.2567 213.224 18.34 212.997 18.34C212.771 18.34 212.574 18.2567 212.408 18.09C212.241 17.9233 212.158 17.7267 212.158 17.5ZM210.417 16.72C210.417 15.8133 210.644 15.11 211.097 14.61C211.557 14.11 212.191 13.86 212.997 13.86C213.804 13.86 214.434 14.11 214.887 14.61C215.347 15.11 215.577 15.8133 215.577 16.72V18.28C215.577 19.2133 215.347 19.9233 214.887 20.41C214.434 20.8967 213.804 21.14 212.997 21.14C212.191 21.14 211.557 20.8967 211.097 20.41C210.644 19.9233 210.417 19.2133 210.417 18.28V16.72ZM212.997 19.94C213.237 19.94 213.437 19.9033 213.597 19.83C213.757 19.75 213.888 19.64 213.988 19.5C214.088 19.36 214.157 19.1933 214.197 19C214.237 18.8067 214.257 18.5933 214.257 18.36V16.64C214.257 16.42 214.234 16.2133 214.188 16.02C214.141 15.8267 214.067 15.66 213.967 15.52C213.867 15.38 213.737 15.27 213.577 15.19C213.417 15.1033 213.224 15.06 212.997 15.06C212.771 15.06 212.577 15.1033 212.417 15.19C212.257 15.27 212.127 15.38 212.027 15.52C211.927 15.66 211.854 15.8267 211.807 16.02C211.761 16.2133 211.738 16.42 211.738 16.64V18.36C211.738 18.5933 211.758 18.8067 211.798 19C211.838 19.1933 211.907 19.36 212.007 19.5C212.107 19.64 212.237 19.75 212.397 19.83C212.557 19.9033 212.757 19.94 212.997 19.94Z"
    fill="#5078E1"
  /><path
    d="M218.701 19.74V14.66H218.521L217.861 17.06H216.601V16.94L217.441 14H220.021V19.74H221.761V21H216.721V19.74H218.701Z"
    fill="#5078E1"
  /><path
    d="M224.824 19.74V14.66H224.644L223.984 17.06H222.724V16.94L223.564 14H226.144V19.74H227.884V21H222.844V19.74H224.824Z"
    fill="#5078E1"
  /><path
    d="M230.527 17.5C230.527 17.2733 230.61 17.0767 230.777 16.91C230.943 16.7433 231.14 16.66 231.367 16.66C231.593 16.66 231.79 16.7433 231.957 16.91C232.123 17.0767 232.207 17.2733 232.207 17.5C232.207 17.7267 232.123 17.9233 231.957 18.09C231.79 18.2567 231.593 18.34 231.367 18.34C231.14 18.34 230.943 18.2567 230.777 18.09C230.61 17.9233 230.527 17.7267 230.527 17.5ZM228.787 16.72C228.787 15.8133 229.013 15.11 229.467 14.61C229.927 14.11 230.56 13.86 231.367 13.86C232.173 13.86 232.803 14.11 233.257 14.61C233.717 15.11 233.947 15.8133 233.947 16.72V18.28C233.947 19.2133 233.717 19.9233 233.257 20.41C232.803 20.8967 232.173 21.14 231.367 21.14C230.56 21.14 229.927 20.8967 229.467 20.41C229.013 19.9233 228.787 19.2133 228.787 18.28V16.72ZM231.367 19.94C231.607 19.94 231.807 19.9033 231.967 19.83C232.127 19.75 232.257 19.64 232.357 19.5C232.457 19.36 232.527 19.1933 232.567 19C232.607 18.8067 232.627 18.5933 232.627 18.36V16.64C232.627 16.42 232.603 16.2133 232.557 16.02C232.51 15.8267 232.437 15.66 232.337 15.52C232.237 15.38 232.107 15.27 231.947 15.19C231.787 15.1033 231.593 15.06 231.367 15.06C231.14 15.06 230.947 15.1033 230.787 15.19C230.627 15.27 230.497 15.38 230.397 15.52C230.297 15.66 230.223 15.8267 230.177 16.02C230.13 16.2133 230.107 16.42 230.107 16.64V18.36C230.107 18.5933 230.127 18.8067 230.167 19C230.207 19.1933 230.277 19.36 230.377 19.5C230.477 19.64 230.607 19.75 230.767 19.83C230.927 19.9033 231.127 19.94 231.367 19.94Z"
    fill="#5078E1"
  /><path
    d="M236.65 17.5C236.65 17.2733 236.733 17.0767 236.9 16.91C237.066 16.7433 237.263 16.66 237.49 16.66C237.716 16.66 237.913 16.7433 238.08 16.91C238.246 17.0767 238.33 17.2733 238.33 17.5C238.33 17.7267 238.246 17.9233 238.08 18.09C237.913 18.2567 237.716 18.34 237.49 18.34C237.263 18.34 237.066 18.2567 236.9 18.09C236.733 17.9233 236.65 17.7267 236.65 17.5ZM234.91 16.72C234.91 15.8133 235.136 15.11 235.59 14.61C236.05 14.11 236.683 13.86 237.49 13.86C238.296 13.86 238.926 14.11 239.38 14.61C239.84 15.11 240.07 15.8133 240.07 16.72V18.28C240.07 19.2133 239.84 19.9233 239.38 20.41C238.926 20.8967 238.296 21.14 237.49 21.14C236.683 21.14 236.05 20.8967 235.59 20.41C235.136 19.9233 234.91 19.2133 234.91 18.28V16.72ZM237.49 19.94C237.73 19.94 237.93 19.9033 238.09 19.83C238.25 19.75 238.38 19.64 238.48 19.5C238.58 19.36 238.65 19.1933 238.69 19C238.73 18.8067 238.75 18.5933 238.75 18.36V16.64C238.75 16.42 238.726 16.2133 238.68 16.02C238.633 15.8267 238.56 15.66 238.46 15.52C238.36 15.38 238.23 15.27 238.07 15.19C237.91 15.1033 237.716 15.06 237.49 15.06C237.263 15.06 237.07 15.1033 236.91 15.19C236.75 15.27 236.62 15.38 236.52 15.52C236.42 15.66 236.346 15.8267 236.3 16.02C236.253 16.2133 236.23 16.42 236.23 16.64V18.36C236.23 18.5933 236.25 18.8067 236.29 19C236.33 19.1933 236.4 19.36 236.5 19.5C236.6 19.64 236.73 19.75 236.89 19.83C237.05 19.9033 237.25 19.94 237.49 19.94Z"
    fill="#5078E1"
  /><path
    d="M242.773 17.5C242.773 17.2733 242.856 17.0767 243.023 16.91C243.189 16.7433 243.386 16.66 243.613 16.66C243.839 16.66 244.036 16.7433 244.203 16.91C244.369 17.0767 244.453 17.2733 244.453 17.5C244.453 17.7267 244.369 17.9233 244.203 18.09C244.036 18.2567 243.839 18.34 243.613 18.34C243.386 18.34 243.189 18.2567 243.023 18.09C242.856 17.9233 242.773 17.7267 242.773 17.5ZM241.033 16.72C241.033 15.8133 241.259 15.11 241.713 14.61C242.173 14.11 242.806 13.86 243.613 13.86C244.419 13.86 245.049 14.11 245.503 14.61C245.963 15.11 246.193 15.8133 246.193 16.72V18.28C246.193 19.2133 245.963 19.9233 245.503 20.41C245.049 20.8967 244.419 21.14 243.613 21.14C242.806 21.14 242.173 20.8967 241.713 20.41C241.259 19.9233 241.033 19.2133 241.033 18.28V16.72ZM243.613 19.94C243.853 19.94 244.053 19.9033 244.213 19.83C244.373 19.75 244.503 19.64 244.603 19.5C244.703 19.36 244.773 19.1933 244.813 19C244.853 18.8067 244.873 18.5933 244.873 18.36V16.64C244.873 16.42 244.849 16.2133 244.803 16.02C244.756 15.8267 244.683 15.66 244.583 15.52C244.483 15.38 244.353 15.27 244.193 15.19C244.033 15.1033 243.839 15.06 243.613 15.06C243.386 15.06 243.193 15.1033 243.033 15.19C242.873 15.27 242.743 15.38 242.643 15.52C242.543 15.66 242.469 15.8267 242.423 16.02C242.376 16.2133 242.353 16.42 242.353 16.64V18.36C242.353 18.5933 242.373 18.8067 242.413 19C242.453 19.1933 242.523 19.36 242.623 19.5C242.723 19.64 242.853 19.75 243.013 19.83C243.173 19.9033 243.373 19.94 243.613 19.94Z"
    fill="#5078E1"
  /><path
    d="M248.896 17.5C248.896 17.2733 248.979 17.0767 249.146 16.91C249.312 16.7433 249.509 16.66 249.736 16.66C249.962 16.66 250.159 16.7433 250.326 16.91C250.492 17.0767 250.576 17.2733 250.576 17.5C250.576 17.7267 250.492 17.9233 250.326 18.09C250.159 18.2567 249.962 18.34 249.736 18.34C249.509 18.34 249.312 18.2567 249.146 18.09C248.979 17.9233 248.896 17.7267 248.896 17.5ZM247.156 16.72C247.156 15.8133 247.382 15.11 247.836 14.61C248.296 14.11 248.929 13.86 249.736 13.86C250.542 13.86 251.172 14.11 251.626 14.61C252.086 15.11 252.316 15.8133 252.316 16.72V18.28C252.316 19.2133 252.086 19.9233 251.626 20.41C251.172 20.8967 250.542 21.14 249.736 21.14C248.929 21.14 248.296 20.8967 247.836 20.41C247.382 19.9233 247.156 19.2133 247.156 18.28V16.72ZM249.736 19.94C249.976 19.94 250.176 19.9033 250.336 19.83C250.496 19.75 250.626 19.64 250.726 19.5C250.826 19.36 250.896 19.1933 250.936 19C250.976 18.8067 250.996 18.5933 250.996 18.36V16.64C250.996 16.42 250.972 16.2133 250.926 16.02C250.879 15.8267 250.806 15.66 250.706 15.52C250.606 15.38 250.476 15.27 250.316 15.19C250.156 15.1033 249.962 15.06 249.736 15.06C249.509 15.06 249.316 15.1033 249.156 15.19C248.996 15.27 248.866 15.38 248.766 15.52C248.666 15.66 248.592 15.8267 248.546 16.02C248.499 16.2133 248.476 16.42 248.476 16.64V18.36C248.476 18.5933 248.496 18.8067 248.536 19C248.576 19.1933 248.646 19.36 248.746 19.5C248.846 19.64 248.976 19.75 249.136 19.83C249.296 19.9033 249.496 19.94 249.736 19.94Z"
    fill="#5078E1"
  /><path
    d="M255.439 19.74V14.66H255.259L254.599 17.06H253.339V16.94L254.179 14H256.759V19.74H258.499V21H253.459V19.74H255.439Z"
    fill="#5078E1"
  /><path
    d="M261.142 17.5C261.142 17.2733 261.225 17.0767 261.392 16.91C261.559 16.7433 261.755 16.66 261.982 16.66C262.209 16.66 262.405 16.7433 262.572 16.91C262.739 17.0767 262.822 17.2733 262.822 17.5C262.822 17.7267 262.739 17.9233 262.572 18.09C262.405 18.2567 262.209 18.34 261.982 18.34C261.755 18.34 261.559 18.2567 261.392 18.09C261.225 17.9233 261.142 17.7267 261.142 17.5ZM259.402 16.72C259.402 15.8133 259.629 15.11 260.082 14.61C260.542 14.11 261.175 13.86 261.982 13.86C262.789 13.86 263.419 14.11 263.872 14.61C264.332 15.11 264.562 15.8133 264.562 16.72V18.28C264.562 19.2133 264.332 19.9233 263.872 20.41C263.419 20.8967 262.789 21.14 261.982 21.14C261.175 21.14 260.542 20.8967 260.082 20.41C259.629 19.9233 259.402 19.2133 259.402 18.28V16.72ZM261.982 19.94C262.222 19.94 262.422 19.9033 262.582 19.83C262.742 19.75 262.872 19.64 262.972 19.5C263.072 19.36 263.142 19.1933 263.182 19C263.222 18.8067 263.242 18.5933 263.242 18.36V16.64C263.242 16.42 263.219 16.2133 263.172 16.02C263.125 15.8267 263.052 15.66 262.952 15.52C262.852 15.38 262.722 15.27 262.562 15.19C262.402 15.1033 262.209 15.06 261.982 15.06C261.755 15.06 261.562 15.1033 261.402 15.19C261.242 15.27 261.112 15.38 261.012 15.52C260.912 15.66 260.839 15.8267 260.792 16.02C260.745 16.2133 260.722 16.42 260.722 16.64V18.36C260.722 18.5933 260.742 18.8067 260.782 19C260.822 19.1933 260.892 19.36 260.992 19.5C261.092 19.64 261.222 19.75 261.382 19.83C261.542 19.9033 261.742 19.94 261.982 19.94Z"
    fill="#5078E1"
  /><path
    d="M267.685 19.74V14.66H267.505L266.845 17.06H265.585V16.94L266.425 14H269.005V19.74H270.745V21H265.705V19.74H267.685Z"
    fill="#5078E1"
  /><path
    d="M273.808 19.74V14.66H273.628L272.968 17.06H271.708V16.94L272.548 14H275.128V19.74H276.868V21H271.828V19.74H273.808Z"
    fill="#5078E1"
  /><path
    d="M279.511 17.5C279.511 17.2733 279.594 17.0767 279.761 16.91C279.928 16.7433 280.124 16.66 280.351 16.66C280.578 16.66 280.774 16.7433 280.941 16.91C281.108 17.0767 281.191 17.2733 281.191 17.5C281.191 17.7267 281.108 17.9233 280.941 18.09C280.774 18.2567 280.578 18.34 280.351 18.34C280.124 18.34 279.928 18.2567 279.761 18.09C279.594 17.9233 279.511 17.7267 279.511 17.5ZM277.771 16.72C277.771 15.8133 277.998 15.11 278.451 14.61C278.911 14.11 279.544 13.86 280.351 13.86C281.158 13.86 281.788 14.11 282.241 14.61C282.701 15.11 282.931 15.8133 282.931 16.72V18.28C282.931 19.2133 282.701 19.9233 282.241 20.41C281.788 20.8967 281.158 21.14 280.351 21.14C279.544 21.14 278.911 20.8967 278.451 20.41C277.998 19.9233 277.771 19.2133 277.771 18.28V16.72ZM280.351 19.94C280.591 19.94 280.791 19.9033 280.951 19.83C281.111 19.75 281.241 19.64 281.341 19.5C281.441 19.36 281.511 19.1933 281.551 19C281.591 18.8067 281.611 18.5933 281.611 18.36V16.64C281.611 16.42 281.588 16.2133 281.541 16.02C281.494 15.8267 281.421 15.66 281.321 15.52C281.221 15.38 281.091 15.27 280.931 15.19C280.771 15.1033 280.578 15.06 280.351 15.06C280.124 15.06 279.931 15.1033 279.771 15.19C279.611 15.27 279.481 15.38 279.381 15.52C279.281 15.66 279.208 15.8267 279.161 16.02C279.114 16.2133 279.091 16.42 279.091 16.64V18.36C279.091 18.5933 279.111 18.8067 279.151 19C279.191 19.1933 279.261 19.36 279.361 19.5C279.461 19.64 279.591 19.75 279.751 19.83C279.911 19.9033 280.111 19.94 280.351 19.94Z"
    fill="#5078E1"
  /><path
    d="M286.054 19.74V14.66H285.874L285.214 17.06H283.954V16.94L284.794 14H287.374V19.74H289.114V21H284.074V19.74H286.054Z"
    fill="#5078E1"
  /><path
    d="M292.177 19.74V14.66H291.997L291.337 17.06H290.077V16.94L290.917 14H293.497V19.74H295.237V21H290.197V19.74H292.177Z"
    fill="#5078E1"
  /><path
    d="M298.3 19.74V14.66H298.12L297.46 17.06H296.2V16.94L297.04 14H299.62V19.74H301.36V21H296.32V19.74H298.3Z"
    fill="#5078E1"
  /><path
    d="M304.003 17.5C304.003 17.2733 304.087 17.0767 304.253 16.91C304.42 16.7433 304.617 16.66 304.843 16.66C305.07 16.66 305.267 16.7433 305.433 16.91C305.6 17.0767 305.683 17.2733 305.683 17.5C305.683 17.7267 305.6 17.9233 305.433 18.09C305.267 18.2567 305.07 18.34 304.843 18.34C304.617 18.34 304.42 18.2567 304.253 18.09C304.087 17.9233 304.003 17.7267 304.003 17.5ZM302.263 16.72C302.263 15.8133 302.49 15.11 302.943 14.61C303.403 14.11 304.037 13.86 304.843 13.86C305.65 13.86 306.28 14.11 306.733 14.61C307.193 15.11 307.423 15.8133 307.423 16.72V18.28C307.423 19.2133 307.193 19.9233 306.733 20.41C306.28 20.8967 305.65 21.14 304.843 21.14C304.037 21.14 303.403 20.8967 302.943 20.41C302.49 19.9233 302.263 19.2133 302.263 18.28V16.72ZM304.843 19.94C305.083 19.94 305.283 19.9033 305.443 19.83C305.603 19.75 305.733 19.64 305.833 19.5C305.933 19.36 306.003 19.1933 306.043 19C306.083 18.8067 306.103 18.5933 306.103 18.36V16.64C306.103 16.42 306.08 16.2133 306.033 16.02C305.987 15.8267 305.913 15.66 305.813 15.52C305.713 15.38 305.583 15.27 305.423 15.19C305.263 15.1033 305.07 15.06 304.843 15.06C304.617 15.06 304.423 15.1033 304.263 15.19C304.103 15.27 303.973 15.38 303.873 15.52C303.773 15.66 303.7 15.8267 303.653 16.02C303.607 16.2133 303.583 16.42 303.583 16.64V18.36C303.583 18.5933 303.603 18.8067 303.643 19C303.683 19.1933 303.753 19.36 303.853 19.5C303.953 19.64 304.083 19.75 304.243 19.83C304.403 19.9033 304.603 19.94 304.843 19.94Z"
    fill="#5078E1"
  /><path
    d="M310.126 17.5C310.126 17.2733 310.21 17.0767 310.376 16.91C310.543 16.7433 310.74 16.66 310.966 16.66C311.193 16.66 311.39 16.7433 311.556 16.91C311.723 17.0767 311.806 17.2733 311.806 17.5C311.806 17.7267 311.723 17.9233 311.556 18.09C311.39 18.2567 311.193 18.34 310.966 18.34C310.74 18.34 310.543 18.2567 310.376 18.09C310.21 17.9233 310.126 17.7267 310.126 17.5ZM308.386 16.72C308.386 15.8133 308.613 15.11 309.066 14.61C309.526 14.11 310.16 13.86 310.966 13.86C311.773 13.86 312.403 14.11 312.856 14.61C313.316 15.11 313.546 15.8133 313.546 16.72V18.28C313.546 19.2133 313.316 19.9233 312.856 20.41C312.403 20.8967 311.773 21.14 310.966 21.14C310.16 21.14 309.526 20.8967 309.066 20.41C308.613 19.9233 308.386 19.2133 308.386 18.28V16.72ZM310.966 19.94C311.206 19.94 311.406 19.9033 311.566 19.83C311.726 19.75 311.856 19.64 311.956 19.5C312.056 19.36 312.126 19.1933 312.166 19C312.206 18.8067 312.226 18.5933 312.226 18.36V16.64C312.226 16.42 312.203 16.2133 312.156 16.02C312.11 15.8267 312.036 15.66 311.936 15.52C311.836 15.38 311.706 15.27 311.546 15.19C311.386 15.1033 311.193 15.06 310.966 15.06C310.74 15.06 310.546 15.1033 310.386 15.19C310.226 15.27 310.096 15.38 309.996 15.52C309.896 15.66 309.823 15.8267 309.776 16.02C309.73 16.2133 309.706 16.42 309.706 16.64V18.36C309.706 18.5933 309.726 18.8067 309.766 19C309.806 19.1933 309.876 19.36 309.976 19.5C310.076 19.64 310.206 19.75 310.366 19.83C310.526 19.9033 310.726 19.94 310.966 19.94Z"
    fill="#5078E1"
  /><path
    d="M316.669 19.74V14.66H316.489L315.829 17.06H314.569V16.94L315.409 14H317.989V19.74H319.729V21H314.689V19.74H316.669Z"
    fill="#5078E1"
  /><path
    d="M322.792 19.74V14.66H322.612L321.952 17.06H320.692V16.94L321.532 14H324.112V19.74H325.852V21H320.812V19.74H322.792Z"
    fill="#5078E1"
  /><path
    d="M328.915 19.74V14.66H328.735L328.075 17.06H326.815V16.94L327.655 14H330.235V19.74H331.975V21H326.935V19.74H328.915Z"
    fill="#5078E1"
  /><path
    d="M334.618 17.5C334.618 17.2733 334.702 17.0767 334.868 16.91C335.035 16.7433 335.232 16.66 335.458 16.66C335.685 16.66 335.882 16.7433 336.048 16.91C336.215 17.0767 336.298 17.2733 336.298 17.5C336.298 17.7267 336.215 17.9233 336.048 18.09C335.882 18.2567 335.685 18.34 335.458 18.34C335.232 18.34 335.035 18.2567 334.868 18.09C334.702 17.9233 334.618 17.7267 334.618 17.5ZM332.878 16.72C332.878 15.8133 333.105 15.11 333.558 14.61C334.018 14.11 334.652 13.86 335.458 13.86C336.265 13.86 336.895 14.11 337.348 14.61C337.808 15.11 338.038 15.8133 338.038 16.72V18.28C338.038 19.2133 337.808 19.9233 337.348 20.41C336.895 20.8967 336.265 21.14 335.458 21.14C334.652 21.14 334.018 20.8967 333.558 20.41C333.105 19.9233 332.878 19.2133 332.878 18.28V16.72ZM335.458 19.94C335.698 19.94 335.898 19.9033 336.058 19.83C336.218 19.75 336.348 19.64 336.448 19.5C336.548 19.36 336.618 19.1933 336.658 19C336.698 18.8067 336.718 18.5933 336.718 18.36V16.64C336.718 16.42 336.695 16.2133 336.648 16.02C336.602 15.8267 336.528 15.66 336.428 15.52C336.328 15.38 336.198 15.27 336.038 15.19C335.878 15.1033 335.685 15.06 335.458 15.06C335.232 15.06 335.038 15.1033 334.878 15.19C334.718 15.27 334.588 15.38 334.488 15.52C334.388 15.66 334.315 15.8267 334.268 16.02C334.222 16.2133 334.198 16.42 334.198 16.64V18.36C334.198 18.5933 334.218 18.8067 334.258 19C334.298 19.1933 334.368 19.36 334.468 19.5C334.568 19.64 334.698 19.75 334.858 19.83C335.018 19.9033 335.218 19.94 335.458 19.94Z"
    fill="#5078E1"
  /><path
    d="M340.741 17.5C340.741 17.2733 340.825 17.0767 340.991 16.91C341.158 16.7433 341.355 16.66 341.581 16.66C341.808 16.66 342.005 16.7433 342.171 16.91C342.338 17.0767 342.421 17.2733 342.421 17.5C342.421 17.7267 342.338 17.9233 342.171 18.09C342.005 18.2567 341.808 18.34 341.581 18.34C341.355 18.34 341.158 18.2567 340.991 18.09C340.825 17.9233 340.741 17.7267 340.741 17.5ZM339.001 16.72C339.001 15.8133 339.228 15.11 339.681 14.61C340.141 14.11 340.775 13.86 341.581 13.86C342.388 13.86 343.018 14.11 343.471 14.61C343.931 15.11 344.161 15.8133 344.161 16.72V18.28C344.161 19.2133 343.931 19.9233 343.471 20.41C343.018 20.8967 342.388 21.14 341.581 21.14C340.775 21.14 340.141 20.8967 339.681 20.41C339.228 19.9233 339.001 19.2133 339.001 18.28V16.72ZM341.581 19.94C341.821 19.94 342.021 19.9033 342.181 19.83C342.341 19.75 342.471 19.64 342.571 19.5C342.671 19.36 342.741 19.1933 342.781 19C342.821 18.8067 342.841 18.5933 342.841 18.36V16.64C342.841 16.42 342.818 16.2133 342.771 16.02C342.725 15.8267 342.651 15.66 342.551 15.52C342.451 15.38 342.321 15.27 342.161 15.19C342.001 15.1033 341.808 15.06 341.581 15.06C341.355 15.06 341.161 15.1033 341.001 15.19C340.841 15.27 340.711 15.38 340.611 15.52C340.511 15.66 340.438 15.8267 340.391 16.02C340.345 16.2133 340.321 16.42 340.321 16.64V18.36C340.321 18.5933 340.341 18.8067 340.381 19C340.421 19.1933 340.491 19.36 340.591 19.5C340.691 19.64 340.821 19.75 340.981 19.83C341.141 19.9033 341.341 19.94 341.581 19.94Z"
    fill="#5078E1"
  /><path
    d="M347.285 19.74V14.66H347.105L346.445 17.06H345.185V16.94L346.025 14H348.605V19.74H350.345V21H345.305V19.74H347.285Z"
    fill="#5078E1"
  /><path
    d="M353.408 19.74V14.66H353.228L352.568 17.06H351.308V16.94L352.148 14H354.728V19.74H356.468V21H351.428V19.74H353.408Z"
    fill="#5078E1"
  /><path
    d="M359.111 17.5C359.111 17.2733 359.194 17.0767 359.361 16.91C359.527 16.7433 359.724 16.66 359.951 16.66C360.177 16.66 360.374 16.7433 360.541 16.91C360.707 17.0767 360.791 17.2733 360.791 17.5C360.791 17.7267 360.707 17.9233 360.541 18.09C360.374 18.2567 360.177 18.34 359.951 18.34C359.724 18.34 359.527 18.2567 359.361 18.09C359.194 17.9233 359.111 17.7267 359.111 17.5ZM357.371 16.72C357.371 15.8133 357.597 15.11 358.051 14.61C358.511 14.11 359.144 13.86 359.951 13.86C360.757 13.86 361.387 14.11 361.841 14.61C362.301 15.11 362.531 15.8133 362.531 16.72V18.28C362.531 19.2133 362.301 19.9233 361.841 20.41C361.387 20.8967 360.757 21.14 359.951 21.14C359.144 21.14 358.511 20.8967 358.051 20.41C357.597 19.9233 357.371 19.2133 357.371 18.28V16.72ZM359.951 19.94C360.191 19.94 360.391 19.9033 360.551 19.83C360.711 19.75 360.841 19.64 360.941 19.5C361.041 19.36 361.111 19.1933 361.151 19C361.191 18.8067 361.211 18.5933 361.211 18.36V16.64C361.211 16.42 361.187 16.2133 361.141 16.02C361.094 15.8267 361.021 15.66 360.921 15.52C360.821 15.38 360.691 15.27 360.531 15.19C360.371 15.1033 360.177 15.06 359.951 15.06C359.724 15.06 359.531 15.1033 359.371 15.19C359.211 15.27 359.081 15.38 358.981 15.52C358.881 15.66 358.807 15.8267 358.761 16.02C358.714 16.2133 358.691 16.42 358.691 16.64V18.36C358.691 18.5933 358.711 18.8067 358.751 19C358.791 19.1933 358.861 19.36 358.961 19.5C359.061 19.64 359.191 19.75 359.351 19.83C359.511 19.9033 359.711 19.94 359.951 19.94Z"
    fill="#5078E1"
  /><path
    d="M365.654 19.74V14.66H365.474L364.814 17.06H363.554V16.94L364.394 14H366.974V19.74H368.714V21H363.674V19.74H365.654Z"
    fill="#5078E1"
  /><path
    d="M371.777 19.74V14.66H371.597L370.937 17.06H369.677V16.94L370.517 14H373.097V19.74H374.837V21H369.797V19.74H371.777Z"
    fill="#5078E1"
  /><path
    d="M377.48 17.5C377.48 17.2733 377.563 17.0767 377.73 16.91C377.896 16.7433 378.093 16.66 378.32 16.66C378.546 16.66 378.743 16.7433 378.91 16.91C379.076 17.0767 379.16 17.2733 379.16 17.5C379.16 17.7267 379.076 17.9233 378.91 18.09C378.743 18.2567 378.546 18.34 378.32 18.34C378.093 18.34 377.896 18.2567 377.73 18.09C377.563 17.9233 377.48 17.7267 377.48 17.5ZM375.74 16.72C375.74 15.8133 375.966 15.11 376.42 14.61C376.88 14.11 377.513 13.86 378.32 13.86C379.126 13.86 379.756 14.11 380.21 14.61C380.67 15.11 380.9 15.8133 380.9 16.72V18.28C380.9 19.2133 380.67 19.9233 380.21 20.41C379.756 20.8967 379.126 21.14 378.32 21.14C377.513 21.14 376.88 20.8967 376.42 20.41C375.966 19.9233 375.74 19.2133 375.74 18.28V16.72ZM378.32 19.94C378.56 19.94 378.76 19.9033 378.92 19.83C379.08 19.75 379.21 19.64 379.31 19.5C379.41 19.36 379.48 19.1933 379.52 19C379.56 18.8067 379.58 18.5933 379.58 18.36V16.64C379.58 16.42 379.556 16.2133 379.51 16.02C379.463 15.8267 379.39 15.66 379.29 15.52C379.19 15.38 379.06 15.27 378.9 15.19C378.74 15.1033 378.546 15.06 378.32 15.06C378.093 15.06 377.9 15.1033 377.74 15.19C377.58 15.27 377.45 15.38 377.35 15.52C377.25 15.66 377.176 15.8267 377.13 16.02C377.083 16.2133 377.06 16.42 377.06 16.64V18.36C377.06 18.5933 377.08 18.8067 377.12 19C377.16 19.1933 377.23 19.36 377.33 19.5C377.43 19.64 377.56 19.75 377.72 19.83C377.88 19.9033 378.08 19.94 378.32 19.94Z"
    fill="#5078E1"
  /><path
    d="M384.023 19.74V14.66H383.843L383.183 17.06H381.923V16.94L382.763 14H385.343V19.74H387.083V21H382.043V19.74H384.023Z"
    fill="#5078E1"
  /><path
    d="M390.146 19.74V14.66H389.966L389.306 17.06H388.046V16.94L388.886 14H391.466V19.74H393.206V21H388.166V19.74H390.146Z"
    fill="#5078E1"
  /><path
    d="M396.269 19.74V14.66H396.089L395.429 17.06H394.169V16.94L395.009 14H397.589V19.74H399.329V21H394.289V19.74H396.269Z"
    fill="#5078E1"
  /><path
    d="M402.392 19.74V14.66H402.212L401.552 17.06H400.292V16.94L401.132 14H403.712V19.74H405.452V21H400.412V19.74H402.392Z"
    fill="#5078E1"
  /><path
    d="M408.095 17.5C408.095 17.2733 408.178 17.0767 408.345 16.91C408.512 16.7433 408.708 16.66 408.935 16.66C409.162 16.66 409.358 16.7433 409.525 16.91C409.692 17.0767 409.775 17.2733 409.775 17.5C409.775 17.7267 409.692 17.9233 409.525 18.09C409.358 18.2567 409.162 18.34 408.935 18.34C408.708 18.34 408.512 18.2567 408.345 18.09C408.178 17.9233 408.095 17.7267 408.095 17.5ZM406.355 16.72C406.355 15.8133 406.582 15.11 407.035 14.61C407.495 14.11 408.128 13.86 408.935 13.86C409.742 13.86 410.372 14.11 410.825 14.61C411.285 15.11 411.515 15.8133 411.515 16.72V18.28C411.515 19.2133 411.285 19.9233 410.825 20.41C410.372 20.8967 409.742 21.14 408.935 21.14C408.128 21.14 407.495 20.8967 407.035 20.41C406.582 19.9233 406.355 19.2133 406.355 18.28V16.72ZM408.935 19.94C409.175 19.94 409.375 19.9033 409.535 19.83C409.695 19.75 409.825 19.64 409.925 19.5C410.025 19.36 410.095 19.1933 410.135 19C410.175 18.8067 410.195 18.5933 410.195 18.36V16.64C410.195 16.42 410.172 16.2133 410.125 16.02C410.078 15.8267 410.005 15.66 409.905 15.52C409.805 15.38 409.675 15.27 409.515 15.19C409.355 15.1033 409.162 15.06 408.935 15.06C408.708 15.06 408.515 15.1033 408.355 15.19C408.195 15.27 408.065 15.38 407.965 15.52C407.865 15.66 407.792 15.8267 407.745 16.02C407.698 16.2133 407.675 16.42 407.675 16.64V18.36C407.675 18.5933 407.695 18.8067 407.735 19C407.775 19.1933 407.845 19.36 407.945 19.5C408.045 19.64 408.175 19.75 408.335 19.83C408.495 19.9033 408.695 19.94 408.935 19.94Z"
    fill="#5078E1"
  /><path
    d="M414.638 19.74V14.66H414.458L413.798 17.06H412.538V16.94L413.378 14H415.958V19.74H417.698V21H412.658V19.74H414.638Z"
    fill="#5078E1"
  /><path
    d="M420.761 19.74V14.66H420.581L419.921 17.06H418.661V16.94L419.501 14H422.081V19.74H423.821V21H418.781V19.74H420.761Z"
    fill="#5078E1"
  /><path
    d="M426.464 17.5C426.464 17.2733 426.547 17.0767 426.714 16.91C426.881 16.7433 427.077 16.66 427.304 16.66C427.531 16.66 427.727 16.7433 427.894 16.91C428.061 17.0767 428.144 17.2733 428.144 17.5C428.144 17.7267 428.061 17.9233 427.894 18.09C427.727 18.2567 427.531 18.34 427.304 18.34C427.077 18.34 426.881 18.2567 426.714 18.09C426.547 17.9233 426.464 17.7267 426.464 17.5ZM424.724 16.72C424.724 15.8133 424.951 15.11 425.404 14.61C425.864 14.11 426.497 13.86 427.304 13.86C428.111 13.86 428.741 14.11 429.194 14.61C429.654 15.11 429.884 15.8133 429.884 16.72V18.28C429.884 19.2133 429.654 19.9233 429.194 20.41C428.741 20.8967 428.111 21.14 427.304 21.14C426.497 21.14 425.864 20.8967 425.404 20.41C424.951 19.9233 424.724 19.2133 424.724 18.28V16.72ZM427.304 19.94C427.544 19.94 427.744 19.9033 427.904 19.83C428.064 19.75 428.194 19.64 428.294 19.5C428.394 19.36 428.464 19.1933 428.504 19C428.544 18.8067 428.564 18.5933 428.564 18.36V16.64C428.564 16.42 428.541 16.2133 428.494 16.02C428.447 15.8267 428.374 15.66 428.274 15.52C428.174 15.38 428.044 15.27 427.884 15.19C427.724 15.1033 427.531 15.06 427.304 15.06C427.077 15.06 426.884 15.1033 426.724 15.19C426.564 15.27 426.434 15.38 426.334 15.52C426.234 15.66 426.161 15.8267 426.114 16.02C426.067 16.2133 426.044 16.42 426.044 16.64V18.36C426.044 18.5933 426.064 18.8067 426.104 19C426.144 19.1933 426.214 19.36 426.314 19.5C426.414 19.64 426.544 19.75 426.704 19.83C426.864 19.9033 427.064 19.94 427.304 19.94Z"
    fill="#5078E1"
  /><path
    d="M433.007 19.74V14.66H432.827L432.167 17.06H430.907V16.94L431.747 14H434.327V19.74H436.067V21H431.027V19.74H433.007Z"
    fill="#5078E1"
  /><path
    d="M439.13 19.74V14.66H438.95L438.29 17.06H437.03V16.94L437.87 14H440.45V19.74H442.19V21H437.15V19.74H439.13Z"
    fill="#5078E1"
  /><path
    d="M444.833 17.5C444.833 17.2733 444.917 17.0767 445.083 16.91C445.25 16.7433 445.447 16.66 445.673 16.66C445.9 16.66 446.097 16.7433 446.263 16.91C446.43 17.0767 446.513 17.2733 446.513 17.5C446.513 17.7267 446.43 17.9233 446.263 18.09C446.097 18.2567 445.9 18.34 445.673 18.34C445.447 18.34 445.25 18.2567 445.083 18.09C444.917 17.9233 444.833 17.7267 444.833 17.5ZM443.093 16.72C443.093 15.8133 443.32 15.11 443.773 14.61C444.233 14.11 444.867 13.86 445.673 13.86C446.48 13.86 447.11 14.11 447.563 14.61C448.023 15.11 448.253 15.8133 448.253 16.72V18.28C448.253 19.2133 448.023 19.9233 447.563 20.41C447.11 20.8967 446.48 21.14 445.673 21.14C444.867 21.14 444.233 20.8967 443.773 20.41C443.32 19.9233 443.093 19.2133 443.093 18.28V16.72ZM445.673 19.94C445.913 19.94 446.113 19.9033 446.273 19.83C446.433 19.75 446.563 19.64 446.663 19.5C446.763 19.36 446.833 19.1933 446.873 19C446.913 18.8067 446.933 18.5933 446.933 18.36V16.64C446.933 16.42 446.91 16.2133 446.863 16.02C446.817 15.8267 446.743 15.66 446.643 15.52C446.543 15.38 446.413 15.27 446.253 15.19C446.093 15.1033 445.9 15.06 445.673 15.06C445.447 15.06 445.253 15.1033 445.093 15.19C444.933 15.27 444.803 15.38 444.703 15.52C444.603 15.66 444.53 15.8267 444.483 16.02C444.437 16.2133 444.413 16.42 444.413 16.64V18.36C444.413 18.5933 444.433 18.8067 444.473 19C444.513 19.1933 444.583 19.36 444.683 19.5C444.783 19.64 444.913 19.75 445.073 19.83C445.233 19.9033 445.433 19.94 445.673 19.94Z"
    fill="#5078E1"
  /><path
    d="M451.376 19.74V14.66H451.196L450.536 17.06H449.276V16.94L450.116 14H452.696V19.74H454.436V21H449.396V19.74H451.376Z"
    fill="#5078E1"
  /><path
    d="M457.079 17.5C457.079 17.2733 457.163 17.0767 457.329 16.91C457.496 16.7433 457.693 16.66 457.919 16.66C458.146 16.66 458.343 16.7433 458.509 16.91C458.676 17.0767 458.759 17.2733 458.759 17.5C458.759 17.7267 458.676 17.9233 458.509 18.09C458.343 18.2567 458.146 18.34 457.919 18.34C457.693 18.34 457.496 18.2567 457.329 18.09C457.163 17.9233 457.079 17.7267 457.079 17.5ZM455.339 16.72C455.339 15.8133 455.566 15.11 456.019 14.61C456.479 14.11 457.113 13.86 457.919 13.86C458.726 13.86 459.356 14.11 459.809 14.61C460.269 15.11 460.499 15.8133 460.499 16.72V18.28C460.499 19.2133 460.269 19.9233 459.809 20.41C459.356 20.8967 458.726 21.14 457.919 21.14C457.113 21.14 456.479 20.8967 456.019 20.41C455.566 19.9233 455.339 19.2133 455.339 18.28V16.72ZM457.919 19.94C458.159 19.94 458.359 19.9033 458.519 19.83C458.679 19.75 458.809 19.64 458.909 19.5C459.009 19.36 459.079 19.1933 459.119 19C459.159 18.8067 459.179 18.5933 459.179 18.36V16.64C459.179 16.42 459.156 16.2133 459.109 16.02C459.063 15.8267 458.989 15.66 458.889 15.52C458.789 15.38 458.659 15.27 458.499 15.19C458.339 15.1033 458.146 15.06 457.919 15.06C457.693 15.06 457.499 15.1033 457.339 15.19C457.179 15.27 457.049 15.38 456.949 15.52C456.849 15.66 456.776 15.8267 456.729 16.02C456.683 16.2133 456.659 16.42 456.659 16.64V18.36C456.659 18.5933 456.679 18.8067 456.719 19C456.759 19.1933 456.829 19.36 456.929 19.5C457.029 19.64 457.159 19.75 457.319 19.83C457.479 19.9033 457.679 19.94 457.919 19.94Z"
    fill="#5078E1"
  /><path
    d="M463.622 19.74V14.66H463.442L462.782 17.06H461.522V16.94L462.362 14H464.942V19.74H466.682V21H461.642V19.74H463.622Z"
    fill="#5078E1"
  /><path
    d="M469.745 19.74V14.66H469.565L468.905 17.06H467.645V16.94L468.485 14H471.065V19.74H472.805V21H467.765V19.74H469.745Z"
    fill="#5078E1"
  /><path
    d="M475.869 19.74V14.66H475.689L475.029 17.06H473.769V16.94L474.609 14H477.189V19.74H478.929V21H473.889V19.74H475.869Z"
    fill="#5078E1"
  /><path
    d="M481.572 17.5C481.572 17.2733 481.655 17.0767 481.822 16.91C481.988 16.7433 482.185 16.66 482.412 16.66C482.638 16.66 482.835 16.7433 483.002 16.91C483.168 17.0767 483.252 17.2733 483.252 17.5C483.252 17.7267 483.168 17.9233 483.002 18.09C482.835 18.2567 482.638 18.34 482.412 18.34C482.185 18.34 481.988 18.2567 481.822 18.09C481.655 17.9233 481.572 17.7267 481.572 17.5ZM479.832 16.72C479.832 15.8133 480.058 15.11 480.512 14.61C480.972 14.11 481.605 13.86 482.412 13.86C483.218 13.86 483.848 14.11 484.302 14.61C484.762 15.11 484.992 15.8133 484.992 16.72V18.28C484.992 19.2133 484.762 19.9233 484.302 20.41C483.848 20.8967 483.218 21.14 482.412 21.14C481.605 21.14 480.972 20.8967 480.512 20.41C480.058 19.9233 479.832 19.2133 479.832 18.28V16.72ZM482.412 19.94C482.652 19.94 482.852 19.9033 483.012 19.83C483.172 19.75 483.302 19.64 483.402 19.5C483.502 19.36 483.572 19.1933 483.612 19C483.652 18.8067 483.672 18.5933 483.672 18.36V16.64C483.672 16.42 483.648 16.2133 483.602 16.02C483.555 15.8267 483.482 15.66 483.382 15.52C483.282 15.38 483.152 15.27 482.992 15.19C482.832 15.1033 482.638 15.06 482.412 15.06C482.185 15.06 481.992 15.1033 481.832 15.19C481.672 15.27 481.542 15.38 481.442 15.52C481.342 15.66 481.268 15.8267 481.222 16.02C481.175 16.2133 481.152 16.42 481.152 16.64V18.36C481.152 18.5933 481.172 18.8067 481.212 19C481.252 19.1933 481.322 19.36 481.422 19.5C481.522 19.64 481.652 19.75 481.812 19.83C481.972 19.9033 482.172 19.94 482.412 19.94Z"
    fill="#5078E1"
  /><path
    d="M488.115 19.74V14.66H487.935L487.275 17.06H486.015V16.94L486.855 14H489.435V19.74H491.175V21H486.135V19.74H488.115Z"
    fill="#5078E1"
  /><path
    d="M494.238 19.74V14.66H494.058L493.398 17.06H492.138V16.94L492.978 14H495.558V19.74H497.298V21H492.258V19.74H494.238Z"
    fill="#5078E1"
  /><path
    d="M500.361 19.74V14.66H500.181L499.521 17.06H498.261V16.94L499.101 14H501.681V19.74H503.421V21H498.381V19.74H500.361Z"
    fill="#5078E1"
  /><path
    d="M506.064 17.5C506.064 17.2733 506.147 17.0767 506.314 16.91C506.48 16.7433 506.677 16.66 506.904 16.66C507.13 16.66 507.327 16.7433 507.494 16.91C507.66 17.0767 507.744 17.2733 507.744 17.5C507.744 17.7267 507.66 17.9233 507.494 18.09C507.327 18.2567 507.13 18.34 506.904 18.34C506.677 18.34 506.48 18.2567 506.314 18.09C506.147 17.9233 506.064 17.7267 506.064 17.5ZM504.324 16.72C504.324 15.8133 504.55 15.11 505.004 14.61C505.464 14.11 506.097 13.86 506.904 13.86C507.71 13.86 508.34 14.11 508.794 14.61C509.254 15.11 509.484 15.8133 509.484 16.72V18.28C509.484 19.2133 509.254 19.9233 508.794 20.41C508.34 20.8967 507.71 21.14 506.904 21.14C506.097 21.14 505.464 20.8967 505.004 20.41C504.55 19.9233 504.324 19.2133 504.324 18.28V16.72ZM506.904 19.94C507.144 19.94 507.344 19.9033 507.504 19.83C507.664 19.75 507.794 19.64 507.894 19.5C507.994 19.36 508.064 19.1933 508.104 19C508.144 18.8067 508.164 18.5933 508.164 18.36V16.64C508.164 16.42 508.14 16.2133 508.094 16.02C508.047 15.8267 507.974 15.66 507.874 15.52C507.774 15.38 507.644 15.27 507.484 15.19C507.324 15.1033 507.13 15.06 506.904 15.06C506.677 15.06 506.484 15.1033 506.324 15.19C506.164 15.27 506.034 15.38 505.934 15.52C505.834 15.66 505.76 15.8267 505.714 16.02C505.667 16.2133 505.644 16.42 505.644 16.64V18.36C505.644 18.5933 505.664 18.8067 505.704 19C505.744 19.1933 505.814 19.36 505.914 19.5C506.014 19.64 506.144 19.75 506.304 19.83C506.464 19.9033 506.664 19.94 506.904 19.94Z"
    fill="#5078E1"
  /><path
    d="M512.607 19.74V14.66H512.427L511.767 17.06H510.507V16.94L511.347 14H513.927V19.74H515.667V21H510.627V19.74H512.607Z"
    fill="#5078E1"
  /><path
    d="M518.73 19.74V14.66H518.55L517.89 17.06H516.63V16.94L517.47 14H520.05V19.74H521.79V21H516.75V19.74H518.73Z"
    fill="#5078E1"
  /><path
    d="M524.433 17.5C524.433 17.2733 524.516 17.0767 524.683 16.91C524.85 16.7433 525.046 16.66 525.273 16.66C525.5 16.66 525.696 16.7433 525.863 16.91C526.03 17.0767 526.113 17.2733 526.113 17.5C526.113 17.7267 526.03 17.9233 525.863 18.09C525.696 18.2567 525.5 18.34 525.273 18.34C525.046 18.34 524.85 18.2567 524.683 18.09C524.516 17.9233 524.433 17.7267 524.433 17.5ZM522.693 16.72C522.693 15.8133 522.92 15.11 523.373 14.61C523.833 14.11 524.466 13.86 525.273 13.86C526.08 13.86 526.71 14.11 527.163 14.61C527.623 15.11 527.853 15.8133 527.853 16.72V18.28C527.853 19.2133 527.623 19.9233 527.163 20.41C526.71 20.8967 526.08 21.14 525.273 21.14C524.466 21.14 523.833 20.8967 523.373 20.41C522.92 19.9233 522.693 19.2133 522.693 18.28V16.72ZM525.273 19.94C525.513 19.94 525.713 19.9033 525.873 19.83C526.033 19.75 526.163 19.64 526.263 19.5C526.363 19.36 526.433 19.1933 526.473 19C526.513 18.8067 526.533 18.5933 526.533 18.36V16.64C526.533 16.42 526.51 16.2133 526.463 16.02C526.416 15.8267 526.343 15.66 526.243 15.52C526.143 15.38 526.013 15.27 525.853 15.19C525.693 15.1033 525.5 15.06 525.273 15.06C525.046 15.06 524.853 15.1033 524.693 15.19C524.533 15.27 524.403 15.38 524.303 15.52C524.203 15.66 524.13 15.8267 524.083 16.02C524.036 16.2133 524.013 16.42 524.013 16.64V18.36C524.013 18.5933 524.033 18.8067 524.073 19C524.113 19.1933 524.183 19.36 524.283 19.5C524.383 19.64 524.513 19.75 524.673 19.83C524.833 19.9033 525.033 19.94 525.273 19.94Z"
    fill="#5078E1"
  /><path
    d="M530.556 17.5C530.556 17.2733 530.639 17.0767 530.806 16.91C530.973 16.7433 531.169 16.66 531.396 16.66C531.623 16.66 531.819 16.7433 531.986 16.91C532.153 17.0767 532.236 17.2733 532.236 17.5C532.236 17.7267 532.153 17.9233 531.986 18.09C531.819 18.2567 531.623 18.34 531.396 18.34C531.169 18.34 530.973 18.2567 530.806 18.09C530.639 17.9233 530.556 17.7267 530.556 17.5ZM528.816 16.72C528.816 15.8133 529.043 15.11 529.496 14.61C529.956 14.11 530.589 13.86 531.396 13.86C532.203 13.86 532.833 14.11 533.286 14.61C533.746 15.11 533.976 15.8133 533.976 16.72V18.28C533.976 19.2133 533.746 19.9233 533.286 20.41C532.833 20.8967 532.203 21.14 531.396 21.14C530.589 21.14 529.956 20.8967 529.496 20.41C529.043 19.9233 528.816 19.2133 528.816 18.28V16.72ZM531.396 19.94C531.636 19.94 531.836 19.9033 531.996 19.83C532.156 19.75 532.286 19.64 532.386 19.5C532.486 19.36 532.556 19.1933 532.596 19C532.636 18.8067 532.656 18.5933 532.656 18.36V16.64C532.656 16.42 532.633 16.2133 532.586 16.02C532.539 15.8267 532.466 15.66 532.366 15.52C532.266 15.38 532.136 15.27 531.976 15.19C531.816 15.1033 531.623 15.06 531.396 15.06C531.169 15.06 530.976 15.1033 530.816 15.19C530.656 15.27 530.526 15.38 530.426 15.52C530.326 15.66 530.253 15.8267 530.206 16.02C530.159 16.2133 530.136 16.42 530.136 16.64V18.36C530.136 18.5933 530.156 18.8067 530.196 19C530.236 19.1933 530.306 19.36 530.406 19.5C530.506 19.64 530.636 19.75 530.796 19.83C530.956 19.9033 531.156 19.94 531.396 19.94Z"
    fill="#5078E1"
  /><path
    d="M536.679 17.5C536.679 17.2733 536.762 17.0767 536.929 16.91C537.096 16.7433 537.292 16.66 537.519 16.66C537.746 16.66 537.942 16.7433 538.109 16.91C538.276 17.0767 538.359 17.2733 538.359 17.5C538.359 17.7267 538.276 17.9233 538.109 18.09C537.942 18.2567 537.746 18.34 537.519 18.34C537.292 18.34 537.096 18.2567 536.929 18.09C536.762 17.9233 536.679 17.7267 536.679 17.5ZM534.939 16.72C534.939 15.8133 535.166 15.11 535.619 14.61C536.079 14.11 536.712 13.86 537.519 13.86C538.326 13.86 538.956 14.11 539.409 14.61C539.869 15.11 540.099 15.8133 540.099 16.72V18.28C540.099 19.2133 539.869 19.9233 539.409 20.41C538.956 20.8967 538.326 21.14 537.519 21.14C536.712 21.14 536.079 20.8967 535.619 20.41C535.166 19.9233 534.939 19.2133 534.939 18.28V16.72ZM537.519 19.94C537.759 19.94 537.959 19.9033 538.119 19.83C538.279 19.75 538.409 19.64 538.509 19.5C538.609 19.36 538.679 19.1933 538.719 19C538.759 18.8067 538.779 18.5933 538.779 18.36V16.64C538.779 16.42 538.756 16.2133 538.709 16.02C538.662 15.8267 538.589 15.66 538.489 15.52C538.389 15.38 538.259 15.27 538.099 15.19C537.939 15.1033 537.746 15.06 537.519 15.06C537.292 15.06 537.099 15.1033 536.939 15.19C536.779 15.27 536.649 15.38 536.549 15.52C536.449 15.66 536.376 15.8267 536.329 16.02C536.282 16.2133 536.259 16.42 536.259 16.64V18.36C536.259 18.5933 536.279 18.8067 536.319 19C536.359 19.1933 536.429 19.36 536.529 19.5C536.629 19.64 536.759 19.75 536.919 19.83C537.079 19.9033 537.279 19.94 537.519 19.94Z"
    fill="#5078E1"
  /><path
    d="M542.802 17.5C542.802 17.2733 542.885 17.0767 543.052 16.91C543.219 16.7433 543.415 16.66 543.642 16.66C543.869 16.66 544.065 16.7433 544.232 16.91C544.399 17.0767 544.482 17.2733 544.482 17.5C544.482 17.7267 544.399 17.9233 544.232 18.09C544.065 18.2567 543.869 18.34 543.642 18.34C543.415 18.34 543.219 18.2567 543.052 18.09C542.885 17.9233 542.802 17.7267 542.802 17.5ZM541.062 16.72C541.062 15.8133 541.289 15.11 541.742 14.61C542.202 14.11 542.835 13.86 543.642 13.86C544.449 13.86 545.079 14.11 545.532 14.61C545.992 15.11 546.222 15.8133 546.222 16.72V18.28C546.222 19.2133 545.992 19.9233 545.532 20.41C545.079 20.8967 544.449 21.14 543.642 21.14C542.835 21.14 542.202 20.8967 541.742 20.41C541.289 19.9233 541.062 19.2133 541.062 18.28V16.72ZM543.642 19.94C543.882 19.94 544.082 19.9033 544.242 19.83C544.402 19.75 544.532 19.64 544.632 19.5C544.732 19.36 544.802 19.1933 544.842 19C544.882 18.8067 544.902 18.5933 544.902 18.36V16.64C544.902 16.42 544.879 16.2133 544.832 16.02C544.785 15.8267 544.712 15.66 544.612 15.52C544.512 15.38 544.382 15.27 544.222 15.19C544.062 15.1033 543.869 15.06 543.642 15.06C543.415 15.06 543.222 15.1033 543.062 15.19C542.902 15.27 542.772 15.38 542.672 15.52C542.572 15.66 542.499 15.8267 542.452 16.02C542.405 16.2133 542.382 16.42 542.382 16.64V18.36C542.382 18.5933 542.402 18.8067 542.442 19C542.482 19.1933 542.552 19.36 542.652 19.5C542.752 19.64 542.882 19.75 543.042 19.83C543.202 19.9033 543.402 19.94 543.642 19.94Z"
    fill="#5078E1"
  /><path
    d="M549.345 19.74V14.66H549.165L548.505 17.06H547.245V16.94L548.085 14H550.665V19.74H552.405V21H547.365V19.74H549.345Z"
    fill="#5078E1"
  /><path
    d="M555.048 17.5C555.048 17.2733 555.131 17.0767 555.298 16.91C555.465 16.7433 555.661 16.66 555.888 16.66C556.115 16.66 556.311 16.7433 556.478 16.91C556.645 17.0767 556.728 17.2733 556.728 17.5C556.728 17.7267 556.645 17.9233 556.478 18.09C556.311 18.2567 556.115 18.34 555.888 18.34C555.661 18.34 555.465 18.2567 555.298 18.09C555.131 17.9233 555.048 17.7267 555.048 17.5ZM553.308 16.72C553.308 15.8133 553.535 15.11 553.988 14.61C554.448 14.11 555.081 13.86 555.888 13.86C556.695 13.86 557.325 14.11 557.778 14.61C558.238 15.11 558.468 15.8133 558.468 16.72V18.28C558.468 19.2133 558.238 19.9233 557.778 20.41C557.325 20.8967 556.695 21.14 555.888 21.14C555.081 21.14 554.448 20.8967 553.988 20.41C553.535 19.9233 553.308 19.2133 553.308 18.28V16.72ZM555.888 19.94C556.128 19.94 556.328 19.9033 556.488 19.83C556.648 19.75 556.778 19.64 556.878 19.5C556.978 19.36 557.048 19.1933 557.088 19C557.128 18.8067 557.148 18.5933 557.148 18.36V16.64C557.148 16.42 557.125 16.2133 557.078 16.02C557.031 15.8267 556.958 15.66 556.858 15.52C556.758 15.38 556.628 15.27 556.468 15.19C556.308 15.1033 556.115 15.06 555.888 15.06C555.661 15.06 555.468 15.1033 555.308 15.19C555.148 15.27 555.018 15.38 554.918 15.52C554.818 15.66 554.745 15.8267 554.698 16.02C554.651 16.2133 554.628 16.42 554.628 16.64V18.36C554.628 18.5933 554.648 18.8067 554.688 19C554.728 19.1933 554.798 19.36 554.898 19.5C554.998 19.64 555.128 19.75 555.288 19.83C555.448 19.9033 555.648 19.94 555.888 19.94Z"
    fill="#5078E1"
  /><path
    d="M561.591 19.74V14.66H561.411L560.751 17.06H559.491V16.94L560.331 14H562.911V19.74H564.651V21H559.611V19.74H561.591Z"
    fill="#5078E1"
  /><path
    d="M567.714 19.74V14.66H567.534L566.874 17.06H565.614V16.94L566.454 14H569.034V19.74H570.774V21H565.734V19.74H567.714Z"
    fill="#5078E1"
  /><path
    d="M573.837 19.74V14.66H573.657L572.997 17.06H571.737V16.94L572.577 14H575.157V19.74H576.897V21H571.857V19.74H573.837Z"
    fill="#5078E1"
  /><path
    d="M579.54 17.5C579.54 17.2733 579.624 17.0767 579.79 16.91C579.957 16.7433 580.154 16.66 580.38 16.66C580.607 16.66 580.804 16.7433 580.97 16.91C581.137 17.0767 581.22 17.2733 581.22 17.5C581.22 17.7267 581.137 17.9233 580.97 18.09C580.804 18.2567 580.607 18.34 580.38 18.34C580.154 18.34 579.957 18.2567 579.79 18.09C579.624 17.9233 579.54 17.7267 579.54 17.5ZM577.8 16.72C577.8 15.8133 578.027 15.11 578.48 14.61C578.94 14.11 579.574 13.86 580.38 13.86C581.187 13.86 581.817 14.11 582.27 14.61C582.73 15.11 582.96 15.8133 582.96 16.72V18.28C582.96 19.2133 582.73 19.9233 582.27 20.41C581.817 20.8967 581.187 21.14 580.38 21.14C579.574 21.14 578.94 20.8967 578.48 20.41C578.027 19.9233 577.8 19.2133 577.8 18.28V16.72ZM580.38 19.94C580.62 19.94 580.82 19.9033 580.98 19.83C581.14 19.75 581.27 19.64 581.37 19.5C581.47 19.36 581.54 19.1933 581.58 19C581.62 18.8067 581.64 18.5933 581.64 18.36V16.64C581.64 16.42 581.617 16.2133 581.57 16.02C581.524 15.8267 581.45 15.66 581.35 15.52C581.25 15.38 581.12 15.27 580.96 15.19C580.8 15.1033 580.607 15.06 580.38 15.06C580.154 15.06 579.96 15.1033 579.8 15.19C579.64 15.27 579.51 15.38 579.41 15.52C579.31 15.66 579.237 15.8267 579.19 16.02C579.144 16.2133 579.12 16.42 579.12 16.64V18.36C579.12 18.5933 579.14 18.8067 579.18 19C579.22 19.1933 579.29 19.36 579.39 19.5C579.49 19.64 579.62 19.75 579.78 19.83C579.94 19.9033 580.14 19.94 580.38 19.94Z"
    fill="#5078E1"
  /><path
    d="M585.663 17.5C585.663 17.2733 585.747 17.0767 585.913 16.91C586.08 16.7433 586.277 16.66 586.503 16.66C586.73 16.66 586.927 16.7433 587.093 16.91C587.26 17.0767 587.343 17.2733 587.343 17.5C587.343 17.7267 587.26 17.9233 587.093 18.09C586.927 18.2567 586.73 18.34 586.503 18.34C586.277 18.34 586.08 18.2567 585.913 18.09C585.747 17.9233 585.663 17.7267 585.663 17.5ZM583.923 16.72C583.923 15.8133 584.15 15.11 584.603 14.61C585.063 14.11 585.697 13.86 586.503 13.86C587.31 13.86 587.94 14.11 588.393 14.61C588.853 15.11 589.083 15.8133 589.083 16.72V18.28C589.083 19.2133 588.853 19.9233 588.393 20.41C587.94 20.8967 587.31 21.14 586.503 21.14C585.697 21.14 585.063 20.8967 584.603 20.41C584.15 19.9233 583.923 19.2133 583.923 18.28V16.72ZM586.503 19.94C586.743 19.94 586.943 19.9033 587.103 19.83C587.263 19.75 587.393 19.64 587.493 19.5C587.593 19.36 587.663 19.1933 587.703 19C587.743 18.8067 587.763 18.5933 587.763 18.36V16.64C587.763 16.42 587.74 16.2133 587.693 16.02C587.647 15.8267 587.573 15.66 587.473 15.52C587.373 15.38 587.243 15.27 587.083 15.19C586.923 15.1033 586.73 15.06 586.503 15.06C586.277 15.06 586.083 15.1033 585.923 15.19C585.763 15.27 585.633 15.38 585.533 15.52C585.433 15.66 585.36 15.8267 585.313 16.02C585.267 16.2133 585.243 16.42 585.243 16.64V18.36C585.243 18.5933 585.263 18.8067 585.303 19C585.343 19.1933 585.413 19.36 585.513 19.5C585.613 19.64 585.743 19.75 585.903 19.83C586.063 19.9033 586.263 19.94 586.503 19.94Z"
    fill="#5078E1"
  /><path
    d="M592.206 19.74V14.66H592.026L591.366 17.06H590.106V16.94L590.946 14H593.526V19.74H595.266V21H590.226V19.74H592.206Z"
    fill="#5078E1"
  /><path
    d="M597.909 17.5C597.909 17.2733 597.993 17.0767 598.159 16.91C598.326 16.7433 598.523 16.66 598.749 16.66C598.976 16.66 599.173 16.7433 599.339 16.91C599.506 17.0767 599.589 17.2733 599.589 17.5C599.589 17.7267 599.506 17.9233 599.339 18.09C599.173 18.2567 598.976 18.34 598.749 18.34C598.523 18.34 598.326 18.2567 598.159 18.09C597.993 17.9233 597.909 17.7267 597.909 17.5ZM596.169 16.72C596.169 15.8133 596.396 15.11 596.849 14.61C597.309 14.11 597.943 13.86 598.749 13.86C599.556 13.86 600.186 14.11 600.639 14.61C601.099 15.11 601.329 15.8133 601.329 16.72V18.28C601.329 19.2133 601.099 19.9233 600.639 20.41C600.186 20.8967 599.556 21.14 598.749 21.14C597.943 21.14 597.309 20.8967 596.849 20.41C596.396 19.9233 596.169 19.2133 596.169 18.28V16.72ZM598.749 19.94C598.989 19.94 599.189 19.9033 599.349 19.83C599.509 19.75 599.639 19.64 599.739 19.5C599.839 19.36 599.909 19.1933 599.949 19C599.989 18.8067 600.009 18.5933 600.009 18.36V16.64C600.009 16.42 599.986 16.2133 599.939 16.02C599.893 15.8267 599.819 15.66 599.719 15.52C599.619 15.38 599.489 15.27 599.329 15.19C599.169 15.1033 598.976 15.06 598.749 15.06C598.523 15.06 598.329 15.1033 598.169 15.19C598.009 15.27 597.879 15.38 597.779 15.52C597.679 15.66 597.606 15.8267 597.559 16.02C597.513 16.2133 597.489 16.42 597.489 16.64V18.36C597.489 18.5933 597.509 18.8067 597.549 19C597.589 19.1933 597.659 19.36 597.759 19.5C597.859 19.64 597.989 19.75 598.149 19.83C598.309 19.9033 598.509 19.94 598.749 19.94Z"
    fill="#5078E1"
  /><path
    d="M604.032 17.5C604.032 17.2733 604.116 17.0767 604.282 16.91C604.449 16.7433 604.646 16.66 604.873 16.66C605.099 16.66 605.296 16.7433 605.462 16.91C605.629 17.0767 605.712 17.2733 605.712 17.5C605.712 17.7267 605.629 17.9233 605.462 18.09C605.296 18.2567 605.099 18.34 604.873 18.34C604.646 18.34 604.449 18.2567 604.282 18.09C604.116 17.9233 604.032 17.7267 604.032 17.5ZM602.292 16.72C602.292 15.8133 602.519 15.11 602.972 14.61C603.432 14.11 604.066 13.86 604.873 13.86C605.679 13.86 606.309 14.11 606.762 14.61C607.222 15.11 607.452 15.8133 607.452 16.72V18.28C607.452 19.2133 607.222 19.9233 606.762 20.41C606.309 20.8967 605.679 21.14 604.873 21.14C604.066 21.14 603.432 20.8967 602.972 20.41C602.519 19.9233 602.292 19.2133 602.292 18.28V16.72ZM604.873 19.94C605.113 19.94 605.312 19.9033 605.472 19.83C605.632 19.75 605.763 19.64 605.863 19.5C605.963 19.36 606.033 19.1933 606.073 19C606.113 18.8067 606.133 18.5933 606.133 18.36V16.64C606.133 16.42 606.109 16.2133 606.063 16.02C606.016 15.8267 605.942 15.66 605.842 15.52C605.742 15.38 605.612 15.27 605.452 15.19C605.292 15.1033 605.099 15.06 604.873 15.06C604.646 15.06 604.452 15.1033 604.292 15.19C604.132 15.27 604.002 15.38 603.902 15.52C603.802 15.66 603.729 15.8267 603.683 16.02C603.636 16.2133 603.613 16.42 603.613 16.64V18.36C603.613 18.5933 603.633 18.8067 603.673 19C603.713 19.1933 603.783 19.36 603.883 19.5C603.983 19.64 604.112 19.75 604.272 19.83C604.432 19.9033 604.633 19.94 604.873 19.94Z"
    fill="#5078E1"
  /><path
    d="M610.576 19.74V14.66H610.396L609.736 17.06H608.476V16.94L609.316 14H611.896V19.74H613.636V21H608.596V19.74H610.576Z"
    fill="#5078E1"
  /><path
    d="M616.699 19.74V14.66H616.519L615.859 17.06H614.599V16.94L615.439 14H618.019V19.74H619.759V21H614.719V19.74H616.699Z"
    fill="#5078E1"
  /><path
    d="M622.402 17.5C622.402 17.2733 622.485 17.0767 622.652 16.91C622.818 16.7433 623.015 16.66 623.242 16.66C623.468 16.66 623.665 16.7433 623.832 16.91C623.998 17.0767 624.082 17.2733 624.082 17.5C624.082 17.7267 623.998 17.9233 623.832 18.09C623.665 18.2567 623.468 18.34 623.242 18.34C623.015 18.34 622.818 18.2567 622.652 18.09C622.485 17.9233 622.402 17.7267 622.402 17.5ZM620.662 16.72C620.662 15.8133 620.888 15.11 621.342 14.61C621.802 14.11 622.435 13.86 623.242 13.86C624.048 13.86 624.678 14.11 625.132 14.61C625.592 15.11 625.822 15.8133 625.822 16.72V18.28C625.822 19.2133 625.592 19.9233 625.132 20.41C624.678 20.8967 624.048 21.14 623.242 21.14C622.435 21.14 621.802 20.8967 621.342 20.41C620.888 19.9233 620.662 19.2133 620.662 18.28V16.72ZM623.242 19.94C623.482 19.94 623.682 19.9033 623.842 19.83C624.002 19.75 624.132 19.64 624.232 19.5C624.332 19.36 624.402 19.1933 624.442 19C624.482 18.8067 624.502 18.5933 624.502 18.36V16.64C624.502 16.42 624.478 16.2133 624.432 16.02C624.385 15.8267 624.312 15.66 624.212 15.52C624.112 15.38 623.982 15.27 623.822 15.19C623.662 15.1033 623.468 15.06 623.242 15.06C623.015 15.06 622.822 15.1033 622.662 15.19C622.502 15.27 622.372 15.38 622.272 15.52C622.172 15.66 622.098 15.8267 622.052 16.02C622.005 16.2133 621.982 16.42 621.982 16.64V18.36C621.982 18.5933 622.002 18.8067 622.042 19C622.082 19.1933 622.152 19.36 622.252 19.5C622.352 19.64 622.482 19.75 622.642 19.83C622.802 19.9033 623.002 19.94 623.242 19.94Z"
    fill="#5078E1"
  /><path
    d="M628.525 17.5C628.525 17.2733 628.608 17.0767 628.775 16.91C628.941 16.7433 629.138 16.66 629.365 16.66C629.591 16.66 629.788 16.7433 629.955 16.91C630.121 17.0767 630.205 17.2733 630.205 17.5C630.205 17.7267 630.121 17.9233 629.955 18.09C629.788 18.2567 629.591 18.34 629.365 18.34C629.138 18.34 628.941 18.2567 628.775 18.09C628.608 17.9233 628.525 17.7267 628.525 17.5ZM626.785 16.72C626.785 15.8133 627.011 15.11 627.465 14.61C627.925 14.11 628.558 13.86 629.365 13.86C630.171 13.86 630.801 14.11 631.255 14.61C631.715 15.11 631.945 15.8133 631.945 16.72V18.28C631.945 19.2133 631.715 19.9233 631.255 20.41C630.801 20.8967 630.171 21.14 629.365 21.14C628.558 21.14 627.925 20.8967 627.465 20.41C627.011 19.9233 626.785 19.2133 626.785 18.28V16.72ZM629.365 19.94C629.605 19.94 629.805 19.9033 629.965 19.83C630.125 19.75 630.255 19.64 630.355 19.5C630.455 19.36 630.525 19.1933 630.565 19C630.605 18.8067 630.625 18.5933 630.625 18.36V16.64C630.625 16.42 630.601 16.2133 630.555 16.02C630.508 15.8267 630.435 15.66 630.335 15.52C630.235 15.38 630.105 15.27 629.945 15.19C629.785 15.1033 629.591 15.06 629.365 15.06C629.138 15.06 628.945 15.1033 628.785 15.19C628.625 15.27 628.495 15.38 628.395 15.52C628.295 15.66 628.221 15.8267 628.175 16.02C628.128 16.2133 628.105 16.42 628.105 16.64V18.36C628.105 18.5933 628.125 18.8067 628.165 19C628.205 19.1933 628.275 19.36 628.375 19.5C628.475 19.64 628.605 19.75 628.765 19.83C628.925 19.9033 629.125 19.94 629.365 19.94Z"
    fill="#5078E1"
  /><path
    d="M635.068 19.74V14.66H634.888L634.228 17.06H632.968V16.94L633.808 14H636.388V19.74H638.128V21H633.088V19.74H635.068Z"
    fill="#5078E1"
  /><path
    d="M640.771 17.5C640.771 17.2733 640.854 17.0767 641.021 16.91C641.187 16.7433 641.384 16.66 641.611 16.66C641.837 16.66 642.034 16.7433 642.201 16.91C642.367 17.0767 642.451 17.2733 642.451 17.5C642.451 17.7267 642.367 17.9233 642.201 18.09C642.034 18.2567 641.837 18.34 641.611 18.34C641.384 18.34 641.187 18.2567 641.021 18.09C640.854 17.9233 640.771 17.7267 640.771 17.5ZM639.031 16.72C639.031 15.8133 639.257 15.11 639.711 14.61C640.171 14.11 640.804 13.86 641.611 13.86C642.417 13.86 643.047 14.11 643.501 14.61C643.961 15.11 644.191 15.8133 644.191 16.72V18.28C644.191 19.2133 643.961 19.9233 643.501 20.41C643.047 20.8967 642.417 21.14 641.611 21.14C640.804 21.14 640.171 20.8967 639.711 20.41C639.257 19.9233 639.031 19.2133 639.031 18.28V16.72ZM641.611 19.94C641.851 19.94 642.051 19.9033 642.211 19.83C642.371 19.75 642.501 19.64 642.601 19.5C642.701 19.36 642.771 19.1933 642.811 19C642.851 18.8067 642.871 18.5933 642.871 18.36V16.64C642.871 16.42 642.847 16.2133 642.801 16.02C642.754 15.8267 642.681 15.66 642.581 15.52C642.481 15.38 642.351 15.27 642.191 15.19C642.031 15.1033 641.837 15.06 641.611 15.06C641.384 15.06 641.191 15.1033 641.031 15.19C640.871 15.27 640.741 15.38 640.641 15.52C640.541 15.66 640.467 15.8267 640.421 16.02C640.374 16.2133 640.351 16.42 640.351 16.64V18.36C640.351 18.5933 640.371 18.8067 640.411 19C640.451 19.1933 640.521 19.36 640.621 19.5C640.721 19.64 640.851 19.75 641.011 19.83C641.171 19.9033 641.371 19.94 641.611 19.94Z"
    fill="#5078E1"
  /><path
    d="M647.314 19.74V14.66H647.134L646.474 17.06H645.214V16.94L646.054 14H648.634V19.74H650.374V21H645.334V19.74H647.314Z"
    fill="#5078E1"
  /><path
    d="M653.017 17.5C653.017 17.2733 653.1 17.0767 653.267 16.91C653.434 16.7433 653.63 16.66 653.857 16.66C654.084 16.66 654.28 16.7433 654.447 16.91C654.614 17.0767 654.697 17.2733 654.697 17.5C654.697 17.7267 654.614 17.9233 654.447 18.09C654.28 18.2567 654.084 18.34 653.857 18.34C653.63 18.34 653.434 18.2567 653.267 18.09C653.1 17.9233 653.017 17.7267 653.017 17.5ZM651.277 16.72C651.277 15.8133 651.504 15.11 651.957 14.61C652.417 14.11 653.05 13.86 653.857 13.86C654.664 13.86 655.294 14.11 655.747 14.61C656.207 15.11 656.437 15.8133 656.437 16.72V18.28C656.437 19.2133 656.207 19.9233 655.747 20.41C655.294 20.8967 654.664 21.14 653.857 21.14C653.05 21.14 652.417 20.8967 651.957 20.41C651.504 19.9233 651.277 19.2133 651.277 18.28V16.72ZM653.857 19.94C654.097 19.94 654.297 19.9033 654.457 19.83C654.617 19.75 654.747 19.64 654.847 19.5C654.947 19.36 655.017 19.1933 655.057 19C655.097 18.8067 655.117 18.5933 655.117 18.36V16.64C655.117 16.42 655.094 16.2133 655.047 16.02C655 15.8267 654.927 15.66 654.827 15.52C654.727 15.38 654.597 15.27 654.437 15.19C654.277 15.1033 654.084 15.06 653.857 15.06C653.63 15.06 653.437 15.1033 653.277 15.19C653.117 15.27 652.987 15.38 652.887 15.52C652.787 15.66 652.714 15.8267 652.667 16.02C652.62 16.2133 652.597 16.42 652.597 16.64V18.36C652.597 18.5933 652.617 18.8067 652.657 19C652.697 19.1933 652.767 19.36 652.867 19.5C652.967 19.64 653.097 19.75 653.257 19.83C653.417 19.9033 653.617 19.94 653.857 19.94Z"
    fill="#5078E1"
  /><path
    d="M659.14 17.5C659.14 17.2733 659.223 17.0767 659.39 16.91C659.557 16.7433 659.753 16.66 659.98 16.66C660.207 16.66 660.403 16.7433 660.57 16.91C660.737 17.0767 660.82 17.2733 660.82 17.5C660.82 17.7267 660.737 17.9233 660.57 18.09C660.403 18.2567 660.207 18.34 659.98 18.34C659.753 18.34 659.557 18.2567 659.39 18.09C659.223 17.9233 659.14 17.7267 659.14 17.5ZM657.4 16.72C657.4 15.8133 657.627 15.11 658.08 14.61C658.54 14.11 659.173 13.86 659.98 13.86C660.787 13.86 661.417 14.11 661.87 14.61C662.33 15.11 662.56 15.8133 662.56 16.72V18.28C662.56 19.2133 662.33 19.9233 661.87 20.41C661.417 20.8967 660.787 21.14 659.98 21.14C659.173 21.14 658.54 20.8967 658.08 20.41C657.627 19.9233 657.4 19.2133 657.4 18.28V16.72ZM659.98 19.94C660.22 19.94 660.42 19.9033 660.58 19.83C660.74 19.75 660.87 19.64 660.97 19.5C661.07 19.36 661.14 19.1933 661.18 19C661.22 18.8067 661.24 18.5933 661.24 18.36V16.64C661.24 16.42 661.217 16.2133 661.17 16.02C661.123 15.8267 661.05 15.66 660.95 15.52C660.85 15.38 660.72 15.27 660.56 15.19C660.4 15.1033 660.207 15.06 659.98 15.06C659.753 15.06 659.56 15.1033 659.4 15.19C659.24 15.27 659.11 15.38 659.01 15.52C658.91 15.66 658.837 15.8267 658.79 16.02C658.743 16.2133 658.72 16.42 658.72 16.64V18.36C658.72 18.5933 658.74 18.8067 658.78 19C658.82 19.1933 658.89 19.36 658.99 19.5C659.09 19.64 659.22 19.75 659.38 19.83C659.54 19.9033 659.74 19.94 659.98 19.94Z"
    fill="#5078E1"
  /><path
    d="M665.683 19.74V14.66H665.503L664.843 17.06H663.583V16.94L664.423 14H667.003V19.74H668.743V21H663.703V19.74H665.683Z"
    fill="#5078E1"
  /><path
    d="M16.22 34.5C16.22 34.2733 16.3033 34.0767 16.47 33.91C16.6367 33.7433 16.8333 33.66 17.06 33.66C17.2867 33.66 17.4833 33.7433 17.65 33.91C17.8167 34.0767 17.9 34.2733 17.9 34.5C17.9 34.7267 17.8167 34.9233 17.65 35.09C17.4833 35.2567 17.2867 35.34 17.06 35.34C16.8333 35.34 16.6367 35.2567 16.47 35.09C16.3033 34.9233 16.22 34.7267 16.22 34.5ZM14.48 33.72C14.48 32.8133 14.7067 32.11 15.16 31.61C15.62 31.11 16.2533 30.86 17.06 30.86C17.8667 30.86 18.4967 31.11 18.95 31.61C19.41 32.11 19.64 32.8133 19.64 33.72V35.28C19.64 36.2133 19.41 36.9233 18.95 37.41C18.4967 37.8967 17.8667 38.14 17.06 38.14C16.2533 38.14 15.62 37.8967 15.16 37.41C14.7067 36.9233 14.48 36.2133 14.48 35.28V33.72ZM17.06 36.94C17.3 36.94 17.5 36.9033 17.66 36.83C17.82 36.75 17.95 36.64 18.05 36.5C18.15 36.36 18.22 36.1933 18.26 36C18.3 35.8067 18.32 35.5933 18.32 35.36V33.64C18.32 33.42 18.2967 33.2133 18.25 33.02C18.2033 32.8267 18.13 32.66 18.03 32.52C17.93 32.38 17.8 32.27 17.64 32.19C17.48 32.1033 17.2867 32.06 17.06 32.06C16.8333 32.06 16.64 32.1033 16.48 32.19C16.32 32.27 16.19 32.38 16.09 32.52C15.99 32.66 15.9167 32.8267 15.87 33.02C15.8233 33.2133 15.8 33.42 15.8 33.64V35.36C15.8 35.5933 15.82 35.8067 15.86 36C15.9 36.1933 15.97 36.36 16.07 36.5C16.17 36.64 16.3 36.75 16.46 36.83C16.62 36.9033 16.82 36.94 17.06 36.94Z"
    fill="#5078E1"
  /><path
    d="M22.343 34.5C22.343 34.2733 22.4264 34.0767 22.593 33.91C22.7597 33.7433 22.9564 33.66 23.183 33.66C23.4097 33.66 23.6064 33.7433 23.773 33.91C23.9397 34.0767 24.023 34.2733 24.023 34.5C24.023 34.7267 23.9397 34.9233 23.773 35.09C23.6064 35.2567 23.4097 35.34 23.183 35.34C22.9564 35.34 22.7597 35.2567 22.593 35.09C22.4264 34.9233 22.343 34.7267 22.343 34.5ZM20.603 33.72C20.603 32.8133 20.8297 32.11 21.283 31.61C21.743 31.11 22.3764 30.86 23.183 30.86C23.9897 30.86 24.6197 31.11 25.073 31.61C25.533 32.11 25.763 32.8133 25.763 33.72V35.28C25.763 36.2133 25.533 36.9233 25.073 37.41C24.6197 37.8967 23.9897 38.14 23.183 38.14C22.3764 38.14 21.743 37.8967 21.283 37.41C20.8297 36.9233 20.603 36.2133 20.603 35.28V33.72ZM23.183 36.94C23.423 36.94 23.623 36.9033 23.783 36.83C23.943 36.75 24.073 36.64 24.173 36.5C24.273 36.36 24.343 36.1933 24.383 36C24.423 35.8067 24.443 35.5933 24.443 35.36V33.64C24.443 33.42 24.4197 33.2133 24.373 33.02C24.3264 32.8267 24.253 32.66 24.153 32.52C24.053 32.38 23.923 32.27 23.763 32.19C23.603 32.1033 23.4097 32.06 23.183 32.06C22.9564 32.06 22.763 32.1033 22.603 32.19C22.443 32.27 22.313 32.38 22.213 32.52C22.113 32.66 22.0397 32.8267 21.993 33.02C21.9464 33.2133 21.923 33.42 21.923 33.64V35.36C21.923 35.5933 21.943 35.8067 21.983 36C22.023 36.1933 22.093 36.36 22.193 36.5C22.293 36.64 22.423 36.75 22.583 36.83C22.743 36.9033 22.943 36.94 23.183 36.94Z"
    fill="#5078E1"
  /><path
    d="M28.4661 34.5C28.4661 34.2733 28.5494 34.0767 28.7161 33.91C28.8828 33.7433 29.0794 33.66 29.3061 33.66C29.5328 33.66 29.7294 33.7433 29.8961 33.91C30.0628 34.0767 30.1461 34.2733 30.1461 34.5C30.1461 34.7267 30.0628 34.9233 29.8961 35.09C29.7294 35.2567 29.5328 35.34 29.3061 35.34C29.0794 35.34 28.8828 35.2567 28.7161 35.09C28.5494 34.9233 28.4661 34.7267 28.4661 34.5ZM26.7261 33.72C26.7261 32.8133 26.9528 32.11 27.4061 31.61C27.8661 31.11 28.4994 30.86 29.3061 30.86C30.1128 30.86 30.7428 31.11 31.1961 31.61C31.6561 32.11 31.8861 32.8133 31.8861 33.72V35.28C31.8861 36.2133 31.6561 36.9233 31.1961 37.41C30.7428 37.8967 30.1128 38.14 29.3061 38.14C28.4994 38.14 27.8661 37.8967 27.4061 37.41C26.9528 36.9233 26.7261 36.2133 26.7261 35.28V33.72ZM29.3061 36.94C29.5461 36.94 29.7461 36.9033 29.9061 36.83C30.0661 36.75 30.1961 36.64 30.2961 36.5C30.3961 36.36 30.4661 36.1933 30.5061 36C30.5461 35.8067 30.5661 35.5933 30.5661 35.36V33.64C30.5661 33.42 30.5428 33.2133 30.4961 33.02C30.4494 32.8267 30.3761 32.66 30.2761 32.52C30.1761 32.38 30.0461 32.27 29.8861 32.19C29.7261 32.1033 29.5328 32.06 29.3061 32.06C29.0794 32.06 28.8861 32.1033 28.7261 32.19C28.5661 32.27 28.4361 32.38 28.3361 32.52C28.2361 32.66 28.1628 32.8267 28.1161 33.02C28.0694 33.2133 28.0461 33.42 28.0461 33.64V35.36C28.0461 35.5933 28.0661 35.8067 28.1061 36C28.1461 36.1933 28.2161 36.36 28.3161 36.5C28.4161 36.64 28.5461 36.75 28.7061 36.83C28.8661 36.9033 29.0661 36.94 29.3061 36.94Z"
    fill="#5078E1"
  /><path
    d="M34.5891 34.5C34.5891 34.2733 34.6725 34.0767 34.8391 33.91C35.0058 33.7433 35.2025 33.66 35.4291 33.66C35.6558 33.66 35.8525 33.7433 36.0191 33.91C36.1858 34.0767 36.2691 34.2733 36.2691 34.5C36.2691 34.7267 36.1858 34.9233 36.0191 35.09C35.8525 35.2567 35.6558 35.34 35.4291 35.34C35.2025 35.34 35.0058 35.2567 34.8391 35.09C34.6725 34.9233 34.5891 34.7267 34.5891 34.5ZM32.8491 33.72C32.8491 32.8133 33.0758 32.11 33.5291 31.61C33.9891 31.11 34.6225 30.86 35.4291 30.86C36.2358 30.86 36.8658 31.11 37.3191 31.61C37.7791 32.11 38.0091 32.8133 38.0091 33.72V35.28C38.0091 36.2133 37.7791 36.9233 37.3191 37.41C36.8658 37.8967 36.2358 38.14 35.4291 38.14C34.6225 38.14 33.9891 37.8967 33.5291 37.41C33.0758 36.9233 32.8491 36.2133 32.8491 35.28V33.72ZM35.4291 36.94C35.6691 36.94 35.8691 36.9033 36.0291 36.83C36.1891 36.75 36.3191 36.64 36.4191 36.5C36.5191 36.36 36.5891 36.1933 36.6291 36C36.6691 35.8067 36.6891 35.5933 36.6891 35.36V33.64C36.6891 33.42 36.6658 33.2133 36.6191 33.02C36.5725 32.8267 36.4991 32.66 36.3991 32.52C36.2991 32.38 36.1691 32.27 36.0091 32.19C35.8491 32.1033 35.6558 32.06 35.4291 32.06C35.2025 32.06 35.0091 32.1033 34.8491 32.19C34.6891 32.27 34.5591 32.38 34.4591 32.52C34.3591 32.66 34.2858 32.8267 34.2391 33.02C34.1925 33.2133 34.1691 33.42 34.1691 33.64V35.36C34.1691 35.5933 34.1891 35.8067 34.2291 36C34.2691 36.1933 34.3391 36.36 34.4391 36.5C34.5391 36.64 34.6691 36.75 34.8291 36.83C34.9891 36.9033 35.1891 36.94 35.4291 36.94Z"
    fill="#5078E1"
  /><path
    d="M40.7122 34.5C40.7122 34.2733 40.7955 34.0767 40.9622 33.91C41.1288 33.7433 41.3255 33.66 41.5522 33.66C41.7788 33.66 41.9755 33.7433 42.1422 33.91C42.3089 34.0767 42.3922 34.2733 42.3922 34.5C42.3922 34.7267 42.3089 34.9233 42.1422 35.09C41.9755 35.2567 41.7788 35.34 41.5522 35.34C41.3255 35.34 41.1288 35.2567 40.9622 35.09C40.7955 34.9233 40.7122 34.7267 40.7122 34.5ZM38.9722 33.72C38.9722 32.8133 39.1989 32.11 39.6522 31.61C40.1122 31.11 40.7455 30.86 41.5522 30.86C42.3588 30.86 42.9889 31.11 43.4422 31.61C43.9022 32.11 44.1322 32.8133 44.1322 33.72V35.28C44.1322 36.2133 43.9022 36.9233 43.4422 37.41C42.9889 37.8967 42.3588 38.14 41.5522 38.14C40.7455 38.14 40.1122 37.8967 39.6522 37.41C39.1989 36.9233 38.9722 36.2133 38.9722 35.28V33.72ZM41.5522 36.94C41.7922 36.94 41.9922 36.9033 42.1522 36.83C42.3122 36.75 42.4422 36.64 42.5422 36.5C42.6422 36.36 42.7122 36.1933 42.7522 36C42.7922 35.8067 42.8122 35.5933 42.8122 35.36V33.64C42.8122 33.42 42.7889 33.2133 42.7422 33.02C42.6955 32.8267 42.6222 32.66 42.5222 32.52C42.4222 32.38 42.2922 32.27 42.1322 32.19C41.9722 32.1033 41.7788 32.06 41.5522 32.06C41.3255 32.06 41.1322 32.1033 40.9722 32.19C40.8122 32.27 40.6822 32.38 40.5822 32.52C40.4822 32.66 40.4088 32.8267 40.3622 33.02C40.3155 33.2133 40.2922 33.42 40.2922 33.64V35.36C40.2922 35.5933 40.3122 35.8067 40.3522 36C40.3922 36.1933 40.4622 36.36 40.5622 36.5C40.6622 36.64 40.7922 36.75 40.9522 36.83C41.1122 36.9033 41.3122 36.94 41.5522 36.94Z"
    fill="#5078E1"
  /><path
    d="M46.8352 34.5C46.8352 34.2733 46.9186 34.0767 47.0852 33.91C47.2519 33.7433 47.4486 33.66 47.6752 33.66C47.9019 33.66 48.0986 33.7433 48.2652 33.91C48.4319 34.0767 48.5152 34.2733 48.5152 34.5C48.5152 34.7267 48.4319 34.9233 48.2652 35.09C48.0986 35.2567 47.9019 35.34 47.6752 35.34C47.4486 35.34 47.2519 35.2567 47.0852 35.09C46.9186 34.9233 46.8352 34.7267 46.8352 34.5ZM45.0952 33.72C45.0952 32.8133 45.3219 32.11 45.7752 31.61C46.2352 31.11 46.8686 30.86 47.6752 30.86C48.4819 30.86 49.1119 31.11 49.5652 31.61C50.0252 32.11 50.2552 32.8133 50.2552 33.72V35.28C50.2552 36.2133 50.0252 36.9233 49.5652 37.41C49.1119 37.8967 48.4819 38.14 47.6752 38.14C46.8686 38.14 46.2352 37.8967 45.7752 37.41C45.3219 36.9233 45.0952 36.2133 45.0952 35.28V33.72ZM47.6752 36.94C47.9152 36.94 48.1152 36.9033 48.2752 36.83C48.4352 36.75 48.5652 36.64 48.6652 36.5C48.7652 36.36 48.8352 36.1933 48.8752 36C48.9152 35.8067 48.9352 35.5933 48.9352 35.36V33.64C48.9352 33.42 48.9119 33.2133 48.8652 33.02C48.8186 32.8267 48.7452 32.66 48.6452 32.52C48.5452 32.38 48.4152 32.27 48.2552 32.19C48.0952 32.1033 47.9019 32.06 47.6752 32.06C47.4486 32.06 47.2552 32.1033 47.0952 32.19C46.9352 32.27 46.8052 32.38 46.7052 32.52C46.6052 32.66 46.5319 32.8267 46.4852 33.02C46.4386 33.2133 46.4152 33.42 46.4152 33.64V35.36C46.4152 35.5933 46.4352 35.8067 46.4752 36C46.5152 36.1933 46.5852 36.36 46.6852 36.5C46.7852 36.64 46.9152 36.75 47.0752 36.83C47.2352 36.9033 47.4352 36.94 47.6752 36.94Z"
    fill="#5078E1"
  /><path
    d="M53.3783 36.74V31.66H53.1983L52.5383 34.06H51.2783V33.94L52.1183 31H54.6983V36.74H56.4383V38H51.3983V36.74H53.3783Z"
    fill="#5078E1"
  /><path
    d="M59.5013 36.74V31.66H59.3213L58.6613 34.06H57.4013V33.94L58.2413 31H60.8213V36.74H62.5613V38H57.5213V36.74H59.5013Z"
    fill="#5078E1"
  /><path
    d="M65.6244 36.74V31.66H65.4444L64.7844 34.06H63.5244V33.94L64.3644 31H66.9444V36.74H68.6844V38H63.6444V36.74H65.6244Z"
    fill="#5078E1"
  /><path
    d="M71.3274 34.5C71.3274 34.2733 71.4108 34.0767 71.5774 33.91C71.7441 33.7433 71.9408 33.66 72.1674 33.66C72.3941 33.66 72.5908 33.7433 72.7574 33.91C72.9241 34.0767 73.0074 34.2733 73.0074 34.5C73.0074 34.7267 72.9241 34.9233 72.7574 35.09C72.5908 35.2567 72.3941 35.34 72.1674 35.34C71.9408 35.34 71.7441 35.2567 71.5774 35.09C71.4108 34.9233 71.3274 34.7267 71.3274 34.5ZM69.5874 33.72C69.5874 32.8133 69.8141 32.11 70.2674 31.61C70.7274 31.11 71.3608 30.86 72.1674 30.86C72.9741 30.86 73.6041 31.11 74.0574 31.61C74.5174 32.11 74.7474 32.8133 74.7474 33.72V35.28C74.7474 36.2133 74.5174 36.9233 74.0574 37.41C73.6041 37.8967 72.9741 38.14 72.1674 38.14C71.3608 38.14 70.7274 37.8967 70.2674 37.41C69.8141 36.9233 69.5874 36.2133 69.5874 35.28V33.72ZM72.1674 36.94C72.4074 36.94 72.6074 36.9033 72.7674 36.83C72.9274 36.75 73.0574 36.64 73.1574 36.5C73.2574 36.36 73.3274 36.1933 73.3674 36C73.4074 35.8067 73.4274 35.5933 73.4274 35.36V33.64C73.4274 33.42 73.4041 33.2133 73.3574 33.02C73.3108 32.8267 73.2374 32.66 73.1374 32.52C73.0374 32.38 72.9074 32.27 72.7474 32.19C72.5874 32.1033 72.3941 32.06 72.1674 32.06C71.9408 32.06 71.7474 32.1033 71.5874 32.19C71.4274 32.27 71.2974 32.38 71.1974 32.52C71.0974 32.66 71.0241 32.8267 70.9774 33.02C70.9308 33.2133 70.9074 33.42 70.9074 33.64V35.36C70.9074 35.5933 70.9274 35.8067 70.9674 36C71.0074 36.1933 71.0774 36.36 71.1774 36.5C71.2774 36.64 71.4074 36.75 71.5674 36.83C71.7274 36.9033 71.9274 36.94 72.1674 36.94Z"
    fill="#5078E1"
  /><path
    d="M77.4505 34.5C77.4505 34.2733 77.5338 34.0767 77.7005 33.91C77.8671 33.7433 78.0638 33.66 78.2905 33.66C78.5171 33.66 78.7138 33.7433 78.8805 33.91C79.0471 34.0767 79.1305 34.2733 79.1305 34.5C79.1305 34.7267 79.0471 34.9233 78.8805 35.09C78.7138 35.2567 78.5171 35.34 78.2905 35.34C78.0638 35.34 77.8671 35.2567 77.7005 35.09C77.5338 34.9233 77.4505 34.7267 77.4505 34.5ZM75.7105 33.72C75.7105 32.8133 75.9371 32.11 76.3905 31.61C76.8505 31.11 77.4838 30.86 78.2905 30.86C79.0971 30.86 79.7271 31.11 80.1805 31.61C80.6405 32.11 80.8705 32.8133 80.8705 33.72V35.28C80.8705 36.2133 80.6405 36.9233 80.1805 37.41C79.7271 37.8967 79.0971 38.14 78.2905 38.14C77.4838 38.14 76.8505 37.8967 76.3905 37.41C75.9371 36.9233 75.7105 36.2133 75.7105 35.28V33.72ZM78.2905 36.94C78.5305 36.94 78.7305 36.9033 78.8905 36.83C79.0505 36.75 79.1805 36.64 79.2805 36.5C79.3805 36.36 79.4505 36.1933 79.4905 36C79.5305 35.8067 79.5505 35.5933 79.5505 35.36V33.64C79.5505 33.42 79.5271 33.2133 79.4805 33.02C79.4338 32.8267 79.3605 32.66 79.2605 32.52C79.1605 32.38 79.0305 32.27 78.8705 32.19C78.7105 32.1033 78.5171 32.06 78.2905 32.06C78.0638 32.06 77.8705 32.1033 77.7105 32.19C77.5505 32.27 77.4205 32.38 77.3205 32.52C77.2205 32.66 77.1471 32.8267 77.1005 33.02C77.0538 33.2133 77.0305 33.42 77.0305 33.64V35.36C77.0305 35.5933 77.0505 35.8067 77.0905 36C77.1305 36.1933 77.2005 36.36 77.3005 36.5C77.4005 36.64 77.5305 36.75 77.6905 36.83C77.8505 36.9033 78.0505 36.94 78.2905 36.94Z"
    fill="#5078E1"
  /><path
    d="M83.5735 34.5C83.5735 34.2733 83.6568 34.0767 83.8235 33.91C83.9902 33.7433 84.1868 33.66 84.4135 33.66C84.6402 33.66 84.8368 33.7433 85.0035 33.91C85.1702 34.0767 85.2535 34.2733 85.2535 34.5C85.2535 34.7267 85.1702 34.9233 85.0035 35.09C84.8368 35.2567 84.6402 35.34 84.4135 35.34C84.1868 35.34 83.9902 35.2567 83.8235 35.09C83.6568 34.9233 83.5735 34.7267 83.5735 34.5ZM81.8335 33.72C81.8335 32.8133 82.0602 32.11 82.5135 31.61C82.9735 31.11 83.6068 30.86 84.4135 30.86C85.2202 30.86 85.8502 31.11 86.3035 31.61C86.7635 32.11 86.9935 32.8133 86.9935 33.72V35.28C86.9935 36.2133 86.7635 36.9233 86.3035 37.41C85.8502 37.8967 85.2202 38.14 84.4135 38.14C83.6068 38.14 82.9735 37.8967 82.5135 37.41C82.0602 36.9233 81.8335 36.2133 81.8335 35.28V33.72ZM84.4135 36.94C84.6535 36.94 84.8535 36.9033 85.0135 36.83C85.1735 36.75 85.3035 36.64 85.4035 36.5C85.5035 36.36 85.5735 36.1933 85.6135 36C85.6535 35.8067 85.6735 35.5933 85.6735 35.36V33.64C85.6735 33.42 85.6502 33.2133 85.6035 33.02C85.5568 32.8267 85.4835 32.66 85.3835 32.52C85.2835 32.38 85.1535 32.27 84.9935 32.19C84.8335 32.1033 84.6402 32.06 84.4135 32.06C84.1868 32.06 83.9935 32.1033 83.8335 32.19C83.6735 32.27 83.5435 32.38 83.4435 32.52C83.3435 32.66 83.2702 32.8267 83.2235 33.02C83.1768 33.2133 83.1535 33.42 83.1535 33.64V35.36C83.1535 35.5933 83.1735 35.8067 83.2135 36C83.2535 36.1933 83.3235 36.36 83.4235 36.5C83.5235 36.64 83.6535 36.75 83.8135 36.83C83.9735 36.9033 84.1735 36.94 84.4135 36.94Z"
    fill="#5078E1"
  /><path
    d="M89.6966 34.5C89.6966 34.2733 89.7799 34.0767 89.9466 33.91C90.1132 33.7433 90.3099 33.66 90.5366 33.66C90.7632 33.66 90.9599 33.7433 91.1266 33.91C91.2932 34.0767 91.3766 34.2733 91.3766 34.5C91.3766 34.7267 91.2932 34.9233 91.1266 35.09C90.9599 35.2567 90.7632 35.34 90.5366 35.34C90.3099 35.34 90.1132 35.2567 89.9466 35.09C89.7799 34.9233 89.6966 34.7267 89.6966 34.5ZM87.9566 33.72C87.9566 32.8133 88.1832 32.11 88.6366 31.61C89.0966 31.11 89.7299 30.86 90.5366 30.86C91.3432 30.86 91.9732 31.11 92.4266 31.61C92.8866 32.11 93.1166 32.8133 93.1166 33.72V35.28C93.1166 36.2133 92.8866 36.9233 92.4266 37.41C91.9732 37.8967 91.3432 38.14 90.5366 38.14C89.7299 38.14 89.0966 37.8967 88.6366 37.41C88.1832 36.9233 87.9566 36.2133 87.9566 35.28V33.72ZM90.5366 36.94C90.7766 36.94 90.9766 36.9033 91.1366 36.83C91.2966 36.75 91.4266 36.64 91.5266 36.5C91.6266 36.36 91.6966 36.1933 91.7366 36C91.7766 35.8067 91.7966 35.5933 91.7966 35.36V33.64C91.7966 33.42 91.7732 33.2133 91.7266 33.02C91.6799 32.8267 91.6066 32.66 91.5066 32.52C91.4066 32.38 91.2766 32.27 91.1166 32.19C90.9566 32.1033 90.7632 32.06 90.5366 32.06C90.3099 32.06 90.1166 32.1033 89.9566 32.19C89.7966 32.27 89.6666 32.38 89.5666 32.52C89.4666 32.66 89.3932 32.8267 89.3466 33.02C89.2999 33.2133 89.2766 33.42 89.2766 33.64V35.36C89.2766 35.5933 89.2966 35.8067 89.3366 36C89.3766 36.1933 89.4466 36.36 89.5466 36.5C89.6466 36.64 89.7766 36.75 89.9366 36.83C90.0966 36.9033 90.2966 36.94 90.5366 36.94Z"
    fill="#5078E1"
  /><path
    d="M95.8196 34.5C95.8196 34.2733 95.9029 34.0767 96.0696 33.91C96.2363 33.7433 96.4329 33.66 96.6596 33.66C96.8863 33.66 97.0829 33.7433 97.2496 33.91C97.4163 34.0767 97.4996 34.2733 97.4996 34.5C97.4996 34.7267 97.4163 34.9233 97.2496 35.09C97.0829 35.2567 96.8863 35.34 96.6596 35.34C96.4329 35.34 96.2363 35.2567 96.0696 35.09C95.9029 34.9233 95.8196 34.7267 95.8196 34.5ZM94.0796 33.72C94.0796 32.8133 94.3063 32.11 94.7596 31.61C95.2196 31.11 95.8529 30.86 96.6596 30.86C97.4663 30.86 98.0963 31.11 98.5496 31.61C99.0096 32.11 99.2396 32.8133 99.2396 33.72V35.28C99.2396 36.2133 99.0096 36.9233 98.5496 37.41C98.0963 37.8967 97.4663 38.14 96.6596 38.14C95.8529 38.14 95.2196 37.8967 94.7596 37.41C94.3063 36.9233 94.0796 36.2133 94.0796 35.28V33.72ZM96.6596 36.94C96.8996 36.94 97.0996 36.9033 97.2596 36.83C97.4196 36.75 97.5496 36.64 97.6496 36.5C97.7496 36.36 97.8196 36.1933 97.8596 36C97.8996 35.8067 97.9196 35.5933 97.9196 35.36V33.64C97.9196 33.42 97.8963 33.2133 97.8496 33.02C97.8029 32.8267 97.7296 32.66 97.6296 32.52C97.5296 32.38 97.3996 32.27 97.2396 32.19C97.0796 32.1033 96.8863 32.06 96.6596 32.06C96.4329 32.06 96.2396 32.1033 96.0796 32.19C95.9196 32.27 95.7896 32.38 95.6896 32.52C95.5896 32.66 95.5163 32.8267 95.4696 33.02C95.4229 33.2133 95.3996 33.42 95.3996 33.64V35.36C95.3996 35.5933 95.4196 35.8067 95.4596 36C95.4996 36.1933 95.5696 36.36 95.6696 36.5C95.7696 36.64 95.8996 36.75 96.0596 36.83C96.2196 36.9033 96.4196 36.94 96.6596 36.94Z"
    fill="#5078E1"
  /><path
    d="M102.363 36.74V31.66H102.183L101.523 34.06H100.263V33.94L101.103 31H103.683V36.74H105.423V38H100.383V36.74H102.363Z"
    fill="#5078E1"
  /><path
    d="M108.486 36.74V31.66H108.306L107.646 34.06H106.386V33.94L107.226 31H109.806V36.74H111.546V38H106.506V36.74H108.486Z"
    fill="#5078E1"
  /><path
    d="M114.609 36.74V31.66H114.429L113.769 34.06H112.509V33.94L113.349 31H115.929V36.74H117.669V38H112.629V36.74H114.609Z"
    fill="#5078E1"
  /><path
    d="M120.312 34.5C120.312 34.2733 120.395 34.0767 120.562 33.91C120.728 33.7433 120.925 33.66 121.152 33.66C121.378 33.66 121.575 33.7433 121.742 33.91C121.908 34.0767 121.992 34.2733 121.992 34.5C121.992 34.7267 121.908 34.9233 121.742 35.09C121.575 35.2567 121.378 35.34 121.152 35.34C120.925 35.34 120.728 35.2567 120.562 35.09C120.395 34.9233 120.312 34.7267 120.312 34.5ZM118.572 33.72C118.572 32.8133 118.798 32.11 119.252 31.61C119.712 31.11 120.345 30.86 121.152 30.86C121.958 30.86 122.588 31.11 123.042 31.61C123.502 32.11 123.732 32.8133 123.732 33.72V35.28C123.732 36.2133 123.502 36.9233 123.042 37.41C122.588 37.8967 121.958 38.14 121.152 38.14C120.345 38.14 119.712 37.8967 119.252 37.41C118.798 36.9233 118.572 36.2133 118.572 35.28V33.72ZM121.152 36.94C121.392 36.94 121.592 36.9033 121.752 36.83C121.912 36.75 122.042 36.64 122.142 36.5C122.242 36.36 122.312 36.1933 122.352 36C122.392 35.8067 122.412 35.5933 122.412 35.36V33.64C122.412 33.42 122.388 33.2133 122.342 33.02C122.295 32.8267 122.222 32.66 122.122 32.52C122.022 32.38 121.892 32.27 121.732 32.19C121.572 32.1033 121.378 32.06 121.152 32.06C120.925 32.06 120.732 32.1033 120.572 32.19C120.412 32.27 120.282 32.38 120.182 32.52C120.082 32.66 120.008 32.8267 119.962 33.02C119.915 33.2133 119.892 33.42 119.892 33.64V35.36C119.892 35.5933 119.912 35.8067 119.952 36C119.992 36.1933 120.062 36.36 120.162 36.5C120.262 36.64 120.392 36.75 120.552 36.83C120.712 36.9033 120.912 36.94 121.152 36.94Z"
    fill="#5078E1"
  /><path
    d="M126.435 34.5C126.435 34.2733 126.518 34.0767 126.685 33.91C126.852 33.7433 127.048 33.66 127.275 33.66C127.502 33.66 127.698 33.7433 127.865 33.91C128.032 34.0767 128.115 34.2733 128.115 34.5C128.115 34.7267 128.032 34.9233 127.865 35.09C127.698 35.2567 127.502 35.34 127.275 35.34C127.048 35.34 126.852 35.2567 126.685 35.09C126.518 34.9233 126.435 34.7267 126.435 34.5ZM124.695 33.72C124.695 32.8133 124.922 32.11 125.375 31.61C125.835 31.11 126.468 30.86 127.275 30.86C128.082 30.86 128.712 31.11 129.165 31.61C129.625 32.11 129.855 32.8133 129.855 33.72V35.28C129.855 36.2133 129.625 36.9233 129.165 37.41C128.712 37.8967 128.082 38.14 127.275 38.14C126.468 38.14 125.835 37.8967 125.375 37.41C124.922 36.9233 124.695 36.2133 124.695 35.28V33.72ZM127.275 36.94C127.515 36.94 127.715 36.9033 127.875 36.83C128.035 36.75 128.165 36.64 128.265 36.5C128.365 36.36 128.435 36.1933 128.475 36C128.515 35.8067 128.535 35.5933 128.535 35.36V33.64C128.535 33.42 128.512 33.2133 128.465 33.02C128.418 32.8267 128.345 32.66 128.245 32.52C128.145 32.38 128.015 32.27 127.855 32.19C127.695 32.1033 127.502 32.06 127.275 32.06C127.048 32.06 126.855 32.1033 126.695 32.19C126.535 32.27 126.405 32.38 126.305 32.52C126.205 32.66 126.132 32.8267 126.085 33.02C126.038 33.2133 126.015 33.42 126.015 33.64V35.36C126.015 35.5933 126.035 35.8067 126.075 36C126.115 36.1933 126.185 36.36 126.285 36.5C126.385 36.64 126.515 36.75 126.675 36.83C126.835 36.9033 127.035 36.94 127.275 36.94Z"
    fill="#5078E1"
  /><path
    d="M132.978 36.74V31.66H132.798L132.138 34.06H130.878V33.94L131.718 31H134.298V36.74H136.038V38H130.998V36.74H132.978Z"
    fill="#5078E1"
  /><path
    d="M138.681 34.5C138.681 34.2733 138.764 34.0767 138.931 33.91C139.098 33.7433 139.294 33.66 139.521 33.66C139.748 33.66 139.944 33.7433 140.111 33.91C140.278 34.0767 140.361 34.2733 140.361 34.5C140.361 34.7267 140.278 34.9233 140.111 35.09C139.944 35.2567 139.748 35.34 139.521 35.34C139.294 35.34 139.098 35.2567 138.931 35.09C138.764 34.9233 138.681 34.7267 138.681 34.5ZM136.941 33.72C136.941 32.8133 137.168 32.11 137.621 31.61C138.081 31.11 138.714 30.86 139.521 30.86C140.328 30.86 140.958 31.11 141.411 31.61C141.871 32.11 142.101 32.8133 142.101 33.72V35.28C142.101 36.2133 141.871 36.9233 141.411 37.41C140.958 37.8967 140.328 38.14 139.521 38.14C138.714 38.14 138.081 37.8967 137.621 37.41C137.168 36.9233 136.941 36.2133 136.941 35.28V33.72ZM139.521 36.94C139.761 36.94 139.961 36.9033 140.121 36.83C140.281 36.75 140.411 36.64 140.511 36.5C140.611 36.36 140.681 36.1933 140.721 36C140.761 35.8067 140.781 35.5933 140.781 35.36V33.64C140.781 33.42 140.758 33.2133 140.711 33.02C140.664 32.8267 140.591 32.66 140.491 32.52C140.391 32.38 140.261 32.27 140.101 32.19C139.941 32.1033 139.748 32.06 139.521 32.06C139.294 32.06 139.101 32.1033 138.941 32.19C138.781 32.27 138.651 32.38 138.551 32.52C138.451 32.66 138.378 32.8267 138.331 33.02C138.284 33.2133 138.261 33.42 138.261 33.64V35.36C138.261 35.5933 138.281 35.8067 138.321 36C138.361 36.1933 138.431 36.36 138.531 36.5C138.631 36.64 138.761 36.75 138.921 36.83C139.081 36.9033 139.281 36.94 139.521 36.94Z"
    fill="#5078E1"
  /><path
    d="M144.804 34.5C144.804 34.2733 144.887 34.0767 145.054 33.91C145.221 33.7433 145.417 33.66 145.644 33.66C145.871 33.66 146.067 33.7433 146.234 33.91C146.401 34.0767 146.484 34.2733 146.484 34.5C146.484 34.7267 146.401 34.9233 146.234 35.09C146.067 35.2567 145.871 35.34 145.644 35.34C145.417 35.34 145.221 35.2567 145.054 35.09C144.887 34.9233 144.804 34.7267 144.804 34.5ZM143.064 33.72C143.064 32.8133 143.291 32.11 143.744 31.61C144.204 31.11 144.837 30.86 145.644 30.86C146.451 30.86 147.081 31.11 147.534 31.61C147.994 32.11 148.224 32.8133 148.224 33.72V35.28C148.224 36.2133 147.994 36.9233 147.534 37.41C147.081 37.8967 146.451 38.14 145.644 38.14C144.837 38.14 144.204 37.8967 143.744 37.41C143.291 36.9233 143.064 36.2133 143.064 35.28V33.72ZM145.644 36.94C145.884 36.94 146.084 36.9033 146.244 36.83C146.404 36.75 146.534 36.64 146.634 36.5C146.734 36.36 146.804 36.1933 146.844 36C146.884 35.8067 146.904 35.5933 146.904 35.36V33.64C146.904 33.42 146.881 33.2133 146.834 33.02C146.787 32.8267 146.714 32.66 146.614 32.52C146.514 32.38 146.384 32.27 146.224 32.19C146.064 32.1033 145.871 32.06 145.644 32.06C145.417 32.06 145.224 32.1033 145.064 32.19C144.904 32.27 144.774 32.38 144.674 32.52C144.574 32.66 144.501 32.8267 144.454 33.02C144.407 33.2133 144.384 33.42 144.384 33.64V35.36C144.384 35.5933 144.404 35.8067 144.444 36C144.484 36.1933 144.554 36.36 144.654 36.5C144.754 36.64 144.884 36.75 145.044 36.83C145.204 36.9033 145.404 36.94 145.644 36.94Z"
    fill="#5078E1"
  /><path
    d="M151.347 36.74V31.66H151.167L150.507 34.06H149.247V33.94L150.087 31H152.667V36.74H154.407V38H149.367V36.74H151.347Z"
    fill="#5078E1"
  /><path
    d="M157.47 36.74V31.66H157.29L156.63 34.06H155.37V33.94L156.21 31H158.79V36.74H160.53V38H155.49V36.74H157.47Z"
    fill="#5078E1"
  /><path
    d="M163.173 34.5C163.173 34.2733 163.256 34.0767 163.423 33.91C163.59 33.7433 163.786 33.66 164.013 33.66C164.24 33.66 164.436 33.7433 164.603 33.91C164.77 34.0767 164.853 34.2733 164.853 34.5C164.853 34.7267 164.77 34.9233 164.603 35.09C164.436 35.2567 164.24 35.34 164.013 35.34C163.786 35.34 163.59 35.2567 163.423 35.09C163.256 34.9233 163.173 34.7267 163.173 34.5ZM161.433 33.72C161.433 32.8133 161.66 32.11 162.113 31.61C162.573 31.11 163.206 30.86 164.013 30.86C164.82 30.86 165.45 31.11 165.903 31.61C166.363 32.11 166.593 32.8133 166.593 33.72V35.28C166.593 36.2133 166.363 36.9233 165.903 37.41C165.45 37.8967 164.82 38.14 164.013 38.14C163.206 38.14 162.573 37.8967 162.113 37.41C161.66 36.9233 161.433 36.2133 161.433 35.28V33.72ZM164.013 36.94C164.253 36.94 164.453 36.9033 164.613 36.83C164.773 36.75 164.903 36.64 165.003 36.5C165.103 36.36 165.173 36.1933 165.213 36C165.253 35.8067 165.273 35.5933 165.273 35.36V33.64C165.273 33.42 165.25 33.2133 165.203 33.02C165.156 32.8267 165.083 32.66 164.983 32.52C164.883 32.38 164.753 32.27 164.593 32.19C164.433 32.1033 164.24 32.06 164.013 32.06C163.786 32.06 163.593 32.1033 163.433 32.19C163.273 32.27 163.143 32.38 163.043 32.52C162.943 32.66 162.87 32.8267 162.823 33.02C162.776 33.2133 162.753 33.42 162.753 33.64V35.36C162.753 35.5933 162.773 35.8067 162.813 36C162.853 36.1933 162.923 36.36 163.023 36.5C163.123 36.64 163.253 36.75 163.413 36.83C163.573 36.9033 163.773 36.94 164.013 36.94Z"
    fill="#5078E1"
  /><path
    d="M169.716 36.74V31.66H169.536L168.876 34.06H167.616V33.94L168.456 31H171.036V36.74H172.776V38H167.736V36.74H169.716Z"
    fill="#5078E1"
  /><path
    d="M175.839 36.74V31.66H175.659L174.999 34.06H173.739V33.94L174.579 31H177.159V36.74H178.899V38H173.859V36.74H175.839Z"
    fill="#5078E1"
  /><path
    d="M181.962 36.74V31.66H181.782L181.122 34.06H179.862V33.94L180.702 31H183.282V36.74H185.022V38H179.982V36.74H181.962Z"
    fill="#5078E1"
  /><path
    d="M188.085 36.74V31.66H187.905L187.245 34.06H185.985V33.94L186.825 31H189.405V36.74H191.145V38H186.105V36.74H188.085Z"
    fill="#5078E1"
  /><path
    d="M193.788 34.5C193.788 34.2733 193.872 34.0767 194.038 33.91C194.205 33.7433 194.402 33.66 194.628 33.66C194.855 33.66 195.052 33.7433 195.218 33.91C195.385 34.0767 195.468 34.2733 195.468 34.5C195.468 34.7267 195.385 34.9233 195.218 35.09C195.052 35.2567 194.855 35.34 194.628 35.34C194.402 35.34 194.205 35.2567 194.038 35.09C193.872 34.9233 193.788 34.7267 193.788 34.5ZM192.048 33.72C192.048 32.8133 192.275 32.11 192.728 31.61C193.188 31.11 193.822 30.86 194.628 30.86C195.435 30.86 196.065 31.11 196.518 31.61C196.978 32.11 197.208 32.8133 197.208 33.72V35.28C197.208 36.2133 196.978 36.9233 196.518 37.41C196.065 37.8967 195.435 38.14 194.628 38.14C193.822 38.14 193.188 37.8967 192.728 37.41C192.275 36.9233 192.048 36.2133 192.048 35.28V33.72ZM194.628 36.94C194.868 36.94 195.068 36.9033 195.228 36.83C195.388 36.75 195.518 36.64 195.618 36.5C195.718 36.36 195.788 36.1933 195.828 36C195.868 35.8067 195.888 35.5933 195.888 35.36V33.64C195.888 33.42 195.865 33.2133 195.818 33.02C195.772 32.8267 195.698 32.66 195.598 32.52C195.498 32.38 195.368 32.27 195.208 32.19C195.048 32.1033 194.855 32.06 194.628 32.06C194.402 32.06 194.208 32.1033 194.048 32.19C193.888 32.27 193.758 32.38 193.658 32.52C193.558 32.66 193.485 32.8267 193.438 33.02C193.392 33.2133 193.368 33.42 193.368 33.64V35.36C193.368 35.5933 193.388 35.8067 193.428 36C193.468 36.1933 193.538 36.36 193.638 36.5C193.738 36.64 193.868 36.75 194.028 36.83C194.188 36.9033 194.388 36.94 194.628 36.94Z"
    fill="#5078E1"
  /><path
    d="M200.331 36.74V31.66H200.151L199.491 34.06H198.231V33.94L199.071 31H201.651V36.74H203.391V38H198.351V36.74H200.331Z"
    fill="#5078E1"
  /><path
    d="M206.454 36.74V31.66H206.274L205.614 34.06H204.354V33.94L205.194 31H207.774V36.74H209.514V38H204.474V36.74H206.454Z"
    fill="#5078E1"
  /><path
    d="M212.577 36.74V31.66H212.397L211.738 34.06H210.477V33.94L211.317 31H213.897V36.74H215.637V38H210.597V36.74H212.577Z"
    fill="#5078E1"
  /><path
    d="M218.281 34.5C218.281 34.2733 218.364 34.0767 218.531 33.91C218.697 33.7433 218.894 33.66 219.121 33.66C219.347 33.66 219.544 33.7433 219.711 33.91C219.877 34.0767 219.961 34.2733 219.961 34.5C219.961 34.7267 219.877 34.9233 219.711 35.09C219.544 35.2567 219.347 35.34 219.121 35.34C218.894 35.34 218.697 35.2567 218.531 35.09C218.364 34.9233 218.281 34.7267 218.281 34.5ZM216.541 33.72C216.541 32.8133 216.767 32.11 217.221 31.61C217.681 31.11 218.314 30.86 219.121 30.86C219.927 30.86 220.557 31.11 221.011 31.61C221.471 32.11 221.701 32.8133 221.701 33.72V35.28C221.701 36.2133 221.471 36.9233 221.011 37.41C220.557 37.8967 219.927 38.14 219.121 38.14C218.314 38.14 217.681 37.8967 217.221 37.41C216.767 36.9233 216.541 36.2133 216.541 35.28V33.72ZM219.121 36.94C219.361 36.94 219.561 36.9033 219.721 36.83C219.881 36.75 220.011 36.64 220.111 36.5C220.211 36.36 220.281 36.1933 220.321 36C220.361 35.8067 220.381 35.5933 220.381 35.36V33.64C220.381 33.42 220.357 33.2133 220.311 33.02C220.264 32.8267 220.191 32.66 220.091 32.52C219.991 32.38 219.861 32.27 219.701 32.19C219.541 32.1033 219.347 32.06 219.121 32.06C218.894 32.06 218.701 32.1033 218.541 32.19C218.381 32.27 218.251 32.38 218.151 32.52C218.051 32.66 217.977 32.8267 217.931 33.02C217.884 33.2133 217.861 33.42 217.861 33.64V35.36C217.861 35.5933 217.881 35.8067 217.921 36C217.961 36.1933 218.031 36.36 218.131 36.5C218.231 36.64 218.361 36.75 218.521 36.83C218.681 36.9033 218.881 36.94 219.121 36.94Z"
    fill="#5078E1"
  /><path
    d="M224.824 36.74V31.66H224.644L223.984 34.06H222.724V33.94L223.564 31H226.144V36.74H227.884V38H222.844V36.74H224.824Z"
    fill="#5078E1"
  /><path
    d="M230.527 34.5C230.527 34.2733 230.61 34.0767 230.777 33.91C230.943 33.7433 231.14 33.66 231.367 33.66C231.593 33.66 231.79 33.7433 231.957 33.91C232.123 34.0767 232.207 34.2733 232.207 34.5C232.207 34.7267 232.123 34.9233 231.957 35.09C231.79 35.2567 231.593 35.34 231.367 35.34C231.14 35.34 230.943 35.2567 230.777 35.09C230.61 34.9233 230.527 34.7267 230.527 34.5ZM228.787 33.72C228.787 32.8133 229.013 32.11 229.467 31.61C229.927 31.11 230.56 30.86 231.367 30.86C232.173 30.86 232.803 31.11 233.257 31.61C233.717 32.11 233.947 32.8133 233.947 33.72V35.28C233.947 36.2133 233.717 36.9233 233.257 37.41C232.803 37.8967 232.173 38.14 231.367 38.14C230.56 38.14 229.927 37.8967 229.467 37.41C229.013 36.9233 228.787 36.2133 228.787 35.28V33.72ZM231.367 36.94C231.607 36.94 231.807 36.9033 231.967 36.83C232.127 36.75 232.257 36.64 232.357 36.5C232.457 36.36 232.527 36.1933 232.567 36C232.607 35.8067 232.627 35.5933 232.627 35.36V33.64C232.627 33.42 232.603 33.2133 232.557 33.02C232.51 32.8267 232.437 32.66 232.337 32.52C232.237 32.38 232.107 32.27 231.947 32.19C231.787 32.1033 231.593 32.06 231.367 32.06C231.14 32.06 230.947 32.1033 230.787 32.19C230.627 32.27 230.497 32.38 230.397 32.52C230.297 32.66 230.223 32.8267 230.177 33.02C230.13 33.2133 230.107 33.42 230.107 33.64V35.36C230.107 35.5933 230.127 35.8067 230.167 36C230.207 36.1933 230.277 36.36 230.377 36.5C230.477 36.64 230.607 36.75 230.767 36.83C230.927 36.9033 231.127 36.94 231.367 36.94Z"
    fill="#5078E1"
  /><path
    d="M236.65 34.5C236.65 34.2733 236.733 34.0767 236.9 33.91C237.066 33.7433 237.263 33.66 237.49 33.66C237.716 33.66 237.913 33.7433 238.08 33.91C238.246 34.0767 238.33 34.2733 238.33 34.5C238.33 34.7267 238.246 34.9233 238.08 35.09C237.913 35.2567 237.716 35.34 237.49 35.34C237.263 35.34 237.066 35.2567 236.9 35.09C236.733 34.9233 236.65 34.7267 236.65 34.5ZM234.91 33.72C234.91 32.8133 235.136 32.11 235.59 31.61C236.05 31.11 236.683 30.86 237.49 30.86C238.296 30.86 238.926 31.11 239.38 31.61C239.84 32.11 240.07 32.8133 240.07 33.72V35.28C240.07 36.2133 239.84 36.9233 239.38 37.41C238.926 37.8967 238.296 38.14 237.49 38.14C236.683 38.14 236.05 37.8967 235.59 37.41C235.136 36.9233 234.91 36.2133 234.91 35.28V33.72ZM237.49 36.94C237.73 36.94 237.93 36.9033 238.09 36.83C238.25 36.75 238.38 36.64 238.48 36.5C238.58 36.36 238.65 36.1933 238.69 36C238.73 35.8067 238.75 35.5933 238.75 35.36V33.64C238.75 33.42 238.726 33.2133 238.68 33.02C238.633 32.8267 238.56 32.66 238.46 32.52C238.36 32.38 238.23 32.27 238.07 32.19C237.91 32.1033 237.716 32.06 237.49 32.06C237.263 32.06 237.07 32.1033 236.91 32.19C236.75 32.27 236.62 32.38 236.52 32.52C236.42 32.66 236.346 32.8267 236.3 33.02C236.253 33.2133 236.23 33.42 236.23 33.64V35.36C236.23 35.5933 236.25 35.8067 236.29 36C236.33 36.1933 236.4 36.36 236.5 36.5C236.6 36.64 236.73 36.75 236.89 36.83C237.05 36.9033 237.25 36.94 237.49 36.94Z"
    fill="#5078E1"
  /><path
    d="M242.773 34.5C242.773 34.2733 242.856 34.0767 243.023 33.91C243.189 33.7433 243.386 33.66 243.613 33.66C243.839 33.66 244.036 33.7433 244.203 33.91C244.369 34.0767 244.453 34.2733 244.453 34.5C244.453 34.7267 244.369 34.9233 244.203 35.09C244.036 35.2567 243.839 35.34 243.613 35.34C243.386 35.34 243.189 35.2567 243.023 35.09C242.856 34.9233 242.773 34.7267 242.773 34.5ZM241.033 33.72C241.033 32.8133 241.259 32.11 241.713 31.61C242.173 31.11 242.806 30.86 243.613 30.86C244.419 30.86 245.049 31.11 245.503 31.61C245.963 32.11 246.193 32.8133 246.193 33.72V35.28C246.193 36.2133 245.963 36.9233 245.503 37.41C245.049 37.8967 244.419 38.14 243.613 38.14C242.806 38.14 242.173 37.8967 241.713 37.41C241.259 36.9233 241.033 36.2133 241.033 35.28V33.72ZM243.613 36.94C243.853 36.94 244.053 36.9033 244.213 36.83C244.373 36.75 244.503 36.64 244.603 36.5C244.703 36.36 244.773 36.1933 244.813 36C244.853 35.8067 244.873 35.5933 244.873 35.36V33.64C244.873 33.42 244.849 33.2133 244.803 33.02C244.756 32.8267 244.683 32.66 244.583 32.52C244.483 32.38 244.353 32.27 244.193 32.19C244.033 32.1033 243.839 32.06 243.613 32.06C243.386 32.06 243.193 32.1033 243.033 32.19C242.873 32.27 242.743 32.38 242.643 32.52C242.543 32.66 242.469 32.8267 242.423 33.02C242.376 33.2133 242.353 33.42 242.353 33.64V35.36C242.353 35.5933 242.373 35.8067 242.413 36C242.453 36.1933 242.523 36.36 242.623 36.5C242.723 36.64 242.853 36.75 243.013 36.83C243.173 36.9033 243.373 36.94 243.613 36.94Z"
    fill="#5078E1"
  /><path
    d="M249.316 36.74V31.66H249.136L248.476 34.06H247.216V33.94L248.056 31H250.636V36.74H252.376V38H247.336V36.74H249.316Z"
    fill="#5078E1"
  /><path
    d="M255.439 36.74V31.66H255.259L254.599 34.06H253.339V33.94L254.179 31H256.759V36.74H258.499V38H253.459V36.74H255.439Z"
    fill="#5078E1"
  /><path
    d="M261.142 34.5C261.142 34.2733 261.225 34.0767 261.392 33.91C261.559 33.7433 261.755 33.66 261.982 33.66C262.209 33.66 262.405 33.7433 262.572 33.91C262.739 34.0767 262.822 34.2733 262.822 34.5C262.822 34.7267 262.739 34.9233 262.572 35.09C262.405 35.2567 262.209 35.34 261.982 35.34C261.755 35.34 261.559 35.2567 261.392 35.09C261.225 34.9233 261.142 34.7267 261.142 34.5ZM259.402 33.72C259.402 32.8133 259.629 32.11 260.082 31.61C260.542 31.11 261.175 30.86 261.982 30.86C262.789 30.86 263.419 31.11 263.872 31.61C264.332 32.11 264.562 32.8133 264.562 33.72V35.28C264.562 36.2133 264.332 36.9233 263.872 37.41C263.419 37.8967 262.789 38.14 261.982 38.14C261.175 38.14 260.542 37.8967 260.082 37.41C259.629 36.9233 259.402 36.2133 259.402 35.28V33.72ZM261.982 36.94C262.222 36.94 262.422 36.9033 262.582 36.83C262.742 36.75 262.872 36.64 262.972 36.5C263.072 36.36 263.142 36.1933 263.182 36C263.222 35.8067 263.242 35.5933 263.242 35.36V33.64C263.242 33.42 263.219 33.2133 263.172 33.02C263.125 32.8267 263.052 32.66 262.952 32.52C262.852 32.38 262.722 32.27 262.562 32.19C262.402 32.1033 262.209 32.06 261.982 32.06C261.755 32.06 261.562 32.1033 261.402 32.19C261.242 32.27 261.112 32.38 261.012 32.52C260.912 32.66 260.839 32.8267 260.792 33.02C260.745 33.2133 260.722 33.42 260.722 33.64V35.36C260.722 35.5933 260.742 35.8067 260.782 36C260.822 36.1933 260.892 36.36 260.992 36.5C261.092 36.64 261.222 36.75 261.382 36.83C261.542 36.9033 261.742 36.94 261.982 36.94Z"
    fill="#5078E1"
  /><path
    d="M267.265 34.5C267.265 34.2733 267.348 34.0767 267.515 33.91C267.682 33.7433 267.878 33.66 268.105 33.66C268.332 33.66 268.528 33.7433 268.695 33.91C268.862 34.0767 268.945 34.2733 268.945 34.5C268.945 34.7267 268.862 34.9233 268.695 35.09C268.528 35.2567 268.332 35.34 268.105 35.34C267.878 35.34 267.682 35.2567 267.515 35.09C267.348 34.9233 267.265 34.7267 267.265 34.5ZM265.525 33.72C265.525 32.8133 265.752 32.11 266.205 31.61C266.665 31.11 267.298 30.86 268.105 30.86C268.912 30.86 269.542 31.11 269.995 31.61C270.455 32.11 270.685 32.8133 270.685 33.72V35.28C270.685 36.2133 270.455 36.9233 269.995 37.41C269.542 37.8967 268.912 38.14 268.105 38.14C267.298 38.14 266.665 37.8967 266.205 37.41C265.752 36.9233 265.525 36.2133 265.525 35.28V33.72ZM268.105 36.94C268.345 36.94 268.545 36.9033 268.705 36.83C268.865 36.75 268.995 36.64 269.095 36.5C269.195 36.36 269.265 36.1933 269.305 36C269.345 35.8067 269.365 35.5933 269.365 35.36V33.64C269.365 33.42 269.342 33.2133 269.295 33.02C269.248 32.8267 269.175 32.66 269.075 32.52C268.975 32.38 268.845 32.27 268.685 32.19C268.525 32.1033 268.332 32.06 268.105 32.06C267.878 32.06 267.685 32.1033 267.525 32.19C267.365 32.27 267.235 32.38 267.135 32.52C267.035 32.66 266.962 32.8267 266.915 33.02C266.868 33.2133 266.845 33.42 266.845 33.64V35.36C266.845 35.5933 266.865 35.8067 266.905 36C266.945 36.1933 267.015 36.36 267.115 36.5C267.215 36.64 267.345 36.75 267.505 36.83C267.665 36.9033 267.865 36.94 268.105 36.94Z"
    fill="#5078E1"
  /><path
    d="M273.808 36.74V31.66H273.628L272.968 34.06H271.708V33.94L272.548 31H275.128V36.74H276.868V38H271.828V36.74H273.808Z"
    fill="#5078E1"
  /><path
    d="M279.511 34.5C279.511 34.2733 279.594 34.0767 279.761 33.91C279.928 33.7433 280.124 33.66 280.351 33.66C280.578 33.66 280.774 33.7433 280.941 33.91C281.108 34.0767 281.191 34.2733 281.191 34.5C281.191 34.7267 281.108 34.9233 280.941 35.09C280.774 35.2567 280.578 35.34 280.351 35.34C280.124 35.34 279.928 35.2567 279.761 35.09C279.594 34.9233 279.511 34.7267 279.511 34.5ZM277.771 33.72C277.771 32.8133 277.998 32.11 278.451 31.61C278.911 31.11 279.544 30.86 280.351 30.86C281.158 30.86 281.788 31.11 282.241 31.61C282.701 32.11 282.931 32.8133 282.931 33.72V35.28C282.931 36.2133 282.701 36.9233 282.241 37.41C281.788 37.8967 281.158 38.14 280.351 38.14C279.544 38.14 278.911 37.8967 278.451 37.41C277.998 36.9233 277.771 36.2133 277.771 35.28V33.72ZM280.351 36.94C280.591 36.94 280.791 36.9033 280.951 36.83C281.111 36.75 281.241 36.64 281.341 36.5C281.441 36.36 281.511 36.1933 281.551 36C281.591 35.8067 281.611 35.5933 281.611 35.36V33.64C281.611 33.42 281.588 33.2133 281.541 33.02C281.494 32.8267 281.421 32.66 281.321 32.52C281.221 32.38 281.091 32.27 280.931 32.19C280.771 32.1033 280.578 32.06 280.351 32.06C280.124 32.06 279.931 32.1033 279.771 32.19C279.611 32.27 279.481 32.38 279.381 32.52C279.281 32.66 279.208 32.8267 279.161 33.02C279.114 33.2133 279.091 33.42 279.091 33.64V35.36C279.091 35.5933 279.111 35.8067 279.151 36C279.191 36.1933 279.261 36.36 279.361 36.5C279.461 36.64 279.591 36.75 279.751 36.83C279.911 36.9033 280.111 36.94 280.351 36.94Z"
    fill="#5078E1"
  /><path
    d="M286.054 36.74V31.66H285.874L285.214 34.06H283.954V33.94L284.794 31H287.374V36.74H289.114V38H284.074V36.74H286.054Z"
    fill="#5078E1"
  /><path
    d="M291.757 34.5C291.757 34.2733 291.84 34.0767 292.007 33.91C292.174 33.7433 292.37 33.66 292.597 33.66C292.824 33.66 293.02 33.7433 293.187 33.91C293.354 34.0767 293.437 34.2733 293.437 34.5C293.437 34.7267 293.354 34.9233 293.187 35.09C293.02 35.2567 292.824 35.34 292.597 35.34C292.37 35.34 292.174 35.2567 292.007 35.09C291.84 34.9233 291.757 34.7267 291.757 34.5ZM290.017 33.72C290.017 32.8133 290.244 32.11 290.697 31.61C291.157 31.11 291.79 30.86 292.597 30.86C293.404 30.86 294.034 31.11 294.487 31.61C294.947 32.11 295.177 32.8133 295.177 33.72V35.28C295.177 36.2133 294.947 36.9233 294.487 37.41C294.034 37.8967 293.404 38.14 292.597 38.14C291.79 38.14 291.157 37.8967 290.697 37.41C290.244 36.9233 290.017 36.2133 290.017 35.28V33.72ZM292.597 36.94C292.837 36.94 293.037 36.9033 293.197 36.83C293.357 36.75 293.487 36.64 293.587 36.5C293.687 36.36 293.757 36.1933 293.797 36C293.837 35.8067 293.857 35.5933 293.857 35.36V33.64C293.857 33.42 293.834 33.2133 293.787 33.02C293.74 32.8267 293.667 32.66 293.567 32.52C293.467 32.38 293.337 32.27 293.177 32.19C293.017 32.1033 292.824 32.06 292.597 32.06C292.37 32.06 292.177 32.1033 292.017 32.19C291.857 32.27 291.727 32.38 291.627 32.52C291.527 32.66 291.454 32.8267 291.407 33.02C291.36 33.2133 291.337 33.42 291.337 33.64V35.36C291.337 35.5933 291.357 35.8067 291.397 36C291.437 36.1933 291.507 36.36 291.607 36.5C291.707 36.64 291.837 36.75 291.997 36.83C292.157 36.9033 292.357 36.94 292.597 36.94Z"
    fill="#5078E1"
  /><path
    d="M298.3 36.74V31.66H298.12L297.46 34.06H296.2V33.94L297.04 31H299.62V36.74H301.36V38H296.32V36.74H298.3Z"
    fill="#5078E1"
  /><path
    d="M304.423 36.74V31.66H304.243L303.583 34.06H302.323V33.94L303.163 31H305.743V36.74H307.483V38H302.443V36.74H304.423Z"
    fill="#5078E1"
  /><path
    d="M310.126 34.5C310.126 34.2733 310.21 34.0767 310.376 33.91C310.543 33.7433 310.74 33.66 310.966 33.66C311.193 33.66 311.39 33.7433 311.556 33.91C311.723 34.0767 311.806 34.2733 311.806 34.5C311.806 34.7267 311.723 34.9233 311.556 35.09C311.39 35.2567 311.193 35.34 310.966 35.34C310.74 35.34 310.543 35.2567 310.376 35.09C310.21 34.9233 310.126 34.7267 310.126 34.5ZM308.386 33.72C308.386 32.8133 308.613 32.11 309.066 31.61C309.526 31.11 310.16 30.86 310.966 30.86C311.773 30.86 312.403 31.11 312.856 31.61C313.316 32.11 313.546 32.8133 313.546 33.72V35.28C313.546 36.2133 313.316 36.9233 312.856 37.41C312.403 37.8967 311.773 38.14 310.966 38.14C310.16 38.14 309.526 37.8967 309.066 37.41C308.613 36.9233 308.386 36.2133 308.386 35.28V33.72ZM310.966 36.94C311.206 36.94 311.406 36.9033 311.566 36.83C311.726 36.75 311.856 36.64 311.956 36.5C312.056 36.36 312.126 36.1933 312.166 36C312.206 35.8067 312.226 35.5933 312.226 35.36V33.64C312.226 33.42 312.203 33.2133 312.156 33.02C312.11 32.8267 312.036 32.66 311.936 32.52C311.836 32.38 311.706 32.27 311.546 32.19C311.386 32.1033 311.193 32.06 310.966 32.06C310.74 32.06 310.546 32.1033 310.386 32.19C310.226 32.27 310.096 32.38 309.996 32.52C309.896 32.66 309.823 32.8267 309.776 33.02C309.73 33.2133 309.706 33.42 309.706 33.64V35.36C309.706 35.5933 309.726 35.8067 309.766 36C309.806 36.1933 309.876 36.36 309.976 36.5C310.076 36.64 310.206 36.75 310.366 36.83C310.526 36.9033 310.726 36.94 310.966 36.94Z"
    fill="#5078E1"
  /><path
    d="M316.249 34.5C316.249 34.2733 316.333 34.0767 316.499 33.91C316.666 33.7433 316.863 33.66 317.089 33.66C317.316 33.66 317.513 33.7433 317.679 33.91C317.846 34.0767 317.929 34.2733 317.929 34.5C317.929 34.7267 317.846 34.9233 317.679 35.09C317.513 35.2567 317.316 35.34 317.089 35.34C316.863 35.34 316.666 35.2567 316.499 35.09C316.333 34.9233 316.249 34.7267 316.249 34.5ZM314.509 33.72C314.509 32.8133 314.736 32.11 315.189 31.61C315.649 31.11 316.283 30.86 317.089 30.86C317.896 30.86 318.526 31.11 318.979 31.61C319.439 32.11 319.669 32.8133 319.669 33.72V35.28C319.669 36.2133 319.439 36.9233 318.979 37.41C318.526 37.8967 317.896 38.14 317.089 38.14C316.283 38.14 315.649 37.8967 315.189 37.41C314.736 36.9233 314.509 36.2133 314.509 35.28V33.72ZM317.089 36.94C317.329 36.94 317.529 36.9033 317.689 36.83C317.849 36.75 317.979 36.64 318.079 36.5C318.179 36.36 318.249 36.1933 318.289 36C318.329 35.8067 318.349 35.5933 318.349 35.36V33.64C318.349 33.42 318.326 33.2133 318.279 33.02C318.233 32.8267 318.159 32.66 318.059 32.52C317.959 32.38 317.829 32.27 317.669 32.19C317.509 32.1033 317.316 32.06 317.089 32.06C316.863 32.06 316.669 32.1033 316.509 32.19C316.349 32.27 316.219 32.38 316.119 32.52C316.019 32.66 315.946 32.8267 315.899 33.02C315.853 33.2133 315.829 33.42 315.829 33.64V35.36C315.829 35.5933 315.849 35.8067 315.889 36C315.929 36.1933 315.999 36.36 316.099 36.5C316.199 36.64 316.329 36.75 316.489 36.83C316.649 36.9033 316.849 36.94 317.089 36.94Z"
    fill="#5078E1"
  /><path
    d="M322.372 34.5C322.372 34.2733 322.456 34.0767 322.622 33.91C322.789 33.7433 322.986 33.66 323.212 33.66C323.439 33.66 323.636 33.7433 323.802 33.91C323.969 34.0767 324.052 34.2733 324.052 34.5C324.052 34.7267 323.969 34.9233 323.802 35.09C323.636 35.2567 323.439 35.34 323.212 35.34C322.986 35.34 322.789 35.2567 322.622 35.09C322.456 34.9233 322.372 34.7267 322.372 34.5ZM320.632 33.72C320.632 32.8133 320.859 32.11 321.312 31.61C321.772 31.11 322.406 30.86 323.212 30.86C324.019 30.86 324.649 31.11 325.102 31.61C325.562 32.11 325.792 32.8133 325.792 33.72V35.28C325.792 36.2133 325.562 36.9233 325.102 37.41C324.649 37.8967 324.019 38.14 323.212 38.14C322.406 38.14 321.772 37.8967 321.312 37.41C320.859 36.9233 320.632 36.2133 320.632 35.28V33.72ZM323.212 36.94C323.452 36.94 323.652 36.9033 323.812 36.83C323.972 36.75 324.102 36.64 324.202 36.5C324.302 36.36 324.372 36.1933 324.412 36C324.452 35.8067 324.472 35.5933 324.472 35.36V33.64C324.472 33.42 324.449 33.2133 324.402 33.02C324.356 32.8267 324.282 32.66 324.182 32.52C324.082 32.38 323.952 32.27 323.792 32.19C323.632 32.1033 323.439 32.06 323.212 32.06C322.986 32.06 322.792 32.1033 322.632 32.19C322.472 32.27 322.342 32.38 322.242 32.52C322.142 32.66 322.069 32.8267 322.022 33.02C321.976 33.2133 321.952 33.42 321.952 33.64V35.36C321.952 35.5933 321.972 35.8067 322.012 36C322.052 36.1933 322.122 36.36 322.222 36.5C322.322 36.64 322.452 36.75 322.612 36.83C322.772 36.9033 322.972 36.94 323.212 36.94Z"
    fill="#5078E1"
  /><path
    d="M328.915 36.74V31.66H328.735L328.075 34.06H326.815V33.94L327.655 31H330.235V36.74H331.975V38H326.935V36.74H328.915Z"
    fill="#5078E1"
  /><path
    d="M335.038 36.74V31.66H334.858L334.198 34.06H332.938V33.94L333.778 31H336.358V36.74H338.098V38H333.058V36.74H335.038Z"
    fill="#5078E1"
  /><path
    d="M340.741 34.5C340.741 34.2733 340.825 34.0767 340.991 33.91C341.158 33.7433 341.355 33.66 341.581 33.66C341.808 33.66 342.005 33.7433 342.171 33.91C342.338 34.0767 342.421 34.2733 342.421 34.5C342.421 34.7267 342.338 34.9233 342.171 35.09C342.005 35.2567 341.808 35.34 341.581 35.34C341.355 35.34 341.158 35.2567 340.991 35.09C340.825 34.9233 340.741 34.7267 340.741 34.5ZM339.001 33.72C339.001 32.8133 339.228 32.11 339.681 31.61C340.141 31.11 340.775 30.86 341.581 30.86C342.388 30.86 343.018 31.11 343.471 31.61C343.931 32.11 344.161 32.8133 344.161 33.72V35.28C344.161 36.2133 343.931 36.9233 343.471 37.41C343.018 37.8967 342.388 38.14 341.581 38.14C340.775 38.14 340.141 37.8967 339.681 37.41C339.228 36.9233 339.001 36.2133 339.001 35.28V33.72ZM341.581 36.94C341.821 36.94 342.021 36.9033 342.181 36.83C342.341 36.75 342.471 36.64 342.571 36.5C342.671 36.36 342.741 36.1933 342.781 36C342.821 35.8067 342.841 35.5933 342.841 35.36V33.64C342.841 33.42 342.818 33.2133 342.771 33.02C342.725 32.8267 342.651 32.66 342.551 32.52C342.451 32.38 342.321 32.27 342.161 32.19C342.001 32.1033 341.808 32.06 341.581 32.06C341.355 32.06 341.161 32.1033 341.001 32.19C340.841 32.27 340.711 32.38 340.611 32.52C340.511 32.66 340.438 32.8267 340.391 33.02C340.345 33.2133 340.321 33.42 340.321 33.64V35.36C340.321 35.5933 340.341 35.8067 340.381 36C340.421 36.1933 340.491 36.36 340.591 36.5C340.691 36.64 340.821 36.75 340.981 36.83C341.141 36.9033 341.341 36.94 341.581 36.94Z"
    fill="#5078E1"
  /><path
    d="M347.285 36.74V31.66H347.105L346.445 34.06H345.185V33.94L346.025 31H348.605V36.74H350.345V38H345.305V36.74H347.285Z"
    fill="#5078E1"
  /><path
    d="M353.408 36.74V31.66H353.228L352.568 34.06H351.308V33.94L352.148 31H354.728V36.74H356.468V38H351.428V36.74H353.408Z"
    fill="#5078E1"
  /><path
    d="M359.531 36.74V31.66H359.351L358.691 34.06H357.431V33.94L358.271 31H360.851V36.74H362.591V38H357.551V36.74H359.531Z"
    fill="#5078E1"
  /><path
    d="M365.234 34.5C365.234 34.2733 365.317 34.0767 365.484 33.91C365.65 33.7433 365.847 33.66 366.074 33.66C366.3 33.66 366.497 33.7433 366.664 33.91C366.83 34.0767 366.914 34.2733 366.914 34.5C366.914 34.7267 366.83 34.9233 366.664 35.09C366.497 35.2567 366.3 35.34 366.074 35.34C365.847 35.34 365.65 35.2567 365.484 35.09C365.317 34.9233 365.234 34.7267 365.234 34.5ZM363.494 33.72C363.494 32.8133 363.72 32.11 364.174 31.61C364.634 31.11 365.267 30.86 366.074 30.86C366.88 30.86 367.51 31.11 367.964 31.61C368.424 32.11 368.654 32.8133 368.654 33.72V35.28C368.654 36.2133 368.424 36.9233 367.964 37.41C367.51 37.8967 366.88 38.14 366.074 38.14C365.267 38.14 364.634 37.8967 364.174 37.41C363.72 36.9233 363.494 36.2133 363.494 35.28V33.72ZM366.074 36.94C366.314 36.94 366.514 36.9033 366.674 36.83C366.834 36.75 366.964 36.64 367.064 36.5C367.164 36.36 367.234 36.1933 367.274 36C367.314 35.8067 367.334 35.5933 367.334 35.36V33.64C367.334 33.42 367.31 33.2133 367.264 33.02C367.217 32.8267 367.144 32.66 367.044 32.52C366.944 32.38 366.814 32.27 366.654 32.19C366.494 32.1033 366.3 32.06 366.074 32.06C365.847 32.06 365.654 32.1033 365.494 32.19C365.334 32.27 365.204 32.38 365.104 32.52C365.004 32.66 364.93 32.8267 364.884 33.02C364.837 33.2133 364.814 33.42 364.814 33.64V35.36C364.814 35.5933 364.834 35.8067 364.874 36C364.914 36.1933 364.984 36.36 365.084 36.5C365.184 36.64 365.314 36.75 365.474 36.83C365.634 36.9033 365.834 36.94 366.074 36.94Z"
    fill="#5078E1"
  /><path
    d="M371.777 36.74V31.66H371.597L370.937 34.06H369.677V33.94L370.517 31H373.097V36.74H374.837V38H369.797V36.74H371.777Z"
    fill="#5078E1"
  /><path
    d="M377.48 34.5C377.48 34.2733 377.563 34.0767 377.73 33.91C377.896 33.7433 378.093 33.66 378.32 33.66C378.546 33.66 378.743 33.7433 378.91 33.91C379.076 34.0767 379.16 34.2733 379.16 34.5C379.16 34.7267 379.076 34.9233 378.91 35.09C378.743 35.2567 378.546 35.34 378.32 35.34C378.093 35.34 377.896 35.2567 377.73 35.09C377.563 34.9233 377.48 34.7267 377.48 34.5ZM375.74 33.72C375.74 32.8133 375.966 32.11 376.42 31.61C376.88 31.11 377.513 30.86 378.32 30.86C379.126 30.86 379.756 31.11 380.21 31.61C380.67 32.11 380.9 32.8133 380.9 33.72V35.28C380.9 36.2133 380.67 36.9233 380.21 37.41C379.756 37.8967 379.126 38.14 378.32 38.14C377.513 38.14 376.88 37.8967 376.42 37.41C375.966 36.9233 375.74 36.2133 375.74 35.28V33.72ZM378.32 36.94C378.56 36.94 378.76 36.9033 378.92 36.83C379.08 36.75 379.21 36.64 379.31 36.5C379.41 36.36 379.48 36.1933 379.52 36C379.56 35.8067 379.58 35.5933 379.58 35.36V33.64C379.58 33.42 379.556 33.2133 379.51 33.02C379.463 32.8267 379.39 32.66 379.29 32.52C379.19 32.38 379.06 32.27 378.9 32.19C378.74 32.1033 378.546 32.06 378.32 32.06C378.093 32.06 377.9 32.1033 377.74 32.19C377.58 32.27 377.45 32.38 377.35 32.52C377.25 32.66 377.176 32.8267 377.13 33.02C377.083 33.2133 377.06 33.42 377.06 33.64V35.36C377.06 35.5933 377.08 35.8067 377.12 36C377.16 36.1933 377.23 36.36 377.33 36.5C377.43 36.64 377.56 36.75 377.72 36.83C377.88 36.9033 378.08 36.94 378.32 36.94Z"
    fill="#5078E1"
  /><path
    d="M383.603 34.5C383.603 34.2733 383.686 34.0767 383.853 33.91C384.019 33.7433 384.216 33.66 384.443 33.66C384.669 33.66 384.866 33.7433 385.033 33.91C385.199 34.0767 385.283 34.2733 385.283 34.5C385.283 34.7267 385.199 34.9233 385.033 35.09C384.866 35.2567 384.669 35.34 384.443 35.34C384.216 35.34 384.019 35.2567 383.853 35.09C383.686 34.9233 383.603 34.7267 383.603 34.5ZM381.863 33.72C381.863 32.8133 382.089 32.11 382.543 31.61C383.003 31.11 383.636 30.86 384.443 30.86C385.249 30.86 385.879 31.11 386.333 31.61C386.793 32.11 387.023 32.8133 387.023 33.72V35.28C387.023 36.2133 386.793 36.9233 386.333 37.41C385.879 37.8967 385.249 38.14 384.443 38.14C383.636 38.14 383.003 37.8967 382.543 37.41C382.089 36.9233 381.863 36.2133 381.863 35.28V33.72ZM384.443 36.94C384.683 36.94 384.883 36.9033 385.043 36.83C385.203 36.75 385.333 36.64 385.433 36.5C385.533 36.36 385.603 36.1933 385.643 36C385.683 35.8067 385.703 35.5933 385.703 35.36V33.64C385.703 33.42 385.679 33.2133 385.633 33.02C385.586 32.8267 385.513 32.66 385.413 32.52C385.313 32.38 385.183 32.27 385.023 32.19C384.863 32.1033 384.669 32.06 384.443 32.06C384.216 32.06 384.023 32.1033 383.863 32.19C383.703 32.27 383.573 32.38 383.473 32.52C383.373 32.66 383.299 32.8267 383.253 33.02C383.206 33.2133 383.183 33.42 383.183 33.64V35.36C383.183 35.5933 383.203 35.8067 383.243 36C383.283 36.1933 383.353 36.36 383.453 36.5C383.553 36.64 383.683 36.75 383.843 36.83C384.003 36.9033 384.203 36.94 384.443 36.94Z"
    fill="#5078E1"
  /><path
    d="M389.726 34.5C389.726 34.2733 389.809 34.0767 389.976 33.91C390.143 33.7433 390.339 33.66 390.566 33.66C390.793 33.66 390.989 33.7433 391.156 33.91C391.323 34.0767 391.406 34.2733 391.406 34.5C391.406 34.7267 391.323 34.9233 391.156 35.09C390.989 35.2567 390.793 35.34 390.566 35.34C390.339 35.34 390.143 35.2567 389.976 35.09C389.809 34.9233 389.726 34.7267 389.726 34.5ZM387.986 33.72C387.986 32.8133 388.213 32.11 388.666 31.61C389.126 31.11 389.759 30.86 390.566 30.86C391.373 30.86 392.003 31.11 392.456 31.61C392.916 32.11 393.146 32.8133 393.146 33.72V35.28C393.146 36.2133 392.916 36.9233 392.456 37.41C392.003 37.8967 391.373 38.14 390.566 38.14C389.759 38.14 389.126 37.8967 388.666 37.41C388.213 36.9233 387.986 36.2133 387.986 35.28V33.72ZM390.566 36.94C390.806 36.94 391.006 36.9033 391.166 36.83C391.326 36.75 391.456 36.64 391.556 36.5C391.656 36.36 391.726 36.1933 391.766 36C391.806 35.8067 391.826 35.5933 391.826 35.36V33.64C391.826 33.42 391.803 33.2133 391.756 33.02C391.709 32.8267 391.636 32.66 391.536 32.52C391.436 32.38 391.306 32.27 391.146 32.19C390.986 32.1033 390.793 32.06 390.566 32.06C390.339 32.06 390.146 32.1033 389.986 32.19C389.826 32.27 389.696 32.38 389.596 32.52C389.496 32.66 389.423 32.8267 389.376 33.02C389.329 33.2133 389.306 33.42 389.306 33.64V35.36C389.306 35.5933 389.326 35.8067 389.366 36C389.406 36.1933 389.476 36.36 389.576 36.5C389.676 36.64 389.806 36.75 389.966 36.83C390.126 36.9033 390.326 36.94 390.566 36.94Z"
    fill="#5078E1"
  /><path
    d="M396.269 36.74V31.66H396.089L395.429 34.06H394.169V33.94L395.009 31H397.589V36.74H399.329V38H394.289V36.74H396.269Z"
    fill="#5078E1"
  /><path
    d="M402.392 36.74V31.66H402.212L401.552 34.06H400.292V33.94L401.132 31H403.712V36.74H405.452V38H400.412V36.74H402.392Z"
    fill="#5078E1"
  /><path
    d="M408.515 36.74V31.66H408.335L407.675 34.06H406.415V33.94L407.255 31H409.835V36.74H411.575V38H406.535V36.74H408.515Z"
    fill="#5078E1"
  /><path
    d="M414.218 34.5C414.218 34.2733 414.301 34.0767 414.468 33.91C414.635 33.7433 414.831 33.66 415.058 33.66C415.285 33.66 415.481 33.7433 415.648 33.91C415.815 34.0767 415.898 34.2733 415.898 34.5C415.898 34.7267 415.815 34.9233 415.648 35.09C415.481 35.2567 415.285 35.34 415.058 35.34C414.831 35.34 414.635 35.2567 414.468 35.09C414.301 34.9233 414.218 34.7267 414.218 34.5ZM412.478 33.72C412.478 32.8133 412.705 32.11 413.158 31.61C413.618 31.11 414.251 30.86 415.058 30.86C415.865 30.86 416.495 31.11 416.948 31.61C417.408 32.11 417.638 32.8133 417.638 33.72V35.28C417.638 36.2133 417.408 36.9233 416.948 37.41C416.495 37.8967 415.865 38.14 415.058 38.14C414.251 38.14 413.618 37.8967 413.158 37.41C412.705 36.9233 412.478 36.2133 412.478 35.28V33.72ZM415.058 36.94C415.298 36.94 415.498 36.9033 415.658 36.83C415.818 36.75 415.948 36.64 416.048 36.5C416.148 36.36 416.218 36.1933 416.258 36C416.298 35.8067 416.318 35.5933 416.318 35.36V33.64C416.318 33.42 416.295 33.2133 416.248 33.02C416.201 32.8267 416.128 32.66 416.028 32.52C415.928 32.38 415.798 32.27 415.638 32.19C415.478 32.1033 415.285 32.06 415.058 32.06C414.831 32.06 414.638 32.1033 414.478 32.19C414.318 32.27 414.188 32.38 414.088 32.52C413.988 32.66 413.915 32.8267 413.868 33.02C413.821 33.2133 413.798 33.42 413.798 33.64V35.36C413.798 35.5933 413.818 35.8067 413.858 36C413.898 36.1933 413.968 36.36 414.068 36.5C414.168 36.64 414.298 36.75 414.458 36.83C414.618 36.9033 414.818 36.94 415.058 36.94Z"
    fill="#5078E1"
  /><path
    d="M420.341 34.5C420.341 34.2733 420.424 34.0767 420.591 33.91C420.758 33.7433 420.954 33.66 421.181 33.66C421.408 33.66 421.604 33.7433 421.771 33.91C421.938 34.0767 422.021 34.2733 422.021 34.5C422.021 34.7267 421.938 34.9233 421.771 35.09C421.604 35.2567 421.408 35.34 421.181 35.34C420.954 35.34 420.758 35.2567 420.591 35.09C420.424 34.9233 420.341 34.7267 420.341 34.5ZM418.601 33.72C418.601 32.8133 418.828 32.11 419.281 31.61C419.741 31.11 420.374 30.86 421.181 30.86C421.988 30.86 422.618 31.11 423.071 31.61C423.531 32.11 423.761 32.8133 423.761 33.72V35.28C423.761 36.2133 423.531 36.9233 423.071 37.41C422.618 37.8967 421.988 38.14 421.181 38.14C420.374 38.14 419.741 37.8967 419.281 37.41C418.828 36.9233 418.601 36.2133 418.601 35.28V33.72ZM421.181 36.94C421.421 36.94 421.621 36.9033 421.781 36.83C421.941 36.75 422.071 36.64 422.171 36.5C422.271 36.36 422.341 36.1933 422.381 36C422.421 35.8067 422.441 35.5933 422.441 35.36V33.64C422.441 33.42 422.418 33.2133 422.371 33.02C422.324 32.8267 422.251 32.66 422.151 32.52C422.051 32.38 421.921 32.27 421.761 32.19C421.601 32.1033 421.408 32.06 421.181 32.06C420.954 32.06 420.761 32.1033 420.601 32.19C420.441 32.27 420.311 32.38 420.211 32.52C420.111 32.66 420.038 32.8267 419.991 33.02C419.944 33.2133 419.921 33.42 419.921 33.64V35.36C419.921 35.5933 419.941 35.8067 419.981 36C420.021 36.1933 420.091 36.36 420.191 36.5C420.291 36.64 420.421 36.75 420.581 36.83C420.741 36.9033 420.941 36.94 421.181 36.94Z"
    fill="#5078E1"
  /><path
    d="M426.884 36.74V31.66H426.704L426.044 34.06H424.784V33.94L425.624 31H428.204V36.74H429.944V38H424.904V36.74H426.884Z"
    fill="#5078E1"
  /><path
    d="M433.007 36.74V31.66H432.827L432.167 34.06H430.907V33.94L431.747 31H434.327V36.74H436.067V38H431.027V36.74H433.007Z"
    fill="#5078E1"
  /><path
    d="M438.71 34.5C438.71 34.2733 438.794 34.0767 438.96 33.91C439.127 33.7433 439.324 33.66 439.55 33.66C439.777 33.66 439.974 33.7433 440.14 33.91C440.307 34.0767 440.39 34.2733 440.39 34.5C440.39 34.7267 440.307 34.9233 440.14 35.09C439.974 35.2567 439.777 35.34 439.55 35.34C439.324 35.34 439.127 35.2567 438.96 35.09C438.794 34.9233 438.71 34.7267 438.71 34.5ZM436.97 33.72C436.97 32.8133 437.197 32.11 437.65 31.61C438.11 31.11 438.744 30.86 439.55 30.86C440.357 30.86 440.987 31.11 441.44 31.61C441.9 32.11 442.13 32.8133 442.13 33.72V35.28C442.13 36.2133 441.9 36.9233 441.44 37.41C440.987 37.8967 440.357 38.14 439.55 38.14C438.744 38.14 438.11 37.8967 437.65 37.41C437.197 36.9233 436.97 36.2133 436.97 35.28V33.72ZM439.55 36.94C439.79 36.94 439.99 36.9033 440.15 36.83C440.31 36.75 440.44 36.64 440.54 36.5C440.64 36.36 440.71 36.1933 440.75 36C440.79 35.8067 440.81 35.5933 440.81 35.36V33.64C440.81 33.42 440.787 33.2133 440.74 33.02C440.694 32.8267 440.62 32.66 440.52 32.52C440.42 32.38 440.29 32.27 440.13 32.19C439.97 32.1033 439.777 32.06 439.55 32.06C439.324 32.06 439.13 32.1033 438.97 32.19C438.81 32.27 438.68 32.38 438.58 32.52C438.48 32.66 438.407 32.8267 438.36 33.02C438.314 33.2133 438.29 33.42 438.29 33.64V35.36C438.29 35.5933 438.31 35.8067 438.35 36C438.39 36.1933 438.46 36.36 438.56 36.5C438.66 36.64 438.79 36.75 438.95 36.83C439.11 36.9033 439.31 36.94 439.55 36.94Z"
    fill="#5078E1"
  /><path
    d="M444.833 34.5C444.833 34.2733 444.917 34.0767 445.083 33.91C445.25 33.7433 445.447 33.66 445.673 33.66C445.9 33.66 446.097 33.7433 446.263 33.91C446.43 34.0767 446.513 34.2733 446.513 34.5C446.513 34.7267 446.43 34.9233 446.263 35.09C446.097 35.2567 445.9 35.34 445.673 35.34C445.447 35.34 445.25 35.2567 445.083 35.09C444.917 34.9233 444.833 34.7267 444.833 34.5ZM443.093 33.72C443.093 32.8133 443.32 32.11 443.773 31.61C444.233 31.11 444.867 30.86 445.673 30.86C446.48 30.86 447.11 31.11 447.563 31.61C448.023 32.11 448.253 32.8133 448.253 33.72V35.28C448.253 36.2133 448.023 36.9233 447.563 37.41C447.11 37.8967 446.48 38.14 445.673 38.14C444.867 38.14 444.233 37.8967 443.773 37.41C443.32 36.9233 443.093 36.2133 443.093 35.28V33.72ZM445.673 36.94C445.913 36.94 446.113 36.9033 446.273 36.83C446.433 36.75 446.563 36.64 446.663 36.5C446.763 36.36 446.833 36.1933 446.873 36C446.913 35.8067 446.933 35.5933 446.933 35.36V33.64C446.933 33.42 446.91 33.2133 446.863 33.02C446.817 32.8267 446.743 32.66 446.643 32.52C446.543 32.38 446.413 32.27 446.253 32.19C446.093 32.1033 445.9 32.06 445.673 32.06C445.447 32.06 445.253 32.1033 445.093 32.19C444.933 32.27 444.803 32.38 444.703 32.52C444.603 32.66 444.53 32.8267 444.483 33.02C444.437 33.2133 444.413 33.42 444.413 33.64V35.36C444.413 35.5933 444.433 35.8067 444.473 36C444.513 36.1933 444.583 36.36 444.683 36.5C444.783 36.64 444.913 36.75 445.073 36.83C445.233 36.9033 445.433 36.94 445.673 36.94Z"
    fill="#5078E1"
  /><path
    d="M451.376 36.74V31.66H451.196L450.536 34.06H449.276V33.94L450.116 31H452.696V36.74H454.436V38H449.396V36.74H451.376Z"
    fill="#5078E1"
  /><path
    d="M457.079 34.5C457.079 34.2733 457.163 34.0767 457.329 33.91C457.496 33.7433 457.693 33.66 457.919 33.66C458.146 33.66 458.343 33.7433 458.509 33.91C458.676 34.0767 458.759 34.2733 458.759 34.5C458.759 34.7267 458.676 34.9233 458.509 35.09C458.343 35.2567 458.146 35.34 457.919 35.34C457.693 35.34 457.496 35.2567 457.329 35.09C457.163 34.9233 457.079 34.7267 457.079 34.5ZM455.339 33.72C455.339 32.8133 455.566 32.11 456.019 31.61C456.479 31.11 457.113 30.86 457.919 30.86C458.726 30.86 459.356 31.11 459.809 31.61C460.269 32.11 460.499 32.8133 460.499 33.72V35.28C460.499 36.2133 460.269 36.9233 459.809 37.41C459.356 37.8967 458.726 38.14 457.919 38.14C457.113 38.14 456.479 37.8967 456.019 37.41C455.566 36.9233 455.339 36.2133 455.339 35.28V33.72ZM457.919 36.94C458.159 36.94 458.359 36.9033 458.519 36.83C458.679 36.75 458.809 36.64 458.909 36.5C459.009 36.36 459.079 36.1933 459.119 36C459.159 35.8067 459.179 35.5933 459.179 35.36V33.64C459.179 33.42 459.156 33.2133 459.109 33.02C459.063 32.8267 458.989 32.66 458.889 32.52C458.789 32.38 458.659 32.27 458.499 32.19C458.339 32.1033 458.146 32.06 457.919 32.06C457.693 32.06 457.499 32.1033 457.339 32.19C457.179 32.27 457.049 32.38 456.949 32.52C456.849 32.66 456.776 32.8267 456.729 33.02C456.683 33.2133 456.659 33.42 456.659 33.64V35.36C456.659 35.5933 456.679 35.8067 456.719 36C456.759 36.1933 456.829 36.36 456.929 36.5C457.029 36.64 457.159 36.75 457.319 36.83C457.479 36.9033 457.679 36.94 457.919 36.94Z"
    fill="#5078E1"
  /><path
    d="M463.202 34.5C463.202 34.2733 463.286 34.0767 463.452 33.91C463.619 33.7433 463.816 33.66 464.042 33.66C464.269 33.66 464.466 33.7433 464.632 33.91C464.799 34.0767 464.882 34.2733 464.882 34.5C464.882 34.7267 464.799 34.9233 464.632 35.09C464.466 35.2567 464.269 35.34 464.042 35.34C463.816 35.34 463.619 35.2567 463.452 35.09C463.286 34.9233 463.202 34.7267 463.202 34.5ZM461.462 33.72C461.462 32.8133 461.689 32.11 462.142 31.61C462.602 31.11 463.236 30.86 464.042 30.86C464.849 30.86 465.479 31.11 465.932 31.61C466.392 32.11 466.622 32.8133 466.622 33.72V35.28C466.622 36.2133 466.392 36.9233 465.932 37.41C465.479 37.8967 464.849 38.14 464.042 38.14C463.236 38.14 462.602 37.8967 462.142 37.41C461.689 36.9233 461.462 36.2133 461.462 35.28V33.72ZM464.042 36.94C464.282 36.94 464.482 36.9033 464.642 36.83C464.802 36.75 464.932 36.64 465.032 36.5C465.132 36.36 465.202 36.1933 465.242 36C465.282 35.8067 465.302 35.5933 465.302 35.36V33.64C465.302 33.42 465.279 33.2133 465.232 33.02C465.186 32.8267 465.112 32.66 465.012 32.52C464.912 32.38 464.782 32.27 464.622 32.19C464.462 32.1033 464.269 32.06 464.042 32.06C463.816 32.06 463.622 32.1033 463.462 32.19C463.302 32.27 463.172 32.38 463.072 32.52C462.972 32.66 462.899 32.8267 462.852 33.02C462.806 33.2133 462.782 33.42 462.782 33.64V35.36C462.782 35.5933 462.802 35.8067 462.842 36C462.882 36.1933 462.952 36.36 463.052 36.5C463.152 36.64 463.282 36.75 463.442 36.83C463.602 36.9033 463.802 36.94 464.042 36.94Z"
    fill="#5078E1"
  /><path
    d="M469.325 34.5C469.325 34.2733 469.409 34.0767 469.575 33.91C469.742 33.7433 469.939 33.66 470.165 33.66C470.392 33.66 470.589 33.7433 470.755 33.91C470.922 34.0767 471.005 34.2733 471.005 34.5C471.005 34.7267 470.922 34.9233 470.755 35.09C470.589 35.2567 470.392 35.34 470.165 35.34C469.939 35.34 469.742 35.2567 469.575 35.09C469.409 34.9233 469.325 34.7267 469.325 34.5ZM467.585 33.72C467.585 32.8133 467.812 32.11 468.265 31.61C468.725 31.11 469.359 30.86 470.165 30.86C470.972 30.86 471.602 31.11 472.055 31.61C472.515 32.11 472.745 32.8133 472.745 33.72V35.28C472.745 36.2133 472.515 36.9233 472.055 37.41C471.602 37.8967 470.972 38.14 470.165 38.14C469.359 38.14 468.725 37.8967 468.265 37.41C467.812 36.9233 467.585 36.2133 467.585 35.28V33.72ZM470.165 36.94C470.405 36.94 470.605 36.9033 470.765 36.83C470.925 36.75 471.055 36.64 471.155 36.5C471.255 36.36 471.325 36.1933 471.365 36C471.405 35.8067 471.425 35.5933 471.425 35.36V33.64C471.425 33.42 471.402 33.2133 471.355 33.02C471.309 32.8267 471.235 32.66 471.135 32.52C471.035 32.38 470.905 32.27 470.745 32.19C470.585 32.1033 470.392 32.06 470.165 32.06C469.939 32.06 469.745 32.1033 469.585 32.19C469.425 32.27 469.295 32.38 469.195 32.52C469.095 32.66 469.022 32.8267 468.975 33.02C468.929 33.2133 468.905 33.42 468.905 33.64V35.36C468.905 35.5933 468.925 35.8067 468.965 36C469.005 36.1933 469.075 36.36 469.175 36.5C469.275 36.64 469.405 36.75 469.565 36.83C469.725 36.9033 469.925 36.94 470.165 36.94Z"
    fill="#5078E1"
  /><path
    d="M475.449 34.5C475.449 34.2733 475.532 34.0767 475.699 33.91C475.865 33.7433 476.062 33.66 476.288 33.66C476.515 33.66 476.712 33.7433 476.879 33.91C477.045 34.0767 477.129 34.2733 477.129 34.5C477.129 34.7267 477.045 34.9233 476.879 35.09C476.712 35.2567 476.515 35.34 476.288 35.34C476.062 35.34 475.865 35.2567 475.699 35.09C475.532 34.9233 475.449 34.7267 475.449 34.5ZM473.709 33.72C473.709 32.8133 473.935 32.11 474.389 31.61C474.849 31.11 475.482 30.86 476.288 30.86C477.095 30.86 477.725 31.11 478.179 31.61C478.639 32.11 478.869 32.8133 478.869 33.72V35.28C478.869 36.2133 478.639 36.9233 478.179 37.41C477.725 37.8967 477.095 38.14 476.288 38.14C475.482 38.14 474.849 37.8967 474.389 37.41C473.935 36.9233 473.709 36.2133 473.709 35.28V33.72ZM476.288 36.94C476.528 36.94 476.729 36.9033 476.889 36.83C477.049 36.75 477.179 36.64 477.279 36.5C477.379 36.36 477.449 36.1933 477.489 36C477.529 35.8067 477.549 35.5933 477.549 35.36V33.64C477.549 33.42 477.525 33.2133 477.479 33.02C477.432 32.8267 477.358 32.66 477.258 32.52C477.158 32.38 477.029 32.27 476.869 32.19C476.709 32.1033 476.515 32.06 476.288 32.06C476.062 32.06 475.869 32.1033 475.709 32.19C475.549 32.27 475.418 32.38 475.318 32.52C475.218 32.66 475.145 32.8267 475.099 33.02C475.052 33.2133 475.029 33.42 475.029 33.64V35.36C475.029 35.5933 475.049 35.8067 475.089 36C475.129 36.1933 475.199 36.36 475.299 36.5C475.399 36.64 475.529 36.75 475.689 36.83C475.849 36.9033 476.048 36.94 476.288 36.94Z"
    fill="#5078E1"
  /><path
    d="M481.572 34.5C481.572 34.2733 481.655 34.0767 481.822 33.91C481.988 33.7433 482.185 33.66 482.412 33.66C482.638 33.66 482.835 33.7433 483.002 33.91C483.168 34.0767 483.252 34.2733 483.252 34.5C483.252 34.7267 483.168 34.9233 483.002 35.09C482.835 35.2567 482.638 35.34 482.412 35.34C482.185 35.34 481.988 35.2567 481.822 35.09C481.655 34.9233 481.572 34.7267 481.572 34.5ZM479.832 33.72C479.832 32.8133 480.058 32.11 480.512 31.61C480.972 31.11 481.605 30.86 482.412 30.86C483.218 30.86 483.848 31.11 484.302 31.61C484.762 32.11 484.992 32.8133 484.992 33.72V35.28C484.992 36.2133 484.762 36.9233 484.302 37.41C483.848 37.8967 483.218 38.14 482.412 38.14C481.605 38.14 480.972 37.8967 480.512 37.41C480.058 36.9233 479.832 36.2133 479.832 35.28V33.72ZM482.412 36.94C482.652 36.94 482.852 36.9033 483.012 36.83C483.172 36.75 483.302 36.64 483.402 36.5C483.502 36.36 483.572 36.1933 483.612 36C483.652 35.8067 483.672 35.5933 483.672 35.36V33.64C483.672 33.42 483.648 33.2133 483.602 33.02C483.555 32.8267 483.482 32.66 483.382 32.52C483.282 32.38 483.152 32.27 482.992 32.19C482.832 32.1033 482.638 32.06 482.412 32.06C482.185 32.06 481.992 32.1033 481.832 32.19C481.672 32.27 481.542 32.38 481.442 32.52C481.342 32.66 481.268 32.8267 481.222 33.02C481.175 33.2133 481.152 33.42 481.152 33.64V35.36C481.152 35.5933 481.172 35.8067 481.212 36C481.252 36.1933 481.322 36.36 481.422 36.5C481.522 36.64 481.652 36.75 481.812 36.83C481.972 36.9033 482.172 36.94 482.412 36.94Z"
    fill="#5078E1"
  /><path
    d="M487.695 34.5C487.695 34.2733 487.778 34.0767 487.945 33.91C488.111 33.7433 488.308 33.66 488.535 33.66C488.761 33.66 488.958 33.7433 489.125 33.91C489.291 34.0767 489.375 34.2733 489.375 34.5C489.375 34.7267 489.291 34.9233 489.125 35.09C488.958 35.2567 488.761 35.34 488.535 35.34C488.308 35.34 488.111 35.2567 487.945 35.09C487.778 34.9233 487.695 34.7267 487.695 34.5ZM485.955 33.72C485.955 32.8133 486.181 32.11 486.635 31.61C487.095 31.11 487.728 30.86 488.535 30.86C489.341 30.86 489.971 31.11 490.425 31.61C490.885 32.11 491.115 32.8133 491.115 33.72V35.28C491.115 36.2133 490.885 36.9233 490.425 37.41C489.971 37.8967 489.341 38.14 488.535 38.14C487.728 38.14 487.095 37.8967 486.635 37.41C486.181 36.9233 485.955 36.2133 485.955 35.28V33.72ZM488.535 36.94C488.775 36.94 488.975 36.9033 489.135 36.83C489.295 36.75 489.425 36.64 489.525 36.5C489.625 36.36 489.695 36.1933 489.735 36C489.775 35.8067 489.795 35.5933 489.795 35.36V33.64C489.795 33.42 489.771 33.2133 489.725 33.02C489.678 32.8267 489.605 32.66 489.505 32.52C489.405 32.38 489.275 32.27 489.115 32.19C488.955 32.1033 488.761 32.06 488.535 32.06C488.308 32.06 488.115 32.1033 487.955 32.19C487.795 32.27 487.665 32.38 487.565 32.52C487.465 32.66 487.391 32.8267 487.345 33.02C487.298 33.2133 487.275 33.42 487.275 33.64V35.36C487.275 35.5933 487.295 35.8067 487.335 36C487.375 36.1933 487.445 36.36 487.545 36.5C487.645 36.64 487.775 36.75 487.935 36.83C488.095 36.9033 488.295 36.94 488.535 36.94Z"
    fill="#5078E1"
  /><path
    d="M494.238 36.74V31.66H494.058L493.398 34.06H492.138V33.94L492.978 31H495.558V36.74H497.298V38H492.258V36.74H494.238Z"
    fill="#5078E1"
  /><path
    d="M500.361 36.74V31.66H500.181L499.521 34.06H498.261V33.94L499.101 31H501.681V36.74H503.421V38H498.381V36.74H500.361Z"
    fill="#5078E1"
  /><path
    d="M506.484 36.74V31.66H506.304L505.644 34.06H504.384V33.94L505.224 31H507.804V36.74H509.544V38H504.504V36.74H506.484Z"
    fill="#5078E1"
  /><path
    d="M512.607 36.74V31.66H512.427L511.767 34.06H510.507V33.94L511.347 31H513.927V36.74H515.667V38H510.627V36.74H512.607Z"
    fill="#5078E1"
  /><path
    d="M518.31 34.5C518.31 34.2733 518.393 34.0767 518.56 33.91C518.726 33.7433 518.923 33.66 519.15 33.66C519.376 33.66 519.573 33.7433 519.74 33.91C519.907 34.0767 519.99 34.2733 519.99 34.5C519.99 34.7267 519.907 34.9233 519.74 35.09C519.573 35.2567 519.376 35.34 519.15 35.34C518.923 35.34 518.726 35.2567 518.56 35.09C518.393 34.9233 518.31 34.7267 518.31 34.5ZM516.57 33.72C516.57 32.8133 516.796 32.11 517.25 31.61C517.71 31.11 518.343 30.86 519.15 30.86C519.956 30.86 520.587 31.11 521.04 31.61C521.5 32.11 521.73 32.8133 521.73 33.72V35.28C521.73 36.2133 521.5 36.9233 521.04 37.41C520.587 37.8967 519.956 38.14 519.15 38.14C518.343 38.14 517.71 37.8967 517.25 37.41C516.796 36.9233 516.57 36.2133 516.57 35.28V33.72ZM519.15 36.94C519.39 36.94 519.59 36.9033 519.75 36.83C519.91 36.75 520.04 36.64 520.14 36.5C520.24 36.36 520.31 36.1933 520.35 36C520.39 35.8067 520.41 35.5933 520.41 35.36V33.64C520.41 33.42 520.386 33.2133 520.34 33.02C520.293 32.8267 520.22 32.66 520.12 32.52C520.02 32.38 519.89 32.27 519.73 32.19C519.57 32.1033 519.376 32.06 519.15 32.06C518.923 32.06 518.73 32.1033 518.57 32.19C518.41 32.27 518.28 32.38 518.18 32.52C518.08 32.66 518.007 32.8267 517.96 33.02C517.913 33.2133 517.89 33.42 517.89 33.64V35.36C517.89 35.5933 517.91 35.8067 517.95 36C517.99 36.1933 518.06 36.36 518.16 36.5C518.26 36.64 518.39 36.75 518.55 36.83C518.71 36.9033 518.91 36.94 519.15 36.94Z"
    fill="#5078E1"
  /><path
    d="M524.433 34.5C524.433 34.2733 524.516 34.0767 524.683 33.91C524.85 33.7433 525.046 33.66 525.273 33.66C525.5 33.66 525.696 33.7433 525.863 33.91C526.03 34.0767 526.113 34.2733 526.113 34.5C526.113 34.7267 526.03 34.9233 525.863 35.09C525.696 35.2567 525.5 35.34 525.273 35.34C525.046 35.34 524.85 35.2567 524.683 35.09C524.516 34.9233 524.433 34.7267 524.433 34.5ZM522.693 33.72C522.693 32.8133 522.92 32.11 523.373 31.61C523.833 31.11 524.466 30.86 525.273 30.86C526.08 30.86 526.71 31.11 527.163 31.61C527.623 32.11 527.853 32.8133 527.853 33.72V35.28C527.853 36.2133 527.623 36.9233 527.163 37.41C526.71 37.8967 526.08 38.14 525.273 38.14C524.466 38.14 523.833 37.8967 523.373 37.41C522.92 36.9233 522.693 36.2133 522.693 35.28V33.72ZM525.273 36.94C525.513 36.94 525.713 36.9033 525.873 36.83C526.033 36.75 526.163 36.64 526.263 36.5C526.363 36.36 526.433 36.1933 526.473 36C526.513 35.8067 526.533 35.5933 526.533 35.36V33.64C526.533 33.42 526.51 33.2133 526.463 33.02C526.416 32.8267 526.343 32.66 526.243 32.52C526.143 32.38 526.013 32.27 525.853 32.19C525.693 32.1033 525.5 32.06 525.273 32.06C525.046 32.06 524.853 32.1033 524.693 32.19C524.533 32.27 524.403 32.38 524.303 32.52C524.203 32.66 524.13 32.8267 524.083 33.02C524.036 33.2133 524.013 33.42 524.013 33.64V35.36C524.013 35.5933 524.033 35.8067 524.073 36C524.113 36.1933 524.183 36.36 524.283 36.5C524.383 36.64 524.513 36.75 524.673 36.83C524.833 36.9033 525.033 36.94 525.273 36.94Z"
    fill="#5078E1"
  /><path
    d="M530.976 36.74V31.66H530.796L530.136 34.06H528.876V33.94L529.716 31H532.296V36.74H534.036V38H528.996V36.74H530.976Z"
    fill="#5078E1"
  /><path
    d="M536.679 34.5C536.679 34.2733 536.762 34.0767 536.929 33.91C537.096 33.7433 537.292 33.66 537.519 33.66C537.746 33.66 537.942 33.7433 538.109 33.91C538.276 34.0767 538.359 34.2733 538.359 34.5C538.359 34.7267 538.276 34.9233 538.109 35.09C537.942 35.2567 537.746 35.34 537.519 35.34C537.292 35.34 537.096 35.2567 536.929 35.09C536.762 34.9233 536.679 34.7267 536.679 34.5ZM534.939 33.72C534.939 32.8133 535.166 32.11 535.619 31.61C536.079 31.11 536.712 30.86 537.519 30.86C538.326 30.86 538.956 31.11 539.409 31.61C539.869 32.11 540.099 32.8133 540.099 33.72V35.28C540.099 36.2133 539.869 36.9233 539.409 37.41C538.956 37.8967 538.326 38.14 537.519 38.14C536.712 38.14 536.079 37.8967 535.619 37.41C535.166 36.9233 534.939 36.2133 534.939 35.28V33.72ZM537.519 36.94C537.759 36.94 537.959 36.9033 538.119 36.83C538.279 36.75 538.409 36.64 538.509 36.5C538.609 36.36 538.679 36.1933 538.719 36C538.759 35.8067 538.779 35.5933 538.779 35.36V33.64C538.779 33.42 538.756 33.2133 538.709 33.02C538.662 32.8267 538.589 32.66 538.489 32.52C538.389 32.38 538.259 32.27 538.099 32.19C537.939 32.1033 537.746 32.06 537.519 32.06C537.292 32.06 537.099 32.1033 536.939 32.19C536.779 32.27 536.649 32.38 536.549 32.52C536.449 32.66 536.376 32.8267 536.329 33.02C536.282 33.2133 536.259 33.42 536.259 33.64V35.36C536.259 35.5933 536.279 35.8067 536.319 36C536.359 36.1933 536.429 36.36 536.529 36.5C536.629 36.64 536.759 36.75 536.919 36.83C537.079 36.9033 537.279 36.94 537.519 36.94Z"
    fill="#5078E1"
  /><path
    d="M543.222 36.74V31.66H543.042L542.382 34.06H541.122V33.94L541.962 31H544.542V36.74H546.282V38H541.242V36.74H543.222Z"
    fill="#5078E1"
  /><path
    d="M549.345 36.74V31.66H549.165L548.505 34.06H547.245V33.94L548.085 31H550.665V36.74H552.405V38H547.365V36.74H549.345Z"
    fill="#5078E1"
  /><path
    d="M555.048 34.5C555.048 34.2733 555.131 34.0767 555.298 33.91C555.465 33.7433 555.661 33.66 555.888 33.66C556.115 33.66 556.311 33.7433 556.478 33.91C556.645 34.0767 556.728 34.2733 556.728 34.5C556.728 34.7267 556.645 34.9233 556.478 35.09C556.311 35.2567 556.115 35.34 555.888 35.34C555.661 35.34 555.465 35.2567 555.298 35.09C555.131 34.9233 555.048 34.7267 555.048 34.5ZM553.308 33.72C553.308 32.8133 553.535 32.11 553.988 31.61C554.448 31.11 555.081 30.86 555.888 30.86C556.695 30.86 557.325 31.11 557.778 31.61C558.238 32.11 558.468 32.8133 558.468 33.72V35.28C558.468 36.2133 558.238 36.9233 557.778 37.41C557.325 37.8967 556.695 38.14 555.888 38.14C555.081 38.14 554.448 37.8967 553.988 37.41C553.535 36.9233 553.308 36.2133 553.308 35.28V33.72ZM555.888 36.94C556.128 36.94 556.328 36.9033 556.488 36.83C556.648 36.75 556.778 36.64 556.878 36.5C556.978 36.36 557.048 36.1933 557.088 36C557.128 35.8067 557.148 35.5933 557.148 35.36V33.64C557.148 33.42 557.125 33.2133 557.078 33.02C557.031 32.8267 556.958 32.66 556.858 32.52C556.758 32.38 556.628 32.27 556.468 32.19C556.308 32.1033 556.115 32.06 555.888 32.06C555.661 32.06 555.468 32.1033 555.308 32.19C555.148 32.27 555.018 32.38 554.918 32.52C554.818 32.66 554.745 32.8267 554.698 33.02C554.651 33.2133 554.628 33.42 554.628 33.64V35.36C554.628 35.5933 554.648 35.8067 554.688 36C554.728 36.1933 554.798 36.36 554.898 36.5C554.998 36.64 555.128 36.75 555.288 36.83C555.448 36.9033 555.648 36.94 555.888 36.94Z"
    fill="#5078E1"
  /><path
    d="M561.591 36.74V31.66H561.411L560.751 34.06H559.491V33.94L560.331 31H562.911V36.74H564.651V38H559.611V36.74H561.591Z"
    fill="#5078E1"
  /><path
    d="M567.714 36.74V31.66H567.534L566.874 34.06H565.614V33.94L566.454 31H569.034V36.74H570.774V38H565.734V36.74H567.714Z"
    fill="#5078E1"
  /><path
    d="M573.837 36.74V31.66H573.657L572.997 34.06H571.737V33.94L572.577 31H575.157V36.74H576.897V38H571.857V36.74H573.837Z"
    fill="#5078E1"
  /><path
    d="M579.96 36.74V31.66H579.78L579.12 34.06H577.86V33.94L578.7 31H581.28V36.74H583.02V38H577.98V36.74H579.96Z"
    fill="#5078E1"
  /><path
    d="M585.663 34.5C585.663 34.2733 585.747 34.0767 585.913 33.91C586.08 33.7433 586.277 33.66 586.503 33.66C586.73 33.66 586.927 33.7433 587.093 33.91C587.26 34.0767 587.343 34.2733 587.343 34.5C587.343 34.7267 587.26 34.9233 587.093 35.09C586.927 35.2567 586.73 35.34 586.503 35.34C586.277 35.34 586.08 35.2567 585.913 35.09C585.747 34.9233 585.663 34.7267 585.663 34.5ZM583.923 33.72C583.923 32.8133 584.15 32.11 584.603 31.61C585.063 31.11 585.697 30.86 586.503 30.86C587.31 30.86 587.94 31.11 588.393 31.61C588.853 32.11 589.083 32.8133 589.083 33.72V35.28C589.083 36.2133 588.853 36.9233 588.393 37.41C587.94 37.8967 587.31 38.14 586.503 38.14C585.697 38.14 585.063 37.8967 584.603 37.41C584.15 36.9233 583.923 36.2133 583.923 35.28V33.72ZM586.503 36.94C586.743 36.94 586.943 36.9033 587.103 36.83C587.263 36.75 587.393 36.64 587.493 36.5C587.593 36.36 587.663 36.1933 587.703 36C587.743 35.8067 587.763 35.5933 587.763 35.36V33.64C587.763 33.42 587.74 33.2133 587.693 33.02C587.647 32.8267 587.573 32.66 587.473 32.52C587.373 32.38 587.243 32.27 587.083 32.19C586.923 32.1033 586.73 32.06 586.503 32.06C586.277 32.06 586.083 32.1033 585.923 32.19C585.763 32.27 585.633 32.38 585.533 32.52C585.433 32.66 585.36 32.8267 585.313 33.02C585.267 33.2133 585.243 33.42 585.243 33.64V35.36C585.243 35.5933 585.263 35.8067 585.303 36C585.343 36.1933 585.413 36.36 585.513 36.5C585.613 36.64 585.743 36.75 585.903 36.83C586.063 36.9033 586.263 36.94 586.503 36.94Z"
    fill="#5078E1"
  /><path
    d="M592.206 36.74V31.66H592.026L591.366 34.06H590.106V33.94L590.946 31H593.526V36.74H595.266V38H590.226V36.74H592.206Z"
    fill="#5078E1"
  /><path
    d="M598.329 36.74V31.66H598.149L597.489 34.06H596.229V33.94L597.069 31H599.649V36.74H601.389V38H596.349V36.74H598.329Z"
    fill="#5078E1"
  /><path
    d="M604.452 36.74V31.66H604.272L603.613 34.06H602.352V33.94L603.193 31H605.772V36.74H607.512V38H602.472V36.74H604.452Z"
    fill="#5078E1"
  /><path
    d="M610.156 34.5C610.156 34.2733 610.239 34.0767 610.406 33.91C610.572 33.7433 610.769 33.66 610.996 33.66C611.222 33.66 611.419 33.7433 611.586 33.91C611.752 34.0767 611.836 34.2733 611.836 34.5C611.836 34.7267 611.752 34.9233 611.586 35.09C611.419 35.2567 611.222 35.34 610.996 35.34C610.769 35.34 610.572 35.2567 610.406 35.09C610.239 34.9233 610.156 34.7267 610.156 34.5ZM608.416 33.72C608.416 32.8133 608.642 32.11 609.096 31.61C609.556 31.11 610.189 30.86 610.996 30.86C611.802 30.86 612.432 31.11 612.886 31.61C613.346 32.11 613.576 32.8133 613.576 33.72V35.28C613.576 36.2133 613.346 36.9233 612.886 37.41C612.432 37.8967 611.802 38.14 610.996 38.14C610.189 38.14 609.556 37.8967 609.096 37.41C608.642 36.9233 608.416 36.2133 608.416 35.28V33.72ZM610.996 36.94C611.236 36.94 611.436 36.9033 611.596 36.83C611.756 36.75 611.886 36.64 611.986 36.5C612.086 36.36 612.156 36.1933 612.196 36C612.236 35.8067 612.256 35.5933 612.256 35.36V33.64C612.256 33.42 612.232 33.2133 612.186 33.02C612.139 32.8267 612.066 32.66 611.966 32.52C611.866 32.38 611.736 32.27 611.576 32.19C611.416 32.1033 611.222 32.06 610.996 32.06C610.769 32.06 610.576 32.1033 610.416 32.19C610.256 32.27 610.126 32.38 610.026 32.52C609.926 32.66 609.852 32.8267 609.806 33.02C609.759 33.2133 609.736 33.42 609.736 33.64V35.36C609.736 35.5933 609.756 35.8067 609.796 36C609.836 36.1933 609.906 36.36 610.006 36.5C610.106 36.64 610.236 36.75 610.396 36.83C610.556 36.9033 610.756 36.94 610.996 36.94Z"
    fill="#5078E1"
  /><path
    d="M616.699 36.74V31.66H616.519L615.859 34.06H614.599V33.94L615.439 31H618.019V36.74H619.759V38H614.719V36.74H616.699Z"
    fill="#5078E1"
  /><path
    d="M622.402 34.5C622.402 34.2733 622.485 34.0767 622.652 33.91C622.818 33.7433 623.015 33.66 623.242 33.66C623.468 33.66 623.665 33.7433 623.832 33.91C623.998 34.0767 624.082 34.2733 624.082 34.5C624.082 34.7267 623.998 34.9233 623.832 35.09C623.665 35.2567 623.468 35.34 623.242 35.34C623.015 35.34 622.818 35.2567 622.652 35.09C622.485 34.9233 622.402 34.7267 622.402 34.5ZM620.662 33.72C620.662 32.8133 620.888 32.11 621.342 31.61C621.802 31.11 622.435 30.86 623.242 30.86C624.048 30.86 624.678 31.11 625.132 31.61C625.592 32.11 625.822 32.8133 625.822 33.72V35.28C625.822 36.2133 625.592 36.9233 625.132 37.41C624.678 37.8967 624.048 38.14 623.242 38.14C622.435 38.14 621.802 37.8967 621.342 37.41C620.888 36.9233 620.662 36.2133 620.662 35.28V33.72ZM623.242 36.94C623.482 36.94 623.682 36.9033 623.842 36.83C624.002 36.75 624.132 36.64 624.232 36.5C624.332 36.36 624.402 36.1933 624.442 36C624.482 35.8067 624.502 35.5933 624.502 35.36V33.64C624.502 33.42 624.478 33.2133 624.432 33.02C624.385 32.8267 624.312 32.66 624.212 32.52C624.112 32.38 623.982 32.27 623.822 32.19C623.662 32.1033 623.468 32.06 623.242 32.06C623.015 32.06 622.822 32.1033 622.662 32.19C622.502 32.27 622.372 32.38 622.272 32.52C622.172 32.66 622.098 32.8267 622.052 33.02C622.005 33.2133 621.982 33.42 621.982 33.64V35.36C621.982 35.5933 622.002 35.8067 622.042 36C622.082 36.1933 622.152 36.36 622.252 36.5C622.352 36.64 622.482 36.75 622.642 36.83C622.802 36.9033 623.002 36.94 623.242 36.94Z"
    fill="#5078E1"
  /><path
    d="M628.945 36.74V31.66H628.765L628.105 34.06H626.845V33.94L627.685 31H630.265V36.74H632.005V38H626.965V36.74H628.945Z"
    fill="#5078E1"
  /><path
    d="M634.648 34.5C634.648 34.2733 634.731 34.0767 634.898 33.91C635.064 33.7433 635.261 33.66 635.488 33.66C635.714 33.66 635.911 33.7433 636.078 33.91C636.244 34.0767 636.328 34.2733 636.328 34.5C636.328 34.7267 636.244 34.9233 636.078 35.09C635.911 35.2567 635.714 35.34 635.488 35.34C635.261 35.34 635.064 35.2567 634.898 35.09C634.731 34.9233 634.648 34.7267 634.648 34.5ZM632.908 33.72C632.908 32.8133 633.134 32.11 633.588 31.61C634.048 31.11 634.681 30.86 635.488 30.86C636.294 30.86 636.924 31.11 637.378 31.61C637.838 32.11 638.068 32.8133 638.068 33.72V35.28C638.068 36.2133 637.838 36.9233 637.378 37.41C636.924 37.8967 636.294 38.14 635.488 38.14C634.681 38.14 634.048 37.8967 633.588 37.41C633.134 36.9233 632.908 36.2133 632.908 35.28V33.72ZM635.488 36.94C635.728 36.94 635.928 36.9033 636.088 36.83C636.248 36.75 636.378 36.64 636.478 36.5C636.578 36.36 636.648 36.1933 636.688 36C636.728 35.8067 636.748 35.5933 636.748 35.36V33.64C636.748 33.42 636.724 33.2133 636.678 33.02C636.631 32.8267 636.558 32.66 636.458 32.52C636.358 32.38 636.228 32.27 636.068 32.19C635.908 32.1033 635.714 32.06 635.488 32.06C635.261 32.06 635.068 32.1033 634.908 32.19C634.748 32.27 634.618 32.38 634.518 32.52C634.418 32.66 634.344 32.8267 634.298 33.02C634.251 33.2133 634.228 33.42 634.228 33.64V35.36C634.228 35.5933 634.248 35.8067 634.288 36C634.328 36.1933 634.398 36.36 634.498 36.5C634.598 36.64 634.728 36.75 634.888 36.83C635.048 36.9033 635.248 36.94 635.488 36.94Z"
    fill="#5078E1"
  /><path
    d="M641.191 36.74V31.66H641.011L640.351 34.06H639.091V33.94L639.931 31H642.511V36.74H644.251V38H639.211V36.74H641.191Z"
    fill="#5078E1"
  /><path
    d="M647.314 36.74V31.66H647.134L646.474 34.06H645.214V33.94L646.054 31H648.634V36.74H650.374V38H645.334V36.74H647.314Z"
    fill="#5078E1"
  /><path
    d="M653.437 36.74V31.66H653.257L652.597 34.06H651.337V33.94L652.177 31H654.757V36.74H656.497V38H651.457V36.74H653.437Z"
    fill="#5078E1"
  /><path
    d="M659.14 34.5C659.14 34.2733 659.223 34.0767 659.39 33.91C659.557 33.7433 659.753 33.66 659.98 33.66C660.207 33.66 660.403 33.7433 660.57 33.91C660.737 34.0767 660.82 34.2733 660.82 34.5C660.82 34.7267 660.737 34.9233 660.57 35.09C660.403 35.2567 660.207 35.34 659.98 35.34C659.753 35.34 659.557 35.2567 659.39 35.09C659.223 34.9233 659.14 34.7267 659.14 34.5ZM657.4 33.72C657.4 32.8133 657.627 32.11 658.08 31.61C658.54 31.11 659.173 30.86 659.98 30.86C660.787 30.86 661.417 31.11 661.87 31.61C662.33 32.11 662.56 32.8133 662.56 33.72V35.28C662.56 36.2133 662.33 36.9233 661.87 37.41C661.417 37.8967 660.787 38.14 659.98 38.14C659.173 38.14 658.54 37.8967 658.08 37.41C657.627 36.9233 657.4 36.2133 657.4 35.28V33.72ZM659.98 36.94C660.22 36.94 660.42 36.9033 660.58 36.83C660.74 36.75 660.87 36.64 660.97 36.5C661.07 36.36 661.14 36.1933 661.18 36C661.22 35.8067 661.24 35.5933 661.24 35.36V33.64C661.24 33.42 661.217 33.2133 661.17 33.02C661.123 32.8267 661.05 32.66 660.95 32.52C660.85 32.38 660.72 32.27 660.56 32.19C660.4 32.1033 660.207 32.06 659.98 32.06C659.753 32.06 659.56 32.1033 659.4 32.19C659.24 32.27 659.11 32.38 659.01 32.52C658.91 32.66 658.837 32.8267 658.79 33.02C658.743 33.2133 658.72 33.42 658.72 33.64V35.36C658.72 35.5933 658.74 35.8067 658.78 36C658.82 36.1933 658.89 36.36 658.99 36.5C659.09 36.64 659.22 36.75 659.38 36.83C659.54 36.9033 659.74 36.94 659.98 36.94Z"
    fill="#5078E1"
  /><path
    d="M665.263 34.5C665.263 34.2733 665.346 34.0767 665.513 33.91C665.68 33.7433 665.876 33.66 666.103 33.66C666.33 33.66 666.526 33.7433 666.693 33.91C666.86 34.0767 666.943 34.2733 666.943 34.5C666.943 34.7267 666.86 34.9233 666.693 35.09C666.526 35.2567 666.33 35.34 666.103 35.34C665.876 35.34 665.68 35.2567 665.513 35.09C665.346 34.9233 665.263 34.7267 665.263 34.5ZM663.523 33.72C663.523 32.8133 663.75 32.11 664.203 31.61C664.663 31.11 665.296 30.86 666.103 30.86C666.91 30.86 667.54 31.11 667.993 31.61C668.453 32.11 668.683 32.8133 668.683 33.72V35.28C668.683 36.2133 668.453 36.9233 667.993 37.41C667.54 37.8967 666.91 38.14 666.103 38.14C665.296 38.14 664.663 37.8967 664.203 37.41C663.75 36.9233 663.523 36.2133 663.523 35.28V33.72ZM666.103 36.94C666.343 36.94 666.543 36.9033 666.703 36.83C666.863 36.75 666.993 36.64 667.093 36.5C667.193 36.36 667.263 36.1933 667.303 36C667.343 35.8067 667.363 35.5933 667.363 35.36V33.64C667.363 33.42 667.34 33.2133 667.293 33.02C667.246 32.8267 667.173 32.66 667.073 32.52C666.973 32.38 666.843 32.27 666.683 32.19C666.523 32.1033 666.33 32.06 666.103 32.06C665.876 32.06 665.683 32.1033 665.523 32.19C665.363 32.27 665.233 32.38 665.133 32.52C665.033 32.66 664.96 32.8267 664.913 33.02C664.866 33.2133 664.843 33.42 664.843 33.64V35.36C664.843 35.5933 664.863 35.8067 664.903 36C664.943 36.1933 665.013 36.36 665.113 36.5C665.213 36.64 665.343 36.75 665.503 36.83C665.663 36.9033 665.863 36.94 666.103 36.94Z"
    fill="#5078E1"
  /><path
    d="M16.64 53.74V48.66H16.46L15.8 51.06H14.54V50.94L15.38 48H17.96V53.74H19.7V55H14.66V53.74H16.64Z"
    fill="#5078E1"
  /><path
    d="M22.343 51.5C22.343 51.2733 22.4264 51.0767 22.593 50.91C22.7597 50.7433 22.9564 50.66 23.183 50.66C23.4097 50.66 23.6064 50.7433 23.773 50.91C23.9397 51.0767 24.023 51.2733 24.023 51.5C24.023 51.7267 23.9397 51.9233 23.773 52.09C23.6064 52.2567 23.4097 52.34 23.183 52.34C22.9564 52.34 22.7597 52.2567 22.593 52.09C22.4264 51.9233 22.343 51.7267 22.343 51.5ZM20.603 50.72C20.603 49.8133 20.8297 49.11 21.283 48.61C21.743 48.11 22.3764 47.86 23.183 47.86C23.9897 47.86 24.6197 48.11 25.073 48.61C25.533 49.11 25.763 49.8133 25.763 50.72V52.28C25.763 53.2133 25.533 53.9233 25.073 54.41C24.6197 54.8967 23.9897 55.14 23.183 55.14C22.3764 55.14 21.743 54.8967 21.283 54.41C20.8297 53.9233 20.603 53.2133 20.603 52.28V50.72ZM23.183 53.94C23.423 53.94 23.623 53.9033 23.783 53.83C23.943 53.75 24.073 53.64 24.173 53.5C24.273 53.36 24.343 53.1933 24.383 53C24.423 52.8067 24.443 52.5933 24.443 52.36V50.64C24.443 50.42 24.4197 50.2133 24.373 50.02C24.3264 49.8267 24.253 49.66 24.153 49.52C24.053 49.38 23.923 49.27 23.763 49.19C23.603 49.1033 23.4097 49.06 23.183 49.06C22.9564 49.06 22.763 49.1033 22.603 49.19C22.443 49.27 22.313 49.38 22.213 49.52C22.113 49.66 22.0397 49.8267 21.993 50.02C21.9464 50.2133 21.923 50.42 21.923 50.64V52.36C21.923 52.5933 21.943 52.8067 21.983 53C22.023 53.1933 22.093 53.36 22.193 53.5C22.293 53.64 22.423 53.75 22.583 53.83C22.743 53.9033 22.943 53.94 23.183 53.94Z"
    fill="#5078E1"
  /><path
    d="M28.4661 51.5C28.4661 51.2733 28.5494 51.0767 28.7161 50.91C28.8828 50.7433 29.0794 50.66 29.3061 50.66C29.5328 50.66 29.7294 50.7433 29.8961 50.91C30.0628 51.0767 30.1461 51.2733 30.1461 51.5C30.1461 51.7267 30.0628 51.9233 29.8961 52.09C29.7294 52.2567 29.5328 52.34 29.3061 52.34C29.0794 52.34 28.8828 52.2567 28.7161 52.09C28.5494 51.9233 28.4661 51.7267 28.4661 51.5ZM26.7261 50.72C26.7261 49.8133 26.9528 49.11 27.4061 48.61C27.8661 48.11 28.4994 47.86 29.3061 47.86C30.1128 47.86 30.7428 48.11 31.1961 48.61C31.6561 49.11 31.8861 49.8133 31.8861 50.72V52.28C31.8861 53.2133 31.6561 53.9233 31.1961 54.41C30.7428 54.8967 30.1128 55.14 29.3061 55.14C28.4994 55.14 27.8661 54.8967 27.4061 54.41C26.9528 53.9233 26.7261 53.2133 26.7261 52.28V50.72ZM29.3061 53.94C29.5461 53.94 29.7461 53.9033 29.9061 53.83C30.0661 53.75 30.1961 53.64 30.2961 53.5C30.3961 53.36 30.4661 53.1933 30.5061 53C30.5461 52.8067 30.5661 52.5933 30.5661 52.36V50.64C30.5661 50.42 30.5428 50.2133 30.4961 50.02C30.4494 49.8267 30.3761 49.66 30.2761 49.52C30.1761 49.38 30.0461 49.27 29.8861 49.19C29.7261 49.1033 29.5328 49.06 29.3061 49.06C29.0794 49.06 28.8861 49.1033 28.7261 49.19C28.5661 49.27 28.4361 49.38 28.3361 49.52C28.2361 49.66 28.1628 49.8267 28.1161 50.02C28.0694 50.2133 28.0461 50.42 28.0461 50.64V52.36C28.0461 52.5933 28.0661 52.8067 28.1061 53C28.1461 53.1933 28.2161 53.36 28.3161 53.5C28.4161 53.64 28.5461 53.75 28.7061 53.83C28.8661 53.9033 29.0661 53.94 29.3061 53.94Z"
    fill="#5078E1"
  /><path
    d="M34.5891 51.5C34.5891 51.2733 34.6725 51.0767 34.8391 50.91C35.0058 50.7433 35.2025 50.66 35.4291 50.66C35.6558 50.66 35.8525 50.7433 36.0191 50.91C36.1858 51.0767 36.2691 51.2733 36.2691 51.5C36.2691 51.7267 36.1858 51.9233 36.0191 52.09C35.8525 52.2567 35.6558 52.34 35.4291 52.34C35.2025 52.34 35.0058 52.2567 34.8391 52.09C34.6725 51.9233 34.5891 51.7267 34.5891 51.5ZM32.8491 50.72C32.8491 49.8133 33.0758 49.11 33.5291 48.61C33.9891 48.11 34.6225 47.86 35.4291 47.86C36.2358 47.86 36.8658 48.11 37.3191 48.61C37.7791 49.11 38.0091 49.8133 38.0091 50.72V52.28C38.0091 53.2133 37.7791 53.9233 37.3191 54.41C36.8658 54.8967 36.2358 55.14 35.4291 55.14C34.6225 55.14 33.9891 54.8967 33.5291 54.41C33.0758 53.9233 32.8491 53.2133 32.8491 52.28V50.72ZM35.4291 53.94C35.6691 53.94 35.8691 53.9033 36.0291 53.83C36.1891 53.75 36.3191 53.64 36.4191 53.5C36.5191 53.36 36.5891 53.1933 36.6291 53C36.6691 52.8067 36.6891 52.5933 36.6891 52.36V50.64C36.6891 50.42 36.6658 50.2133 36.6191 50.02C36.5725 49.8267 36.4991 49.66 36.3991 49.52C36.2991 49.38 36.1691 49.27 36.0091 49.19C35.8491 49.1033 35.6558 49.06 35.4291 49.06C35.2025 49.06 35.0091 49.1033 34.8491 49.19C34.6891 49.27 34.5591 49.38 34.4591 49.52C34.3591 49.66 34.2858 49.8267 34.2391 50.02C34.1925 50.2133 34.1691 50.42 34.1691 50.64V52.36C34.1691 52.5933 34.1891 52.8067 34.2291 53C34.2691 53.1933 34.3391 53.36 34.4391 53.5C34.5391 53.64 34.6691 53.75 34.8291 53.83C34.9891 53.9033 35.1891 53.94 35.4291 53.94Z"
    fill="#5078E1"
  /><path
    d="M41.1322 53.74V48.66H40.9522L40.2922 51.06H39.0322V50.94L39.8722 48H42.4522V53.74H44.1922V55H39.1522V53.74H41.1322Z"
    fill="#5078E1"
  /><path
    d="M46.8352 51.5C46.8352 51.2733 46.9186 51.0767 47.0852 50.91C47.2519 50.7433 47.4486 50.66 47.6752 50.66C47.9019 50.66 48.0986 50.7433 48.2652 50.91C48.4319 51.0767 48.5152 51.2733 48.5152 51.5C48.5152 51.7267 48.4319 51.9233 48.2652 52.09C48.0986 52.2567 47.9019 52.34 47.6752 52.34C47.4486 52.34 47.2519 52.2567 47.0852 52.09C46.9186 51.9233 46.8352 51.7267 46.8352 51.5ZM45.0952 50.72C45.0952 49.8133 45.3219 49.11 45.7752 48.61C46.2352 48.11 46.8686 47.86 47.6752 47.86C48.4819 47.86 49.1119 48.11 49.5652 48.61C50.0252 49.11 50.2552 49.8133 50.2552 50.72V52.28C50.2552 53.2133 50.0252 53.9233 49.5652 54.41C49.1119 54.8967 48.4819 55.14 47.6752 55.14C46.8686 55.14 46.2352 54.8967 45.7752 54.41C45.3219 53.9233 45.0952 53.2133 45.0952 52.28V50.72ZM47.6752 53.94C47.9152 53.94 48.1152 53.9033 48.2752 53.83C48.4352 53.75 48.5652 53.64 48.6652 53.5C48.7652 53.36 48.8352 53.1933 48.8752 53C48.9152 52.8067 48.9352 52.5933 48.9352 52.36V50.64C48.9352 50.42 48.9119 50.2133 48.8652 50.02C48.8186 49.8267 48.7452 49.66 48.6452 49.52C48.5452 49.38 48.4152 49.27 48.2552 49.19C48.0952 49.1033 47.9019 49.06 47.6752 49.06C47.4486 49.06 47.2552 49.1033 47.0952 49.19C46.9352 49.27 46.8052 49.38 46.7052 49.52C46.6052 49.66 46.5319 49.8267 46.4852 50.02C46.4386 50.2133 46.4152 50.42 46.4152 50.64V52.36C46.4152 52.5933 46.4352 52.8067 46.4752 53C46.5152 53.1933 46.5852 53.36 46.6852 53.5C46.7852 53.64 46.9152 53.75 47.0752 53.83C47.2352 53.9033 47.4352 53.94 47.6752 53.94Z"
    fill="#5078E1"
  /><path
    d="M52.9583 51.5C52.9583 51.2733 53.0416 51.0767 53.2083 50.91C53.3749 50.7433 53.5716 50.66 53.7983 50.66C54.0249 50.66 54.2216 50.7433 54.3883 50.91C54.5549 51.0767 54.6383 51.2733 54.6383 51.5C54.6383 51.7267 54.5549 51.9233 54.3883 52.09C54.2216 52.2567 54.0249 52.34 53.7983 52.34C53.5716 52.34 53.3749 52.2567 53.2083 52.09C53.0416 51.9233 52.9583 51.7267 52.9583 51.5ZM51.2183 50.72C51.2183 49.8133 51.4449 49.11 51.8983 48.61C52.3583 48.11 52.9916 47.86 53.7983 47.86C54.6049 47.86 55.2349 48.11 55.6883 48.61C56.1483 49.11 56.3783 49.8133 56.3783 50.72V52.28C56.3783 53.2133 56.1483 53.9233 55.6883 54.41C55.2349 54.8967 54.6049 55.14 53.7983 55.14C52.9916 55.14 52.3583 54.8967 51.8983 54.41C51.4449 53.9233 51.2183 53.2133 51.2183 52.28V50.72ZM53.7983 53.94C54.0383 53.94 54.2383 53.9033 54.3983 53.83C54.5583 53.75 54.6883 53.64 54.7883 53.5C54.8883 53.36 54.9583 53.1933 54.9983 53C55.0383 52.8067 55.0583 52.5933 55.0583 52.36V50.64C55.0583 50.42 55.0349 50.2133 54.9883 50.02C54.9416 49.8267 54.8683 49.66 54.7683 49.52C54.6683 49.38 54.5383 49.27 54.3783 49.19C54.2183 49.1033 54.0249 49.06 53.7983 49.06C53.5716 49.06 53.3783 49.1033 53.2183 49.19C53.0583 49.27 52.9283 49.38 52.8283 49.52C52.7283 49.66 52.6549 49.8267 52.6083 50.02C52.5616 50.2133 52.5383 50.42 52.5383 50.64V52.36C52.5383 52.5933 52.5583 52.8067 52.5983 53C52.6383 53.1933 52.7083 53.36 52.8083 53.5C52.9083 53.64 53.0383 53.75 53.1983 53.83C53.3583 53.9033 53.5583 53.94 53.7983 53.94Z"
    fill="#5078E1"
  /><path
    d="M59.0813 51.5C59.0813 51.2733 59.1647 51.0767 59.3313 50.91C59.498 50.7433 59.6947 50.66 59.9213 50.66C60.148 50.66 60.3447 50.7433 60.5113 50.91C60.678 51.0767 60.7613 51.2733 60.7613 51.5C60.7613 51.7267 60.678 51.9233 60.5113 52.09C60.3447 52.2567 60.148 52.34 59.9213 52.34C59.6947 52.34 59.498 52.2567 59.3313 52.09C59.1647 51.9233 59.0813 51.7267 59.0813 51.5ZM57.3413 50.72C57.3413 49.8133 57.568 49.11 58.0213 48.61C58.4813 48.11 59.1147 47.86 59.9213 47.86C60.728 47.86 61.358 48.11 61.8113 48.61C62.2713 49.11 62.5013 49.8133 62.5013 50.72V52.28C62.5013 53.2133 62.2713 53.9233 61.8113 54.41C61.358 54.8967 60.728 55.14 59.9213 55.14C59.1147 55.14 58.4813 54.8967 58.0213 54.41C57.568 53.9233 57.3413 53.2133 57.3413 52.28V50.72ZM59.9213 53.94C60.1613 53.94 60.3613 53.9033 60.5213 53.83C60.6813 53.75 60.8113 53.64 60.9113 53.5C61.0113 53.36 61.0813 53.1933 61.1213 53C61.1613 52.8067 61.1813 52.5933 61.1813 52.36V50.64C61.1813 50.42 61.158 50.2133 61.1113 50.02C61.0647 49.8267 60.9913 49.66 60.8913 49.52C60.7913 49.38 60.6613 49.27 60.5013 49.19C60.3413 49.1033 60.148 49.06 59.9213 49.06C59.6947 49.06 59.5013 49.1033 59.3413 49.19C59.1813 49.27 59.0513 49.38 58.9513 49.52C58.8513 49.66 58.778 49.8267 58.7313 50.02C58.6847 50.2133 58.6613 50.42 58.6613 50.64V52.36C58.6613 52.5933 58.6813 52.8067 58.7213 53C58.7613 53.1933 58.8313 53.36 58.9313 53.5C59.0313 53.64 59.1613 53.75 59.3213 53.83C59.4813 53.9033 59.6813 53.94 59.9213 53.94Z"
    fill="#5078E1"
  /><path
    d="M65.2044 51.5C65.2044 51.2733 65.2877 51.0767 65.4544 50.91C65.621 50.7433 65.8177 50.66 66.0444 50.66C66.271 50.66 66.4677 50.7433 66.6344 50.91C66.801 51.0767 66.8844 51.2733 66.8844 51.5C66.8844 51.7267 66.801 51.9233 66.6344 52.09C66.4677 52.2567 66.271 52.34 66.0444 52.34C65.8177 52.34 65.621 52.2567 65.4544 52.09C65.2877 51.9233 65.2044 51.7267 65.2044 51.5ZM63.4644 50.72C63.4644 49.8133 63.691 49.11 64.1444 48.61C64.6044 48.11 65.2377 47.86 66.0444 47.86C66.851 47.86 67.481 48.11 67.9344 48.61C68.3944 49.11 68.6244 49.8133 68.6244 50.72V52.28C68.6244 53.2133 68.3944 53.9233 67.9344 54.41C67.481 54.8967 66.851 55.14 66.0444 55.14C65.2377 55.14 64.6044 54.8967 64.1444 54.41C63.691 53.9233 63.4644 53.2133 63.4644 52.28V50.72ZM66.0444 53.94C66.2844 53.94 66.4844 53.9033 66.6444 53.83C66.8044 53.75 66.9344 53.64 67.0344 53.5C67.1344 53.36 67.2044 53.1933 67.2444 53C67.2844 52.8067 67.3044 52.5933 67.3044 52.36V50.64C67.3044 50.42 67.281 50.2133 67.2344 50.02C67.1877 49.8267 67.1144 49.66 67.0144 49.52C66.9144 49.38 66.7844 49.27 66.6244 49.19C66.4644 49.1033 66.271 49.06 66.0444 49.06C65.8177 49.06 65.6244 49.1033 65.4644 49.19C65.3044 49.27 65.1744 49.38 65.0744 49.52C64.9744 49.66 64.901 49.8267 64.8544 50.02C64.8077 50.2133 64.7844 50.42 64.7844 50.64V52.36C64.7844 52.5933 64.8044 52.8067 64.8444 53C64.8844 53.1933 64.9544 53.36 65.0544 53.5C65.1544 53.64 65.2844 53.75 65.4444 53.83C65.6044 53.9033 65.8044 53.94 66.0444 53.94Z"
    fill="#5078E1"
  /><path
    d="M71.3274 51.5C71.3274 51.2733 71.4108 51.0767 71.5774 50.91C71.7441 50.7433 71.9408 50.66 72.1674 50.66C72.3941 50.66 72.5908 50.7433 72.7574 50.91C72.9241 51.0767 73.0074 51.2733 73.0074 51.5C73.0074 51.7267 72.9241 51.9233 72.7574 52.09C72.5908 52.2567 72.3941 52.34 72.1674 52.34C71.9408 52.34 71.7441 52.2567 71.5774 52.09C71.4108 51.9233 71.3274 51.7267 71.3274 51.5ZM69.5874 50.72C69.5874 49.8133 69.8141 49.11 70.2674 48.61C70.7274 48.11 71.3608 47.86 72.1674 47.86C72.9741 47.86 73.6041 48.11 74.0574 48.61C74.5174 49.11 74.7474 49.8133 74.7474 50.72V52.28C74.7474 53.2133 74.5174 53.9233 74.0574 54.41C73.6041 54.8967 72.9741 55.14 72.1674 55.14C71.3608 55.14 70.7274 54.8967 70.2674 54.41C69.8141 53.9233 69.5874 53.2133 69.5874 52.28V50.72ZM72.1674 53.94C72.4074 53.94 72.6074 53.9033 72.7674 53.83C72.9274 53.75 73.0574 53.64 73.1574 53.5C73.2574 53.36 73.3274 53.1933 73.3674 53C73.4074 52.8067 73.4274 52.5933 73.4274 52.36V50.64C73.4274 50.42 73.4041 50.2133 73.3574 50.02C73.3108 49.8267 73.2374 49.66 73.1374 49.52C73.0374 49.38 72.9074 49.27 72.7474 49.19C72.5874 49.1033 72.3941 49.06 72.1674 49.06C71.9408 49.06 71.7474 49.1033 71.5874 49.19C71.4274 49.27 71.2974 49.38 71.1974 49.52C71.0974 49.66 71.0241 49.8267 70.9774 50.02C70.9308 50.2133 70.9074 50.42 70.9074 50.64V52.36C70.9074 52.5933 70.9274 52.8067 70.9674 53C71.0074 53.1933 71.0774 53.36 71.1774 53.5C71.2774 53.64 71.4074 53.75 71.5674 53.83C71.7274 53.9033 71.9274 53.94 72.1674 53.94Z"
    fill="#5078E1"
  /><path
    d="M77.4505 51.5C77.4505 51.2733 77.5338 51.0767 77.7005 50.91C77.8671 50.7433 78.0638 50.66 78.2905 50.66C78.5171 50.66 78.7138 50.7433 78.8805 50.91C79.0471 51.0767 79.1305 51.2733 79.1305 51.5C79.1305 51.7267 79.0471 51.9233 78.8805 52.09C78.7138 52.2567 78.5171 52.34 78.2905 52.34C78.0638 52.34 77.8671 52.2567 77.7005 52.09C77.5338 51.9233 77.4505 51.7267 77.4505 51.5ZM75.7105 50.72C75.7105 49.8133 75.9371 49.11 76.3905 48.61C76.8505 48.11 77.4838 47.86 78.2905 47.86C79.0971 47.86 79.7271 48.11 80.1805 48.61C80.6405 49.11 80.8705 49.8133 80.8705 50.72V52.28C80.8705 53.2133 80.6405 53.9233 80.1805 54.41C79.7271 54.8967 79.0971 55.14 78.2905 55.14C77.4838 55.14 76.8505 54.8967 76.3905 54.41C75.9371 53.9233 75.7105 53.2133 75.7105 52.28V50.72ZM78.2905 53.94C78.5305 53.94 78.7305 53.9033 78.8905 53.83C79.0505 53.75 79.1805 53.64 79.2805 53.5C79.3805 53.36 79.4505 53.1933 79.4905 53C79.5305 52.8067 79.5505 52.5933 79.5505 52.36V50.64C79.5505 50.42 79.5271 50.2133 79.4805 50.02C79.4338 49.8267 79.3605 49.66 79.2605 49.52C79.1605 49.38 79.0305 49.27 78.8705 49.19C78.7105 49.1033 78.5171 49.06 78.2905 49.06C78.0638 49.06 77.8705 49.1033 77.7105 49.19C77.5505 49.27 77.4205 49.38 77.3205 49.52C77.2205 49.66 77.1471 49.8267 77.1005 50.02C77.0538 50.2133 77.0305 50.42 77.0305 50.64V52.36C77.0305 52.5933 77.0505 52.8067 77.0905 53C77.1305 53.1933 77.2005 53.36 77.3005 53.5C77.4005 53.64 77.5305 53.75 77.6905 53.83C77.8505 53.9033 78.0505 53.94 78.2905 53.94Z"
    fill="#5078E1"
  /><path
    d="M83.9935 53.74V48.66H83.8135L83.1535 51.06H81.8935V50.94L82.7335 48H85.3135V53.74H87.0535V55H82.0135V53.74H83.9935Z"
    fill="#5078E1"
  /><path
    d="M90.1166 53.74V48.66H89.9366L89.2766 51.06H88.0166V50.94L88.8566 48H91.4366V53.74H93.1766V55H88.1366V53.74H90.1166Z"
    fill="#5078E1"
  /><path
    d="M95.8196 51.5C95.8196 51.2733 95.9029 51.0767 96.0696 50.91C96.2363 50.7433 96.4329 50.66 96.6596 50.66C96.8863 50.66 97.0829 50.7433 97.2496 50.91C97.4163 51.0767 97.4996 51.2733 97.4996 51.5C97.4996 51.7267 97.4163 51.9233 97.2496 52.09C97.0829 52.2567 96.8863 52.34 96.6596 52.34C96.4329 52.34 96.2363 52.2567 96.0696 52.09C95.9029 51.9233 95.8196 51.7267 95.8196 51.5ZM94.0796 50.72C94.0796 49.8133 94.3063 49.11 94.7596 48.61C95.2196 48.11 95.8529 47.86 96.6596 47.86C97.4663 47.86 98.0963 48.11 98.5496 48.61C99.0096 49.11 99.2396 49.8133 99.2396 50.72V52.28C99.2396 53.2133 99.0096 53.9233 98.5496 54.41C98.0963 54.8967 97.4663 55.14 96.6596 55.14C95.8529 55.14 95.2196 54.8967 94.7596 54.41C94.3063 53.9233 94.0796 53.2133 94.0796 52.28V50.72ZM96.6596 53.94C96.8996 53.94 97.0996 53.9033 97.2596 53.83C97.4196 53.75 97.5496 53.64 97.6496 53.5C97.7496 53.36 97.8196 53.1933 97.8596 53C97.8996 52.8067 97.9196 52.5933 97.9196 52.36V50.64C97.9196 50.42 97.8963 50.2133 97.8496 50.02C97.8029 49.8267 97.7296 49.66 97.6296 49.52C97.5296 49.38 97.3996 49.27 97.2396 49.19C97.0796 49.1033 96.8863 49.06 96.6596 49.06C96.4329 49.06 96.2396 49.1033 96.0796 49.19C95.9196 49.27 95.7896 49.38 95.6896 49.52C95.5896 49.66 95.5163 49.8267 95.4696 50.02C95.4229 50.2133 95.3996 50.42 95.3996 50.64V52.36C95.3996 52.5933 95.4196 52.8067 95.4596 53C95.4996 53.1933 95.5696 53.36 95.6696 53.5C95.7696 53.64 95.8996 53.75 96.0596 53.83C96.2196 53.9033 96.4196 53.94 96.6596 53.94Z"
    fill="#5078E1"
  /><path
    d="M101.943 51.5C101.943 51.2733 102.026 51.0767 102.193 50.91C102.359 50.7433 102.556 50.66 102.783 50.66C103.009 50.66 103.206 50.7433 103.373 50.91C103.539 51.0767 103.623 51.2733 103.623 51.5C103.623 51.7267 103.539 51.9233 103.373 52.09C103.206 52.2567 103.009 52.34 102.783 52.34C102.556 52.34 102.359 52.2567 102.193 52.09C102.026 51.9233 101.943 51.7267 101.943 51.5ZM100.203 50.72C100.203 49.8133 100.429 49.11 100.883 48.61C101.343 48.11 101.976 47.86 102.783 47.86C103.589 47.86 104.219 48.11 104.673 48.61C105.133 49.11 105.363 49.8133 105.363 50.72V52.28C105.363 53.2133 105.133 53.9233 104.673 54.41C104.219 54.8967 103.589 55.14 102.783 55.14C101.976 55.14 101.343 54.8967 100.883 54.41C100.429 53.9233 100.203 53.2133 100.203 52.28V50.72ZM102.783 53.94C103.023 53.94 103.223 53.9033 103.383 53.83C103.543 53.75 103.673 53.64 103.773 53.5C103.873 53.36 103.943 53.1933 103.983 53C104.023 52.8067 104.043 52.5933 104.043 52.36V50.64C104.043 50.42 104.019 50.2133 103.973 50.02C103.926 49.8267 103.853 49.66 103.753 49.52C103.653 49.38 103.523 49.27 103.363 49.19C103.203 49.1033 103.009 49.06 102.783 49.06C102.556 49.06 102.363 49.1033 102.203 49.19C102.043 49.27 101.913 49.38 101.813 49.52C101.713 49.66 101.639 49.8267 101.593 50.02C101.546 50.2133 101.523 50.42 101.523 50.64V52.36C101.523 52.5933 101.543 52.8067 101.583 53C101.623 53.1933 101.693 53.36 101.793 53.5C101.893 53.64 102.023 53.75 102.183 53.83C102.343 53.9033 102.543 53.94 102.783 53.94Z"
    fill="#5078E1"
  /><path
    d="M108.066 51.5C108.066 51.2733 108.149 51.0767 108.316 50.91C108.482 50.7433 108.679 50.66 108.906 50.66C109.132 50.66 109.329 50.7433 109.496 50.91C109.662 51.0767 109.746 51.2733 109.746 51.5C109.746 51.7267 109.662 51.9233 109.496 52.09C109.329 52.2567 109.132 52.34 108.906 52.34C108.679 52.34 108.482 52.2567 108.316 52.09C108.149 51.9233 108.066 51.7267 108.066 51.5ZM106.326 50.72C106.326 49.8133 106.552 49.11 107.006 48.61C107.466 48.11 108.099 47.86 108.906 47.86C109.712 47.86 110.342 48.11 110.796 48.61C111.256 49.11 111.486 49.8133 111.486 50.72V52.28C111.486 53.2133 111.256 53.9233 110.796 54.41C110.342 54.8967 109.712 55.14 108.906 55.14C108.099 55.14 107.466 54.8967 107.006 54.41C106.552 53.9233 106.326 53.2133 106.326 52.28V50.72ZM108.906 53.94C109.146 53.94 109.346 53.9033 109.506 53.83C109.666 53.75 109.796 53.64 109.896 53.5C109.996 53.36 110.066 53.1933 110.106 53C110.146 52.8067 110.166 52.5933 110.166 52.36V50.64C110.166 50.42 110.142 50.2133 110.096 50.02C110.049 49.8267 109.976 49.66 109.876 49.52C109.776 49.38 109.646 49.27 109.486 49.19C109.326 49.1033 109.132 49.06 108.906 49.06C108.679 49.06 108.486 49.1033 108.326 49.19C108.166 49.27 108.036 49.38 107.936 49.52C107.836 49.66 107.762 49.8267 107.716 50.02C107.669 50.2133 107.646 50.42 107.646 50.64V52.36C107.646 52.5933 107.666 52.8067 107.706 53C107.746 53.1933 107.816 53.36 107.916 53.5C108.016 53.64 108.146 53.75 108.306 53.83C108.466 53.9033 108.666 53.94 108.906 53.94Z"
    fill="#5078E1"
  /><path
    d="M114.609 53.74V48.66H114.429L113.769 51.06H112.509V50.94L113.349 48H115.929V53.74H117.669V55H112.629V53.74H114.609Z"
    fill="#5078E1"
  /><path
    d="M120.312 51.5C120.312 51.2733 120.395 51.0767 120.562 50.91C120.728 50.7433 120.925 50.66 121.152 50.66C121.378 50.66 121.575 50.7433 121.742 50.91C121.908 51.0767 121.992 51.2733 121.992 51.5C121.992 51.7267 121.908 51.9233 121.742 52.09C121.575 52.2567 121.378 52.34 121.152 52.34C120.925 52.34 120.728 52.2567 120.562 52.09C120.395 51.9233 120.312 51.7267 120.312 51.5ZM118.572 50.72C118.572 49.8133 118.798 49.11 119.252 48.61C119.712 48.11 120.345 47.86 121.152 47.86C121.958 47.86 122.588 48.11 123.042 48.61C123.502 49.11 123.732 49.8133 123.732 50.72V52.28C123.732 53.2133 123.502 53.9233 123.042 54.41C122.588 54.8967 121.958 55.14 121.152 55.14C120.345 55.14 119.712 54.8967 119.252 54.41C118.798 53.9233 118.572 53.2133 118.572 52.28V50.72ZM121.152 53.94C121.392 53.94 121.592 53.9033 121.752 53.83C121.912 53.75 122.042 53.64 122.142 53.5C122.242 53.36 122.312 53.1933 122.352 53C122.392 52.8067 122.412 52.5933 122.412 52.36V50.64C122.412 50.42 122.388 50.2133 122.342 50.02C122.295 49.8267 122.222 49.66 122.122 49.52C122.022 49.38 121.892 49.27 121.732 49.19C121.572 49.1033 121.378 49.06 121.152 49.06C120.925 49.06 120.732 49.1033 120.572 49.19C120.412 49.27 120.282 49.38 120.182 49.52C120.082 49.66 120.008 49.8267 119.962 50.02C119.915 50.2133 119.892 50.42 119.892 50.64V52.36C119.892 52.5933 119.912 52.8067 119.952 53C119.992 53.1933 120.062 53.36 120.162 53.5C120.262 53.64 120.392 53.75 120.552 53.83C120.712 53.9033 120.912 53.94 121.152 53.94Z"
    fill="#5078E1"
  /><path
    d="M126.435 51.5C126.435 51.2733 126.518 51.0767 126.685 50.91C126.852 50.7433 127.048 50.66 127.275 50.66C127.502 50.66 127.698 50.7433 127.865 50.91C128.032 51.0767 128.115 51.2733 128.115 51.5C128.115 51.7267 128.032 51.9233 127.865 52.09C127.698 52.2567 127.502 52.34 127.275 52.34C127.048 52.34 126.852 52.2567 126.685 52.09C126.518 51.9233 126.435 51.7267 126.435 51.5ZM124.695 50.72C124.695 49.8133 124.922 49.11 125.375 48.61C125.835 48.11 126.468 47.86 127.275 47.86C128.082 47.86 128.712 48.11 129.165 48.61C129.625 49.11 129.855 49.8133 129.855 50.72V52.28C129.855 53.2133 129.625 53.9233 129.165 54.41C128.712 54.8967 128.082 55.14 127.275 55.14C126.468 55.14 125.835 54.8967 125.375 54.41C124.922 53.9233 124.695 53.2133 124.695 52.28V50.72ZM127.275 53.94C127.515 53.94 127.715 53.9033 127.875 53.83C128.035 53.75 128.165 53.64 128.265 53.5C128.365 53.36 128.435 53.1933 128.475 53C128.515 52.8067 128.535 52.5933 128.535 52.36V50.64C128.535 50.42 128.512 50.2133 128.465 50.02C128.418 49.8267 128.345 49.66 128.245 49.52C128.145 49.38 128.015 49.27 127.855 49.19C127.695 49.1033 127.502 49.06 127.275 49.06C127.048 49.06 126.855 49.1033 126.695 49.19C126.535 49.27 126.405 49.38 126.305 49.52C126.205 49.66 126.132 49.8267 126.085 50.02C126.038 50.2133 126.015 50.42 126.015 50.64V52.36C126.015 52.5933 126.035 52.8067 126.075 53C126.115 53.1933 126.185 53.36 126.285 53.5C126.385 53.64 126.515 53.75 126.675 53.83C126.835 53.9033 127.035 53.94 127.275 53.94Z"
    fill="#5078E1"
  /><path
    d="M132.978 53.74V48.66H132.798L132.138 51.06H130.878V50.94L131.718 48H134.298V53.74H136.038V55H130.998V53.74H132.978Z"
    fill="#5078E1"
  /><path
    d="M139.101 53.74V48.66H138.921L138.261 51.06H137.001V50.94L137.841 48H140.421V53.74H142.161V55H137.121V53.74H139.101Z"
    fill="#5078E1"
  /><path
    d="M145.224 53.74V48.66H145.044L144.384 51.06H143.124V50.94L143.964 48H146.544V53.74H148.284V55H143.244V53.74H145.224Z"
    fill="#5078E1"
  /><path
    d="M150.927 51.5C150.927 51.2733 151.01 51.0767 151.177 50.91C151.344 50.7433 151.54 50.66 151.767 50.66C151.994 50.66 152.19 50.7433 152.357 50.91C152.524 51.0767 152.607 51.2733 152.607 51.5C152.607 51.7267 152.524 51.9233 152.357 52.09C152.19 52.2567 151.994 52.34 151.767 52.34C151.54 52.34 151.344 52.2567 151.177 52.09C151.01 51.9233 150.927 51.7267 150.927 51.5ZM149.187 50.72C149.187 49.8133 149.414 49.11 149.867 48.61C150.327 48.11 150.96 47.86 151.767 47.86C152.574 47.86 153.204 48.11 153.657 48.61C154.117 49.11 154.347 49.8133 154.347 50.72V52.28C154.347 53.2133 154.117 53.9233 153.657 54.41C153.204 54.8967 152.574 55.14 151.767 55.14C150.96 55.14 150.327 54.8967 149.867 54.41C149.414 53.9233 149.187 53.2133 149.187 52.28V50.72ZM151.767 53.94C152.007 53.94 152.207 53.9033 152.367 53.83C152.527 53.75 152.657 53.64 152.757 53.5C152.857 53.36 152.927 53.1933 152.967 53C153.007 52.8067 153.027 52.5933 153.027 52.36V50.64C153.027 50.42 153.004 50.2133 152.957 50.02C152.91 49.8267 152.837 49.66 152.737 49.52C152.637 49.38 152.507 49.27 152.347 49.19C152.187 49.1033 151.994 49.06 151.767 49.06C151.54 49.06 151.347 49.1033 151.187 49.19C151.027 49.27 150.897 49.38 150.797 49.52C150.697 49.66 150.624 49.8267 150.577 50.02C150.53 50.2133 150.507 50.42 150.507 50.64V52.36C150.507 52.5933 150.527 52.8067 150.567 53C150.607 53.1933 150.677 53.36 150.777 53.5C150.877 53.64 151.007 53.75 151.167 53.83C151.327 53.9033 151.527 53.94 151.767 53.94Z"
    fill="#5078E1"
  /><path
    d="M157.47 53.74V48.66H157.29L156.63 51.06H155.37V50.94L156.21 48H158.79V53.74H160.53V55H155.49V53.74H157.47Z"
    fill="#5078E1"
  /><path
    d="M163.173 51.5C163.173 51.2733 163.256 51.0767 163.423 50.91C163.59 50.7433 163.786 50.66 164.013 50.66C164.24 50.66 164.436 50.7433 164.603 50.91C164.77 51.0767 164.853 51.2733 164.853 51.5C164.853 51.7267 164.77 51.9233 164.603 52.09C164.436 52.2567 164.24 52.34 164.013 52.34C163.786 52.34 163.59 52.2567 163.423 52.09C163.256 51.9233 163.173 51.7267 163.173 51.5ZM161.433 50.72C161.433 49.8133 161.66 49.11 162.113 48.61C162.573 48.11 163.206 47.86 164.013 47.86C164.82 47.86 165.45 48.11 165.903 48.61C166.363 49.11 166.593 49.8133 166.593 50.72V52.28C166.593 53.2133 166.363 53.9233 165.903 54.41C165.45 54.8967 164.82 55.14 164.013 55.14C163.206 55.14 162.573 54.8967 162.113 54.41C161.66 53.9233 161.433 53.2133 161.433 52.28V50.72ZM164.013 53.94C164.253 53.94 164.453 53.9033 164.613 53.83C164.773 53.75 164.903 53.64 165.003 53.5C165.103 53.36 165.173 53.1933 165.213 53C165.253 52.8067 165.273 52.5933 165.273 52.36V50.64C165.273 50.42 165.25 50.2133 165.203 50.02C165.156 49.8267 165.083 49.66 164.983 49.52C164.883 49.38 164.753 49.27 164.593 49.19C164.433 49.1033 164.24 49.06 164.013 49.06C163.786 49.06 163.593 49.1033 163.433 49.19C163.273 49.27 163.143 49.38 163.043 49.52C162.943 49.66 162.87 49.8267 162.823 50.02C162.776 50.2133 162.753 50.42 162.753 50.64V52.36C162.753 52.5933 162.773 52.8067 162.813 53C162.853 53.1933 162.923 53.36 163.023 53.5C163.123 53.64 163.253 53.75 163.413 53.83C163.573 53.9033 163.773 53.94 164.013 53.94Z"
    fill="#5078E1"
  /><path
    d="M169.716 53.74V48.66H169.536L168.876 51.06H167.616V50.94L168.456 48H171.036V53.74H172.776V55H167.736V53.74H169.716Z"
    fill="#5078E1"
  /><path
    d="M175.419 51.5C175.419 51.2733 175.503 51.0767 175.669 50.91C175.836 50.7433 176.033 50.66 176.259 50.66C176.486 50.66 176.683 50.7433 176.849 50.91C177.016 51.0767 177.099 51.2733 177.099 51.5C177.099 51.7267 177.016 51.9233 176.849 52.09C176.683 52.2567 176.486 52.34 176.259 52.34C176.033 52.34 175.836 52.2567 175.669 52.09C175.503 51.9233 175.419 51.7267 175.419 51.5ZM173.679 50.72C173.679 49.8133 173.906 49.11 174.359 48.61C174.819 48.11 175.453 47.86 176.259 47.86C177.066 47.86 177.696 48.11 178.149 48.61C178.609 49.11 178.839 49.8133 178.839 50.72V52.28C178.839 53.2133 178.609 53.9233 178.149 54.41C177.696 54.8967 177.066 55.14 176.259 55.14C175.453 55.14 174.819 54.8967 174.359 54.41C173.906 53.9233 173.679 53.2133 173.679 52.28V50.72ZM176.259 53.94C176.499 53.94 176.699 53.9033 176.859 53.83C177.019 53.75 177.149 53.64 177.249 53.5C177.349 53.36 177.419 53.1933 177.459 53C177.499 52.8067 177.519 52.5933 177.519 52.36V50.64C177.519 50.42 177.496 50.2133 177.449 50.02C177.403 49.8267 177.329 49.66 177.229 49.52C177.129 49.38 176.999 49.27 176.839 49.19C176.679 49.1033 176.486 49.06 176.259 49.06C176.033 49.06 175.839 49.1033 175.679 49.19C175.519 49.27 175.389 49.38 175.289 49.52C175.189 49.66 175.116 49.8267 175.069 50.02C175.023 50.2133 174.999 50.42 174.999 50.64V52.36C174.999 52.5933 175.019 52.8067 175.059 53C175.099 53.1933 175.169 53.36 175.269 53.5C175.369 53.64 175.499 53.75 175.659 53.83C175.819 53.9033 176.019 53.94 176.259 53.94Z"
    fill="#5078E1"
  /><path
    d="M181.962 53.74V48.66H181.782L181.122 51.06H179.862V50.94L180.702 48H183.282V53.74H185.022V55H179.982V53.74H181.962Z"
    fill="#5078E1"
  /><path
    d="M188.085 53.74V48.66H187.905L187.245 51.06H185.985V50.94L186.825 48H189.405V53.74H191.145V55H186.105V53.74H188.085Z"
    fill="#5078E1"
  /><path
    d="M194.208 53.74V48.66H194.028L193.368 51.06H192.108V50.94L192.948 48H195.528V53.74H197.268V55H192.228V53.74H194.208Z"
    fill="#5078E1"
  /><path
    d="M199.911 51.5C199.911 51.2733 199.995 51.0767 200.161 50.91C200.328 50.7433 200.525 50.66 200.751 50.66C200.978 50.66 201.175 50.7433 201.341 50.91C201.508 51.0767 201.591 51.2733 201.591 51.5C201.591 51.7267 201.508 51.9233 201.341 52.09C201.175 52.2567 200.978 52.34 200.751 52.34C200.525 52.34 200.328 52.2567 200.161 52.09C199.995 51.9233 199.911 51.7267 199.911 51.5ZM198.171 50.72C198.171 49.8133 198.398 49.11 198.851 48.61C199.311 48.11 199.945 47.86 200.751 47.86C201.558 47.86 202.188 48.11 202.641 48.61C203.101 49.11 203.331 49.8133 203.331 50.72V52.28C203.331 53.2133 203.101 53.9233 202.641 54.41C202.188 54.8967 201.558 55.14 200.751 55.14C199.945 55.14 199.311 54.8967 198.851 54.41C198.398 53.9233 198.171 53.2133 198.171 52.28V50.72ZM200.751 53.94C200.991 53.94 201.191 53.9033 201.351 53.83C201.511 53.75 201.641 53.64 201.741 53.5C201.841 53.36 201.911 53.1933 201.951 53C201.991 52.8067 202.011 52.5933 202.011 52.36V50.64C202.011 50.42 201.988 50.2133 201.941 50.02C201.895 49.8267 201.821 49.66 201.721 49.52C201.621 49.38 201.491 49.27 201.331 49.19C201.171 49.1033 200.978 49.06 200.751 49.06C200.525 49.06 200.331 49.1033 200.171 49.19C200.011 49.27 199.881 49.38 199.781 49.52C199.681 49.66 199.608 49.8267 199.561 50.02C199.515 50.2133 199.491 50.42 199.491 50.64V52.36C199.491 52.5933 199.511 52.8067 199.551 53C199.591 53.1933 199.661 53.36 199.761 53.5C199.861 53.64 199.991 53.75 200.151 53.83C200.311 53.9033 200.511 53.94 200.751 53.94Z"
    fill="#5078E1"
  /><path
    d="M206.034 51.5C206.034 51.2733 206.118 51.0767 206.284 50.91C206.451 50.7433 206.648 50.66 206.874 50.66C207.101 50.66 207.298 50.7433 207.464 50.91C207.631 51.0767 207.714 51.2733 207.714 51.5C207.714 51.7267 207.631 51.9233 207.464 52.09C207.298 52.2567 207.101 52.34 206.874 52.34C206.648 52.34 206.451 52.2567 206.284 52.09C206.118 51.9233 206.034 51.7267 206.034 51.5ZM204.294 50.72C204.294 49.8133 204.521 49.11 204.974 48.61C205.434 48.11 206.068 47.86 206.874 47.86C207.681 47.86 208.311 48.11 208.764 48.61C209.224 49.11 209.454 49.8133 209.454 50.72V52.28C209.454 53.2133 209.224 53.9233 208.764 54.41C208.311 54.8967 207.681 55.14 206.874 55.14C206.068 55.14 205.434 54.8967 204.974 54.41C204.521 53.9233 204.294 53.2133 204.294 52.28V50.72ZM206.874 53.94C207.114 53.94 207.314 53.9033 207.474 53.83C207.634 53.75 207.764 53.64 207.864 53.5C207.964 53.36 208.034 53.1933 208.074 53C208.114 52.8067 208.134 52.5933 208.134 52.36V50.64C208.134 50.42 208.111 50.2133 208.064 50.02C208.018 49.8267 207.944 49.66 207.844 49.52C207.744 49.38 207.614 49.27 207.454 49.19C207.294 49.1033 207.101 49.06 206.874 49.06C206.648 49.06 206.454 49.1033 206.294 49.19C206.134 49.27 206.004 49.38 205.904 49.52C205.804 49.66 205.731 49.8267 205.684 50.02C205.638 50.2133 205.614 50.42 205.614 50.64V52.36C205.614 52.5933 205.634 52.8067 205.674 53C205.714 53.1933 205.784 53.36 205.884 53.5C205.984 53.64 206.114 53.75 206.274 53.83C206.434 53.9033 206.634 53.94 206.874 53.94Z"
    fill="#5078E1"
  /><path
    d="M212.577 53.74V48.66H212.397L211.738 51.06H210.477V50.94L211.317 48H213.897V53.74H215.637V55H210.597V53.74H212.577Z"
    fill="#5078E1"
  /><path
    d="M218.701 53.74V48.66H218.521L217.861 51.06H216.601V50.94L217.441 48H220.021V53.74H221.761V55H216.721V53.74H218.701Z"
    fill="#5078E1"
  /><path
    d="M224.404 51.5C224.404 51.2733 224.487 51.0767 224.654 50.91C224.82 50.7433 225.017 50.66 225.244 50.66C225.47 50.66 225.667 50.7433 225.834 50.91C226 51.0767 226.084 51.2733 226.084 51.5C226.084 51.7267 226 51.9233 225.834 52.09C225.667 52.2567 225.47 52.34 225.244 52.34C225.017 52.34 224.82 52.2567 224.654 52.09C224.487 51.9233 224.404 51.7267 224.404 51.5ZM222.664 50.72C222.664 49.8133 222.89 49.11 223.344 48.61C223.804 48.11 224.437 47.86 225.244 47.86C226.05 47.86 226.68 48.11 227.134 48.61C227.594 49.11 227.824 49.8133 227.824 50.72V52.28C227.824 53.2133 227.594 53.9233 227.134 54.41C226.68 54.8967 226.05 55.14 225.244 55.14C224.437 55.14 223.804 54.8967 223.344 54.41C222.89 53.9233 222.664 53.2133 222.664 52.28V50.72ZM225.244 53.94C225.484 53.94 225.684 53.9033 225.844 53.83C226.004 53.75 226.134 53.64 226.234 53.5C226.334 53.36 226.404 53.1933 226.444 53C226.484 52.8067 226.504 52.5933 226.504 52.36V50.64C226.504 50.42 226.48 50.2133 226.434 50.02C226.387 49.8267 226.314 49.66 226.214 49.52C226.114 49.38 225.984 49.27 225.824 49.19C225.664 49.1033 225.47 49.06 225.244 49.06C225.017 49.06 224.824 49.1033 224.664 49.19C224.504 49.27 224.374 49.38 224.274 49.52C224.174 49.66 224.1 49.8267 224.054 50.02C224.007 50.2133 223.984 50.42 223.984 50.64V52.36C223.984 52.5933 224.004 52.8067 224.044 53C224.084 53.1933 224.154 53.36 224.254 53.5C224.354 53.64 224.484 53.75 224.644 53.83C224.804 53.9033 225.004 53.94 225.244 53.94Z"
    fill="#5078E1"
  /><path
    d="M230.947 53.74V48.66H230.767L230.107 51.06H228.847V50.94L229.687 48H232.267V53.74H234.007V55H228.967V53.74H230.947Z"
    fill="#5078E1"
  /><path
    d="M237.07 53.74V48.66H236.89L236.23 51.06H234.97V50.94L235.81 48H238.39V53.74H240.13V55H235.09V53.74H237.07Z"
    fill="#5078E1"
  /><path
    d="M242.773 51.5C242.773 51.2733 242.856 51.0767 243.023 50.91C243.189 50.7433 243.386 50.66 243.613 50.66C243.839 50.66 244.036 50.7433 244.203 50.91C244.369 51.0767 244.453 51.2733 244.453 51.5C244.453 51.7267 244.369 51.9233 244.203 52.09C244.036 52.2567 243.839 52.34 243.613 52.34C243.386 52.34 243.189 52.2567 243.023 52.09C242.856 51.9233 242.773 51.7267 242.773 51.5ZM241.033 50.72C241.033 49.8133 241.259 49.11 241.713 48.61C242.173 48.11 242.806 47.86 243.613 47.86C244.419 47.86 245.049 48.11 245.503 48.61C245.963 49.11 246.193 49.8133 246.193 50.72V52.28C246.193 53.2133 245.963 53.9233 245.503 54.41C245.049 54.8967 244.419 55.14 243.613 55.14C242.806 55.14 242.173 54.8967 241.713 54.41C241.259 53.9233 241.033 53.2133 241.033 52.28V50.72ZM243.613 53.94C243.853 53.94 244.053 53.9033 244.213 53.83C244.373 53.75 244.503 53.64 244.603 53.5C244.703 53.36 244.773 53.1933 244.813 53C244.853 52.8067 244.873 52.5933 244.873 52.36V50.64C244.873 50.42 244.849 50.2133 244.803 50.02C244.756 49.8267 244.683 49.66 244.583 49.52C244.483 49.38 244.353 49.27 244.193 49.19C244.033 49.1033 243.839 49.06 243.613 49.06C243.386 49.06 243.193 49.1033 243.033 49.19C242.873 49.27 242.743 49.38 242.643 49.52C242.543 49.66 242.469 49.8267 242.423 50.02C242.376 50.2133 242.353 50.42 242.353 50.64V52.36C242.353 52.5933 242.373 52.8067 242.413 53C242.453 53.1933 242.523 53.36 242.623 53.5C242.723 53.64 242.853 53.75 243.013 53.83C243.173 53.9033 243.373 53.94 243.613 53.94Z"
    fill="#5078E1"
  /><path
    d="M249.316 53.74V48.66H249.136L248.476 51.06H247.216V50.94L248.056 48H250.636V53.74H252.376V55H247.336V53.74H249.316Z"
    fill="#5078E1"
  /><path
    d="M255.019 51.5C255.019 51.2733 255.102 51.0767 255.269 50.91C255.435 50.7433 255.632 50.66 255.859 50.66C256.085 50.66 256.282 50.7433 256.449 50.91C256.615 51.0767 256.699 51.2733 256.699 51.5C256.699 51.7267 256.615 51.9233 256.449 52.09C256.282 52.2567 256.085 52.34 255.859 52.34C255.632 52.34 255.435 52.2567 255.269 52.09C255.102 51.9233 255.019 51.7267 255.019 51.5ZM253.279 50.72C253.279 49.8133 253.505 49.11 253.959 48.61C254.419 48.11 255.052 47.86 255.859 47.86C256.665 47.86 257.295 48.11 257.749 48.61C258.209 49.11 258.439 49.8133 258.439 50.72V52.28C258.439 53.2133 258.209 53.9233 257.749 54.41C257.295 54.8967 256.665 55.14 255.859 55.14C255.052 55.14 254.419 54.8967 253.959 54.41C253.505 53.9233 253.279 53.2133 253.279 52.28V50.72ZM255.859 53.94C256.099 53.94 256.299 53.9033 256.459 53.83C256.619 53.75 256.749 53.64 256.849 53.5C256.949 53.36 257.019 53.1933 257.059 53C257.099 52.8067 257.119 52.5933 257.119 52.36V50.64C257.119 50.42 257.095 50.2133 257.049 50.02C257.002 49.8267 256.929 49.66 256.829 49.52C256.729 49.38 256.599 49.27 256.439 49.19C256.279 49.1033 256.085 49.06 255.859 49.06C255.632 49.06 255.439 49.1033 255.279 49.19C255.119 49.27 254.989 49.38 254.889 49.52C254.789 49.66 254.715 49.8267 254.669 50.02C254.622 50.2133 254.599 50.42 254.599 50.64V52.36C254.599 52.5933 254.619 52.8067 254.659 53C254.699 53.1933 254.769 53.36 254.869 53.5C254.969 53.64 255.099 53.75 255.259 53.83C255.419 53.9033 255.619 53.94 255.859 53.94Z"
    fill="#5078E1"
  /><path
    d="M261.142 51.5C261.142 51.2733 261.225 51.0767 261.392 50.91C261.559 50.7433 261.755 50.66 261.982 50.66C262.209 50.66 262.405 50.7433 262.572 50.91C262.739 51.0767 262.822 51.2733 262.822 51.5C262.822 51.7267 262.739 51.9233 262.572 52.09C262.405 52.2567 262.209 52.34 261.982 52.34C261.755 52.34 261.559 52.2567 261.392 52.09C261.225 51.9233 261.142 51.7267 261.142 51.5ZM259.402 50.72C259.402 49.8133 259.629 49.11 260.082 48.61C260.542 48.11 261.175 47.86 261.982 47.86C262.789 47.86 263.419 48.11 263.872 48.61C264.332 49.11 264.562 49.8133 264.562 50.72V52.28C264.562 53.2133 264.332 53.9233 263.872 54.41C263.419 54.8967 262.789 55.14 261.982 55.14C261.175 55.14 260.542 54.8967 260.082 54.41C259.629 53.9233 259.402 53.2133 259.402 52.28V50.72ZM261.982 53.94C262.222 53.94 262.422 53.9033 262.582 53.83C262.742 53.75 262.872 53.64 262.972 53.5C263.072 53.36 263.142 53.1933 263.182 53C263.222 52.8067 263.242 52.5933 263.242 52.36V50.64C263.242 50.42 263.219 50.2133 263.172 50.02C263.125 49.8267 263.052 49.66 262.952 49.52C262.852 49.38 262.722 49.27 262.562 49.19C262.402 49.1033 262.209 49.06 261.982 49.06C261.755 49.06 261.562 49.1033 261.402 49.19C261.242 49.27 261.112 49.38 261.012 49.52C260.912 49.66 260.839 49.8267 260.792 50.02C260.745 50.2133 260.722 50.42 260.722 50.64V52.36C260.722 52.5933 260.742 52.8067 260.782 53C260.822 53.1933 260.892 53.36 260.992 53.5C261.092 53.64 261.222 53.75 261.382 53.83C261.542 53.9033 261.742 53.94 261.982 53.94Z"
    fill="#5078E1"
  /><path
    d="M267.685 53.74V48.66H267.505L266.845 51.06H265.585V50.94L266.425 48H269.005V53.74H270.745V55H265.705V53.74H267.685Z"
    fill="#5078E1"
  /><path
    d="M273.388 51.5C273.388 51.2733 273.471 51.0767 273.638 50.91C273.805 50.7433 274.001 50.66 274.228 50.66C274.455 50.66 274.651 50.7433 274.818 50.91C274.985 51.0767 275.068 51.2733 275.068 51.5C275.068 51.7267 274.985 51.9233 274.818 52.09C274.651 52.2567 274.455 52.34 274.228 52.34C274.001 52.34 273.805 52.2567 273.638 52.09C273.471 51.9233 273.388 51.7267 273.388 51.5ZM271.648 50.72C271.648 49.8133 271.875 49.11 272.328 48.61C272.788 48.11 273.421 47.86 274.228 47.86C275.035 47.86 275.665 48.11 276.118 48.61C276.578 49.11 276.808 49.8133 276.808 50.72V52.28C276.808 53.2133 276.578 53.9233 276.118 54.41C275.665 54.8967 275.035 55.14 274.228 55.14C273.421 55.14 272.788 54.8967 272.328 54.41C271.875 53.9233 271.648 53.2133 271.648 52.28V50.72ZM274.228 53.94C274.468 53.94 274.668 53.9033 274.828 53.83C274.988 53.75 275.118 53.64 275.218 53.5C275.318 53.36 275.388 53.1933 275.428 53C275.468 52.8067 275.488 52.5933 275.488 52.36V50.64C275.488 50.42 275.465 50.2133 275.418 50.02C275.371 49.8267 275.298 49.66 275.198 49.52C275.098 49.38 274.968 49.27 274.808 49.19C274.648 49.1033 274.455 49.06 274.228 49.06C274.001 49.06 273.808 49.1033 273.648 49.19C273.488 49.27 273.358 49.38 273.258 49.52C273.158 49.66 273.085 49.8267 273.038 50.02C272.991 50.2133 272.968 50.42 272.968 50.64V52.36C272.968 52.5933 272.988 52.8067 273.028 53C273.068 53.1933 273.138 53.36 273.238 53.5C273.338 53.64 273.468 53.75 273.628 53.83C273.788 53.9033 273.988 53.94 274.228 53.94Z"
    fill="#5078E1"
  /><path
    d="M279.931 53.74V48.66H279.751L279.091 51.06H277.831V50.94L278.671 48H281.251V53.74H282.991V55H277.951V53.74H279.931Z"
    fill="#5078E1"
  /><path
    d="M286.054 53.74V48.66H285.874L285.214 51.06H283.954V50.94L284.794 48H287.374V53.74H289.114V55H284.074V53.74H286.054Z"
    fill="#5078E1"
  /><path
    d="M291.757 51.5C291.757 51.2733 291.84 51.0767 292.007 50.91C292.174 50.7433 292.37 50.66 292.597 50.66C292.824 50.66 293.02 50.7433 293.187 50.91C293.354 51.0767 293.437 51.2733 293.437 51.5C293.437 51.7267 293.354 51.9233 293.187 52.09C293.02 52.2567 292.824 52.34 292.597 52.34C292.37 52.34 292.174 52.2567 292.007 52.09C291.84 51.9233 291.757 51.7267 291.757 51.5ZM290.017 50.72C290.017 49.8133 290.244 49.11 290.697 48.61C291.157 48.11 291.79 47.86 292.597 47.86C293.404 47.86 294.034 48.11 294.487 48.61C294.947 49.11 295.177 49.8133 295.177 50.72V52.28C295.177 53.2133 294.947 53.9233 294.487 54.41C294.034 54.8967 293.404 55.14 292.597 55.14C291.79 55.14 291.157 54.8967 290.697 54.41C290.244 53.9233 290.017 53.2133 290.017 52.28V50.72ZM292.597 53.94C292.837 53.94 293.037 53.9033 293.197 53.83C293.357 53.75 293.487 53.64 293.587 53.5C293.687 53.36 293.757 53.1933 293.797 53C293.837 52.8067 293.857 52.5933 293.857 52.36V50.64C293.857 50.42 293.834 50.2133 293.787 50.02C293.74 49.8267 293.667 49.66 293.567 49.52C293.467 49.38 293.337 49.27 293.177 49.19C293.017 49.1033 292.824 49.06 292.597 49.06C292.37 49.06 292.177 49.1033 292.017 49.19C291.857 49.27 291.727 49.38 291.627 49.52C291.527 49.66 291.454 49.8267 291.407 50.02C291.36 50.2133 291.337 50.42 291.337 50.64V52.36C291.337 52.5933 291.357 52.8067 291.397 53C291.437 53.1933 291.507 53.36 291.607 53.5C291.707 53.64 291.837 53.75 291.997 53.83C292.157 53.9033 292.357 53.94 292.597 53.94Z"
    fill="#5078E1"
  /><path
    d="M298.3 53.74V48.66H298.12L297.46 51.06H296.2V50.94L297.04 48H299.62V53.74H301.36V55H296.32V53.74H298.3Z"
    fill="#5078E1"
  /><path
    d="M304.423 53.74V48.66H304.243L303.583 51.06H302.323V50.94L303.163 48H305.743V53.74H307.483V55H302.443V53.74H304.423Z"
    fill="#5078E1"
  /><path
    d="M310.546 53.74V48.66H310.366L309.706 51.06H308.446V50.94L309.286 48H311.866V53.74H313.606V55H308.566V53.74H310.546Z"
    fill="#5078E1"
  /><path
    d="M316.249 51.5C316.249 51.2733 316.333 51.0767 316.499 50.91C316.666 50.7433 316.863 50.66 317.089 50.66C317.316 50.66 317.513 50.7433 317.679 50.91C317.846 51.0767 317.929 51.2733 317.929 51.5C317.929 51.7267 317.846 51.9233 317.679 52.09C317.513 52.2567 317.316 52.34 317.089 52.34C316.863 52.34 316.666 52.2567 316.499 52.09C316.333 51.9233 316.249 51.7267 316.249 51.5ZM314.509 50.72C314.509 49.8133 314.736 49.11 315.189 48.61C315.649 48.11 316.283 47.86 317.089 47.86C317.896 47.86 318.526 48.11 318.979 48.61C319.439 49.11 319.669 49.8133 319.669 50.72V52.28C319.669 53.2133 319.439 53.9233 318.979 54.41C318.526 54.8967 317.896 55.14 317.089 55.14C316.283 55.14 315.649 54.8967 315.189 54.41C314.736 53.9233 314.509 53.2133 314.509 52.28V50.72ZM317.089 53.94C317.329 53.94 317.529 53.9033 317.689 53.83C317.849 53.75 317.979 53.64 318.079 53.5C318.179 53.36 318.249 53.1933 318.289 53C318.329 52.8067 318.349 52.5933 318.349 52.36V50.64C318.349 50.42 318.326 50.2133 318.279 50.02C318.233 49.8267 318.159 49.66 318.059 49.52C317.959 49.38 317.829 49.27 317.669 49.19C317.509 49.1033 317.316 49.06 317.089 49.06C316.863 49.06 316.669 49.1033 316.509 49.19C316.349 49.27 316.219 49.38 316.119 49.52C316.019 49.66 315.946 49.8267 315.899 50.02C315.853 50.2133 315.829 50.42 315.829 50.64V52.36C315.829 52.5933 315.849 52.8067 315.889 53C315.929 53.1933 315.999 53.36 316.099 53.5C316.199 53.64 316.329 53.75 316.489 53.83C316.649 53.9033 316.849 53.94 317.089 53.94Z"
    fill="#5078E1"
  /><path
    d="M322.372 51.5C322.372 51.2733 322.456 51.0767 322.622 50.91C322.789 50.7433 322.986 50.66 323.212 50.66C323.439 50.66 323.636 50.7433 323.802 50.91C323.969 51.0767 324.052 51.2733 324.052 51.5C324.052 51.7267 323.969 51.9233 323.802 52.09C323.636 52.2567 323.439 52.34 323.212 52.34C322.986 52.34 322.789 52.2567 322.622 52.09C322.456 51.9233 322.372 51.7267 322.372 51.5ZM320.632 50.72C320.632 49.8133 320.859 49.11 321.312 48.61C321.772 48.11 322.406 47.86 323.212 47.86C324.019 47.86 324.649 48.11 325.102 48.61C325.562 49.11 325.792 49.8133 325.792 50.72V52.28C325.792 53.2133 325.562 53.9233 325.102 54.41C324.649 54.8967 324.019 55.14 323.212 55.14C322.406 55.14 321.772 54.8967 321.312 54.41C320.859 53.9233 320.632 53.2133 320.632 52.28V50.72ZM323.212 53.94C323.452 53.94 323.652 53.9033 323.812 53.83C323.972 53.75 324.102 53.64 324.202 53.5C324.302 53.36 324.372 53.1933 324.412 53C324.452 52.8067 324.472 52.5933 324.472 52.36V50.64C324.472 50.42 324.449 50.2133 324.402 50.02C324.356 49.8267 324.282 49.66 324.182 49.52C324.082 49.38 323.952 49.27 323.792 49.19C323.632 49.1033 323.439 49.06 323.212 49.06C322.986 49.06 322.792 49.1033 322.632 49.19C322.472 49.27 322.342 49.38 322.242 49.52C322.142 49.66 322.069 49.8267 322.022 50.02C321.976 50.2133 321.952 50.42 321.952 50.64V52.36C321.952 52.5933 321.972 52.8067 322.012 53C322.052 53.1933 322.122 53.36 322.222 53.5C322.322 53.64 322.452 53.75 322.612 53.83C322.772 53.9033 322.972 53.94 323.212 53.94Z"
    fill="#5078E1"
  /><path
    d="M328.915 53.74V48.66H328.735L328.075 51.06H326.815V50.94L327.655 48H330.235V53.74H331.975V55H326.935V53.74H328.915Z"
    fill="#5078E1"
  /><path
    d="M335.038 53.74V48.66H334.858L334.198 51.06H332.938V50.94L333.778 48H336.358V53.74H338.098V55H333.058V53.74H335.038Z"
    fill="#5078E1"
  /><path
    d="M340.741 51.5C340.741 51.2733 340.825 51.0767 340.991 50.91C341.158 50.7433 341.355 50.66 341.581 50.66C341.808 50.66 342.005 50.7433 342.171 50.91C342.338 51.0767 342.421 51.2733 342.421 51.5C342.421 51.7267 342.338 51.9233 342.171 52.09C342.005 52.2567 341.808 52.34 341.581 52.34C341.355 52.34 341.158 52.2567 340.991 52.09C340.825 51.9233 340.741 51.7267 340.741 51.5ZM339.001 50.72C339.001 49.8133 339.228 49.11 339.681 48.61C340.141 48.11 340.775 47.86 341.581 47.86C342.388 47.86 343.018 48.11 343.471 48.61C343.931 49.11 344.161 49.8133 344.161 50.72V52.28C344.161 53.2133 343.931 53.9233 343.471 54.41C343.018 54.8967 342.388 55.14 341.581 55.14C340.775 55.14 340.141 54.8967 339.681 54.41C339.228 53.9233 339.001 53.2133 339.001 52.28V50.72ZM341.581 53.94C341.821 53.94 342.021 53.9033 342.181 53.83C342.341 53.75 342.471 53.64 342.571 53.5C342.671 53.36 342.741 53.1933 342.781 53C342.821 52.8067 342.841 52.5933 342.841 52.36V50.64C342.841 50.42 342.818 50.2133 342.771 50.02C342.725 49.8267 342.651 49.66 342.551 49.52C342.451 49.38 342.321 49.27 342.161 49.19C342.001 49.1033 341.808 49.06 341.581 49.06C341.355 49.06 341.161 49.1033 341.001 49.19C340.841 49.27 340.711 49.38 340.611 49.52C340.511 49.66 340.438 49.8267 340.391 50.02C340.345 50.2133 340.321 50.42 340.321 50.64V52.36C340.321 52.5933 340.341 52.8067 340.381 53C340.421 53.1933 340.491 53.36 340.591 53.5C340.691 53.64 340.821 53.75 340.981 53.83C341.141 53.9033 341.341 53.94 341.581 53.94Z"
    fill="#5078E1"
  /><path
    d="M346.865 51.5C346.865 51.2733 346.948 51.0767 347.115 50.91C347.281 50.7433 347.478 50.66 347.705 50.66C347.931 50.66 348.128 50.7433 348.295 50.91C348.461 51.0767 348.545 51.2733 348.545 51.5C348.545 51.7267 348.461 51.9233 348.295 52.09C348.128 52.2567 347.931 52.34 347.705 52.34C347.478 52.34 347.281 52.2567 347.115 52.09C346.948 51.9233 346.865 51.7267 346.865 51.5ZM345.125 50.72C345.125 49.8133 345.351 49.11 345.805 48.61C346.265 48.11 346.898 47.86 347.705 47.86C348.511 47.86 349.141 48.11 349.595 48.61C350.055 49.11 350.285 49.8133 350.285 50.72V52.28C350.285 53.2133 350.055 53.9233 349.595 54.41C349.141 54.8967 348.511 55.14 347.705 55.14C346.898 55.14 346.265 54.8967 345.805 54.41C345.351 53.9233 345.125 53.2133 345.125 52.28V50.72ZM347.705 53.94C347.945 53.94 348.145 53.9033 348.305 53.83C348.465 53.75 348.595 53.64 348.695 53.5C348.795 53.36 348.865 53.1933 348.905 53C348.945 52.8067 348.965 52.5933 348.965 52.36V50.64C348.965 50.42 348.941 50.2133 348.895 50.02C348.848 49.8267 348.775 49.66 348.675 49.52C348.575 49.38 348.445 49.27 348.285 49.19C348.125 49.1033 347.931 49.06 347.705 49.06C347.478 49.06 347.285 49.1033 347.125 49.19C346.965 49.27 346.835 49.38 346.735 49.52C346.635 49.66 346.561 49.8267 346.515 50.02C346.468 50.2133 346.445 50.42 346.445 50.64V52.36C346.445 52.5933 346.465 52.8067 346.505 53C346.545 53.1933 346.615 53.36 346.715 53.5C346.815 53.64 346.945 53.75 347.105 53.83C347.265 53.9033 347.465 53.94 347.705 53.94Z"
    fill="#5078E1"
  /><path
    d="M353.408 53.74V48.66H353.228L352.568 51.06H351.308V50.94L352.148 48H354.728V53.74H356.468V55H351.428V53.74H353.408Z"
    fill="#5078E1"
  /><path
    d="M359.111 51.5C359.111 51.2733 359.194 51.0767 359.361 50.91C359.527 50.7433 359.724 50.66 359.951 50.66C360.177 50.66 360.374 50.7433 360.541 50.91C360.707 51.0767 360.791 51.2733 360.791 51.5C360.791 51.7267 360.707 51.9233 360.541 52.09C360.374 52.2567 360.177 52.34 359.951 52.34C359.724 52.34 359.527 52.2567 359.361 52.09C359.194 51.9233 359.111 51.7267 359.111 51.5ZM357.371 50.72C357.371 49.8133 357.597 49.11 358.051 48.61C358.511 48.11 359.144 47.86 359.951 47.86C360.757 47.86 361.387 48.11 361.841 48.61C362.301 49.11 362.531 49.8133 362.531 50.72V52.28C362.531 53.2133 362.301 53.9233 361.841 54.41C361.387 54.8967 360.757 55.14 359.951 55.14C359.144 55.14 358.511 54.8967 358.051 54.41C357.597 53.9233 357.371 53.2133 357.371 52.28V50.72ZM359.951 53.94C360.191 53.94 360.391 53.9033 360.551 53.83C360.711 53.75 360.841 53.64 360.941 53.5C361.041 53.36 361.111 53.1933 361.151 53C361.191 52.8067 361.211 52.5933 361.211 52.36V50.64C361.211 50.42 361.187 50.2133 361.141 50.02C361.094 49.8267 361.021 49.66 360.921 49.52C360.821 49.38 360.691 49.27 360.531 49.19C360.371 49.1033 360.177 49.06 359.951 49.06C359.724 49.06 359.531 49.1033 359.371 49.19C359.211 49.27 359.081 49.38 358.981 49.52C358.881 49.66 358.807 49.8267 358.761 50.02C358.714 50.2133 358.691 50.42 358.691 50.64V52.36C358.691 52.5933 358.711 52.8067 358.751 53C358.791 53.1933 358.861 53.36 358.961 53.5C359.061 53.64 359.191 53.75 359.351 53.83C359.511 53.9033 359.711 53.94 359.951 53.94Z"
    fill="#5078E1"
  /><path
    d="M365.654 53.74V48.66H365.474L364.814 51.06H363.554V50.94L364.394 48H366.974V53.74H368.714V55H363.674V53.74H365.654Z"
    fill="#5078E1"
  /><path
    d="M371.357 51.5C371.357 51.2733 371.44 51.0767 371.607 50.91C371.773 50.7433 371.97 50.66 372.197 50.66C372.423 50.66 372.62 50.7433 372.787 50.91C372.953 51.0767 373.037 51.2733 373.037 51.5C373.037 51.7267 372.953 51.9233 372.787 52.09C372.62 52.2567 372.423 52.34 372.197 52.34C371.97 52.34 371.773 52.2567 371.607 52.09C371.44 51.9233 371.357 51.7267 371.357 51.5ZM369.617 50.72C369.617 49.8133 369.843 49.11 370.297 48.61C370.757 48.11 371.39 47.86 372.197 47.86C373.003 47.86 373.633 48.11 374.087 48.61C374.547 49.11 374.777 49.8133 374.777 50.72V52.28C374.777 53.2133 374.547 53.9233 374.087 54.41C373.633 54.8967 373.003 55.14 372.197 55.14C371.39 55.14 370.757 54.8967 370.297 54.41C369.843 53.9233 369.617 53.2133 369.617 52.28V50.72ZM372.197 53.94C372.437 53.94 372.637 53.9033 372.797 53.83C372.957 53.75 373.087 53.64 373.187 53.5C373.287 53.36 373.357 53.1933 373.397 53C373.437 52.8067 373.457 52.5933 373.457 52.36V50.64C373.457 50.42 373.433 50.2133 373.387 50.02C373.34 49.8267 373.267 49.66 373.167 49.52C373.067 49.38 372.937 49.27 372.777 49.19C372.617 49.1033 372.423 49.06 372.197 49.06C371.97 49.06 371.777 49.1033 371.617 49.19C371.457 49.27 371.327 49.38 371.227 49.52C371.127 49.66 371.053 49.8267 371.007 50.02C370.96 50.2133 370.937 50.42 370.937 50.64V52.36C370.937 52.5933 370.957 52.8067 370.997 53C371.037 53.1933 371.107 53.36 371.207 53.5C371.307 53.64 371.437 53.75 371.597 53.83C371.757 53.9033 371.957 53.94 372.197 53.94Z"
    fill="#5078E1"
  /><path
    d="M377.9 53.74V48.66H377.72L377.06 51.06H375.8V50.94L376.64 48H379.22V53.74H380.96V55H375.92V53.74H377.9Z"
    fill="#5078E1"
  /><path
    d="M384.023 53.74V48.66H383.843L383.183 51.06H381.923V50.94L382.763 48H385.343V53.74H387.083V55H382.043V53.74H384.023Z"
    fill="#5078E1"
  /><path
    d="M390.146 53.74V48.66H389.966L389.306 51.06H388.046V50.94L388.886 48H391.466V53.74H393.206V55H388.166V53.74H390.146Z"
    fill="#5078E1"
  /><path
    d="M395.849 51.5C395.849 51.2733 395.932 51.0767 396.099 50.91C396.266 50.7433 396.462 50.66 396.689 50.66C396.916 50.66 397.112 50.7433 397.279 50.91C397.446 51.0767 397.529 51.2733 397.529 51.5C397.529 51.7267 397.446 51.9233 397.279 52.09C397.112 52.2567 396.916 52.34 396.689 52.34C396.462 52.34 396.266 52.2567 396.099 52.09C395.932 51.9233 395.849 51.7267 395.849 51.5ZM394.109 50.72C394.109 49.8133 394.336 49.11 394.789 48.61C395.249 48.11 395.882 47.86 396.689 47.86C397.496 47.86 398.126 48.11 398.579 48.61C399.039 49.11 399.269 49.8133 399.269 50.72V52.28C399.269 53.2133 399.039 53.9233 398.579 54.41C398.126 54.8967 397.496 55.14 396.689 55.14C395.882 55.14 395.249 54.8967 394.789 54.41C394.336 53.9233 394.109 53.2133 394.109 52.28V50.72ZM396.689 53.94C396.929 53.94 397.129 53.9033 397.289 53.83C397.449 53.75 397.579 53.64 397.679 53.5C397.779 53.36 397.849 53.1933 397.889 53C397.929 52.8067 397.949 52.5933 397.949 52.36V50.64C397.949 50.42 397.926 50.2133 397.879 50.02C397.832 49.8267 397.759 49.66 397.659 49.52C397.559 49.38 397.429 49.27 397.269 49.19C397.109 49.1033 396.916 49.06 396.689 49.06C396.462 49.06 396.269 49.1033 396.109 49.19C395.949 49.27 395.819 49.38 395.719 49.52C395.619 49.66 395.546 49.8267 395.499 50.02C395.452 50.2133 395.429 50.42 395.429 50.64V52.36C395.429 52.5933 395.449 52.8067 395.489 53C395.529 53.1933 395.599 53.36 395.699 53.5C395.799 53.64 395.929 53.75 396.089 53.83C396.249 53.9033 396.449 53.94 396.689 53.94Z"
    fill="#5078E1"
  /><path
    d="M401.972 51.5C401.972 51.2733 402.055 51.0767 402.222 50.91C402.389 50.7433 402.585 50.66 402.812 50.66C403.039 50.66 403.235 50.7433 403.402 50.91C403.569 51.0767 403.652 51.2733 403.652 51.5C403.652 51.7267 403.569 51.9233 403.402 52.09C403.235 52.2567 403.039 52.34 402.812 52.34C402.585 52.34 402.389 52.2567 402.222 52.09C402.055 51.9233 401.972 51.7267 401.972 51.5ZM400.232 50.72C400.232 49.8133 400.459 49.11 400.912 48.61C401.372 48.11 402.005 47.86 402.812 47.86C403.619 47.86 404.249 48.11 404.702 48.61C405.162 49.11 405.392 49.8133 405.392 50.72V52.28C405.392 53.2133 405.162 53.9233 404.702 54.41C404.249 54.8967 403.619 55.14 402.812 55.14C402.005 55.14 401.372 54.8967 400.912 54.41C400.459 53.9233 400.232 53.2133 400.232 52.28V50.72ZM402.812 53.94C403.052 53.94 403.252 53.9033 403.412 53.83C403.572 53.75 403.702 53.64 403.802 53.5C403.902 53.36 403.972 53.1933 404.012 53C404.052 52.8067 404.072 52.5933 404.072 52.36V50.64C404.072 50.42 404.049 50.2133 404.002 50.02C403.955 49.8267 403.882 49.66 403.782 49.52C403.682 49.38 403.552 49.27 403.392 49.19C403.232 49.1033 403.039 49.06 402.812 49.06C402.585 49.06 402.392 49.1033 402.232 49.19C402.072 49.27 401.942 49.38 401.842 49.52C401.742 49.66 401.669 49.8267 401.622 50.02C401.575 50.2133 401.552 50.42 401.552 50.64V52.36C401.552 52.5933 401.572 52.8067 401.612 53C401.652 53.1933 401.722 53.36 401.822 53.5C401.922 53.64 402.052 53.75 402.212 53.83C402.372 53.9033 402.572 53.94 402.812 53.94Z"
    fill="#5078E1"
  /><path
    d="M408.515 53.74V48.66H408.335L407.675 51.06H406.415V50.94L407.255 48H409.835V53.74H411.575V55H406.535V53.74H408.515Z"
    fill="#5078E1"
  /><path
    d="M414.638 53.74V48.66H414.458L413.798 51.06H412.538V50.94L413.378 48H415.958V53.74H417.698V55H412.658V53.74H414.638Z"
    fill="#5078E1"
  /><path
    d="M420.341 51.5C420.341 51.2733 420.424 51.0767 420.591 50.91C420.758 50.7433 420.954 50.66 421.181 50.66C421.408 50.66 421.604 50.7433 421.771 50.91C421.938 51.0767 422.021 51.2733 422.021 51.5C422.021 51.7267 421.938 51.9233 421.771 52.09C421.604 52.2567 421.408 52.34 421.181 52.34C420.954 52.34 420.758 52.2567 420.591 52.09C420.424 51.9233 420.341 51.7267 420.341 51.5ZM418.601 50.72C418.601 49.8133 418.828 49.11 419.281 48.61C419.741 48.11 420.374 47.86 421.181 47.86C421.988 47.86 422.618 48.11 423.071 48.61C423.531 49.11 423.761 49.8133 423.761 50.72V52.28C423.761 53.2133 423.531 53.9233 423.071 54.41C422.618 54.8967 421.988 55.14 421.181 55.14C420.374 55.14 419.741 54.8967 419.281 54.41C418.828 53.9233 418.601 53.2133 418.601 52.28V50.72ZM421.181 53.94C421.421 53.94 421.621 53.9033 421.781 53.83C421.941 53.75 422.071 53.64 422.171 53.5C422.271 53.36 422.341 53.1933 422.381 53C422.421 52.8067 422.441 52.5933 422.441 52.36V50.64C422.441 50.42 422.418 50.2133 422.371 50.02C422.324 49.8267 422.251 49.66 422.151 49.52C422.051 49.38 421.921 49.27 421.761 49.19C421.601 49.1033 421.408 49.06 421.181 49.06C420.954 49.06 420.761 49.1033 420.601 49.19C420.441 49.27 420.311 49.38 420.211 49.52C420.111 49.66 420.038 49.8267 419.991 50.02C419.944 50.2133 419.921 50.42 419.921 50.64V52.36C419.921 52.5933 419.941 52.8067 419.981 53C420.021 53.1933 420.091 53.36 420.191 53.5C420.291 53.64 420.421 53.75 420.581 53.83C420.741 53.9033 420.941 53.94 421.181 53.94Z"
    fill="#5078E1"
  /><path
    d="M426.884 53.74V48.66H426.704L426.044 51.06H424.784V50.94L425.624 48H428.204V53.74H429.944V55H424.904V53.74H426.884Z"
    fill="#5078E1"
  /><path
    d="M433.007 53.74V48.66H432.827L432.167 51.06H430.907V50.94L431.747 48H434.327V53.74H436.067V55H431.027V53.74H433.007Z"
    fill="#5078E1"
  /><path
    d="M439.13 53.74V48.66H438.95L438.29 51.06H437.03V50.94L437.87 48H440.45V53.74H442.19V55H437.15V53.74H439.13Z"
    fill="#5078E1"
  /><path
    d="M444.833 51.5C444.833 51.2733 444.917 51.0767 445.083 50.91C445.25 50.7433 445.447 50.66 445.673 50.66C445.9 50.66 446.097 50.7433 446.263 50.91C446.43 51.0767 446.513 51.2733 446.513 51.5C446.513 51.7267 446.43 51.9233 446.263 52.09C446.097 52.2567 445.9 52.34 445.673 52.34C445.447 52.34 445.25 52.2567 445.083 52.09C444.917 51.9233 444.833 51.7267 444.833 51.5ZM443.093 50.72C443.093 49.8133 443.32 49.11 443.773 48.61C444.233 48.11 444.867 47.86 445.673 47.86C446.48 47.86 447.11 48.11 447.563 48.61C448.023 49.11 448.253 49.8133 448.253 50.72V52.28C448.253 53.2133 448.023 53.9233 447.563 54.41C447.11 54.8967 446.48 55.14 445.673 55.14C444.867 55.14 444.233 54.8967 443.773 54.41C443.32 53.9233 443.093 53.2133 443.093 52.28V50.72ZM445.673 53.94C445.913 53.94 446.113 53.9033 446.273 53.83C446.433 53.75 446.563 53.64 446.663 53.5C446.763 53.36 446.833 53.1933 446.873 53C446.913 52.8067 446.933 52.5933 446.933 52.36V50.64C446.933 50.42 446.91 50.2133 446.863 50.02C446.817 49.8267 446.743 49.66 446.643 49.52C446.543 49.38 446.413 49.27 446.253 49.19C446.093 49.1033 445.9 49.06 445.673 49.06C445.447 49.06 445.253 49.1033 445.093 49.19C444.933 49.27 444.803 49.38 444.703 49.52C444.603 49.66 444.53 49.8267 444.483 50.02C444.437 50.2133 444.413 50.42 444.413 50.64V52.36C444.413 52.5933 444.433 52.8067 444.473 53C444.513 53.1933 444.583 53.36 444.683 53.5C444.783 53.64 444.913 53.75 445.073 53.83C445.233 53.9033 445.433 53.94 445.673 53.94Z"
    fill="#5078E1"
  /><path
    d="M450.956 51.5C450.956 51.2733 451.04 51.0767 451.206 50.91C451.373 50.7433 451.57 50.66 451.796 50.66C452.023 50.66 452.22 50.7433 452.386 50.91C452.553 51.0767 452.636 51.2733 452.636 51.5C452.636 51.7267 452.553 51.9233 452.386 52.09C452.22 52.2567 452.023 52.34 451.796 52.34C451.57 52.34 451.373 52.2567 451.206 52.09C451.04 51.9233 450.956 51.7267 450.956 51.5ZM449.216 50.72C449.216 49.8133 449.443 49.11 449.896 48.61C450.356 48.11 450.99 47.86 451.796 47.86C452.603 47.86 453.233 48.11 453.686 48.61C454.146 49.11 454.376 49.8133 454.376 50.72V52.28C454.376 53.2133 454.146 53.9233 453.686 54.41C453.233 54.8967 452.603 55.14 451.796 55.14C450.99 55.14 450.356 54.8967 449.896 54.41C449.443 53.9233 449.216 53.2133 449.216 52.28V50.72ZM451.796 53.94C452.036 53.94 452.236 53.9033 452.396 53.83C452.556 53.75 452.686 53.64 452.786 53.5C452.886 53.36 452.956 53.1933 452.996 53C453.036 52.8067 453.056 52.5933 453.056 52.36V50.64C453.056 50.42 453.033 50.2133 452.986 50.02C452.94 49.8267 452.866 49.66 452.766 49.52C452.666 49.38 452.536 49.27 452.376 49.19C452.216 49.1033 452.023 49.06 451.796 49.06C451.57 49.06 451.376 49.1033 451.216 49.19C451.056 49.27 450.926 49.38 450.826 49.52C450.726 49.66 450.653 49.8267 450.606 50.02C450.56 50.2133 450.536 50.42 450.536 50.64V52.36C450.536 52.5933 450.556 52.8067 450.596 53C450.636 53.1933 450.706 53.36 450.806 53.5C450.906 53.64 451.036 53.75 451.196 53.83C451.356 53.9033 451.556 53.94 451.796 53.94Z"
    fill="#5078E1"
  /><path
    d="M457.499 53.74V48.66H457.319L456.659 51.06H455.399V50.94L456.239 48H458.819V53.74H460.559V55H455.519V53.74H457.499Z"
    fill="#5078E1"
  /><path
    d="M463.622 53.74V48.66H463.442L462.782 51.06H461.522V50.94L462.362 48H464.942V53.74H466.682V55H461.642V53.74H463.622Z"
    fill="#5078E1"
  /><path
    d="M469.325 51.5C469.325 51.2733 469.409 51.0767 469.575 50.91C469.742 50.7433 469.939 50.66 470.165 50.66C470.392 50.66 470.589 50.7433 470.755 50.91C470.922 51.0767 471.005 51.2733 471.005 51.5C471.005 51.7267 470.922 51.9233 470.755 52.09C470.589 52.2567 470.392 52.34 470.165 52.34C469.939 52.34 469.742 52.2567 469.575 52.09C469.409 51.9233 469.325 51.7267 469.325 51.5ZM467.585 50.72C467.585 49.8133 467.812 49.11 468.265 48.61C468.725 48.11 469.359 47.86 470.165 47.86C470.972 47.86 471.602 48.11 472.055 48.61C472.515 49.11 472.745 49.8133 472.745 50.72V52.28C472.745 53.2133 472.515 53.9233 472.055 54.41C471.602 54.8967 470.972 55.14 470.165 55.14C469.359 55.14 468.725 54.8967 468.265 54.41C467.812 53.9233 467.585 53.2133 467.585 52.28V50.72ZM470.165 53.94C470.405 53.94 470.605 53.9033 470.765 53.83C470.925 53.75 471.055 53.64 471.155 53.5C471.255 53.36 471.325 53.1933 471.365 53C471.405 52.8067 471.425 52.5933 471.425 52.36V50.64C471.425 50.42 471.402 50.2133 471.355 50.02C471.309 49.8267 471.235 49.66 471.135 49.52C471.035 49.38 470.905 49.27 470.745 49.19C470.585 49.1033 470.392 49.06 470.165 49.06C469.939 49.06 469.745 49.1033 469.585 49.19C469.425 49.27 469.295 49.38 469.195 49.52C469.095 49.66 469.022 49.8267 468.975 50.02C468.929 50.2133 468.905 50.42 468.905 50.64V52.36C468.905 52.5933 468.925 52.8067 468.965 53C469.005 53.1933 469.075 53.36 469.175 53.5C469.275 53.64 469.405 53.75 469.565 53.83C469.725 53.9033 469.925 53.94 470.165 53.94Z"
    fill="#5078E1"
  /><path
    d="M475.449 51.5C475.449 51.2733 475.532 51.0767 475.699 50.91C475.865 50.7433 476.062 50.66 476.288 50.66C476.515 50.66 476.712 50.7433 476.879 50.91C477.045 51.0767 477.129 51.2733 477.129 51.5C477.129 51.7267 477.045 51.9233 476.879 52.09C476.712 52.2567 476.515 52.34 476.288 52.34C476.062 52.34 475.865 52.2567 475.699 52.09C475.532 51.9233 475.449 51.7267 475.449 51.5ZM473.709 50.72C473.709 49.8133 473.935 49.11 474.389 48.61C474.849 48.11 475.482 47.86 476.288 47.86C477.095 47.86 477.725 48.11 478.179 48.61C478.639 49.11 478.869 49.8133 478.869 50.72V52.28C478.869 53.2133 478.639 53.9233 478.179 54.41C477.725 54.8967 477.095 55.14 476.288 55.14C475.482 55.14 474.849 54.8967 474.389 54.41C473.935 53.9233 473.709 53.2133 473.709 52.28V50.72ZM476.288 53.94C476.528 53.94 476.729 53.9033 476.889 53.83C477.049 53.75 477.179 53.64 477.279 53.5C477.379 53.36 477.449 53.1933 477.489 53C477.529 52.8067 477.549 52.5933 477.549 52.36V50.64C477.549 50.42 477.525 50.2133 477.479 50.02C477.432 49.8267 477.358 49.66 477.258 49.52C477.158 49.38 477.029 49.27 476.869 49.19C476.709 49.1033 476.515 49.06 476.288 49.06C476.062 49.06 475.869 49.1033 475.709 49.19C475.549 49.27 475.418 49.38 475.318 49.52C475.218 49.66 475.145 49.8267 475.099 50.02C475.052 50.2133 475.029 50.42 475.029 50.64V52.36C475.029 52.5933 475.049 52.8067 475.089 53C475.129 53.1933 475.199 53.36 475.299 53.5C475.399 53.64 475.529 53.75 475.689 53.83C475.849 53.9033 476.048 53.94 476.288 53.94Z"
    fill="#5078E1"
  /><path
    d="M481.992 53.74V48.66H481.812L481.152 51.06H479.892V50.94L480.732 48H483.312V53.74H485.052V55H480.012V53.74H481.992Z"
    fill="#5078E1"
  /><path
    d="M487.695 51.5C487.695 51.2733 487.778 51.0767 487.945 50.91C488.111 50.7433 488.308 50.66 488.535 50.66C488.761 50.66 488.958 50.7433 489.125 50.91C489.291 51.0767 489.375 51.2733 489.375 51.5C489.375 51.7267 489.291 51.9233 489.125 52.09C488.958 52.2567 488.761 52.34 488.535 52.34C488.308 52.34 488.111 52.2567 487.945 52.09C487.778 51.9233 487.695 51.7267 487.695 51.5ZM485.955 50.72C485.955 49.8133 486.181 49.11 486.635 48.61C487.095 48.11 487.728 47.86 488.535 47.86C489.341 47.86 489.971 48.11 490.425 48.61C490.885 49.11 491.115 49.8133 491.115 50.72V52.28C491.115 53.2133 490.885 53.9233 490.425 54.41C489.971 54.8967 489.341 55.14 488.535 55.14C487.728 55.14 487.095 54.8967 486.635 54.41C486.181 53.9233 485.955 53.2133 485.955 52.28V50.72ZM488.535 53.94C488.775 53.94 488.975 53.9033 489.135 53.83C489.295 53.75 489.425 53.64 489.525 53.5C489.625 53.36 489.695 53.1933 489.735 53C489.775 52.8067 489.795 52.5933 489.795 52.36V50.64C489.795 50.42 489.771 50.2133 489.725 50.02C489.678 49.8267 489.605 49.66 489.505 49.52C489.405 49.38 489.275 49.27 489.115 49.19C488.955 49.1033 488.761 49.06 488.535 49.06C488.308 49.06 488.115 49.1033 487.955 49.19C487.795 49.27 487.665 49.38 487.565 49.52C487.465 49.66 487.391 49.8267 487.345 50.02C487.298 50.2133 487.275 50.42 487.275 50.64V52.36C487.275 52.5933 487.295 52.8067 487.335 53C487.375 53.1933 487.445 53.36 487.545 53.5C487.645 53.64 487.775 53.75 487.935 53.83C488.095 53.9033 488.295 53.94 488.535 53.94Z"
    fill="#5078E1"
  /><path
    d="M493.818 51.5C493.818 51.2733 493.901 51.0767 494.068 50.91C494.234 50.7433 494.431 50.66 494.658 50.66C494.884 50.66 495.081 50.7433 495.248 50.91C495.414 51.0767 495.498 51.2733 495.498 51.5C495.498 51.7267 495.414 51.9233 495.248 52.09C495.081 52.2567 494.884 52.34 494.658 52.34C494.431 52.34 494.234 52.2567 494.068 52.09C493.901 51.9233 493.818 51.7267 493.818 51.5ZM492.078 50.72C492.078 49.8133 492.304 49.11 492.758 48.61C493.218 48.11 493.851 47.86 494.658 47.86C495.464 47.86 496.094 48.11 496.548 48.61C497.008 49.11 497.238 49.8133 497.238 50.72V52.28C497.238 53.2133 497.008 53.9233 496.548 54.41C496.094 54.8967 495.464 55.14 494.658 55.14C493.851 55.14 493.218 54.8967 492.758 54.41C492.304 53.9233 492.078 53.2133 492.078 52.28V50.72ZM494.658 53.94C494.898 53.94 495.098 53.9033 495.258 53.83C495.418 53.75 495.548 53.64 495.648 53.5C495.748 53.36 495.818 53.1933 495.858 53C495.898 52.8067 495.918 52.5933 495.918 52.36V50.64C495.918 50.42 495.894 50.2133 495.848 50.02C495.801 49.8267 495.728 49.66 495.628 49.52C495.528 49.38 495.398 49.27 495.238 49.19C495.078 49.1033 494.884 49.06 494.658 49.06C494.431 49.06 494.238 49.1033 494.078 49.19C493.918 49.27 493.788 49.38 493.688 49.52C493.588 49.66 493.514 49.8267 493.468 50.02C493.421 50.2133 493.398 50.42 493.398 50.64V52.36C493.398 52.5933 493.418 52.8067 493.458 53C493.498 53.1933 493.568 53.36 493.668 53.5C493.768 53.64 493.898 53.75 494.058 53.83C494.218 53.9033 494.418 53.94 494.658 53.94Z"
    fill="#5078E1"
  /><path
    d="M499.941 51.5C499.941 51.2733 500.024 51.0767 500.191 50.91C500.357 50.7433 500.554 50.66 500.781 50.66C501.007 50.66 501.204 50.7433 501.371 50.91C501.537 51.0767 501.621 51.2733 501.621 51.5C501.621 51.7267 501.537 51.9233 501.371 52.09C501.204 52.2567 501.007 52.34 500.781 52.34C500.554 52.34 500.357 52.2567 500.191 52.09C500.024 51.9233 499.941 51.7267 499.941 51.5ZM498.201 50.72C498.201 49.8133 498.427 49.11 498.881 48.61C499.341 48.11 499.974 47.86 500.781 47.86C501.587 47.86 502.217 48.11 502.671 48.61C503.131 49.11 503.361 49.8133 503.361 50.72V52.28C503.361 53.2133 503.131 53.9233 502.671 54.41C502.217 54.8967 501.587 55.14 500.781 55.14C499.974 55.14 499.341 54.8967 498.881 54.41C498.427 53.9233 498.201 53.2133 498.201 52.28V50.72ZM500.781 53.94C501.021 53.94 501.221 53.9033 501.381 53.83C501.541 53.75 501.671 53.64 501.771 53.5C501.871 53.36 501.941 53.1933 501.981 53C502.021 52.8067 502.041 52.5933 502.041 52.36V50.64C502.041 50.42 502.017 50.2133 501.971 50.02C501.924 49.8267 501.851 49.66 501.751 49.52C501.651 49.38 501.521 49.27 501.361 49.19C501.201 49.1033 501.007 49.06 500.781 49.06C500.554 49.06 500.361 49.1033 500.201 49.19C500.041 49.27 499.911 49.38 499.811 49.52C499.711 49.66 499.637 49.8267 499.591 50.02C499.544 50.2133 499.521 50.42 499.521 50.64V52.36C499.521 52.5933 499.541 52.8067 499.581 53C499.621 53.1933 499.691 53.36 499.791 53.5C499.891 53.64 500.021 53.75 500.181 53.83C500.341 53.9033 500.541 53.94 500.781 53.94Z"
    fill="#5078E1"
  /><path
    d="M506.064 51.5C506.064 51.2733 506.147 51.0767 506.314 50.91C506.48 50.7433 506.677 50.66 506.904 50.66C507.13 50.66 507.327 50.7433 507.494 50.91C507.66 51.0767 507.744 51.2733 507.744 51.5C507.744 51.7267 507.66 51.9233 507.494 52.09C507.327 52.2567 507.13 52.34 506.904 52.34C506.677 52.34 506.48 52.2567 506.314 52.09C506.147 51.9233 506.064 51.7267 506.064 51.5ZM504.324 50.72C504.324 49.8133 504.55 49.11 505.004 48.61C505.464 48.11 506.097 47.86 506.904 47.86C507.71 47.86 508.34 48.11 508.794 48.61C509.254 49.11 509.484 49.8133 509.484 50.72V52.28C509.484 53.2133 509.254 53.9233 508.794 54.41C508.34 54.8967 507.71 55.14 506.904 55.14C506.097 55.14 505.464 54.8967 505.004 54.41C504.55 53.9233 504.324 53.2133 504.324 52.28V50.72ZM506.904 53.94C507.144 53.94 507.344 53.9033 507.504 53.83C507.664 53.75 507.794 53.64 507.894 53.5C507.994 53.36 508.064 53.1933 508.104 53C508.144 52.8067 508.164 52.5933 508.164 52.36V50.64C508.164 50.42 508.14 50.2133 508.094 50.02C508.047 49.8267 507.974 49.66 507.874 49.52C507.774 49.38 507.644 49.27 507.484 49.19C507.324 49.1033 507.13 49.06 506.904 49.06C506.677 49.06 506.484 49.1033 506.324 49.19C506.164 49.27 506.034 49.38 505.934 49.52C505.834 49.66 505.76 49.8267 505.714 50.02C505.667 50.2133 505.644 50.42 505.644 50.64V52.36C505.644 52.5933 505.664 52.8067 505.704 53C505.744 53.1933 505.814 53.36 505.914 53.5C506.014 53.64 506.144 53.75 506.304 53.83C506.464 53.9033 506.664 53.94 506.904 53.94Z"
    fill="#5078E1"
  /><path
    d="M512.187 51.5C512.187 51.2733 512.27 51.0767 512.437 50.91C512.603 50.7433 512.8 50.66 513.027 50.66C513.253 50.66 513.45 50.7433 513.617 50.91C513.783 51.0767 513.867 51.2733 513.867 51.5C513.867 51.7267 513.783 51.9233 513.617 52.09C513.45 52.2567 513.253 52.34 513.027 52.34C512.8 52.34 512.603 52.2567 512.437 52.09C512.27 51.9233 512.187 51.7267 512.187 51.5ZM510.447 50.72C510.447 49.8133 510.673 49.11 511.127 48.61C511.587 48.11 512.22 47.86 513.027 47.86C513.833 47.86 514.463 48.11 514.917 48.61C515.377 49.11 515.607 49.8133 515.607 50.72V52.28C515.607 53.2133 515.377 53.9233 514.917 54.41C514.463 54.8967 513.833 55.14 513.027 55.14C512.22 55.14 511.587 54.8967 511.127 54.41C510.673 53.9233 510.447 53.2133 510.447 52.28V50.72ZM513.027 53.94C513.267 53.94 513.467 53.9033 513.627 53.83C513.787 53.75 513.917 53.64 514.017 53.5C514.117 53.36 514.187 53.1933 514.227 53C514.267 52.8067 514.287 52.5933 514.287 52.36V50.64C514.287 50.42 514.263 50.2133 514.217 50.02C514.17 49.8267 514.097 49.66 513.997 49.52C513.897 49.38 513.767 49.27 513.607 49.19C513.447 49.1033 513.253 49.06 513.027 49.06C512.8 49.06 512.607 49.1033 512.447 49.19C512.287 49.27 512.157 49.38 512.057 49.52C511.957 49.66 511.883 49.8267 511.837 50.02C511.79 50.2133 511.767 50.42 511.767 50.64V52.36C511.767 52.5933 511.787 52.8067 511.827 53C511.867 53.1933 511.937 53.36 512.037 53.5C512.137 53.64 512.267 53.75 512.427 53.83C512.587 53.9033 512.787 53.94 513.027 53.94Z"
    fill="#5078E1"
  /><path
    d="M518.31 51.5C518.31 51.2733 518.393 51.0767 518.56 50.91C518.726 50.7433 518.923 50.66 519.15 50.66C519.376 50.66 519.573 50.7433 519.74 50.91C519.907 51.0767 519.99 51.2733 519.99 51.5C519.99 51.7267 519.907 51.9233 519.74 52.09C519.573 52.2567 519.376 52.34 519.15 52.34C518.923 52.34 518.726 52.2567 518.56 52.09C518.393 51.9233 518.31 51.7267 518.31 51.5ZM516.57 50.72C516.57 49.8133 516.796 49.11 517.25 48.61C517.71 48.11 518.343 47.86 519.15 47.86C519.956 47.86 520.587 48.11 521.04 48.61C521.5 49.11 521.73 49.8133 521.73 50.72V52.28C521.73 53.2133 521.5 53.9233 521.04 54.41C520.587 54.8967 519.956 55.14 519.15 55.14C518.343 55.14 517.71 54.8967 517.25 54.41C516.796 53.9233 516.57 53.2133 516.57 52.28V50.72ZM519.15 53.94C519.39 53.94 519.59 53.9033 519.75 53.83C519.91 53.75 520.04 53.64 520.14 53.5C520.24 53.36 520.31 53.1933 520.35 53C520.39 52.8067 520.41 52.5933 520.41 52.36V50.64C520.41 50.42 520.386 50.2133 520.34 50.02C520.293 49.8267 520.22 49.66 520.12 49.52C520.02 49.38 519.89 49.27 519.73 49.19C519.57 49.1033 519.376 49.06 519.15 49.06C518.923 49.06 518.73 49.1033 518.57 49.19C518.41 49.27 518.28 49.38 518.18 49.52C518.08 49.66 518.007 49.8267 517.96 50.02C517.913 50.2133 517.89 50.42 517.89 50.64V52.36C517.89 52.5933 517.91 52.8067 517.95 53C517.99 53.1933 518.06 53.36 518.16 53.5C518.26 53.64 518.39 53.75 518.55 53.83C518.71 53.9033 518.91 53.94 519.15 53.94Z"
    fill="#5078E1"
  /><path
    d="M524.853 53.74V48.66H524.673L524.013 51.06H522.753V50.94L523.593 48H526.173V53.74H527.913V55H522.873V53.74H524.853Z"
    fill="#5078E1"
  /><path
    d="M530.976 53.74V48.66H530.796L530.136 51.06H528.876V50.94L529.716 48H532.296V53.74H534.036V55H528.996V53.74H530.976Z"
    fill="#5078E1"
  /><path
    d="M536.679 51.5C536.679 51.2733 536.762 51.0767 536.929 50.91C537.096 50.7433 537.292 50.66 537.519 50.66C537.746 50.66 537.942 50.7433 538.109 50.91C538.276 51.0767 538.359 51.2733 538.359 51.5C538.359 51.7267 538.276 51.9233 538.109 52.09C537.942 52.2567 537.746 52.34 537.519 52.34C537.292 52.34 537.096 52.2567 536.929 52.09C536.762 51.9233 536.679 51.7267 536.679 51.5ZM534.939 50.72C534.939 49.8133 535.166 49.11 535.619 48.61C536.079 48.11 536.712 47.86 537.519 47.86C538.326 47.86 538.956 48.11 539.409 48.61C539.869 49.11 540.099 49.8133 540.099 50.72V52.28C540.099 53.2133 539.869 53.9233 539.409 54.41C538.956 54.8967 538.326 55.14 537.519 55.14C536.712 55.14 536.079 54.8967 535.619 54.41C535.166 53.9233 534.939 53.2133 534.939 52.28V50.72ZM537.519 53.94C537.759 53.94 537.959 53.9033 538.119 53.83C538.279 53.75 538.409 53.64 538.509 53.5C538.609 53.36 538.679 53.1933 538.719 53C538.759 52.8067 538.779 52.5933 538.779 52.36V50.64C538.779 50.42 538.756 50.2133 538.709 50.02C538.662 49.8267 538.589 49.66 538.489 49.52C538.389 49.38 538.259 49.27 538.099 49.19C537.939 49.1033 537.746 49.06 537.519 49.06C537.292 49.06 537.099 49.1033 536.939 49.19C536.779 49.27 536.649 49.38 536.549 49.52C536.449 49.66 536.376 49.8267 536.329 50.02C536.282 50.2133 536.259 50.42 536.259 50.64V52.36C536.259 52.5933 536.279 52.8067 536.319 53C536.359 53.1933 536.429 53.36 536.529 53.5C536.629 53.64 536.759 53.75 536.919 53.83C537.079 53.9033 537.279 53.94 537.519 53.94Z"
    fill="#5078E1"
  /><path
    d="M542.802 51.5C542.802 51.2733 542.885 51.0767 543.052 50.91C543.219 50.7433 543.415 50.66 543.642 50.66C543.869 50.66 544.065 50.7433 544.232 50.91C544.399 51.0767 544.482 51.2733 544.482 51.5C544.482 51.7267 544.399 51.9233 544.232 52.09C544.065 52.2567 543.869 52.34 543.642 52.34C543.415 52.34 543.219 52.2567 543.052 52.09C542.885 51.9233 542.802 51.7267 542.802 51.5ZM541.062 50.72C541.062 49.8133 541.289 49.11 541.742 48.61C542.202 48.11 542.835 47.86 543.642 47.86C544.449 47.86 545.079 48.11 545.532 48.61C545.992 49.11 546.222 49.8133 546.222 50.72V52.28C546.222 53.2133 545.992 53.9233 545.532 54.41C545.079 54.8967 544.449 55.14 543.642 55.14C542.835 55.14 542.202 54.8967 541.742 54.41C541.289 53.9233 541.062 53.2133 541.062 52.28V50.72ZM543.642 53.94C543.882 53.94 544.082 53.9033 544.242 53.83C544.402 53.75 544.532 53.64 544.632 53.5C544.732 53.36 544.802 53.1933 544.842 53C544.882 52.8067 544.902 52.5933 544.902 52.36V50.64C544.902 50.42 544.879 50.2133 544.832 50.02C544.785 49.8267 544.712 49.66 544.612 49.52C544.512 49.38 544.382 49.27 544.222 49.19C544.062 49.1033 543.869 49.06 543.642 49.06C543.415 49.06 543.222 49.1033 543.062 49.19C542.902 49.27 542.772 49.38 542.672 49.52C542.572 49.66 542.499 49.8267 542.452 50.02C542.405 50.2133 542.382 50.42 542.382 50.64V52.36C542.382 52.5933 542.402 52.8067 542.442 53C542.482 53.1933 542.552 53.36 542.652 53.5C542.752 53.64 542.882 53.75 543.042 53.83C543.202 53.9033 543.402 53.94 543.642 53.94Z"
    fill="#5078E1"
  /><path
    d="M549.345 53.74V48.66H549.165L548.505 51.06H547.245V50.94L548.085 48H550.665V53.74H552.405V55H547.365V53.74H549.345Z"
    fill="#5078E1"
  /><path
    d="M555.468 53.74V48.66H555.288L554.628 51.06H553.368V50.94L554.208 48H556.788V53.74H558.528V55H553.488V53.74H555.468Z"
    fill="#5078E1"
  /><path
    d="M561.171 51.5C561.171 51.2733 561.254 51.0767 561.421 50.91C561.588 50.7433 561.785 50.66 562.011 50.66C562.238 50.66 562.434 50.7433 562.601 50.91C562.768 51.0767 562.851 51.2733 562.851 51.5C562.851 51.7267 562.768 51.9233 562.601 52.09C562.434 52.2567 562.238 52.34 562.011 52.34C561.785 52.34 561.588 52.2567 561.421 52.09C561.254 51.9233 561.171 51.7267 561.171 51.5ZM559.431 50.72C559.431 49.8133 559.658 49.11 560.111 48.61C560.571 48.11 561.205 47.86 562.011 47.86C562.818 47.86 563.448 48.11 563.901 48.61C564.361 49.11 564.591 49.8133 564.591 50.72V52.28C564.591 53.2133 564.361 53.9233 563.901 54.41C563.448 54.8967 562.818 55.14 562.011 55.14C561.205 55.14 560.571 54.8967 560.111 54.41C559.658 53.9233 559.431 53.2133 559.431 52.28V50.72ZM562.011 53.94C562.251 53.94 562.451 53.9033 562.611 53.83C562.771 53.75 562.901 53.64 563.001 53.5C563.101 53.36 563.171 53.1933 563.211 53C563.251 52.8067 563.271 52.5933 563.271 52.36V50.64C563.271 50.42 563.248 50.2133 563.201 50.02C563.155 49.8267 563.081 49.66 562.981 49.52C562.881 49.38 562.751 49.27 562.591 49.19C562.431 49.1033 562.238 49.06 562.011 49.06C561.785 49.06 561.591 49.1033 561.431 49.19C561.271 49.27 561.141 49.38 561.041 49.52C560.941 49.66 560.868 49.8267 560.821 50.02C560.775 50.2133 560.751 50.42 560.751 50.64V52.36C560.751 52.5933 560.771 52.8067 560.811 53C560.851 53.1933 560.921 53.36 561.021 53.5C561.121 53.64 561.251 53.75 561.411 53.83C561.571 53.9033 561.771 53.94 562.011 53.94Z"
    fill="#5078E1"
  /><path
    d="M567.294 51.5C567.294 51.2733 567.378 51.0767 567.544 50.91C567.711 50.7433 567.908 50.66 568.134 50.66C568.361 50.66 568.558 50.7433 568.724 50.91C568.891 51.0767 568.974 51.2733 568.974 51.5C568.974 51.7267 568.891 51.9233 568.724 52.09C568.558 52.2567 568.361 52.34 568.134 52.34C567.908 52.34 567.711 52.2567 567.544 52.09C567.378 51.9233 567.294 51.7267 567.294 51.5ZM565.554 50.72C565.554 49.8133 565.781 49.11 566.234 48.61C566.694 48.11 567.328 47.86 568.134 47.86C568.941 47.86 569.571 48.11 570.024 48.61C570.484 49.11 570.714 49.8133 570.714 50.72V52.28C570.714 53.2133 570.484 53.9233 570.024 54.41C569.571 54.8967 568.941 55.14 568.134 55.14C567.328 55.14 566.694 54.8967 566.234 54.41C565.781 53.9233 565.554 53.2133 565.554 52.28V50.72ZM568.134 53.94C568.374 53.94 568.574 53.9033 568.734 53.83C568.894 53.75 569.024 53.64 569.124 53.5C569.224 53.36 569.294 53.1933 569.334 53C569.374 52.8067 569.394 52.5933 569.394 52.36V50.64C569.394 50.42 569.371 50.2133 569.324 50.02C569.278 49.8267 569.204 49.66 569.104 49.52C569.004 49.38 568.874 49.27 568.714 49.19C568.554 49.1033 568.361 49.06 568.134 49.06C567.908 49.06 567.714 49.1033 567.554 49.19C567.394 49.27 567.264 49.38 567.164 49.52C567.064 49.66 566.991 49.8267 566.944 50.02C566.898 50.2133 566.874 50.42 566.874 50.64V52.36C566.874 52.5933 566.894 52.8067 566.934 53C566.974 53.1933 567.044 53.36 567.144 53.5C567.244 53.64 567.374 53.75 567.534 53.83C567.694 53.9033 567.894 53.94 568.134 53.94Z"
    fill="#5078E1"
  /><path
    d="M573.837 53.74V48.66H573.657L572.997 51.06H571.737V50.94L572.577 48H575.157V53.74H576.897V55H571.857V53.74H573.837Z"
    fill="#5078E1"
  /><path
    d="M579.96 53.74V48.66H579.78L579.12 51.06H577.86V50.94L578.7 48H581.28V53.74H583.02V55H577.98V53.74H579.96Z"
    fill="#5078E1"
  /><path
    d="M586.083 53.74V48.66H585.903L585.243 51.06H583.983V50.94L584.823 48H587.403V53.74H589.143V55H584.103V53.74H586.083Z"
    fill="#5078E1"
  /><path
    d="M591.786 51.5C591.786 51.2733 591.87 51.0767 592.036 50.91C592.203 50.7433 592.4 50.66 592.626 50.66C592.853 50.66 593.05 50.7433 593.216 50.91C593.383 51.0767 593.466 51.2733 593.466 51.5C593.466 51.7267 593.383 51.9233 593.216 52.09C593.05 52.2567 592.853 52.34 592.626 52.34C592.4 52.34 592.203 52.2567 592.036 52.09C591.87 51.9233 591.786 51.7267 591.786 51.5ZM590.046 50.72C590.046 49.8133 590.273 49.11 590.726 48.61C591.186 48.11 591.82 47.86 592.626 47.86C593.433 47.86 594.063 48.11 594.516 48.61C594.976 49.11 595.206 49.8133 595.206 50.72V52.28C595.206 53.2133 594.976 53.9233 594.516 54.41C594.063 54.8967 593.433 55.14 592.626 55.14C591.82 55.14 591.186 54.8967 590.726 54.41C590.273 53.9233 590.046 53.2133 590.046 52.28V50.72ZM592.626 53.94C592.866 53.94 593.066 53.9033 593.226 53.83C593.386 53.75 593.516 53.64 593.616 53.5C593.716 53.36 593.786 53.1933 593.826 53C593.866 52.8067 593.886 52.5933 593.886 52.36V50.64C593.886 50.42 593.863 50.2133 593.816 50.02C593.77 49.8267 593.696 49.66 593.596 49.52C593.496 49.38 593.366 49.27 593.206 49.19C593.046 49.1033 592.853 49.06 592.626 49.06C592.4 49.06 592.206 49.1033 592.046 49.19C591.886 49.27 591.756 49.38 591.656 49.52C591.556 49.66 591.483 49.8267 591.436 50.02C591.39 50.2133 591.366 50.42 591.366 50.64V52.36C591.366 52.5933 591.386 52.8067 591.426 53C591.466 53.1933 591.536 53.36 591.636 53.5C591.736 53.64 591.866 53.75 592.026 53.83C592.186 53.9033 592.386 53.94 592.626 53.94Z"
    fill="#5078E1"
  /><path
    d="M597.909 51.5C597.909 51.2733 597.993 51.0767 598.159 50.91C598.326 50.7433 598.523 50.66 598.749 50.66C598.976 50.66 599.173 50.7433 599.339 50.91C599.506 51.0767 599.589 51.2733 599.589 51.5C599.589 51.7267 599.506 51.9233 599.339 52.09C599.173 52.2567 598.976 52.34 598.749 52.34C598.523 52.34 598.326 52.2567 598.159 52.09C597.993 51.9233 597.909 51.7267 597.909 51.5ZM596.169 50.72C596.169 49.8133 596.396 49.11 596.849 48.61C597.309 48.11 597.943 47.86 598.749 47.86C599.556 47.86 600.186 48.11 600.639 48.61C601.099 49.11 601.329 49.8133 601.329 50.72V52.28C601.329 53.2133 601.099 53.9233 600.639 54.41C600.186 54.8967 599.556 55.14 598.749 55.14C597.943 55.14 597.309 54.8967 596.849 54.41C596.396 53.9233 596.169 53.2133 596.169 52.28V50.72ZM598.749 53.94C598.989 53.94 599.189 53.9033 599.349 53.83C599.509 53.75 599.639 53.64 599.739 53.5C599.839 53.36 599.909 53.1933 599.949 53C599.989 52.8067 600.009 52.5933 600.009 52.36V50.64C600.009 50.42 599.986 50.2133 599.939 50.02C599.893 49.8267 599.819 49.66 599.719 49.52C599.619 49.38 599.489 49.27 599.329 49.19C599.169 49.1033 598.976 49.06 598.749 49.06C598.523 49.06 598.329 49.1033 598.169 49.19C598.009 49.27 597.879 49.38 597.779 49.52C597.679 49.66 597.606 49.8267 597.559 50.02C597.513 50.2133 597.489 50.42 597.489 50.64V52.36C597.489 52.5933 597.509 52.8067 597.549 53C597.589 53.1933 597.659 53.36 597.759 53.5C597.859 53.64 597.989 53.75 598.149 53.83C598.309 53.9033 598.509 53.94 598.749 53.94Z"
    fill="#5078E1"
  /><path
    d="M604.452 53.74V48.66H604.272L603.613 51.06H602.352V50.94L603.193 48H605.772V53.74H607.512V55H602.472V53.74H604.452Z"
    fill="#5078E1"
  /><path
    d="M610.156 51.5C610.156 51.2733 610.239 51.0767 610.406 50.91C610.572 50.7433 610.769 50.66 610.996 50.66C611.222 50.66 611.419 50.7433 611.586 50.91C611.752 51.0767 611.836 51.2733 611.836 51.5C611.836 51.7267 611.752 51.9233 611.586 52.09C611.419 52.2567 611.222 52.34 610.996 52.34C610.769 52.34 610.572 52.2567 610.406 52.09C610.239 51.9233 610.156 51.7267 610.156 51.5ZM608.416 50.72C608.416 49.8133 608.642 49.11 609.096 48.61C609.556 48.11 610.189 47.86 610.996 47.86C611.802 47.86 612.432 48.11 612.886 48.61C613.346 49.11 613.576 49.8133 613.576 50.72V52.28C613.576 53.2133 613.346 53.9233 612.886 54.41C612.432 54.8967 611.802 55.14 610.996 55.14C610.189 55.14 609.556 54.8967 609.096 54.41C608.642 53.9233 608.416 53.2133 608.416 52.28V50.72ZM610.996 53.94C611.236 53.94 611.436 53.9033 611.596 53.83C611.756 53.75 611.886 53.64 611.986 53.5C612.086 53.36 612.156 53.1933 612.196 53C612.236 52.8067 612.256 52.5933 612.256 52.36V50.64C612.256 50.42 612.232 50.2133 612.186 50.02C612.139 49.8267 612.066 49.66 611.966 49.52C611.866 49.38 611.736 49.27 611.576 49.19C611.416 49.1033 611.222 49.06 610.996 49.06C610.769 49.06 610.576 49.1033 610.416 49.19C610.256 49.27 610.126 49.38 610.026 49.52C609.926 49.66 609.852 49.8267 609.806 50.02C609.759 50.2133 609.736 50.42 609.736 50.64V52.36C609.736 52.5933 609.756 52.8067 609.796 53C609.836 53.1933 609.906 53.36 610.006 53.5C610.106 53.64 610.236 53.75 610.396 53.83C610.556 53.9033 610.756 53.94 610.996 53.94Z"
    fill="#5078E1"
  /><path
    d="M616.279 51.5C616.279 51.2733 616.362 51.0767 616.529 50.91C616.695 50.7433 616.892 50.66 617.119 50.66C617.345 50.66 617.542 50.7433 617.709 50.91C617.875 51.0767 617.959 51.2733 617.959 51.5C617.959 51.7267 617.875 51.9233 617.709 52.09C617.542 52.2567 617.345 52.34 617.119 52.34C616.892 52.34 616.695 52.2567 616.529 52.09C616.362 51.9233 616.279 51.7267 616.279 51.5ZM614.539 50.72C614.539 49.8133 614.765 49.11 615.219 48.61C615.679 48.11 616.312 47.86 617.119 47.86C617.925 47.86 618.555 48.11 619.009 48.61C619.469 49.11 619.699 49.8133 619.699 50.72V52.28C619.699 53.2133 619.469 53.9233 619.009 54.41C618.555 54.8967 617.925 55.14 617.119 55.14C616.312 55.14 615.679 54.8967 615.219 54.41C614.765 53.9233 614.539 53.2133 614.539 52.28V50.72ZM617.119 53.94C617.359 53.94 617.559 53.9033 617.719 53.83C617.879 53.75 618.009 53.64 618.109 53.5C618.209 53.36 618.279 53.1933 618.319 53C618.359 52.8067 618.379 52.5933 618.379 52.36V50.64C618.379 50.42 618.355 50.2133 618.309 50.02C618.262 49.8267 618.189 49.66 618.089 49.52C617.989 49.38 617.859 49.27 617.699 49.19C617.539 49.1033 617.345 49.06 617.119 49.06C616.892 49.06 616.699 49.1033 616.539 49.19C616.379 49.27 616.249 49.38 616.149 49.52C616.049 49.66 615.975 49.8267 615.929 50.02C615.882 50.2133 615.859 50.42 615.859 50.64V52.36C615.859 52.5933 615.879 52.8067 615.919 53C615.959 53.1933 616.029 53.36 616.129 53.5C616.229 53.64 616.359 53.75 616.519 53.83C616.679 53.9033 616.879 53.94 617.119 53.94Z"
    fill="#5078E1"
  /><path
    d="M622.822 53.74V48.66H622.642L621.982 51.06H620.722V50.94L621.562 48H624.142V53.74H625.882V55H620.842V53.74H622.822Z"
    fill="#5078E1"
  /><path
    d="M628.945 53.74V48.66H628.765L628.105 51.06H626.845V50.94L627.685 48H630.265V53.74H632.005V55H626.965V53.74H628.945Z"
    fill="#5078E1"
  /><path
    d="M634.648 51.5C634.648 51.2733 634.731 51.0767 634.898 50.91C635.064 50.7433 635.261 50.66 635.488 50.66C635.714 50.66 635.911 50.7433 636.078 50.91C636.244 51.0767 636.328 51.2733 636.328 51.5C636.328 51.7267 636.244 51.9233 636.078 52.09C635.911 52.2567 635.714 52.34 635.488 52.34C635.261 52.34 635.064 52.2567 634.898 52.09C634.731 51.9233 634.648 51.7267 634.648 51.5ZM632.908 50.72C632.908 49.8133 633.134 49.11 633.588 48.61C634.048 48.11 634.681 47.86 635.488 47.86C636.294 47.86 636.924 48.11 637.378 48.61C637.838 49.11 638.068 49.8133 638.068 50.72V52.28C638.068 53.2133 637.838 53.9233 637.378 54.41C636.924 54.8967 636.294 55.14 635.488 55.14C634.681 55.14 634.048 54.8967 633.588 54.41C633.134 53.9233 632.908 53.2133 632.908 52.28V50.72ZM635.488 53.94C635.728 53.94 635.928 53.9033 636.088 53.83C636.248 53.75 636.378 53.64 636.478 53.5C636.578 53.36 636.648 53.1933 636.688 53C636.728 52.8067 636.748 52.5933 636.748 52.36V50.64C636.748 50.42 636.724 50.2133 636.678 50.02C636.631 49.8267 636.558 49.66 636.458 49.52C636.358 49.38 636.228 49.27 636.068 49.19C635.908 49.1033 635.714 49.06 635.488 49.06C635.261 49.06 635.068 49.1033 634.908 49.19C634.748 49.27 634.618 49.38 634.518 49.52C634.418 49.66 634.344 49.8267 634.298 50.02C634.251 50.2133 634.228 50.42 634.228 50.64V52.36C634.228 52.5933 634.248 52.8067 634.288 53C634.328 53.1933 634.398 53.36 634.498 53.5C634.598 53.64 634.728 53.75 634.888 53.83C635.048 53.9033 635.248 53.94 635.488 53.94Z"
    fill="#5078E1"
  /><path
    d="M641.191 53.74V48.66H641.011L640.351 51.06H639.091V50.94L639.931 48H642.511V53.74H644.251V55H639.211V53.74H641.191Z"
    fill="#5078E1"
  /><path
    d="M647.314 53.74V48.66H647.134L646.474 51.06H645.214V50.94L646.054 48H648.634V53.74H650.374V55H645.334V53.74H647.314Z"
    fill="#5078E1"
  /><path
    d="M653.437 53.74V48.66H653.257L652.597 51.06H651.337V50.94L652.177 48H654.757V53.74H656.497V55H651.457V53.74H653.437Z"
    fill="#5078E1"
  /><path
    d="M659.56 53.74V48.66H659.38L658.72 51.06H657.46V50.94L658.3 48H660.88V53.74H662.62V55H657.58V53.74H659.56Z"
    fill="#5078E1"
  /><path
    d="M665.263 51.5C665.263 51.2733 665.346 51.0767 665.513 50.91C665.68 50.7433 665.876 50.66 666.103 50.66C666.33 50.66 666.526 50.7433 666.693 50.91C666.86 51.0767 666.943 51.2733 666.943 51.5C666.943 51.7267 666.86 51.9233 666.693 52.09C666.526 52.2567 666.33 52.34 666.103 52.34C665.876 52.34 665.68 52.2567 665.513 52.09C665.346 51.9233 665.263 51.7267 665.263 51.5ZM663.523 50.72C663.523 49.8133 663.75 49.11 664.203 48.61C664.663 48.11 665.296 47.86 666.103 47.86C666.91 47.86 667.54 48.11 667.993 48.61C668.453 49.11 668.683 49.8133 668.683 50.72V52.28C668.683 53.2133 668.453 53.9233 667.993 54.41C667.54 54.8967 666.91 55.14 666.103 55.14C665.296 55.14 664.663 54.8967 664.203 54.41C663.75 53.9233 663.523 53.2133 663.523 52.28V50.72ZM666.103 53.94C666.343 53.94 666.543 53.9033 666.703 53.83C666.863 53.75 666.993 53.64 667.093 53.5C667.193 53.36 667.263 53.1933 667.303 53C667.343 52.8067 667.363 52.5933 667.363 52.36V50.64C667.363 50.42 667.34 50.2133 667.293 50.02C667.246 49.8267 667.173 49.66 667.073 49.52C666.973 49.38 666.843 49.27 666.683 49.19C666.523 49.1033 666.33 49.06 666.103 49.06C665.876 49.06 665.683 49.1033 665.523 49.19C665.363 49.27 665.233 49.38 665.133 49.52C665.033 49.66 664.96 49.8267 664.913 50.02C664.866 50.2133 664.843 50.42 664.843 50.64V52.36C664.843 52.5933 664.863 52.8067 664.903 53C664.943 53.1933 665.013 53.36 665.113 53.5C665.213 53.64 665.343 53.75 665.503 53.83C665.663 53.9033 665.863 53.94 666.103 53.94Z"
    fill="#5078E1"
  /><path
    d="M16.64 70.74V65.66H16.46L15.8 68.06H14.54V67.94L15.38 65H17.96V70.74H19.7V72H14.66V70.74H16.64Z"
    fill="#5078E1"
  /><path
    d="M22.763 70.74V65.66H22.583L21.923 68.06H20.663V67.94L21.503 65H24.083V70.74H25.823V72H20.783V70.74H22.763Z"
    fill="#5078E1"
  /><path
    d="M28.4661 68.5C28.4661 68.2733 28.5494 68.0767 28.7161 67.91C28.8828 67.7433 29.0794 67.66 29.3061 67.66C29.5328 67.66 29.7294 67.7433 29.8961 67.91C30.0628 68.0767 30.1461 68.2733 30.1461 68.5C30.1461 68.7267 30.0628 68.9233 29.8961 69.09C29.7294 69.2567 29.5328 69.34 29.3061 69.34C29.0794 69.34 28.8828 69.2567 28.7161 69.09C28.5494 68.9233 28.4661 68.7267 28.4661 68.5ZM26.7261 67.72C26.7261 66.8133 26.9528 66.11 27.4061 65.61C27.8661 65.11 28.4994 64.86 29.3061 64.86C30.1128 64.86 30.7428 65.11 31.1961 65.61C31.6561 66.11 31.8861 66.8133 31.8861 67.72V69.28C31.8861 70.2133 31.6561 70.9233 31.1961 71.41C30.7428 71.8967 30.1128 72.14 29.3061 72.14C28.4994 72.14 27.8661 71.8967 27.4061 71.41C26.9528 70.9233 26.7261 70.2133 26.7261 69.28V67.72ZM29.3061 70.94C29.5461 70.94 29.7461 70.9033 29.9061 70.83C30.0661 70.75 30.1961 70.64 30.2961 70.5C30.3961 70.36 30.4661 70.1933 30.5061 70C30.5461 69.8067 30.5661 69.5933 30.5661 69.36V67.64C30.5661 67.42 30.5428 67.2133 30.4961 67.02C30.4494 66.8267 30.3761 66.66 30.2761 66.52C30.1761 66.38 30.0461 66.27 29.8861 66.19C29.7261 66.1033 29.5328 66.06 29.3061 66.06C29.0794 66.06 28.8861 66.1033 28.7261 66.19C28.5661 66.27 28.4361 66.38 28.3361 66.52C28.2361 66.66 28.1628 66.8267 28.1161 67.02C28.0694 67.2133 28.0461 67.42 28.0461 67.64V69.36C28.0461 69.5933 28.0661 69.8067 28.1061 70C28.1461 70.1933 28.2161 70.36 28.3161 70.5C28.4161 70.64 28.5461 70.75 28.7061 70.83C28.8661 70.9033 29.0661 70.94 29.3061 70.94Z"
    fill="#5078E1"
  /><path
    d="M35.0091 70.74V65.66H34.8291L34.1691 68.06H32.9091V67.94L33.7491 65H36.3291V70.74H38.0691V72H33.0291V70.74H35.0091Z"
    fill="#5078E1"
  /><path
    d="M41.1322 70.74V65.66H40.9522L40.2922 68.06H39.0322V67.94L39.8722 65H42.4522V70.74H44.1922V72H39.1522V70.74H41.1322Z"
    fill="#5078E1"
  /><path
    d="M46.8352 68.5C46.8352 68.2733 46.9186 68.0767 47.0852 67.91C47.2519 67.7433 47.4486 67.66 47.6752 67.66C47.9019 67.66 48.0986 67.7433 48.2652 67.91C48.4319 68.0767 48.5152 68.2733 48.5152 68.5C48.5152 68.7267 48.4319 68.9233 48.2652 69.09C48.0986 69.2567 47.9019 69.34 47.6752 69.34C47.4486 69.34 47.2519 69.2567 47.0852 69.09C46.9186 68.9233 46.8352 68.7267 46.8352 68.5ZM45.0952 67.72C45.0952 66.8133 45.3219 66.11 45.7752 65.61C46.2352 65.11 46.8686 64.86 47.6752 64.86C48.4819 64.86 49.1119 65.11 49.5652 65.61C50.0252 66.11 50.2552 66.8133 50.2552 67.72V69.28C50.2552 70.2133 50.0252 70.9233 49.5652 71.41C49.1119 71.8967 48.4819 72.14 47.6752 72.14C46.8686 72.14 46.2352 71.8967 45.7752 71.41C45.3219 70.9233 45.0952 70.2133 45.0952 69.28V67.72ZM47.6752 70.94C47.9152 70.94 48.1152 70.9033 48.2752 70.83C48.4352 70.75 48.5652 70.64 48.6652 70.5C48.7652 70.36 48.8352 70.1933 48.8752 70C48.9152 69.8067 48.9352 69.5933 48.9352 69.36V67.64C48.9352 67.42 48.9119 67.2133 48.8652 67.02C48.8186 66.8267 48.7452 66.66 48.6452 66.52C48.5452 66.38 48.4152 66.27 48.2552 66.19C48.0952 66.1033 47.9019 66.06 47.6752 66.06C47.4486 66.06 47.2552 66.1033 47.0952 66.19C46.9352 66.27 46.8052 66.38 46.7052 66.52C46.6052 66.66 46.5319 66.8267 46.4852 67.02C46.4386 67.2133 46.4152 67.42 46.4152 67.64V69.36C46.4152 69.5933 46.4352 69.8067 46.4752 70C46.5152 70.1933 46.5852 70.36 46.6852 70.5C46.7852 70.64 46.9152 70.75 47.0752 70.83C47.2352 70.9033 47.4352 70.94 47.6752 70.94Z"
    fill="#5078E1"
  /><path
    d="M53.3783 70.74V65.66H53.1983L52.5383 68.06H51.2783V67.94L52.1183 65H54.6983V70.74H56.4383V72H51.3983V70.74H53.3783Z"
    fill="#5078E1"
  /><path
    d="M59.0813 68.5C59.0813 68.2733 59.1647 68.0767 59.3313 67.91C59.498 67.7433 59.6947 67.66 59.9213 67.66C60.148 67.66 60.3447 67.7433 60.5113 67.91C60.678 68.0767 60.7613 68.2733 60.7613 68.5C60.7613 68.7267 60.678 68.9233 60.5113 69.09C60.3447 69.2567 60.148 69.34 59.9213 69.34C59.6947 69.34 59.498 69.2567 59.3313 69.09C59.1647 68.9233 59.0813 68.7267 59.0813 68.5ZM57.3413 67.72C57.3413 66.8133 57.568 66.11 58.0213 65.61C58.4813 65.11 59.1147 64.86 59.9213 64.86C60.728 64.86 61.358 65.11 61.8113 65.61C62.2713 66.11 62.5013 66.8133 62.5013 67.72V69.28C62.5013 70.2133 62.2713 70.9233 61.8113 71.41C61.358 71.8967 60.728 72.14 59.9213 72.14C59.1147 72.14 58.4813 71.8967 58.0213 71.41C57.568 70.9233 57.3413 70.2133 57.3413 69.28V67.72ZM59.9213 70.94C60.1613 70.94 60.3613 70.9033 60.5213 70.83C60.6813 70.75 60.8113 70.64 60.9113 70.5C61.0113 70.36 61.0813 70.1933 61.1213 70C61.1613 69.8067 61.1813 69.5933 61.1813 69.36V67.64C61.1813 67.42 61.158 67.2133 61.1113 67.02C61.0647 66.8267 60.9913 66.66 60.8913 66.52C60.7913 66.38 60.6613 66.27 60.5013 66.19C60.3413 66.1033 60.148 66.06 59.9213 66.06C59.6947 66.06 59.5013 66.1033 59.3413 66.19C59.1813 66.27 59.0513 66.38 58.9513 66.52C58.8513 66.66 58.778 66.8267 58.7313 67.02C58.6847 67.2133 58.6613 67.42 58.6613 67.64V69.36C58.6613 69.5933 58.6813 69.8067 58.7213 70C58.7613 70.1933 58.8313 70.36 58.9313 70.5C59.0313 70.64 59.1613 70.75 59.3213 70.83C59.4813 70.9033 59.6813 70.94 59.9213 70.94Z"
    fill="#5078E1"
  /><path
    d="M65.2044 68.5C65.2044 68.2733 65.2877 68.0767 65.4544 67.91C65.621 67.7433 65.8177 67.66 66.0444 67.66C66.271 67.66 66.4677 67.7433 66.6344 67.91C66.801 68.0767 66.8844 68.2733 66.8844 68.5C66.8844 68.7267 66.801 68.9233 66.6344 69.09C66.4677 69.2567 66.271 69.34 66.0444 69.34C65.8177 69.34 65.621 69.2567 65.4544 69.09C65.2877 68.9233 65.2044 68.7267 65.2044 68.5ZM63.4644 67.72C63.4644 66.8133 63.691 66.11 64.1444 65.61C64.6044 65.11 65.2377 64.86 66.0444 64.86C66.851 64.86 67.481 65.11 67.9344 65.61C68.3944 66.11 68.6244 66.8133 68.6244 67.72V69.28C68.6244 70.2133 68.3944 70.9233 67.9344 71.41C67.481 71.8967 66.851 72.14 66.0444 72.14C65.2377 72.14 64.6044 71.8967 64.1444 71.41C63.691 70.9233 63.4644 70.2133 63.4644 69.28V67.72ZM66.0444 70.94C66.2844 70.94 66.4844 70.9033 66.6444 70.83C66.8044 70.75 66.9344 70.64 67.0344 70.5C67.1344 70.36 67.2044 70.1933 67.2444 70C67.2844 69.8067 67.3044 69.5933 67.3044 69.36V67.64C67.3044 67.42 67.281 67.2133 67.2344 67.02C67.1877 66.8267 67.1144 66.66 67.0144 66.52C66.9144 66.38 66.7844 66.27 66.6244 66.19C66.4644 66.1033 66.271 66.06 66.0444 66.06C65.8177 66.06 65.6244 66.1033 65.4644 66.19C65.3044 66.27 65.1744 66.38 65.0744 66.52C64.9744 66.66 64.901 66.8267 64.8544 67.02C64.8077 67.2133 64.7844 67.42 64.7844 67.64V69.36C64.7844 69.5933 64.8044 69.8067 64.8444 70C64.8844 70.1933 64.9544 70.36 65.0544 70.5C65.1544 70.64 65.2844 70.75 65.4444 70.83C65.6044 70.9033 65.8044 70.94 66.0444 70.94Z"
    fill="#5078E1"
  /><path
    d="M71.7474 70.74V65.66H71.5674L70.9074 68.06H69.6474V67.94L70.4874 65H73.0674V70.74H74.8074V72H69.7674V70.74H71.7474Z"
    fill="#5078E1"
  /><path
    d="M77.4505 68.5C77.4505 68.2733 77.5338 68.0767 77.7005 67.91C77.8671 67.7433 78.0638 67.66 78.2905 67.66C78.5171 67.66 78.7138 67.7433 78.8805 67.91C79.0471 68.0767 79.1305 68.2733 79.1305 68.5C79.1305 68.7267 79.0471 68.9233 78.8805 69.09C78.7138 69.2567 78.5171 69.34 78.2905 69.34C78.0638 69.34 77.8671 69.2567 77.7005 69.09C77.5338 68.9233 77.4505 68.7267 77.4505 68.5ZM75.7105 67.72C75.7105 66.8133 75.9371 66.11 76.3905 65.61C76.8505 65.11 77.4838 64.86 78.2905 64.86C79.0971 64.86 79.7271 65.11 80.1805 65.61C80.6405 66.11 80.8705 66.8133 80.8705 67.72V69.28C80.8705 70.2133 80.6405 70.9233 80.1805 71.41C79.7271 71.8967 79.0971 72.14 78.2905 72.14C77.4838 72.14 76.8505 71.8967 76.3905 71.41C75.9371 70.9233 75.7105 70.2133 75.7105 69.28V67.72ZM78.2905 70.94C78.5305 70.94 78.7305 70.9033 78.8905 70.83C79.0505 70.75 79.1805 70.64 79.2805 70.5C79.3805 70.36 79.4505 70.1933 79.4905 70C79.5305 69.8067 79.5505 69.5933 79.5505 69.36V67.64C79.5505 67.42 79.5271 67.2133 79.4805 67.02C79.4338 66.8267 79.3605 66.66 79.2605 66.52C79.1605 66.38 79.0305 66.27 78.8705 66.19C78.7105 66.1033 78.5171 66.06 78.2905 66.06C78.0638 66.06 77.8705 66.1033 77.7105 66.19C77.5505 66.27 77.4205 66.38 77.3205 66.52C77.2205 66.66 77.1471 66.8267 77.1005 67.02C77.0538 67.2133 77.0305 67.42 77.0305 67.64V69.36C77.0305 69.5933 77.0505 69.8067 77.0905 70C77.1305 70.1933 77.2005 70.36 77.3005 70.5C77.4005 70.64 77.5305 70.75 77.6905 70.83C77.8505 70.9033 78.0505 70.94 78.2905 70.94Z"
    fill="#5078E1"
  /><path
    d="M83.5735 68.5C83.5735 68.2733 83.6568 68.0767 83.8235 67.91C83.9902 67.7433 84.1868 67.66 84.4135 67.66C84.6402 67.66 84.8368 67.7433 85.0035 67.91C85.1702 68.0767 85.2535 68.2733 85.2535 68.5C85.2535 68.7267 85.1702 68.9233 85.0035 69.09C84.8368 69.2567 84.6402 69.34 84.4135 69.34C84.1868 69.34 83.9902 69.2567 83.8235 69.09C83.6568 68.9233 83.5735 68.7267 83.5735 68.5ZM81.8335 67.72C81.8335 66.8133 82.0602 66.11 82.5135 65.61C82.9735 65.11 83.6068 64.86 84.4135 64.86C85.2202 64.86 85.8502 65.11 86.3035 65.61C86.7635 66.11 86.9935 66.8133 86.9935 67.72V69.28C86.9935 70.2133 86.7635 70.9233 86.3035 71.41C85.8502 71.8967 85.2202 72.14 84.4135 72.14C83.6068 72.14 82.9735 71.8967 82.5135 71.41C82.0602 70.9233 81.8335 70.2133 81.8335 69.28V67.72ZM84.4135 70.94C84.6535 70.94 84.8535 70.9033 85.0135 70.83C85.1735 70.75 85.3035 70.64 85.4035 70.5C85.5035 70.36 85.5735 70.1933 85.6135 70C85.6535 69.8067 85.6735 69.5933 85.6735 69.36V67.64C85.6735 67.42 85.6502 67.2133 85.6035 67.02C85.5568 66.8267 85.4835 66.66 85.3835 66.52C85.2835 66.38 85.1535 66.27 84.9935 66.19C84.8335 66.1033 84.6402 66.06 84.4135 66.06C84.1868 66.06 83.9935 66.1033 83.8335 66.19C83.6735 66.27 83.5435 66.38 83.4435 66.52C83.3435 66.66 83.2702 66.8267 83.2235 67.02C83.1768 67.2133 83.1535 67.42 83.1535 67.64V69.36C83.1535 69.5933 83.1735 69.8067 83.2135 70C83.2535 70.1933 83.3235 70.36 83.4235 70.5C83.5235 70.64 83.6535 70.75 83.8135 70.83C83.9735 70.9033 84.1735 70.94 84.4135 70.94Z"
    fill="#5078E1"
  /><path
    d="M89.6966 68.5C89.6966 68.2733 89.7799 68.0767 89.9466 67.91C90.1132 67.7433 90.3099 67.66 90.5366 67.66C90.7632 67.66 90.9599 67.7433 91.1266 67.91C91.2932 68.0767 91.3766 68.2733 91.3766 68.5C91.3766 68.7267 91.2932 68.9233 91.1266 69.09C90.9599 69.2567 90.7632 69.34 90.5366 69.34C90.3099 69.34 90.1132 69.2567 89.9466 69.09C89.7799 68.9233 89.6966 68.7267 89.6966 68.5ZM87.9566 67.72C87.9566 66.8133 88.1832 66.11 88.6366 65.61C89.0966 65.11 89.7299 64.86 90.5366 64.86C91.3432 64.86 91.9732 65.11 92.4266 65.61C92.8866 66.11 93.1166 66.8133 93.1166 67.72V69.28C93.1166 70.2133 92.8866 70.9233 92.4266 71.41C91.9732 71.8967 91.3432 72.14 90.5366 72.14C89.7299 72.14 89.0966 71.8967 88.6366 71.41C88.1832 70.9233 87.9566 70.2133 87.9566 69.28V67.72ZM90.5366 70.94C90.7766 70.94 90.9766 70.9033 91.1366 70.83C91.2966 70.75 91.4266 70.64 91.5266 70.5C91.6266 70.36 91.6966 70.1933 91.7366 70C91.7766 69.8067 91.7966 69.5933 91.7966 69.36V67.64C91.7966 67.42 91.7732 67.2133 91.7266 67.02C91.6799 66.8267 91.6066 66.66 91.5066 66.52C91.4066 66.38 91.2766 66.27 91.1166 66.19C90.9566 66.1033 90.7632 66.06 90.5366 66.06C90.3099 66.06 90.1166 66.1033 89.9566 66.19C89.7966 66.27 89.6666 66.38 89.5666 66.52C89.4666 66.66 89.3932 66.8267 89.3466 67.02C89.2999 67.2133 89.2766 67.42 89.2766 67.64V69.36C89.2766 69.5933 89.2966 69.8067 89.3366 70C89.3766 70.1933 89.4466 70.36 89.5466 70.5C89.6466 70.64 89.7766 70.75 89.9366 70.83C90.0966 70.9033 90.2966 70.94 90.5366 70.94Z"
    fill="#5078E1"
  /><path
    d="M95.8196 68.5C95.8196 68.2733 95.9029 68.0767 96.0696 67.91C96.2363 67.7433 96.4329 67.66 96.6596 67.66C96.8863 67.66 97.0829 67.7433 97.2496 67.91C97.4163 68.0767 97.4996 68.2733 97.4996 68.5C97.4996 68.7267 97.4163 68.9233 97.2496 69.09C97.0829 69.2567 96.8863 69.34 96.6596 69.34C96.4329 69.34 96.2363 69.2567 96.0696 69.09C95.9029 68.9233 95.8196 68.7267 95.8196 68.5ZM94.0796 67.72C94.0796 66.8133 94.3063 66.11 94.7596 65.61C95.2196 65.11 95.8529 64.86 96.6596 64.86C97.4663 64.86 98.0963 65.11 98.5496 65.61C99.0096 66.11 99.2396 66.8133 99.2396 67.72V69.28C99.2396 70.2133 99.0096 70.9233 98.5496 71.41C98.0963 71.8967 97.4663 72.14 96.6596 72.14C95.8529 72.14 95.2196 71.8967 94.7596 71.41C94.3063 70.9233 94.0796 70.2133 94.0796 69.28V67.72ZM96.6596 70.94C96.8996 70.94 97.0996 70.9033 97.2596 70.83C97.4196 70.75 97.5496 70.64 97.6496 70.5C97.7496 70.36 97.8196 70.1933 97.8596 70C97.8996 69.8067 97.9196 69.5933 97.9196 69.36V67.64C97.9196 67.42 97.8963 67.2133 97.8496 67.02C97.8029 66.8267 97.7296 66.66 97.6296 66.52C97.5296 66.38 97.3996 66.27 97.2396 66.19C97.0796 66.1033 96.8863 66.06 96.6596 66.06C96.4329 66.06 96.2396 66.1033 96.0796 66.19C95.9196 66.27 95.7896 66.38 95.6896 66.52C95.5896 66.66 95.5163 66.8267 95.4696 67.02C95.4229 67.2133 95.3996 67.42 95.3996 67.64V69.36C95.3996 69.5933 95.4196 69.8067 95.4596 70C95.4996 70.1933 95.5696 70.36 95.6696 70.5C95.7696 70.64 95.8996 70.75 96.0596 70.83C96.2196 70.9033 96.4196 70.94 96.6596 70.94Z"
    fill="#5078E1"
  /><path
    d="M101.943 68.5C101.943 68.2733 102.026 68.0767 102.193 67.91C102.359 67.7433 102.556 67.66 102.783 67.66C103.009 67.66 103.206 67.7433 103.373 67.91C103.539 68.0767 103.623 68.2733 103.623 68.5C103.623 68.7267 103.539 68.9233 103.373 69.09C103.206 69.2567 103.009 69.34 102.783 69.34C102.556 69.34 102.359 69.2567 102.193 69.09C102.026 68.9233 101.943 68.7267 101.943 68.5ZM100.203 67.72C100.203 66.8133 100.429 66.11 100.883 65.61C101.343 65.11 101.976 64.86 102.783 64.86C103.589 64.86 104.219 65.11 104.673 65.61C105.133 66.11 105.363 66.8133 105.363 67.72V69.28C105.363 70.2133 105.133 70.9233 104.673 71.41C104.219 71.8967 103.589 72.14 102.783 72.14C101.976 72.14 101.343 71.8967 100.883 71.41C100.429 70.9233 100.203 70.2133 100.203 69.28V67.72ZM102.783 70.94C103.023 70.94 103.223 70.9033 103.383 70.83C103.543 70.75 103.673 70.64 103.773 70.5C103.873 70.36 103.943 70.1933 103.983 70C104.023 69.8067 104.043 69.5933 104.043 69.36V67.64C104.043 67.42 104.019 67.2133 103.973 67.02C103.926 66.8267 103.853 66.66 103.753 66.52C103.653 66.38 103.523 66.27 103.363 66.19C103.203 66.1033 103.009 66.06 102.783 66.06C102.556 66.06 102.363 66.1033 102.203 66.19C102.043 66.27 101.913 66.38 101.813 66.52C101.713 66.66 101.639 66.8267 101.593 67.02C101.546 67.2133 101.523 67.42 101.523 67.64V69.36C101.523 69.5933 101.543 69.8067 101.583 70C101.623 70.1933 101.693 70.36 101.793 70.5C101.893 70.64 102.023 70.75 102.183 70.83C102.343 70.9033 102.543 70.94 102.783 70.94Z"
    fill="#5078E1"
  /><path
    d="M108.066 68.5C108.066 68.2733 108.149 68.0767 108.316 67.91C108.482 67.7433 108.679 67.66 108.906 67.66C109.132 67.66 109.329 67.7433 109.496 67.91C109.662 68.0767 109.746 68.2733 109.746 68.5C109.746 68.7267 109.662 68.9233 109.496 69.09C109.329 69.2567 109.132 69.34 108.906 69.34C108.679 69.34 108.482 69.2567 108.316 69.09C108.149 68.9233 108.066 68.7267 108.066 68.5ZM106.326 67.72C106.326 66.8133 106.552 66.11 107.006 65.61C107.466 65.11 108.099 64.86 108.906 64.86C109.712 64.86 110.342 65.11 110.796 65.61C111.256 66.11 111.486 66.8133 111.486 67.72V69.28C111.486 70.2133 111.256 70.9233 110.796 71.41C110.342 71.8967 109.712 72.14 108.906 72.14C108.099 72.14 107.466 71.8967 107.006 71.41C106.552 70.9233 106.326 70.2133 106.326 69.28V67.72ZM108.906 70.94C109.146 70.94 109.346 70.9033 109.506 70.83C109.666 70.75 109.796 70.64 109.896 70.5C109.996 70.36 110.066 70.1933 110.106 70C110.146 69.8067 110.166 69.5933 110.166 69.36V67.64C110.166 67.42 110.142 67.2133 110.096 67.02C110.049 66.8267 109.976 66.66 109.876 66.52C109.776 66.38 109.646 66.27 109.486 66.19C109.326 66.1033 109.132 66.06 108.906 66.06C108.679 66.06 108.486 66.1033 108.326 66.19C108.166 66.27 108.036 66.38 107.936 66.52C107.836 66.66 107.762 66.8267 107.716 67.02C107.669 67.2133 107.646 67.42 107.646 67.64V69.36C107.646 69.5933 107.666 69.8067 107.706 70C107.746 70.1933 107.816 70.36 107.916 70.5C108.016 70.64 108.146 70.75 108.306 70.83C108.466 70.9033 108.666 70.94 108.906 70.94Z"
    fill="#5078E1"
  /><path
    d="M114.609 70.74V65.66H114.429L113.769 68.06H112.509V67.94L113.349 65H115.929V70.74H117.669V72H112.629V70.74H114.609Z"
    fill="#5078E1"
  /><path
    d="M120.732 70.74V65.66H120.552L119.892 68.06H118.632V67.94L119.472 65H122.052V70.74H123.792V72H118.752V70.74H120.732Z"
    fill="#5078E1"
  /><path
    d="M126.855 70.74V65.66H126.675L126.015 68.06H124.755V67.94L125.595 65H128.175V70.74H129.915V72H124.875V70.74H126.855Z"
    fill="#5078E1"
  /><path
    d="M132.558 68.5C132.558 68.2733 132.641 68.0767 132.808 67.91C132.975 67.7433 133.171 67.66 133.398 67.66C133.625 67.66 133.821 67.7433 133.988 67.91C134.155 68.0767 134.238 68.2733 134.238 68.5C134.238 68.7267 134.155 68.9233 133.988 69.09C133.821 69.2567 133.625 69.34 133.398 69.34C133.171 69.34 132.975 69.2567 132.808 69.09C132.641 68.9233 132.558 68.7267 132.558 68.5ZM130.818 67.72C130.818 66.8133 131.045 66.11 131.498 65.61C131.958 65.11 132.591 64.86 133.398 64.86C134.205 64.86 134.835 65.11 135.288 65.61C135.748 66.11 135.978 66.8133 135.978 67.72V69.28C135.978 70.2133 135.748 70.9233 135.288 71.41C134.835 71.8967 134.205 72.14 133.398 72.14C132.591 72.14 131.958 71.8967 131.498 71.41C131.045 70.9233 130.818 70.2133 130.818 69.28V67.72ZM133.398 70.94C133.638 70.94 133.838 70.9033 133.998 70.83C134.158 70.75 134.288 70.64 134.388 70.5C134.488 70.36 134.558 70.1933 134.598 70C134.638 69.8067 134.658 69.5933 134.658 69.36V67.64C134.658 67.42 134.635 67.2133 134.588 67.02C134.541 66.8267 134.468 66.66 134.368 66.52C134.268 66.38 134.138 66.27 133.978 66.19C133.818 66.1033 133.625 66.06 133.398 66.06C133.171 66.06 132.978 66.1033 132.818 66.19C132.658 66.27 132.528 66.38 132.428 66.52C132.328 66.66 132.255 66.8267 132.208 67.02C132.161 67.2133 132.138 67.42 132.138 67.64V69.36C132.138 69.5933 132.158 69.8067 132.198 70C132.238 70.1933 132.308 70.36 132.408 70.5C132.508 70.64 132.638 70.75 132.798 70.83C132.958 70.9033 133.158 70.94 133.398 70.94Z"
    fill="#5078E1"
  /><path
    d="M138.681 68.5C138.681 68.2733 138.764 68.0767 138.931 67.91C139.098 67.7433 139.294 67.66 139.521 67.66C139.748 67.66 139.944 67.7433 140.111 67.91C140.278 68.0767 140.361 68.2733 140.361 68.5C140.361 68.7267 140.278 68.9233 140.111 69.09C139.944 69.2567 139.748 69.34 139.521 69.34C139.294 69.34 139.098 69.2567 138.931 69.09C138.764 68.9233 138.681 68.7267 138.681 68.5ZM136.941 67.72C136.941 66.8133 137.168 66.11 137.621 65.61C138.081 65.11 138.714 64.86 139.521 64.86C140.328 64.86 140.958 65.11 141.411 65.61C141.871 66.11 142.101 66.8133 142.101 67.72V69.28C142.101 70.2133 141.871 70.9233 141.411 71.41C140.958 71.8967 140.328 72.14 139.521 72.14C138.714 72.14 138.081 71.8967 137.621 71.41C137.168 70.9233 136.941 70.2133 136.941 69.28V67.72ZM139.521 70.94C139.761 70.94 139.961 70.9033 140.121 70.83C140.281 70.75 140.411 70.64 140.511 70.5C140.611 70.36 140.681 70.1933 140.721 70C140.761 69.8067 140.781 69.5933 140.781 69.36V67.64C140.781 67.42 140.758 67.2133 140.711 67.02C140.664 66.8267 140.591 66.66 140.491 66.52C140.391 66.38 140.261 66.27 140.101 66.19C139.941 66.1033 139.748 66.06 139.521 66.06C139.294 66.06 139.101 66.1033 138.941 66.19C138.781 66.27 138.651 66.38 138.551 66.52C138.451 66.66 138.378 66.8267 138.331 67.02C138.284 67.2133 138.261 67.42 138.261 67.64V69.36C138.261 69.5933 138.281 69.8067 138.321 70C138.361 70.1933 138.431 70.36 138.531 70.5C138.631 70.64 138.761 70.75 138.921 70.83C139.081 70.9033 139.281 70.94 139.521 70.94Z"
    fill="#5078E1"
  /><path
    d="M145.224 70.74V65.66H145.044L144.384 68.06H143.124V67.94L143.964 65H146.544V70.74H148.284V72H143.244V70.74H145.224Z"
    fill="#5078E1"
  /><path
    d="M150.927 68.5C150.927 68.2733 151.01 68.0767 151.177 67.91C151.344 67.7433 151.54 67.66 151.767 67.66C151.994 67.66 152.19 67.7433 152.357 67.91C152.524 68.0767 152.607 68.2733 152.607 68.5C152.607 68.7267 152.524 68.9233 152.357 69.09C152.19 69.2567 151.994 69.34 151.767 69.34C151.54 69.34 151.344 69.2567 151.177 69.09C151.01 68.9233 150.927 68.7267 150.927 68.5ZM149.187 67.72C149.187 66.8133 149.414 66.11 149.867 65.61C150.327 65.11 150.96 64.86 151.767 64.86C152.574 64.86 153.204 65.11 153.657 65.61C154.117 66.11 154.347 66.8133 154.347 67.72V69.28C154.347 70.2133 154.117 70.9233 153.657 71.41C153.204 71.8967 152.574 72.14 151.767 72.14C150.96 72.14 150.327 71.8967 149.867 71.41C149.414 70.9233 149.187 70.2133 149.187 69.28V67.72ZM151.767 70.94C152.007 70.94 152.207 70.9033 152.367 70.83C152.527 70.75 152.657 70.64 152.757 70.5C152.857 70.36 152.927 70.1933 152.967 70C153.007 69.8067 153.027 69.5933 153.027 69.36V67.64C153.027 67.42 153.004 67.2133 152.957 67.02C152.91 66.8267 152.837 66.66 152.737 66.52C152.637 66.38 152.507 66.27 152.347 66.19C152.187 66.1033 151.994 66.06 151.767 66.06C151.54 66.06 151.347 66.1033 151.187 66.19C151.027 66.27 150.897 66.38 150.797 66.52C150.697 66.66 150.624 66.8267 150.577 67.02C150.53 67.2133 150.507 67.42 150.507 67.64V69.36C150.507 69.5933 150.527 69.8067 150.567 70C150.607 70.1933 150.677 70.36 150.777 70.5C150.877 70.64 151.007 70.75 151.167 70.83C151.327 70.9033 151.527 70.94 151.767 70.94Z"
    fill="#5078E1"
  /><path
    d="M157.05 68.5C157.05 68.2733 157.133 68.0767 157.3 67.91C157.467 67.7433 157.663 67.66 157.89 67.66C158.117 67.66 158.313 67.7433 158.48 67.91C158.647 68.0767 158.73 68.2733 158.73 68.5C158.73 68.7267 158.647 68.9233 158.48 69.09C158.313 69.2567 158.117 69.34 157.89 69.34C157.663 69.34 157.467 69.2567 157.3 69.09C157.133 68.9233 157.05 68.7267 157.05 68.5ZM155.31 67.72C155.31 66.8133 155.537 66.11 155.99 65.61C156.45 65.11 157.083 64.86 157.89 64.86C158.697 64.86 159.327 65.11 159.78 65.61C160.24 66.11 160.47 66.8133 160.47 67.72V69.28C160.47 70.2133 160.24 70.9233 159.78 71.41C159.327 71.8967 158.697 72.14 157.89 72.14C157.083 72.14 156.45 71.8967 155.99 71.41C155.537 70.9233 155.31 70.2133 155.31 69.28V67.72ZM157.89 70.94C158.13 70.94 158.33 70.9033 158.49 70.83C158.65 70.75 158.78 70.64 158.88 70.5C158.98 70.36 159.05 70.1933 159.09 70C159.13 69.8067 159.15 69.5933 159.15 69.36V67.64C159.15 67.42 159.127 67.2133 159.08 67.02C159.033 66.8267 158.96 66.66 158.86 66.52C158.76 66.38 158.63 66.27 158.47 66.19C158.31 66.1033 158.117 66.06 157.89 66.06C157.663 66.06 157.47 66.1033 157.31 66.19C157.15 66.27 157.02 66.38 156.92 66.52C156.82 66.66 156.747 66.8267 156.7 67.02C156.653 67.2133 156.63 67.42 156.63 67.64V69.36C156.63 69.5933 156.65 69.8067 156.69 70C156.73 70.1933 156.8 70.36 156.9 70.5C157 70.64 157.13 70.75 157.29 70.83C157.45 70.9033 157.65 70.94 157.89 70.94Z"
    fill="#5078E1"
  /><path
    d="M163.593 70.74V65.66H163.413L162.753 68.06H161.493V67.94L162.333 65H164.913V70.74H166.653V72H161.613V70.74H163.593Z"
    fill="#5078E1"
  /><path
    d="M169.716 70.74V65.66H169.536L168.876 68.06H167.616V67.94L168.456 65H171.036V70.74H172.776V72H167.736V70.74H169.716Z"
    fill="#5078E1"
  /><path
    d="M175.419 68.5C175.419 68.2733 175.503 68.0767 175.669 67.91C175.836 67.7433 176.033 67.66 176.259 67.66C176.486 67.66 176.683 67.7433 176.849 67.91C177.016 68.0767 177.099 68.2733 177.099 68.5C177.099 68.7267 177.016 68.9233 176.849 69.09C176.683 69.2567 176.486 69.34 176.259 69.34C176.033 69.34 175.836 69.2567 175.669 69.09C175.503 68.9233 175.419 68.7267 175.419 68.5ZM173.679 67.72C173.679 66.8133 173.906 66.11 174.359 65.61C174.819 65.11 175.453 64.86 176.259 64.86C177.066 64.86 177.696 65.11 178.149 65.61C178.609 66.11 178.839 66.8133 178.839 67.72V69.28C178.839 70.2133 178.609 70.9233 178.149 71.41C177.696 71.8967 177.066 72.14 176.259 72.14C175.453 72.14 174.819 71.8967 174.359 71.41C173.906 70.9233 173.679 70.2133 173.679 69.28V67.72ZM176.259 70.94C176.499 70.94 176.699 70.9033 176.859 70.83C177.019 70.75 177.149 70.64 177.249 70.5C177.349 70.36 177.419 70.1933 177.459 70C177.499 69.8067 177.519 69.5933 177.519 69.36V67.64C177.519 67.42 177.496 67.2133 177.449 67.02C177.403 66.8267 177.329 66.66 177.229 66.52C177.129 66.38 176.999 66.27 176.839 66.19C176.679 66.1033 176.486 66.06 176.259 66.06C176.033 66.06 175.839 66.1033 175.679 66.19C175.519 66.27 175.389 66.38 175.289 66.52C175.189 66.66 175.116 66.8267 175.069 67.02C175.023 67.2133 174.999 67.42 174.999 67.64V69.36C174.999 69.5933 175.019 69.8067 175.059 70C175.099 70.1933 175.169 70.36 175.269 70.5C175.369 70.64 175.499 70.75 175.659 70.83C175.819 70.9033 176.019 70.94 176.259 70.94Z"
    fill="#5078E1"
  /><path
    d="M181.542 68.5C181.542 68.2733 181.626 68.0767 181.792 67.91C181.959 67.7433 182.156 67.66 182.382 67.66C182.609 67.66 182.806 67.7433 182.972 67.91C183.139 68.0767 183.222 68.2733 183.222 68.5C183.222 68.7267 183.139 68.9233 182.972 69.09C182.806 69.2567 182.609 69.34 182.382 69.34C182.156 69.34 181.959 69.2567 181.792 69.09C181.626 68.9233 181.542 68.7267 181.542 68.5ZM179.802 67.72C179.802 66.8133 180.029 66.11 180.482 65.61C180.942 65.11 181.576 64.86 182.382 64.86C183.189 64.86 183.819 65.11 184.272 65.61C184.732 66.11 184.962 66.8133 184.962 67.72V69.28C184.962 70.2133 184.732 70.9233 184.272 71.41C183.819 71.8967 183.189 72.14 182.382 72.14C181.576 72.14 180.942 71.8967 180.482 71.41C180.029 70.9233 179.802 70.2133 179.802 69.28V67.72ZM182.382 70.94C182.622 70.94 182.822 70.9033 182.982 70.83C183.142 70.75 183.272 70.64 183.372 70.5C183.472 70.36 183.542 70.1933 183.582 70C183.622 69.8067 183.642 69.5933 183.642 69.36V67.64C183.642 67.42 183.619 67.2133 183.572 67.02C183.526 66.8267 183.452 66.66 183.352 66.52C183.252 66.38 183.122 66.27 182.962 66.19C182.802 66.1033 182.609 66.06 182.382 66.06C182.156 66.06 181.962 66.1033 181.802 66.19C181.642 66.27 181.512 66.38 181.412 66.52C181.312 66.66 181.239 66.8267 181.192 67.02C181.146 67.2133 181.122 67.42 181.122 67.64V69.36C181.122 69.5933 181.142 69.8067 181.182 70C181.222 70.1933 181.292 70.36 181.392 70.5C181.492 70.64 181.622 70.75 181.782 70.83C181.942 70.9033 182.142 70.94 182.382 70.94Z"
    fill="#5078E1"
  /><path
    d="M187.665 68.5C187.665 68.2733 187.749 68.0767 187.915 67.91C188.082 67.7433 188.279 67.66 188.505 67.66C188.732 67.66 188.929 67.7433 189.095 67.91C189.262 68.0767 189.345 68.2733 189.345 68.5C189.345 68.7267 189.262 68.9233 189.095 69.09C188.929 69.2567 188.732 69.34 188.505 69.34C188.279 69.34 188.082 69.2567 187.915 69.09C187.749 68.9233 187.665 68.7267 187.665 68.5ZM185.925 67.72C185.925 66.8133 186.152 66.11 186.605 65.61C187.065 65.11 187.699 64.86 188.505 64.86C189.312 64.86 189.942 65.11 190.395 65.61C190.855 66.11 191.085 66.8133 191.085 67.72V69.28C191.085 70.2133 190.855 70.9233 190.395 71.41C189.942 71.8967 189.312 72.14 188.505 72.14C187.699 72.14 187.065 71.8967 186.605 71.41C186.152 70.9233 185.925 70.2133 185.925 69.28V67.72ZM188.505 70.94C188.745 70.94 188.945 70.9033 189.105 70.83C189.265 70.75 189.395 70.64 189.495 70.5C189.595 70.36 189.665 70.1933 189.705 70C189.745 69.8067 189.765 69.5933 189.765 69.36V67.64C189.765 67.42 189.742 67.2133 189.695 67.02C189.649 66.8267 189.575 66.66 189.475 66.52C189.375 66.38 189.245 66.27 189.085 66.19C188.925 66.1033 188.732 66.06 188.505 66.06C188.279 66.06 188.085 66.1033 187.925 66.19C187.765 66.27 187.635 66.38 187.535 66.52C187.435 66.66 187.362 66.8267 187.315 67.02C187.269 67.2133 187.245 67.42 187.245 67.64V69.36C187.245 69.5933 187.265 69.8067 187.305 70C187.345 70.1933 187.415 70.36 187.515 70.5C187.615 70.64 187.745 70.75 187.905 70.83C188.065 70.9033 188.265 70.94 188.505 70.94Z"
    fill="#5078E1"
  /><path
    d="M193.788 68.5C193.788 68.2733 193.872 68.0767 194.038 67.91C194.205 67.7433 194.402 67.66 194.628 67.66C194.855 67.66 195.052 67.7433 195.218 67.91C195.385 68.0767 195.468 68.2733 195.468 68.5C195.468 68.7267 195.385 68.9233 195.218 69.09C195.052 69.2567 194.855 69.34 194.628 69.34C194.402 69.34 194.205 69.2567 194.038 69.09C193.872 68.9233 193.788 68.7267 193.788 68.5ZM192.048 67.72C192.048 66.8133 192.275 66.11 192.728 65.61C193.188 65.11 193.822 64.86 194.628 64.86C195.435 64.86 196.065 65.11 196.518 65.61C196.978 66.11 197.208 66.8133 197.208 67.72V69.28C197.208 70.2133 196.978 70.9233 196.518 71.41C196.065 71.8967 195.435 72.14 194.628 72.14C193.822 72.14 193.188 71.8967 192.728 71.41C192.275 70.9233 192.048 70.2133 192.048 69.28V67.72ZM194.628 70.94C194.868 70.94 195.068 70.9033 195.228 70.83C195.388 70.75 195.518 70.64 195.618 70.5C195.718 70.36 195.788 70.1933 195.828 70C195.868 69.8067 195.888 69.5933 195.888 69.36V67.64C195.888 67.42 195.865 67.2133 195.818 67.02C195.772 66.8267 195.698 66.66 195.598 66.52C195.498 66.38 195.368 66.27 195.208 66.19C195.048 66.1033 194.855 66.06 194.628 66.06C194.402 66.06 194.208 66.1033 194.048 66.19C193.888 66.27 193.758 66.38 193.658 66.52C193.558 66.66 193.485 66.8267 193.438 67.02C193.392 67.2133 193.368 67.42 193.368 67.64V69.36C193.368 69.5933 193.388 69.8067 193.428 70C193.468 70.1933 193.538 70.36 193.638 70.5C193.738 70.64 193.868 70.75 194.028 70.83C194.188 70.9033 194.388 70.94 194.628 70.94Z"
    fill="#5078E1"
  /><path
    d="M200.331 70.74V65.66H200.151L199.491 68.06H198.231V67.94L199.071 65H201.651V70.74H203.391V72H198.351V70.74H200.331Z"
    fill="#5078E1"
  /><path
    d="M206.034 68.5C206.034 68.2733 206.118 68.0767 206.284 67.91C206.451 67.7433 206.648 67.66 206.874 67.66C207.101 67.66 207.298 67.7433 207.464 67.91C207.631 68.0767 207.714 68.2733 207.714 68.5C207.714 68.7267 207.631 68.9233 207.464 69.09C207.298 69.2567 207.101 69.34 206.874 69.34C206.648 69.34 206.451 69.2567 206.284 69.09C206.118 68.9233 206.034 68.7267 206.034 68.5ZM204.294 67.72C204.294 66.8133 204.521 66.11 204.974 65.61C205.434 65.11 206.068 64.86 206.874 64.86C207.681 64.86 208.311 65.11 208.764 65.61C209.224 66.11 209.454 66.8133 209.454 67.72V69.28C209.454 70.2133 209.224 70.9233 208.764 71.41C208.311 71.8967 207.681 72.14 206.874 72.14C206.068 72.14 205.434 71.8967 204.974 71.41C204.521 70.9233 204.294 70.2133 204.294 69.28V67.72ZM206.874 70.94C207.114 70.94 207.314 70.9033 207.474 70.83C207.634 70.75 207.764 70.64 207.864 70.5C207.964 70.36 208.034 70.1933 208.074 70C208.114 69.8067 208.134 69.5933 208.134 69.36V67.64C208.134 67.42 208.111 67.2133 208.064 67.02C208.018 66.8267 207.944 66.66 207.844 66.52C207.744 66.38 207.614 66.27 207.454 66.19C207.294 66.1033 207.101 66.06 206.874 66.06C206.648 66.06 206.454 66.1033 206.294 66.19C206.134 66.27 206.004 66.38 205.904 66.52C205.804 66.66 205.731 66.8267 205.684 67.02C205.638 67.2133 205.614 67.42 205.614 67.64V69.36C205.614 69.5933 205.634 69.8067 205.674 70C205.714 70.1933 205.784 70.36 205.884 70.5C205.984 70.64 206.114 70.75 206.274 70.83C206.434 70.9033 206.634 70.94 206.874 70.94Z"
    fill="#5078E1"
  /><path
    d="M212.577 70.74V65.66H212.397L211.738 68.06H210.477V67.94L211.317 65H213.897V70.74H215.637V72H210.597V70.74H212.577Z"
    fill="#5078E1"
  /><path
    d="M218.701 70.74V65.66H218.521L217.861 68.06H216.601V67.94L217.441 65H220.021V70.74H221.761V72H216.721V70.74H218.701Z"
    fill="#5078E1"
  /><path
    d="M224.404 68.5C224.404 68.2733 224.487 68.0767 224.654 67.91C224.82 67.7433 225.017 67.66 225.244 67.66C225.47 67.66 225.667 67.7433 225.834 67.91C226 68.0767 226.084 68.2733 226.084 68.5C226.084 68.7267 226 68.9233 225.834 69.09C225.667 69.2567 225.47 69.34 225.244 69.34C225.017 69.34 224.82 69.2567 224.654 69.09C224.487 68.9233 224.404 68.7267 224.404 68.5ZM222.664 67.72C222.664 66.8133 222.89 66.11 223.344 65.61C223.804 65.11 224.437 64.86 225.244 64.86C226.05 64.86 226.68 65.11 227.134 65.61C227.594 66.11 227.824 66.8133 227.824 67.72V69.28C227.824 70.2133 227.594 70.9233 227.134 71.41C226.68 71.8967 226.05 72.14 225.244 72.14C224.437 72.14 223.804 71.8967 223.344 71.41C222.89 70.9233 222.664 70.2133 222.664 69.28V67.72ZM225.244 70.94C225.484 70.94 225.684 70.9033 225.844 70.83C226.004 70.75 226.134 70.64 226.234 70.5C226.334 70.36 226.404 70.1933 226.444 70C226.484 69.8067 226.504 69.5933 226.504 69.36V67.64C226.504 67.42 226.48 67.2133 226.434 67.02C226.387 66.8267 226.314 66.66 226.214 66.52C226.114 66.38 225.984 66.27 225.824 66.19C225.664 66.1033 225.47 66.06 225.244 66.06C225.017 66.06 224.824 66.1033 224.664 66.19C224.504 66.27 224.374 66.38 224.274 66.52C224.174 66.66 224.1 66.8267 224.054 67.02C224.007 67.2133 223.984 67.42 223.984 67.64V69.36C223.984 69.5933 224.004 69.8067 224.044 70C224.084 70.1933 224.154 70.36 224.254 70.5C224.354 70.64 224.484 70.75 224.644 70.83C224.804 70.9033 225.004 70.94 225.244 70.94Z"
    fill="#5078E1"
  /><path
    d="M230.947 70.74V65.66H230.767L230.107 68.06H228.847V67.94L229.687 65H232.267V70.74H234.007V72H228.967V70.74H230.947Z"
    fill="#5078E1"
  /><path
    d="M237.07 70.74V65.66H236.89L236.23 68.06H234.97V67.94L235.81 65H238.39V70.74H240.13V72H235.09V70.74H237.07Z"
    fill="#5078E1"
  /><path
    d="M243.193 70.74V65.66H243.013L242.353 68.06H241.093V67.94L241.933 65H244.513V70.74H246.253V72H241.213V70.74H243.193Z"
    fill="#5078E1"
  /><path
    d="M248.896 68.5C248.896 68.2733 248.979 68.0767 249.146 67.91C249.312 67.7433 249.509 67.66 249.736 67.66C249.962 67.66 250.159 67.7433 250.326 67.91C250.492 68.0767 250.576 68.2733 250.576 68.5C250.576 68.7267 250.492 68.9233 250.326 69.09C250.159 69.2567 249.962 69.34 249.736 69.34C249.509 69.34 249.312 69.2567 249.146 69.09C248.979 68.9233 248.896 68.7267 248.896 68.5ZM247.156 67.72C247.156 66.8133 247.382 66.11 247.836 65.61C248.296 65.11 248.929 64.86 249.736 64.86C250.542 64.86 251.172 65.11 251.626 65.61C252.086 66.11 252.316 66.8133 252.316 67.72V69.28C252.316 70.2133 252.086 70.9233 251.626 71.41C251.172 71.8967 250.542 72.14 249.736 72.14C248.929 72.14 248.296 71.8967 247.836 71.41C247.382 70.9233 247.156 70.2133 247.156 69.28V67.72ZM249.736 70.94C249.976 70.94 250.176 70.9033 250.336 70.83C250.496 70.75 250.626 70.64 250.726 70.5C250.826 70.36 250.896 70.1933 250.936 70C250.976 69.8067 250.996 69.5933 250.996 69.36V67.64C250.996 67.42 250.972 67.2133 250.926 67.02C250.879 66.8267 250.806 66.66 250.706 66.52C250.606 66.38 250.476 66.27 250.316 66.19C250.156 66.1033 249.962 66.06 249.736 66.06C249.509 66.06 249.316 66.1033 249.156 66.19C248.996 66.27 248.866 66.38 248.766 66.52C248.666 66.66 248.592 66.8267 248.546 67.02C248.499 67.2133 248.476 67.42 248.476 67.64V69.36C248.476 69.5933 248.496 69.8067 248.536 70C248.576 70.1933 248.646 70.36 248.746 70.5C248.846 70.64 248.976 70.75 249.136 70.83C249.296 70.9033 249.496 70.94 249.736 70.94Z"
    fill="#5078E1"
  /><path
    d="M255.019 68.5C255.019 68.2733 255.102 68.0767 255.269 67.91C255.435 67.7433 255.632 67.66 255.859 67.66C256.085 67.66 256.282 67.7433 256.449 67.91C256.615 68.0767 256.699 68.2733 256.699 68.5C256.699 68.7267 256.615 68.9233 256.449 69.09C256.282 69.2567 256.085 69.34 255.859 69.34C255.632 69.34 255.435 69.2567 255.269 69.09C255.102 68.9233 255.019 68.7267 255.019 68.5ZM253.279 67.72C253.279 66.8133 253.505 66.11 253.959 65.61C254.419 65.11 255.052 64.86 255.859 64.86C256.665 64.86 257.295 65.11 257.749 65.61C258.209 66.11 258.439 66.8133 258.439 67.72V69.28C258.439 70.2133 258.209 70.9233 257.749 71.41C257.295 71.8967 256.665 72.14 255.859 72.14C255.052 72.14 254.419 71.8967 253.959 71.41C253.505 70.9233 253.279 70.2133 253.279 69.28V67.72ZM255.859 70.94C256.099 70.94 256.299 70.9033 256.459 70.83C256.619 70.75 256.749 70.64 256.849 70.5C256.949 70.36 257.019 70.1933 257.059 70C257.099 69.8067 257.119 69.5933 257.119 69.36V67.64C257.119 67.42 257.095 67.2133 257.049 67.02C257.002 66.8267 256.929 66.66 256.829 66.52C256.729 66.38 256.599 66.27 256.439 66.19C256.279 66.1033 256.085 66.06 255.859 66.06C255.632 66.06 255.439 66.1033 255.279 66.19C255.119 66.27 254.989 66.38 254.889 66.52C254.789 66.66 254.715 66.8267 254.669 67.02C254.622 67.2133 254.599 67.42 254.599 67.64V69.36C254.599 69.5933 254.619 69.8067 254.659 70C254.699 70.1933 254.769 70.36 254.869 70.5C254.969 70.64 255.099 70.75 255.259 70.83C255.419 70.9033 255.619 70.94 255.859 70.94Z"
    fill="#5078E1"
  /><path
    d="M261.562 70.74V65.66H261.382L260.722 68.06H259.462V67.94L260.302 65H262.882V70.74H264.622V72H259.582V70.74H261.562Z"
    fill="#5078E1"
  /><path
    d="M267.685 70.74V65.66H267.505L266.845 68.06H265.585V67.94L266.425 65H269.005V70.74H270.745V72H265.705V70.74H267.685Z"
    fill="#5078E1"
  /><path
    d="M273.808 70.74V65.66H273.628L272.968 68.06H271.708V67.94L272.548 65H275.128V70.74H276.868V72H271.828V70.74H273.808Z"
    fill="#5078E1"
  /><path
    d="M279.511 68.5C279.511 68.2733 279.594 68.0767 279.761 67.91C279.928 67.7433 280.124 67.66 280.351 67.66C280.578 67.66 280.774 67.7433 280.941 67.91C281.108 68.0767 281.191 68.2733 281.191 68.5C281.191 68.7267 281.108 68.9233 280.941 69.09C280.774 69.2567 280.578 69.34 280.351 69.34C280.124 69.34 279.928 69.2567 279.761 69.09C279.594 68.9233 279.511 68.7267 279.511 68.5ZM277.771 67.72C277.771 66.8133 277.998 66.11 278.451 65.61C278.911 65.11 279.544 64.86 280.351 64.86C281.158 64.86 281.788 65.11 282.241 65.61C282.701 66.11 282.931 66.8133 282.931 67.72V69.28C282.931 70.2133 282.701 70.9233 282.241 71.41C281.788 71.8967 281.158 72.14 280.351 72.14C279.544 72.14 278.911 71.8967 278.451 71.41C277.998 70.9233 277.771 70.2133 277.771 69.28V67.72ZM280.351 70.94C280.591 70.94 280.791 70.9033 280.951 70.83C281.111 70.75 281.241 70.64 281.341 70.5C281.441 70.36 281.511 70.1933 281.551 70C281.591 69.8067 281.611 69.5933 281.611 69.36V67.64C281.611 67.42 281.588 67.2133 281.541 67.02C281.494 66.8267 281.421 66.66 281.321 66.52C281.221 66.38 281.091 66.27 280.931 66.19C280.771 66.1033 280.578 66.06 280.351 66.06C280.124 66.06 279.931 66.1033 279.771 66.19C279.611 66.27 279.481 66.38 279.381 66.52C279.281 66.66 279.208 66.8267 279.161 67.02C279.114 67.2133 279.091 67.42 279.091 67.64V69.36C279.091 69.5933 279.111 69.8067 279.151 70C279.191 70.1933 279.261 70.36 279.361 70.5C279.461 70.64 279.591 70.75 279.751 70.83C279.911 70.9033 280.111 70.94 280.351 70.94Z"
    fill="#5078E1"
  /><path
    d="M285.634 68.5C285.634 68.2733 285.717 68.0767 285.884 67.91C286.051 67.7433 286.247 67.66 286.474 67.66C286.701 67.66 286.897 67.7433 287.064 67.91C287.231 68.0767 287.314 68.2733 287.314 68.5C287.314 68.7267 287.231 68.9233 287.064 69.09C286.897 69.2567 286.701 69.34 286.474 69.34C286.247 69.34 286.051 69.2567 285.884 69.09C285.717 68.9233 285.634 68.7267 285.634 68.5ZM283.894 67.72C283.894 66.8133 284.121 66.11 284.574 65.61C285.034 65.11 285.667 64.86 286.474 64.86C287.281 64.86 287.911 65.11 288.364 65.61C288.824 66.11 289.054 66.8133 289.054 67.72V69.28C289.054 70.2133 288.824 70.9233 288.364 71.41C287.911 71.8967 287.281 72.14 286.474 72.14C285.667 72.14 285.034 71.8967 284.574 71.41C284.121 70.9233 283.894 70.2133 283.894 69.28V67.72ZM286.474 70.94C286.714 70.94 286.914 70.9033 287.074 70.83C287.234 70.75 287.364 70.64 287.464 70.5C287.564 70.36 287.634 70.1933 287.674 70C287.714 69.8067 287.734 69.5933 287.734 69.36V67.64C287.734 67.42 287.711 67.2133 287.664 67.02C287.617 66.8267 287.544 66.66 287.444 66.52C287.344 66.38 287.214 66.27 287.054 66.19C286.894 66.1033 286.701 66.06 286.474 66.06C286.247 66.06 286.054 66.1033 285.894 66.19C285.734 66.27 285.604 66.38 285.504 66.52C285.404 66.66 285.331 66.8267 285.284 67.02C285.237 67.2133 285.214 67.42 285.214 67.64V69.36C285.214 69.5933 285.234 69.8067 285.274 70C285.314 70.1933 285.384 70.36 285.484 70.5C285.584 70.64 285.714 70.75 285.874 70.83C286.034 70.9033 286.234 70.94 286.474 70.94Z"
    fill="#5078E1"
  /><path
    d="M292.177 70.74V65.66H291.997L291.337 68.06H290.077V67.94L290.917 65H293.497V70.74H295.237V72H290.197V70.74H292.177Z"
    fill="#5078E1"
  /><path
    d="M298.3 70.74V65.66H298.12L297.46 68.06H296.2V67.94L297.04 65H299.62V70.74H301.36V72H296.32V70.74H298.3Z"
    fill="#5078E1"
  /><path
    d="M304.003 68.5C304.003 68.2733 304.087 68.0767 304.253 67.91C304.42 67.7433 304.617 67.66 304.843 67.66C305.07 67.66 305.267 67.7433 305.433 67.91C305.6 68.0767 305.683 68.2733 305.683 68.5C305.683 68.7267 305.6 68.9233 305.433 69.09C305.267 69.2567 305.07 69.34 304.843 69.34C304.617 69.34 304.42 69.2567 304.253 69.09C304.087 68.9233 304.003 68.7267 304.003 68.5ZM302.263 67.72C302.263 66.8133 302.49 66.11 302.943 65.61C303.403 65.11 304.037 64.86 304.843 64.86C305.65 64.86 306.28 65.11 306.733 65.61C307.193 66.11 307.423 66.8133 307.423 67.72V69.28C307.423 70.2133 307.193 70.9233 306.733 71.41C306.28 71.8967 305.65 72.14 304.843 72.14C304.037 72.14 303.403 71.8967 302.943 71.41C302.49 70.9233 302.263 70.2133 302.263 69.28V67.72ZM304.843 70.94C305.083 70.94 305.283 70.9033 305.443 70.83C305.603 70.75 305.733 70.64 305.833 70.5C305.933 70.36 306.003 70.1933 306.043 70C306.083 69.8067 306.103 69.5933 306.103 69.36V67.64C306.103 67.42 306.08 67.2133 306.033 67.02C305.987 66.8267 305.913 66.66 305.813 66.52C305.713 66.38 305.583 66.27 305.423 66.19C305.263 66.1033 305.07 66.06 304.843 66.06C304.617 66.06 304.423 66.1033 304.263 66.19C304.103 66.27 303.973 66.38 303.873 66.52C303.773 66.66 303.7 66.8267 303.653 67.02C303.607 67.2133 303.583 67.42 303.583 67.64V69.36C303.583 69.5933 303.603 69.8067 303.643 70C303.683 70.1933 303.753 70.36 303.853 70.5C303.953 70.64 304.083 70.75 304.243 70.83C304.403 70.9033 304.603 70.94 304.843 70.94Z"
    fill="#5078E1"
  /><path
    d="M310.546 70.74V65.66H310.366L309.706 68.06H308.446V67.94L309.286 65H311.866V70.74H313.606V72H308.566V70.74H310.546Z"
    fill="#5078E1"
  /><path
    d="M316.669 70.74V65.66H316.489L315.829 68.06H314.569V67.94L315.409 65H317.989V70.74H319.729V72H314.689V70.74H316.669Z"
    fill="#5078E1"
  /><path
    d="M322.372 68.5C322.372 68.2733 322.456 68.0767 322.622 67.91C322.789 67.7433 322.986 67.66 323.212 67.66C323.439 67.66 323.636 67.7433 323.802 67.91C323.969 68.0767 324.052 68.2733 324.052 68.5C324.052 68.7267 323.969 68.9233 323.802 69.09C323.636 69.2567 323.439 69.34 323.212 69.34C322.986 69.34 322.789 69.2567 322.622 69.09C322.456 68.9233 322.372 68.7267 322.372 68.5ZM320.632 67.72C320.632 66.8133 320.859 66.11 321.312 65.61C321.772 65.11 322.406 64.86 323.212 64.86C324.019 64.86 324.649 65.11 325.102 65.61C325.562 66.11 325.792 66.8133 325.792 67.72V69.28C325.792 70.2133 325.562 70.9233 325.102 71.41C324.649 71.8967 324.019 72.14 323.212 72.14C322.406 72.14 321.772 71.8967 321.312 71.41C320.859 70.9233 320.632 70.2133 320.632 69.28V67.72ZM323.212 70.94C323.452 70.94 323.652 70.9033 323.812 70.83C323.972 70.75 324.102 70.64 324.202 70.5C324.302 70.36 324.372 70.1933 324.412 70C324.452 69.8067 324.472 69.5933 324.472 69.36V67.64C324.472 67.42 324.449 67.2133 324.402 67.02C324.356 66.8267 324.282 66.66 324.182 66.52C324.082 66.38 323.952 66.27 323.792 66.19C323.632 66.1033 323.439 66.06 323.212 66.06C322.986 66.06 322.792 66.1033 322.632 66.19C322.472 66.27 322.342 66.38 322.242 66.52C322.142 66.66 322.069 66.8267 322.022 67.02C321.976 67.2133 321.952 67.42 321.952 67.64V69.36C321.952 69.5933 321.972 69.8067 322.012 70C322.052 70.1933 322.122 70.36 322.222 70.5C322.322 70.64 322.452 70.75 322.612 70.83C322.772 70.9033 322.972 70.94 323.212 70.94Z"
    fill="#5078E1"
  /><path
    d="M328.915 70.74V65.66H328.735L328.075 68.06H326.815V67.94L327.655 65H330.235V70.74H331.975V72H326.935V70.74H328.915Z"
    fill="#5078E1"
  /><path
    d="M335.038 70.74V65.66H334.858L334.198 68.06H332.938V67.94L333.778 65H336.358V70.74H338.098V72H333.058V70.74H335.038Z"
    fill="#5078E1"
  /><path
    d="M341.161 70.74V65.66H340.981L340.321 68.06H339.061V67.94L339.901 65H342.481V70.74H344.221V72H339.181V70.74H341.161Z"
    fill="#5078E1"
  /><path
    d="M347.285 70.74V65.66H347.105L346.445 68.06H345.185V67.94L346.025 65H348.605V70.74H350.345V72H345.305V70.74H347.285Z"
    fill="#5078E1"
  /><path
    d="M352.988 68.5C352.988 68.2733 353.071 68.0767 353.238 67.91C353.404 67.7433 353.601 67.66 353.828 67.66C354.054 67.66 354.251 67.7433 354.418 67.91C354.584 68.0767 354.668 68.2733 354.668 68.5C354.668 68.7267 354.584 68.9233 354.418 69.09C354.251 69.2567 354.054 69.34 353.828 69.34C353.601 69.34 353.404 69.2567 353.238 69.09C353.071 68.9233 352.988 68.7267 352.988 68.5ZM351.248 67.72C351.248 66.8133 351.474 66.11 351.928 65.61C352.388 65.11 353.021 64.86 353.828 64.86C354.634 64.86 355.264 65.11 355.718 65.61C356.178 66.11 356.408 66.8133 356.408 67.72V69.28C356.408 70.2133 356.178 70.9233 355.718 71.41C355.264 71.8967 354.634 72.14 353.828 72.14C353.021 72.14 352.388 71.8967 351.928 71.41C351.474 70.9233 351.248 70.2133 351.248 69.28V67.72ZM353.828 70.94C354.068 70.94 354.268 70.9033 354.428 70.83C354.588 70.75 354.718 70.64 354.818 70.5C354.918 70.36 354.988 70.1933 355.028 70C355.068 69.8067 355.088 69.5933 355.088 69.36V67.64C355.088 67.42 355.064 67.2133 355.018 67.02C354.971 66.8267 354.898 66.66 354.798 66.52C354.698 66.38 354.568 66.27 354.408 66.19C354.248 66.1033 354.054 66.06 353.828 66.06C353.601 66.06 353.408 66.1033 353.248 66.19C353.088 66.27 352.958 66.38 352.858 66.52C352.758 66.66 352.684 66.8267 352.638 67.02C352.591 67.2133 352.568 67.42 352.568 67.64V69.36C352.568 69.5933 352.588 69.8067 352.628 70C352.668 70.1933 352.738 70.36 352.838 70.5C352.938 70.64 353.068 70.75 353.228 70.83C353.388 70.9033 353.588 70.94 353.828 70.94Z"
    fill="#5078E1"
  /><path
    d="M359.531 70.74V65.66H359.351L358.691 68.06H357.431V67.94L358.271 65H360.851V70.74H362.591V72H357.551V70.74H359.531Z"
    fill="#5078E1"
  /><path
    d="M365.654 70.74V65.66H365.474L364.814 68.06H363.554V67.94L364.394 65H366.974V70.74H368.714V72H363.674V70.74H365.654Z"
    fill="#5078E1"
  /><path
    d="M371.357 68.5C371.357 68.2733 371.44 68.0767 371.607 67.91C371.773 67.7433 371.97 67.66 372.197 67.66C372.423 67.66 372.62 67.7433 372.787 67.91C372.953 68.0767 373.037 68.2733 373.037 68.5C373.037 68.7267 372.953 68.9233 372.787 69.09C372.62 69.2567 372.423 69.34 372.197 69.34C371.97 69.34 371.773 69.2567 371.607 69.09C371.44 68.9233 371.357 68.7267 371.357 68.5ZM369.617 67.72C369.617 66.8133 369.843 66.11 370.297 65.61C370.757 65.11 371.39 64.86 372.197 64.86C373.003 64.86 373.633 65.11 374.087 65.61C374.547 66.11 374.777 66.8133 374.777 67.72V69.28C374.777 70.2133 374.547 70.9233 374.087 71.41C373.633 71.8967 373.003 72.14 372.197 72.14C371.39 72.14 370.757 71.8967 370.297 71.41C369.843 70.9233 369.617 70.2133 369.617 69.28V67.72ZM372.197 70.94C372.437 70.94 372.637 70.9033 372.797 70.83C372.957 70.75 373.087 70.64 373.187 70.5C373.287 70.36 373.357 70.1933 373.397 70C373.437 69.8067 373.457 69.5933 373.457 69.36V67.64C373.457 67.42 373.433 67.2133 373.387 67.02C373.34 66.8267 373.267 66.66 373.167 66.52C373.067 66.38 372.937 66.27 372.777 66.19C372.617 66.1033 372.423 66.06 372.197 66.06C371.97 66.06 371.777 66.1033 371.617 66.19C371.457 66.27 371.327 66.38 371.227 66.52C371.127 66.66 371.053 66.8267 371.007 67.02C370.96 67.2133 370.937 67.42 370.937 67.64V69.36C370.937 69.5933 370.957 69.8067 370.997 70C371.037 70.1933 371.107 70.36 371.207 70.5C371.307 70.64 371.437 70.75 371.597 70.83C371.757 70.9033 371.957 70.94 372.197 70.94Z"
    fill="#5078E1"
  /><path
    d="M377.9 70.74V65.66H377.72L377.06 68.06H375.8V67.94L376.64 65H379.22V70.74H380.96V72H375.92V70.74H377.9Z"
    fill="#5078E1"
  /><path
    d="M384.023 70.74V65.66H383.843L383.183 68.06H381.923V67.94L382.763 65H385.343V70.74H387.083V72H382.043V70.74H384.023Z"
    fill="#5078E1"
  /><path
    d="M389.726 68.5C389.726 68.2733 389.809 68.0767 389.976 67.91C390.143 67.7433 390.339 67.66 390.566 67.66C390.793 67.66 390.989 67.7433 391.156 67.91C391.323 68.0767 391.406 68.2733 391.406 68.5C391.406 68.7267 391.323 68.9233 391.156 69.09C390.989 69.2567 390.793 69.34 390.566 69.34C390.339 69.34 390.143 69.2567 389.976 69.09C389.809 68.9233 389.726 68.7267 389.726 68.5ZM387.986 67.72C387.986 66.8133 388.213 66.11 388.666 65.61C389.126 65.11 389.759 64.86 390.566 64.86C391.373 64.86 392.003 65.11 392.456 65.61C392.916 66.11 393.146 66.8133 393.146 67.72V69.28C393.146 70.2133 392.916 70.9233 392.456 71.41C392.003 71.8967 391.373 72.14 390.566 72.14C389.759 72.14 389.126 71.8967 388.666 71.41C388.213 70.9233 387.986 70.2133 387.986 69.28V67.72ZM390.566 70.94C390.806 70.94 391.006 70.9033 391.166 70.83C391.326 70.75 391.456 70.64 391.556 70.5C391.656 70.36 391.726 70.1933 391.766 70C391.806 69.8067 391.826 69.5933 391.826 69.36V67.64C391.826 67.42 391.803 67.2133 391.756 67.02C391.709 66.8267 391.636 66.66 391.536 66.52C391.436 66.38 391.306 66.27 391.146 66.19C390.986 66.1033 390.793 66.06 390.566 66.06C390.339 66.06 390.146 66.1033 389.986 66.19C389.826 66.27 389.696 66.38 389.596 66.52C389.496 66.66 389.423 66.8267 389.376 67.02C389.329 67.2133 389.306 67.42 389.306 67.64V69.36C389.306 69.5933 389.326 69.8067 389.366 70C389.406 70.1933 389.476 70.36 389.576 70.5C389.676 70.64 389.806 70.75 389.966 70.83C390.126 70.9033 390.326 70.94 390.566 70.94Z"
    fill="#5078E1"
  /><path
    d="M396.269 70.74V65.66H396.089L395.429 68.06H394.169V67.94L395.009 65H397.589V70.74H399.329V72H394.289V70.74H396.269Z"
    fill="#5078E1"
  /><path
    d="M401.972 68.5C401.972 68.2733 402.055 68.0767 402.222 67.91C402.389 67.7433 402.585 67.66 402.812 67.66C403.039 67.66 403.235 67.7433 403.402 67.91C403.569 68.0767 403.652 68.2733 403.652 68.5C403.652 68.7267 403.569 68.9233 403.402 69.09C403.235 69.2567 403.039 69.34 402.812 69.34C402.585 69.34 402.389 69.2567 402.222 69.09C402.055 68.9233 401.972 68.7267 401.972 68.5ZM400.232 67.72C400.232 66.8133 400.459 66.11 400.912 65.61C401.372 65.11 402.005 64.86 402.812 64.86C403.619 64.86 404.249 65.11 404.702 65.61C405.162 66.11 405.392 66.8133 405.392 67.72V69.28C405.392 70.2133 405.162 70.9233 404.702 71.41C404.249 71.8967 403.619 72.14 402.812 72.14C402.005 72.14 401.372 71.8967 400.912 71.41C400.459 70.9233 400.232 70.2133 400.232 69.28V67.72ZM402.812 70.94C403.052 70.94 403.252 70.9033 403.412 70.83C403.572 70.75 403.702 70.64 403.802 70.5C403.902 70.36 403.972 70.1933 404.012 70C404.052 69.8067 404.072 69.5933 404.072 69.36V67.64C404.072 67.42 404.049 67.2133 404.002 67.02C403.955 66.8267 403.882 66.66 403.782 66.52C403.682 66.38 403.552 66.27 403.392 66.19C403.232 66.1033 403.039 66.06 402.812 66.06C402.585 66.06 402.392 66.1033 402.232 66.19C402.072 66.27 401.942 66.38 401.842 66.52C401.742 66.66 401.669 66.8267 401.622 67.02C401.575 67.2133 401.552 67.42 401.552 67.64V69.36C401.552 69.5933 401.572 69.8067 401.612 70C401.652 70.1933 401.722 70.36 401.822 70.5C401.922 70.64 402.052 70.75 402.212 70.83C402.372 70.9033 402.572 70.94 402.812 70.94Z"
    fill="#5078E1"
  /><path
    d="M408.515 70.74V65.66H408.335L407.675 68.06H406.415V67.94L407.255 65H409.835V70.74H411.575V72H406.535V70.74H408.515Z"
    fill="#5078E1"
  /><path
    d="M414.638 70.74V65.66H414.458L413.798 68.06H412.538V67.94L413.378 65H415.958V70.74H417.698V72H412.658V70.74H414.638Z"
    fill="#5078E1"
  /><path
    d="M420.761 70.74V65.66H420.581L419.921 68.06H418.661V67.94L419.501 65H422.081V70.74H423.821V72H418.781V70.74H420.761Z"
    fill="#5078E1"
  /><path
    d="M426.464 68.5C426.464 68.2733 426.547 68.0767 426.714 67.91C426.881 67.7433 427.077 67.66 427.304 67.66C427.531 67.66 427.727 67.7433 427.894 67.91C428.061 68.0767 428.144 68.2733 428.144 68.5C428.144 68.7267 428.061 68.9233 427.894 69.09C427.727 69.2567 427.531 69.34 427.304 69.34C427.077 69.34 426.881 69.2567 426.714 69.09C426.547 68.9233 426.464 68.7267 426.464 68.5ZM424.724 67.72C424.724 66.8133 424.951 66.11 425.404 65.61C425.864 65.11 426.497 64.86 427.304 64.86C428.111 64.86 428.741 65.11 429.194 65.61C429.654 66.11 429.884 66.8133 429.884 67.72V69.28C429.884 70.2133 429.654 70.9233 429.194 71.41C428.741 71.8967 428.111 72.14 427.304 72.14C426.497 72.14 425.864 71.8967 425.404 71.41C424.951 70.9233 424.724 70.2133 424.724 69.28V67.72ZM427.304 70.94C427.544 70.94 427.744 70.9033 427.904 70.83C428.064 70.75 428.194 70.64 428.294 70.5C428.394 70.36 428.464 70.1933 428.504 70C428.544 69.8067 428.564 69.5933 428.564 69.36V67.64C428.564 67.42 428.541 67.2133 428.494 67.02C428.447 66.8267 428.374 66.66 428.274 66.52C428.174 66.38 428.044 66.27 427.884 66.19C427.724 66.1033 427.531 66.06 427.304 66.06C427.077 66.06 426.884 66.1033 426.724 66.19C426.564 66.27 426.434 66.38 426.334 66.52C426.234 66.66 426.161 66.8267 426.114 67.02C426.067 67.2133 426.044 67.42 426.044 67.64V69.36C426.044 69.5933 426.064 69.8067 426.104 70C426.144 70.1933 426.214 70.36 426.314 70.5C426.414 70.64 426.544 70.75 426.704 70.83C426.864 70.9033 427.064 70.94 427.304 70.94Z"
    fill="#5078E1"
  /><path
    d="M433.007 70.74V65.66H432.827L432.167 68.06H430.907V67.94L431.747 65H434.327V70.74H436.067V72H431.027V70.74H433.007Z"
    fill="#5078E1"
  /><path
    d="M439.13 70.74V65.66H438.95L438.29 68.06H437.03V67.94L437.87 65H440.45V70.74H442.19V72H437.15V70.74H439.13Z"
    fill="#5078E1"
  /><path
    d="M445.253 70.74V65.66H445.073L444.413 68.06H443.153V67.94L443.993 65H446.573V70.74H448.313V72H443.273V70.74H445.253Z"
    fill="#5078E1"
  /><path
    d="M450.956 68.5C450.956 68.2733 451.04 68.0767 451.206 67.91C451.373 67.7433 451.57 67.66 451.796 67.66C452.023 67.66 452.22 67.7433 452.386 67.91C452.553 68.0767 452.636 68.2733 452.636 68.5C452.636 68.7267 452.553 68.9233 452.386 69.09C452.22 69.2567 452.023 69.34 451.796 69.34C451.57 69.34 451.373 69.2567 451.206 69.09C451.04 68.9233 450.956 68.7267 450.956 68.5ZM449.216 67.72C449.216 66.8133 449.443 66.11 449.896 65.61C450.356 65.11 450.99 64.86 451.796 64.86C452.603 64.86 453.233 65.11 453.686 65.61C454.146 66.11 454.376 66.8133 454.376 67.72V69.28C454.376 70.2133 454.146 70.9233 453.686 71.41C453.233 71.8967 452.603 72.14 451.796 72.14C450.99 72.14 450.356 71.8967 449.896 71.41C449.443 70.9233 449.216 70.2133 449.216 69.28V67.72ZM451.796 70.94C452.036 70.94 452.236 70.9033 452.396 70.83C452.556 70.75 452.686 70.64 452.786 70.5C452.886 70.36 452.956 70.1933 452.996 70C453.036 69.8067 453.056 69.5933 453.056 69.36V67.64C453.056 67.42 453.033 67.2133 452.986 67.02C452.94 66.8267 452.866 66.66 452.766 66.52C452.666 66.38 452.536 66.27 452.376 66.19C452.216 66.1033 452.023 66.06 451.796 66.06C451.57 66.06 451.376 66.1033 451.216 66.19C451.056 66.27 450.926 66.38 450.826 66.52C450.726 66.66 450.653 66.8267 450.606 67.02C450.56 67.2133 450.536 67.42 450.536 67.64V69.36C450.536 69.5933 450.556 69.8067 450.596 70C450.636 70.1933 450.706 70.36 450.806 70.5C450.906 70.64 451.036 70.75 451.196 70.83C451.356 70.9033 451.556 70.94 451.796 70.94Z"
    fill="#5078E1"
  /><path
    d="M457.499 70.74V65.66H457.319L456.659 68.06H455.399V67.94L456.239 65H458.819V70.74H460.559V72H455.519V70.74H457.499Z"
    fill="#5078E1"
  /><path
    d="M463.622 70.74V65.66H463.442L462.782 68.06H461.522V67.94L462.362 65H464.942V70.74H466.682V72H461.642V70.74H463.622Z"
    fill="#5078E1"
  /><path
    d="M469.325 68.5C469.325 68.2733 469.409 68.0767 469.575 67.91C469.742 67.7433 469.939 67.66 470.165 67.66C470.392 67.66 470.589 67.7433 470.755 67.91C470.922 68.0767 471.005 68.2733 471.005 68.5C471.005 68.7267 470.922 68.9233 470.755 69.09C470.589 69.2567 470.392 69.34 470.165 69.34C469.939 69.34 469.742 69.2567 469.575 69.09C469.409 68.9233 469.325 68.7267 469.325 68.5ZM467.585 67.72C467.585 66.8133 467.812 66.11 468.265 65.61C468.725 65.11 469.359 64.86 470.165 64.86C470.972 64.86 471.602 65.11 472.055 65.61C472.515 66.11 472.745 66.8133 472.745 67.72V69.28C472.745 70.2133 472.515 70.9233 472.055 71.41C471.602 71.8967 470.972 72.14 470.165 72.14C469.359 72.14 468.725 71.8967 468.265 71.41C467.812 70.9233 467.585 70.2133 467.585 69.28V67.72ZM470.165 70.94C470.405 70.94 470.605 70.9033 470.765 70.83C470.925 70.75 471.055 70.64 471.155 70.5C471.255 70.36 471.325 70.1933 471.365 70C471.405 69.8067 471.425 69.5933 471.425 69.36V67.64C471.425 67.42 471.402 67.2133 471.355 67.02C471.309 66.8267 471.235 66.66 471.135 66.52C471.035 66.38 470.905 66.27 470.745 66.19C470.585 66.1033 470.392 66.06 470.165 66.06C469.939 66.06 469.745 66.1033 469.585 66.19C469.425 66.27 469.295 66.38 469.195 66.52C469.095 66.66 469.022 66.8267 468.975 67.02C468.929 67.2133 468.905 67.42 468.905 67.64V69.36C468.905 69.5933 468.925 69.8067 468.965 70C469.005 70.1933 469.075 70.36 469.175 70.5C469.275 70.64 469.405 70.75 469.565 70.83C469.725 70.9033 469.925 70.94 470.165 70.94Z"
    fill="#5078E1"
  /><path
    d="M475.449 68.5C475.449 68.2733 475.532 68.0767 475.699 67.91C475.865 67.7433 476.062 67.66 476.288 67.66C476.515 67.66 476.712 67.7433 476.879 67.91C477.045 68.0767 477.129 68.2733 477.129 68.5C477.129 68.7267 477.045 68.9233 476.879 69.09C476.712 69.2567 476.515 69.34 476.288 69.34C476.062 69.34 475.865 69.2567 475.699 69.09C475.532 68.9233 475.449 68.7267 475.449 68.5ZM473.709 67.72C473.709 66.8133 473.935 66.11 474.389 65.61C474.849 65.11 475.482 64.86 476.288 64.86C477.095 64.86 477.725 65.11 478.179 65.61C478.639 66.11 478.869 66.8133 478.869 67.72V69.28C478.869 70.2133 478.639 70.9233 478.179 71.41C477.725 71.8967 477.095 72.14 476.288 72.14C475.482 72.14 474.849 71.8967 474.389 71.41C473.935 70.9233 473.709 70.2133 473.709 69.28V67.72ZM476.288 70.94C476.528 70.94 476.729 70.9033 476.889 70.83C477.049 70.75 477.179 70.64 477.279 70.5C477.379 70.36 477.449 70.1933 477.489 70C477.529 69.8067 477.549 69.5933 477.549 69.36V67.64C477.549 67.42 477.525 67.2133 477.479 67.02C477.432 66.8267 477.358 66.66 477.258 66.52C477.158 66.38 477.029 66.27 476.869 66.19C476.709 66.1033 476.515 66.06 476.288 66.06C476.062 66.06 475.869 66.1033 475.709 66.19C475.549 66.27 475.418 66.38 475.318 66.52C475.218 66.66 475.145 66.8267 475.099 67.02C475.052 67.2133 475.029 67.42 475.029 67.64V69.36C475.029 69.5933 475.049 69.8067 475.089 70C475.129 70.1933 475.199 70.36 475.299 70.5C475.399 70.64 475.529 70.75 475.689 70.83C475.849 70.9033 476.048 70.94 476.288 70.94Z"
    fill="#5078E1"
  /><path
    d="M481.572 68.5C481.572 68.2733 481.655 68.0767 481.822 67.91C481.988 67.7433 482.185 67.66 482.412 67.66C482.638 67.66 482.835 67.7433 483.002 67.91C483.168 68.0767 483.252 68.2733 483.252 68.5C483.252 68.7267 483.168 68.9233 483.002 69.09C482.835 69.2567 482.638 69.34 482.412 69.34C482.185 69.34 481.988 69.2567 481.822 69.09C481.655 68.9233 481.572 68.7267 481.572 68.5ZM479.832 67.72C479.832 66.8133 480.058 66.11 480.512 65.61C480.972 65.11 481.605 64.86 482.412 64.86C483.218 64.86 483.848 65.11 484.302 65.61C484.762 66.11 484.992 66.8133 484.992 67.72V69.28C484.992 70.2133 484.762 70.9233 484.302 71.41C483.848 71.8967 483.218 72.14 482.412 72.14C481.605 72.14 480.972 71.8967 480.512 71.41C480.058 70.9233 479.832 70.2133 479.832 69.28V67.72ZM482.412 70.94C482.652 70.94 482.852 70.9033 483.012 70.83C483.172 70.75 483.302 70.64 483.402 70.5C483.502 70.36 483.572 70.1933 483.612 70C483.652 69.8067 483.672 69.5933 483.672 69.36V67.64C483.672 67.42 483.648 67.2133 483.602 67.02C483.555 66.8267 483.482 66.66 483.382 66.52C483.282 66.38 483.152 66.27 482.992 66.19C482.832 66.1033 482.638 66.06 482.412 66.06C482.185 66.06 481.992 66.1033 481.832 66.19C481.672 66.27 481.542 66.38 481.442 66.52C481.342 66.66 481.268 66.8267 481.222 67.02C481.175 67.2133 481.152 67.42 481.152 67.64V69.36C481.152 69.5933 481.172 69.8067 481.212 70C481.252 70.1933 481.322 70.36 481.422 70.5C481.522 70.64 481.652 70.75 481.812 70.83C481.972 70.9033 482.172 70.94 482.412 70.94Z"
    fill="#5078E1"
  /><path
    d="M487.695 68.5C487.695 68.2733 487.778 68.0767 487.945 67.91C488.111 67.7433 488.308 67.66 488.535 67.66C488.761 67.66 488.958 67.7433 489.125 67.91C489.291 68.0767 489.375 68.2733 489.375 68.5C489.375 68.7267 489.291 68.9233 489.125 69.09C488.958 69.2567 488.761 69.34 488.535 69.34C488.308 69.34 488.111 69.2567 487.945 69.09C487.778 68.9233 487.695 68.7267 487.695 68.5ZM485.955 67.72C485.955 66.8133 486.181 66.11 486.635 65.61C487.095 65.11 487.728 64.86 488.535 64.86C489.341 64.86 489.971 65.11 490.425 65.61C490.885 66.11 491.115 66.8133 491.115 67.72V69.28C491.115 70.2133 490.885 70.9233 490.425 71.41C489.971 71.8967 489.341 72.14 488.535 72.14C487.728 72.14 487.095 71.8967 486.635 71.41C486.181 70.9233 485.955 70.2133 485.955 69.28V67.72ZM488.535 70.94C488.775 70.94 488.975 70.9033 489.135 70.83C489.295 70.75 489.425 70.64 489.525 70.5C489.625 70.36 489.695 70.1933 489.735 70C489.775 69.8067 489.795 69.5933 489.795 69.36V67.64C489.795 67.42 489.771 67.2133 489.725 67.02C489.678 66.8267 489.605 66.66 489.505 66.52C489.405 66.38 489.275 66.27 489.115 66.19C488.955 66.1033 488.761 66.06 488.535 66.06C488.308 66.06 488.115 66.1033 487.955 66.19C487.795 66.27 487.665 66.38 487.565 66.52C487.465 66.66 487.391 66.8267 487.345 67.02C487.298 67.2133 487.275 67.42 487.275 67.64V69.36C487.275 69.5933 487.295 69.8067 487.335 70C487.375 70.1933 487.445 70.36 487.545 70.5C487.645 70.64 487.775 70.75 487.935 70.83C488.095 70.9033 488.295 70.94 488.535 70.94Z"
    fill="#5078E1"
  /><path
    d="M494.238 70.74V65.66H494.058L493.398 68.06H492.138V67.94L492.978 65H495.558V70.74H497.298V72H492.258V70.74H494.238Z"
    fill="#5078E1"
  /><path
    d="M499.941 68.5C499.941 68.2733 500.024 68.0767 500.191 67.91C500.357 67.7433 500.554 67.66 500.781 67.66C501.007 67.66 501.204 67.7433 501.371 67.91C501.537 68.0767 501.621 68.2733 501.621 68.5C501.621 68.7267 501.537 68.9233 501.371 69.09C501.204 69.2567 501.007 69.34 500.781 69.34C500.554 69.34 500.357 69.2567 500.191 69.09C500.024 68.9233 499.941 68.7267 499.941 68.5ZM498.201 67.72C498.201 66.8133 498.427 66.11 498.881 65.61C499.341 65.11 499.974 64.86 500.781 64.86C501.587 64.86 502.217 65.11 502.671 65.61C503.131 66.11 503.361 66.8133 503.361 67.72V69.28C503.361 70.2133 503.131 70.9233 502.671 71.41C502.217 71.8967 501.587 72.14 500.781 72.14C499.974 72.14 499.341 71.8967 498.881 71.41C498.427 70.9233 498.201 70.2133 498.201 69.28V67.72ZM500.781 70.94C501.021 70.94 501.221 70.9033 501.381 70.83C501.541 70.75 501.671 70.64 501.771 70.5C501.871 70.36 501.941 70.1933 501.981 70C502.021 69.8067 502.041 69.5933 502.041 69.36V67.64C502.041 67.42 502.017 67.2133 501.971 67.02C501.924 66.8267 501.851 66.66 501.751 66.52C501.651 66.38 501.521 66.27 501.361 66.19C501.201 66.1033 501.007 66.06 500.781 66.06C500.554 66.06 500.361 66.1033 500.201 66.19C500.041 66.27 499.911 66.38 499.811 66.52C499.711 66.66 499.637 66.8267 499.591 67.02C499.544 67.2133 499.521 67.42 499.521 67.64V69.36C499.521 69.5933 499.541 69.8067 499.581 70C499.621 70.1933 499.691 70.36 499.791 70.5C499.891 70.64 500.021 70.75 500.181 70.83C500.341 70.9033 500.541 70.94 500.781 70.94Z"
    fill="#5078E1"
  /><path
    d="M506.484 70.74V65.66H506.304L505.644 68.06H504.384V67.94L505.224 65H507.804V70.74H509.544V72H504.504V70.74H506.484Z"
    fill="#5078E1"
  /><path
    d="M512.607 70.74V65.66H512.427L511.767 68.06H510.507V67.94L511.347 65H513.927V70.74H515.667V72H510.627V70.74H512.607Z"
    fill="#5078E1"
  /><path
    d="M518.73 70.74V65.66H518.55L517.89 68.06H516.63V67.94L517.47 65H520.05V70.74H521.79V72H516.75V70.74H518.73Z"
    fill="#5078E1"
  /><path
    d="M524.433 68.5C524.433 68.2733 524.516 68.0767 524.683 67.91C524.85 67.7433 525.046 67.66 525.273 67.66C525.5 67.66 525.696 67.7433 525.863 67.91C526.03 68.0767 526.113 68.2733 526.113 68.5C526.113 68.7267 526.03 68.9233 525.863 69.09C525.696 69.2567 525.5 69.34 525.273 69.34C525.046 69.34 524.85 69.2567 524.683 69.09C524.516 68.9233 524.433 68.7267 524.433 68.5ZM522.693 67.72C522.693 66.8133 522.92 66.11 523.373 65.61C523.833 65.11 524.466 64.86 525.273 64.86C526.08 64.86 526.71 65.11 527.163 65.61C527.623 66.11 527.853 66.8133 527.853 67.72V69.28C527.853 70.2133 527.623 70.9233 527.163 71.41C526.71 71.8967 526.08 72.14 525.273 72.14C524.466 72.14 523.833 71.8967 523.373 71.41C522.92 70.9233 522.693 70.2133 522.693 69.28V67.72ZM525.273 70.94C525.513 70.94 525.713 70.9033 525.873 70.83C526.033 70.75 526.163 70.64 526.263 70.5C526.363 70.36 526.433 70.1933 526.473 70C526.513 69.8067 526.533 69.5933 526.533 69.36V67.64C526.533 67.42 526.51 67.2133 526.463 67.02C526.416 66.8267 526.343 66.66 526.243 66.52C526.143 66.38 526.013 66.27 525.853 66.19C525.693 66.1033 525.5 66.06 525.273 66.06C525.046 66.06 524.853 66.1033 524.693 66.19C524.533 66.27 524.403 66.38 524.303 66.52C524.203 66.66 524.13 66.8267 524.083 67.02C524.036 67.2133 524.013 67.42 524.013 67.64V69.36C524.013 69.5933 524.033 69.8067 524.073 70C524.113 70.1933 524.183 70.36 524.283 70.5C524.383 70.64 524.513 70.75 524.673 70.83C524.833 70.9033 525.033 70.94 525.273 70.94Z"
    fill="#5078E1"
  /><path
    d="M530.556 68.5C530.556 68.2733 530.639 68.0767 530.806 67.91C530.973 67.7433 531.169 67.66 531.396 67.66C531.623 67.66 531.819 67.7433 531.986 67.91C532.153 68.0767 532.236 68.2733 532.236 68.5C532.236 68.7267 532.153 68.9233 531.986 69.09C531.819 69.2567 531.623 69.34 531.396 69.34C531.169 69.34 530.973 69.2567 530.806 69.09C530.639 68.9233 530.556 68.7267 530.556 68.5ZM528.816 67.72C528.816 66.8133 529.043 66.11 529.496 65.61C529.956 65.11 530.589 64.86 531.396 64.86C532.203 64.86 532.833 65.11 533.286 65.61C533.746 66.11 533.976 66.8133 533.976 67.72V69.28C533.976 70.2133 533.746 70.9233 533.286 71.41C532.833 71.8967 532.203 72.14 531.396 72.14C530.589 72.14 529.956 71.8967 529.496 71.41C529.043 70.9233 528.816 70.2133 528.816 69.28V67.72ZM531.396 70.94C531.636 70.94 531.836 70.9033 531.996 70.83C532.156 70.75 532.286 70.64 532.386 70.5C532.486 70.36 532.556 70.1933 532.596 70C532.636 69.8067 532.656 69.5933 532.656 69.36V67.64C532.656 67.42 532.633 67.2133 532.586 67.02C532.539 66.8267 532.466 66.66 532.366 66.52C532.266 66.38 532.136 66.27 531.976 66.19C531.816 66.1033 531.623 66.06 531.396 66.06C531.169 66.06 530.976 66.1033 530.816 66.19C530.656 66.27 530.526 66.38 530.426 66.52C530.326 66.66 530.253 66.8267 530.206 67.02C530.159 67.2133 530.136 67.42 530.136 67.64V69.36C530.136 69.5933 530.156 69.8067 530.196 70C530.236 70.1933 530.306 70.36 530.406 70.5C530.506 70.64 530.636 70.75 530.796 70.83C530.956 70.9033 531.156 70.94 531.396 70.94Z"
    fill="#5078E1"
  /><path
    d="M537.099 70.74V65.66H536.919L536.259 68.06H534.999V67.94L535.839 65H538.419V70.74H540.159V72H535.119V70.74H537.099Z"
    fill="#5078E1"
  /><path
    d="M542.802 68.5C542.802 68.2733 542.885 68.0767 543.052 67.91C543.219 67.7433 543.415 67.66 543.642 67.66C543.869 67.66 544.065 67.7433 544.232 67.91C544.399 68.0767 544.482 68.2733 544.482 68.5C544.482 68.7267 544.399 68.9233 544.232 69.09C544.065 69.2567 543.869 69.34 543.642 69.34C543.415 69.34 543.219 69.2567 543.052 69.09C542.885 68.9233 542.802 68.7267 542.802 68.5ZM541.062 67.72C541.062 66.8133 541.289 66.11 541.742 65.61C542.202 65.11 542.835 64.86 543.642 64.86C544.449 64.86 545.079 65.11 545.532 65.61C545.992 66.11 546.222 66.8133 546.222 67.72V69.28C546.222 70.2133 545.992 70.9233 545.532 71.41C545.079 71.8967 544.449 72.14 543.642 72.14C542.835 72.14 542.202 71.8967 541.742 71.41C541.289 70.9233 541.062 70.2133 541.062 69.28V67.72ZM543.642 70.94C543.882 70.94 544.082 70.9033 544.242 70.83C544.402 70.75 544.532 70.64 544.632 70.5C544.732 70.36 544.802 70.1933 544.842 70C544.882 69.8067 544.902 69.5933 544.902 69.36V67.64C544.902 67.42 544.879 67.2133 544.832 67.02C544.785 66.8267 544.712 66.66 544.612 66.52C544.512 66.38 544.382 66.27 544.222 66.19C544.062 66.1033 543.869 66.06 543.642 66.06C543.415 66.06 543.222 66.1033 543.062 66.19C542.902 66.27 542.772 66.38 542.672 66.52C542.572 66.66 542.499 66.8267 542.452 67.02C542.405 67.2133 542.382 67.42 542.382 67.64V69.36C542.382 69.5933 542.402 69.8067 542.442 70C542.482 70.1933 542.552 70.36 542.652 70.5C542.752 70.64 542.882 70.75 543.042 70.83C543.202 70.9033 543.402 70.94 543.642 70.94Z"
    fill="#5078E1"
  /><path
    d="M548.925 68.5C548.925 68.2733 549.008 68.0767 549.175 67.91C549.342 67.7433 549.538 67.66 549.765 67.66C549.992 67.66 550.188 67.7433 550.355 67.91C550.522 68.0767 550.605 68.2733 550.605 68.5C550.605 68.7267 550.522 68.9233 550.355 69.09C550.188 69.2567 549.992 69.34 549.765 69.34C549.538 69.34 549.342 69.2567 549.175 69.09C549.008 68.9233 548.925 68.7267 548.925 68.5ZM547.185 67.72C547.185 66.8133 547.412 66.11 547.865 65.61C548.325 65.11 548.958 64.86 549.765 64.86C550.572 64.86 551.202 65.11 551.655 65.61C552.115 66.11 552.345 66.8133 552.345 67.72V69.28C552.345 70.2133 552.115 70.9233 551.655 71.41C551.202 71.8967 550.572 72.14 549.765 72.14C548.958 72.14 548.325 71.8967 547.865 71.41C547.412 70.9233 547.185 70.2133 547.185 69.28V67.72ZM549.765 70.94C550.005 70.94 550.205 70.9033 550.365 70.83C550.525 70.75 550.655 70.64 550.755 70.5C550.855 70.36 550.925 70.1933 550.965 70C551.005 69.8067 551.025 69.5933 551.025 69.36V67.64C551.025 67.42 551.002 67.2133 550.955 67.02C550.908 66.8267 550.835 66.66 550.735 66.52C550.635 66.38 550.505 66.27 550.345 66.19C550.185 66.1033 549.992 66.06 549.765 66.06C549.538 66.06 549.345 66.1033 549.185 66.19C549.025 66.27 548.895 66.38 548.795 66.52C548.695 66.66 548.622 66.8267 548.575 67.02C548.528 67.2133 548.505 67.42 548.505 67.64V69.36C548.505 69.5933 548.525 69.8067 548.565 70C548.605 70.1933 548.675 70.36 548.775 70.5C548.875 70.64 549.005 70.75 549.165 70.83C549.325 70.9033 549.525 70.94 549.765 70.94Z"
    fill="#5078E1"
  /><path
    d="M555.468 70.74V65.66H555.288L554.628 68.06H553.368V67.94L554.208 65H556.788V70.74H558.528V72H553.488V70.74H555.468Z"
    fill="#5078E1"
  /><path
    d="M561.591 70.74V65.66H561.411L560.751 68.06H559.491V67.94L560.331 65H562.911V70.74H564.651V72H559.611V70.74H561.591Z"
    fill="#5078E1"
  /><path
    d="M567.294 68.5C567.294 68.2733 567.378 68.0767 567.544 67.91C567.711 67.7433 567.908 67.66 568.134 67.66C568.361 67.66 568.558 67.7433 568.724 67.91C568.891 68.0767 568.974 68.2733 568.974 68.5C568.974 68.7267 568.891 68.9233 568.724 69.09C568.558 69.2567 568.361 69.34 568.134 69.34C567.908 69.34 567.711 69.2567 567.544 69.09C567.378 68.9233 567.294 68.7267 567.294 68.5ZM565.554 67.72C565.554 66.8133 565.781 66.11 566.234 65.61C566.694 65.11 567.328 64.86 568.134 64.86C568.941 64.86 569.571 65.11 570.024 65.61C570.484 66.11 570.714 66.8133 570.714 67.72V69.28C570.714 70.2133 570.484 70.9233 570.024 71.41C569.571 71.8967 568.941 72.14 568.134 72.14C567.328 72.14 566.694 71.8967 566.234 71.41C565.781 70.9233 565.554 70.2133 565.554 69.28V67.72ZM568.134 70.94C568.374 70.94 568.574 70.9033 568.734 70.83C568.894 70.75 569.024 70.64 569.124 70.5C569.224 70.36 569.294 70.1933 569.334 70C569.374 69.8067 569.394 69.5933 569.394 69.36V67.64C569.394 67.42 569.371 67.2133 569.324 67.02C569.278 66.8267 569.204 66.66 569.104 66.52C569.004 66.38 568.874 66.27 568.714 66.19C568.554 66.1033 568.361 66.06 568.134 66.06C567.908 66.06 567.714 66.1033 567.554 66.19C567.394 66.27 567.264 66.38 567.164 66.52C567.064 66.66 566.991 66.8267 566.944 67.02C566.898 67.2133 566.874 67.42 566.874 67.64V69.36C566.874 69.5933 566.894 69.8067 566.934 70C566.974 70.1933 567.044 70.36 567.144 70.5C567.244 70.64 567.374 70.75 567.534 70.83C567.694 70.9033 567.894 70.94 568.134 70.94Z"
    fill="#5078E1"
  /><path
    d="M573.417 68.5C573.417 68.2733 573.501 68.0767 573.667 67.91C573.834 67.7433 574.031 67.66 574.257 67.66C574.484 67.66 574.681 67.7433 574.847 67.91C575.014 68.0767 575.097 68.2733 575.097 68.5C575.097 68.7267 575.014 68.9233 574.847 69.09C574.681 69.2567 574.484 69.34 574.257 69.34C574.031 69.34 573.834 69.2567 573.667 69.09C573.501 68.9233 573.417 68.7267 573.417 68.5ZM571.677 67.72C571.677 66.8133 571.904 66.11 572.357 65.61C572.817 65.11 573.451 64.86 574.257 64.86C575.064 64.86 575.694 65.11 576.147 65.61C576.607 66.11 576.837 66.8133 576.837 67.72V69.28C576.837 70.2133 576.607 70.9233 576.147 71.41C575.694 71.8967 575.064 72.14 574.257 72.14C573.451 72.14 572.817 71.8967 572.357 71.41C571.904 70.9233 571.677 70.2133 571.677 69.28V67.72ZM574.257 70.94C574.497 70.94 574.697 70.9033 574.857 70.83C575.017 70.75 575.147 70.64 575.247 70.5C575.347 70.36 575.417 70.1933 575.457 70C575.497 69.8067 575.517 69.5933 575.517 69.36V67.64C575.517 67.42 575.494 67.2133 575.447 67.02C575.401 66.8267 575.327 66.66 575.227 66.52C575.127 66.38 574.997 66.27 574.837 66.19C574.677 66.1033 574.484 66.06 574.257 66.06C574.031 66.06 573.837 66.1033 573.677 66.19C573.517 66.27 573.387 66.38 573.287 66.52C573.187 66.66 573.114 66.8267 573.067 67.02C573.021 67.2133 572.997 67.42 572.997 67.64V69.36C572.997 69.5933 573.017 69.8067 573.057 70C573.097 70.1933 573.167 70.36 573.267 70.5C573.367 70.64 573.497 70.75 573.657 70.83C573.817 70.9033 574.017 70.94 574.257 70.94Z"
    fill="#5078E1"
  /><path
    d="M579.96 70.74V65.66H579.78L579.12 68.06H577.86V67.94L578.7 65H581.28V70.74H583.02V72H577.98V70.74H579.96Z"
    fill="#5078E1"
  /><path
    d="M585.663 68.5C585.663 68.2733 585.747 68.0767 585.913 67.91C586.08 67.7433 586.277 67.66 586.503 67.66C586.73 67.66 586.927 67.7433 587.093 67.91C587.26 68.0767 587.343 68.2733 587.343 68.5C587.343 68.7267 587.26 68.9233 587.093 69.09C586.927 69.2567 586.73 69.34 586.503 69.34C586.277 69.34 586.08 69.2567 585.913 69.09C585.747 68.9233 585.663 68.7267 585.663 68.5ZM583.923 67.72C583.923 66.8133 584.15 66.11 584.603 65.61C585.063 65.11 585.697 64.86 586.503 64.86C587.31 64.86 587.94 65.11 588.393 65.61C588.853 66.11 589.083 66.8133 589.083 67.72V69.28C589.083 70.2133 588.853 70.9233 588.393 71.41C587.94 71.8967 587.31 72.14 586.503 72.14C585.697 72.14 585.063 71.8967 584.603 71.41C584.15 70.9233 583.923 70.2133 583.923 69.28V67.72ZM586.503 70.94C586.743 70.94 586.943 70.9033 587.103 70.83C587.263 70.75 587.393 70.64 587.493 70.5C587.593 70.36 587.663 70.1933 587.703 70C587.743 69.8067 587.763 69.5933 587.763 69.36V67.64C587.763 67.42 587.74 67.2133 587.693 67.02C587.647 66.8267 587.573 66.66 587.473 66.52C587.373 66.38 587.243 66.27 587.083 66.19C586.923 66.1033 586.73 66.06 586.503 66.06C586.277 66.06 586.083 66.1033 585.923 66.19C585.763 66.27 585.633 66.38 585.533 66.52C585.433 66.66 585.36 66.8267 585.313 67.02C585.267 67.2133 585.243 67.42 585.243 67.64V69.36C585.243 69.5933 585.263 69.8067 585.303 70C585.343 70.1933 585.413 70.36 585.513 70.5C585.613 70.64 585.743 70.75 585.903 70.83C586.063 70.9033 586.263 70.94 586.503 70.94Z"
    fill="#5078E1"
  /><path
    d="M592.206 70.74V65.66H592.026L591.366 68.06H590.106V67.94L590.946 65H593.526V70.74H595.266V72H590.226V70.74H592.206Z"
    fill="#5078E1"
  /><path
    d="M597.909 68.5C597.909 68.2733 597.993 68.0767 598.159 67.91C598.326 67.7433 598.523 67.66 598.749 67.66C598.976 67.66 599.173 67.7433 599.339 67.91C599.506 68.0767 599.589 68.2733 599.589 68.5C599.589 68.7267 599.506 68.9233 599.339 69.09C599.173 69.2567 598.976 69.34 598.749 69.34C598.523 69.34 598.326 69.2567 598.159 69.09C597.993 68.9233 597.909 68.7267 597.909 68.5ZM596.169 67.72C596.169 66.8133 596.396 66.11 596.849 65.61C597.309 65.11 597.943 64.86 598.749 64.86C599.556 64.86 600.186 65.11 600.639 65.61C601.099 66.11 601.329 66.8133 601.329 67.72V69.28C601.329 70.2133 601.099 70.9233 600.639 71.41C600.186 71.8967 599.556 72.14 598.749 72.14C597.943 72.14 597.309 71.8967 596.849 71.41C596.396 70.9233 596.169 70.2133 596.169 69.28V67.72ZM598.749 70.94C598.989 70.94 599.189 70.9033 599.349 70.83C599.509 70.75 599.639 70.64 599.739 70.5C599.839 70.36 599.909 70.1933 599.949 70C599.989 69.8067 600.009 69.5933 600.009 69.36V67.64C600.009 67.42 599.986 67.2133 599.939 67.02C599.893 66.8267 599.819 66.66 599.719 66.52C599.619 66.38 599.489 66.27 599.329 66.19C599.169 66.1033 598.976 66.06 598.749 66.06C598.523 66.06 598.329 66.1033 598.169 66.19C598.009 66.27 597.879 66.38 597.779 66.52C597.679 66.66 597.606 66.8267 597.559 67.02C597.513 67.2133 597.489 67.42 597.489 67.64V69.36C597.489 69.5933 597.509 69.8067 597.549 70C597.589 70.1933 597.659 70.36 597.759 70.5C597.859 70.64 597.989 70.75 598.149 70.83C598.309 70.9033 598.509 70.94 598.749 70.94Z"
    fill="#5078E1"
  /><path
    d="M604.032 68.5C604.032 68.2733 604.116 68.0767 604.282 67.91C604.449 67.7433 604.646 67.66 604.873 67.66C605.099 67.66 605.296 67.7433 605.462 67.91C605.629 68.0767 605.712 68.2733 605.712 68.5C605.712 68.7267 605.629 68.9233 605.462 69.09C605.296 69.2567 605.099 69.34 604.873 69.34C604.646 69.34 604.449 69.2567 604.282 69.09C604.116 68.9233 604.032 68.7267 604.032 68.5ZM602.292 67.72C602.292 66.8133 602.519 66.11 602.972 65.61C603.432 65.11 604.066 64.86 604.873 64.86C605.679 64.86 606.309 65.11 606.762 65.61C607.222 66.11 607.452 66.8133 607.452 67.72V69.28C607.452 70.2133 607.222 70.9233 606.762 71.41C606.309 71.8967 605.679 72.14 604.873 72.14C604.066 72.14 603.432 71.8967 602.972 71.41C602.519 70.9233 602.292 70.2133 602.292 69.28V67.72ZM604.873 70.94C605.113 70.94 605.312 70.9033 605.472 70.83C605.632 70.75 605.763 70.64 605.863 70.5C605.963 70.36 606.033 70.1933 606.073 70C606.113 69.8067 606.133 69.5933 606.133 69.36V67.64C606.133 67.42 606.109 67.2133 606.063 67.02C606.016 66.8267 605.942 66.66 605.842 66.52C605.742 66.38 605.612 66.27 605.452 66.19C605.292 66.1033 605.099 66.06 604.873 66.06C604.646 66.06 604.452 66.1033 604.292 66.19C604.132 66.27 604.002 66.38 603.902 66.52C603.802 66.66 603.729 66.8267 603.683 67.02C603.636 67.2133 603.613 67.42 603.613 67.64V69.36C603.613 69.5933 603.633 69.8067 603.673 70C603.713 70.1933 603.783 70.36 603.883 70.5C603.983 70.64 604.112 70.75 604.272 70.83C604.432 70.9033 604.633 70.94 604.873 70.94Z"
    fill="#5078E1"
  /><path
    d="M610.576 70.74V65.66H610.396L609.736 68.06H608.476V67.94L609.316 65H611.896V70.74H613.636V72H608.596V70.74H610.576Z"
    fill="#5078E1"
  /><path
    d="M616.279 68.5C616.279 68.2733 616.362 68.0767 616.529 67.91C616.695 67.7433 616.892 67.66 617.119 67.66C617.345 67.66 617.542 67.7433 617.709 67.91C617.875 68.0767 617.959 68.2733 617.959 68.5C617.959 68.7267 617.875 68.9233 617.709 69.09C617.542 69.2567 617.345 69.34 617.119 69.34C616.892 69.34 616.695 69.2567 616.529 69.09C616.362 68.9233 616.279 68.7267 616.279 68.5ZM614.539 67.72C614.539 66.8133 614.765 66.11 615.219 65.61C615.679 65.11 616.312 64.86 617.119 64.86C617.925 64.86 618.555 65.11 619.009 65.61C619.469 66.11 619.699 66.8133 619.699 67.72V69.28C619.699 70.2133 619.469 70.9233 619.009 71.41C618.555 71.8967 617.925 72.14 617.119 72.14C616.312 72.14 615.679 71.8967 615.219 71.41C614.765 70.9233 614.539 70.2133 614.539 69.28V67.72ZM617.119 70.94C617.359 70.94 617.559 70.9033 617.719 70.83C617.879 70.75 618.009 70.64 618.109 70.5C618.209 70.36 618.279 70.1933 618.319 70C618.359 69.8067 618.379 69.5933 618.379 69.36V67.64C618.379 67.42 618.355 67.2133 618.309 67.02C618.262 66.8267 618.189 66.66 618.089 66.52C617.989 66.38 617.859 66.27 617.699 66.19C617.539 66.1033 617.345 66.06 617.119 66.06C616.892 66.06 616.699 66.1033 616.539 66.19C616.379 66.27 616.249 66.38 616.149 66.52C616.049 66.66 615.975 66.8267 615.929 67.02C615.882 67.2133 615.859 67.42 615.859 67.64V69.36C615.859 69.5933 615.879 69.8067 615.919 70C615.959 70.1933 616.029 70.36 616.129 70.5C616.229 70.64 616.359 70.75 616.519 70.83C616.679 70.9033 616.879 70.94 617.119 70.94Z"
    fill="#5078E1"
  /><path
    d="M622.402 68.5C622.402 68.2733 622.485 68.0767 622.652 67.91C622.818 67.7433 623.015 67.66 623.242 67.66C623.468 67.66 623.665 67.7433 623.832 67.91C623.998 68.0767 624.082 68.2733 624.082 68.5C624.082 68.7267 623.998 68.9233 623.832 69.09C623.665 69.2567 623.468 69.34 623.242 69.34C623.015 69.34 622.818 69.2567 622.652 69.09C622.485 68.9233 622.402 68.7267 622.402 68.5ZM620.662 67.72C620.662 66.8133 620.888 66.11 621.342 65.61C621.802 65.11 622.435 64.86 623.242 64.86C624.048 64.86 624.678 65.11 625.132 65.61C625.592 66.11 625.822 66.8133 625.822 67.72V69.28C625.822 70.2133 625.592 70.9233 625.132 71.41C624.678 71.8967 624.048 72.14 623.242 72.14C622.435 72.14 621.802 71.8967 621.342 71.41C620.888 70.9233 620.662 70.2133 620.662 69.28V67.72ZM623.242 70.94C623.482 70.94 623.682 70.9033 623.842 70.83C624.002 70.75 624.132 70.64 624.232 70.5C624.332 70.36 624.402 70.1933 624.442 70C624.482 69.8067 624.502 69.5933 624.502 69.36V67.64C624.502 67.42 624.478 67.2133 624.432 67.02C624.385 66.8267 624.312 66.66 624.212 66.52C624.112 66.38 623.982 66.27 623.822 66.19C623.662 66.1033 623.468 66.06 623.242 66.06C623.015 66.06 622.822 66.1033 622.662 66.19C622.502 66.27 622.372 66.38 622.272 66.52C622.172 66.66 622.098 66.8267 622.052 67.02C622.005 67.2133 621.982 67.42 621.982 67.64V69.36C621.982 69.5933 622.002 69.8067 622.042 70C622.082 70.1933 622.152 70.36 622.252 70.5C622.352 70.64 622.482 70.75 622.642 70.83C622.802 70.9033 623.002 70.94 623.242 70.94Z"
    fill="#5078E1"
  /><path
    d="M628.525 68.5C628.525 68.2733 628.608 68.0767 628.775 67.91C628.941 67.7433 629.138 67.66 629.365 67.66C629.591 67.66 629.788 67.7433 629.955 67.91C630.121 68.0767 630.205 68.2733 630.205 68.5C630.205 68.7267 630.121 68.9233 629.955 69.09C629.788 69.2567 629.591 69.34 629.365 69.34C629.138 69.34 628.941 69.2567 628.775 69.09C628.608 68.9233 628.525 68.7267 628.525 68.5ZM626.785 67.72C626.785 66.8133 627.011 66.11 627.465 65.61C627.925 65.11 628.558 64.86 629.365 64.86C630.171 64.86 630.801 65.11 631.255 65.61C631.715 66.11 631.945 66.8133 631.945 67.72V69.28C631.945 70.2133 631.715 70.9233 631.255 71.41C630.801 71.8967 630.171 72.14 629.365 72.14C628.558 72.14 627.925 71.8967 627.465 71.41C627.011 70.9233 626.785 70.2133 626.785 69.28V67.72ZM629.365 70.94C629.605 70.94 629.805 70.9033 629.965 70.83C630.125 70.75 630.255 70.64 630.355 70.5C630.455 70.36 630.525 70.1933 630.565 70C630.605 69.8067 630.625 69.5933 630.625 69.36V67.64C630.625 67.42 630.601 67.2133 630.555 67.02C630.508 66.8267 630.435 66.66 630.335 66.52C630.235 66.38 630.105 66.27 629.945 66.19C629.785 66.1033 629.591 66.06 629.365 66.06C629.138 66.06 628.945 66.1033 628.785 66.19C628.625 66.27 628.495 66.38 628.395 66.52C628.295 66.66 628.221 66.8267 628.175 67.02C628.128 67.2133 628.105 67.42 628.105 67.64V69.36C628.105 69.5933 628.125 69.8067 628.165 70C628.205 70.1933 628.275 70.36 628.375 70.5C628.475 70.64 628.605 70.75 628.765 70.83C628.925 70.9033 629.125 70.94 629.365 70.94Z"
    fill="#5078E1"
  /><path
    d="M634.648 68.5C634.648 68.2733 634.731 68.0767 634.898 67.91C635.064 67.7433 635.261 67.66 635.488 67.66C635.714 67.66 635.911 67.7433 636.078 67.91C636.244 68.0767 636.328 68.2733 636.328 68.5C636.328 68.7267 636.244 68.9233 636.078 69.09C635.911 69.2567 635.714 69.34 635.488 69.34C635.261 69.34 635.064 69.2567 634.898 69.09C634.731 68.9233 634.648 68.7267 634.648 68.5ZM632.908 67.72C632.908 66.8133 633.134 66.11 633.588 65.61C634.048 65.11 634.681 64.86 635.488 64.86C636.294 64.86 636.924 65.11 637.378 65.61C637.838 66.11 638.068 66.8133 638.068 67.72V69.28C638.068 70.2133 637.838 70.9233 637.378 71.41C636.924 71.8967 636.294 72.14 635.488 72.14C634.681 72.14 634.048 71.8967 633.588 71.41C633.134 70.9233 632.908 70.2133 632.908 69.28V67.72ZM635.488 70.94C635.728 70.94 635.928 70.9033 636.088 70.83C636.248 70.75 636.378 70.64 636.478 70.5C636.578 70.36 636.648 70.1933 636.688 70C636.728 69.8067 636.748 69.5933 636.748 69.36V67.64C636.748 67.42 636.724 67.2133 636.678 67.02C636.631 66.8267 636.558 66.66 636.458 66.52C636.358 66.38 636.228 66.27 636.068 66.19C635.908 66.1033 635.714 66.06 635.488 66.06C635.261 66.06 635.068 66.1033 634.908 66.19C634.748 66.27 634.618 66.38 634.518 66.52C634.418 66.66 634.344 66.8267 634.298 67.02C634.251 67.2133 634.228 67.42 634.228 67.64V69.36C634.228 69.5933 634.248 69.8067 634.288 70C634.328 70.1933 634.398 70.36 634.498 70.5C634.598 70.64 634.728 70.75 634.888 70.83C635.048 70.9033 635.248 70.94 635.488 70.94Z"
    fill="#5078E1"
  /><path
    d="M640.771 68.5C640.771 68.2733 640.854 68.0767 641.021 67.91C641.187 67.7433 641.384 67.66 641.611 67.66C641.837 67.66 642.034 67.7433 642.201 67.91C642.367 68.0767 642.451 68.2733 642.451 68.5C642.451 68.7267 642.367 68.9233 642.201 69.09C642.034 69.2567 641.837 69.34 641.611 69.34C641.384 69.34 641.187 69.2567 641.021 69.09C640.854 68.9233 640.771 68.7267 640.771 68.5ZM639.031 67.72C639.031 66.8133 639.257 66.11 639.711 65.61C640.171 65.11 640.804 64.86 641.611 64.86C642.417 64.86 643.047 65.11 643.501 65.61C643.961 66.11 644.191 66.8133 644.191 67.72V69.28C644.191 70.2133 643.961 70.9233 643.501 71.41C643.047 71.8967 642.417 72.14 641.611 72.14C640.804 72.14 640.171 71.8967 639.711 71.41C639.257 70.9233 639.031 70.2133 639.031 69.28V67.72ZM641.611 70.94C641.851 70.94 642.051 70.9033 642.211 70.83C642.371 70.75 642.501 70.64 642.601 70.5C642.701 70.36 642.771 70.1933 642.811 70C642.851 69.8067 642.871 69.5933 642.871 69.36V67.64C642.871 67.42 642.847 67.2133 642.801 67.02C642.754 66.8267 642.681 66.66 642.581 66.52C642.481 66.38 642.351 66.27 642.191 66.19C642.031 66.1033 641.837 66.06 641.611 66.06C641.384 66.06 641.191 66.1033 641.031 66.19C640.871 66.27 640.741 66.38 640.641 66.52C640.541 66.66 640.467 66.8267 640.421 67.02C640.374 67.2133 640.351 67.42 640.351 67.64V69.36C640.351 69.5933 640.371 69.8067 640.411 70C640.451 70.1933 640.521 70.36 640.621 70.5C640.721 70.64 640.851 70.75 641.011 70.83C641.171 70.9033 641.371 70.94 641.611 70.94Z"
    fill="#5078E1"
  /><path
    d="M646.894 68.5C646.894 68.2733 646.977 68.0767 647.144 67.91C647.31 67.7433 647.507 67.66 647.734 67.66C647.961 67.66 648.157 67.7433 648.324 67.91C648.49 68.0767 648.574 68.2733 648.574 68.5C648.574 68.7267 648.49 68.9233 648.324 69.09C648.157 69.2567 647.961 69.34 647.734 69.34C647.507 69.34 647.31 69.2567 647.144 69.09C646.977 68.9233 646.894 68.7267 646.894 68.5ZM645.154 67.72C645.154 66.8133 645.38 66.11 645.834 65.61C646.294 65.11 646.927 64.86 647.734 64.86C648.541 64.86 649.17 65.11 649.624 65.61C650.084 66.11 650.314 66.8133 650.314 67.72V69.28C650.314 70.2133 650.084 70.9233 649.624 71.41C649.17 71.8967 648.541 72.14 647.734 72.14C646.927 72.14 646.294 71.8967 645.834 71.41C645.38 70.9233 645.154 70.2133 645.154 69.28V67.72ZM647.734 70.94C647.974 70.94 648.174 70.9033 648.334 70.83C648.494 70.75 648.624 70.64 648.724 70.5C648.824 70.36 648.894 70.1933 648.934 70C648.974 69.8067 648.994 69.5933 648.994 69.36V67.64C648.994 67.42 648.971 67.2133 648.924 67.02C648.877 66.8267 648.804 66.66 648.704 66.52C648.604 66.38 648.474 66.27 648.314 66.19C648.154 66.1033 647.961 66.06 647.734 66.06C647.507 66.06 647.314 66.1033 647.154 66.19C646.994 66.27 646.864 66.38 646.764 66.52C646.664 66.66 646.591 66.8267 646.544 67.02C646.497 67.2133 646.474 67.42 646.474 67.64V69.36C646.474 69.5933 646.494 69.8067 646.534 70C646.574 70.1933 646.644 70.36 646.744 70.5C646.844 70.64 646.974 70.75 647.134 70.83C647.294 70.9033 647.494 70.94 647.734 70.94Z"
    fill="#5078E1"
  /><path
    d="M653.437 70.74V65.66H653.257L652.597 68.06H651.337V67.94L652.177 65H654.757V70.74H656.497V72H651.457V70.74H653.437Z"
    fill="#5078E1"
  /><path
    d="M659.56 70.74V65.66H659.38L658.72 68.06H657.46V67.94L658.3 65H660.88V70.74H662.62V72H657.58V70.74H659.56Z"
    fill="#5078E1"
  /><path
    d="M665.263 68.5C665.263 68.2733 665.346 68.0767 665.513 67.91C665.68 67.7433 665.876 67.66 666.103 67.66C666.33 67.66 666.526 67.7433 666.693 67.91C666.86 68.0767 666.943 68.2733 666.943 68.5C666.943 68.7267 666.86 68.9233 666.693 69.09C666.526 69.2567 666.33 69.34 666.103 69.34C665.876 69.34 665.68 69.2567 665.513 69.09C665.346 68.9233 665.263 68.7267 665.263 68.5ZM663.523 67.72C663.523 66.8133 663.75 66.11 664.203 65.61C664.663 65.11 665.296 64.86 666.103 64.86C666.91 64.86 667.54 65.11 667.993 65.61C668.453 66.11 668.683 66.8133 668.683 67.72V69.28C668.683 70.2133 668.453 70.9233 667.993 71.41C667.54 71.8967 666.91 72.14 666.103 72.14C665.296 72.14 664.663 71.8967 664.203 71.41C663.75 70.9233 663.523 70.2133 663.523 69.28V67.72ZM666.103 70.94C666.343 70.94 666.543 70.9033 666.703 70.83C666.863 70.75 666.993 70.64 667.093 70.5C667.193 70.36 667.263 70.1933 667.303 70C667.343 69.8067 667.363 69.5933 667.363 69.36V67.64C667.363 67.42 667.34 67.2133 667.293 67.02C667.246 66.8267 667.173 66.66 667.073 66.52C666.973 66.38 666.843 66.27 666.683 66.19C666.523 66.1033 666.33 66.06 666.103 66.06C665.876 66.06 665.683 66.1033 665.523 66.19C665.363 66.27 665.233 66.38 665.133 66.52C665.033 66.66 664.96 66.8267 664.913 67.02C664.866 67.2133 664.843 67.42 664.843 67.64V69.36C664.843 69.5933 664.863 69.8067 664.903 70C664.943 70.1933 665.013 70.36 665.113 70.5C665.213 70.64 665.343 70.75 665.503 70.83C665.663 70.9033 665.863 70.94 666.103 70.94Z"
    fill="#5078E1"
  /><path
    d="M16.22 85.5C16.22 85.2733 16.3033 85.0767 16.47 84.91C16.6367 84.7433 16.8333 84.66 17.06 84.66C17.2867 84.66 17.4833 84.7433 17.65 84.91C17.8167 85.0767 17.9 85.2733 17.9 85.5C17.9 85.7267 17.8167 85.9233 17.65 86.09C17.4833 86.2567 17.2867 86.34 17.06 86.34C16.8333 86.34 16.6367 86.2567 16.47 86.09C16.3033 85.9233 16.22 85.7267 16.22 85.5ZM14.48 84.72C14.48 83.8133 14.7067 83.11 15.16 82.61C15.62 82.11 16.2533 81.86 17.06 81.86C17.8667 81.86 18.4967 82.11 18.95 82.61C19.41 83.11 19.64 83.8133 19.64 84.72V86.28C19.64 87.2133 19.41 87.9233 18.95 88.41C18.4967 88.8967 17.8667 89.14 17.06 89.14C16.2533 89.14 15.62 88.8967 15.16 88.41C14.7067 87.9233 14.48 87.2133 14.48 86.28V84.72ZM17.06 87.94C17.3 87.94 17.5 87.9033 17.66 87.83C17.82 87.75 17.95 87.64 18.05 87.5C18.15 87.36 18.22 87.1933 18.26 87C18.3 86.8067 18.32 86.5933 18.32 86.36V84.64C18.32 84.42 18.2967 84.2133 18.25 84.02C18.2033 83.8267 18.13 83.66 18.03 83.52C17.93 83.38 17.8 83.27 17.64 83.19C17.48 83.1033 17.2867 83.06 17.06 83.06C16.8333 83.06 16.64 83.1033 16.48 83.19C16.32 83.27 16.19 83.38 16.09 83.52C15.99 83.66 15.9167 83.8267 15.87 84.02C15.8233 84.2133 15.8 84.42 15.8 84.64V86.36C15.8 86.5933 15.82 86.8067 15.86 87C15.9 87.1933 15.97 87.36 16.07 87.5C16.17 87.64 16.3 87.75 16.46 87.83C16.62 87.9033 16.82 87.94 17.06 87.94Z"
    fill="#5078E1"
  /><path
    d="M22.343 85.5C22.343 85.2733 22.4264 85.0767 22.593 84.91C22.7597 84.7433 22.9564 84.66 23.183 84.66C23.4097 84.66 23.6064 84.7433 23.773 84.91C23.9397 85.0767 24.023 85.2733 24.023 85.5C24.023 85.7267 23.9397 85.9233 23.773 86.09C23.6064 86.2567 23.4097 86.34 23.183 86.34C22.9564 86.34 22.7597 86.2567 22.593 86.09C22.4264 85.9233 22.343 85.7267 22.343 85.5ZM20.603 84.72C20.603 83.8133 20.8297 83.11 21.283 82.61C21.743 82.11 22.3764 81.86 23.183 81.86C23.9897 81.86 24.6197 82.11 25.073 82.61C25.533 83.11 25.763 83.8133 25.763 84.72V86.28C25.763 87.2133 25.533 87.9233 25.073 88.41C24.6197 88.8967 23.9897 89.14 23.183 89.14C22.3764 89.14 21.743 88.8967 21.283 88.41C20.8297 87.9233 20.603 87.2133 20.603 86.28V84.72ZM23.183 87.94C23.423 87.94 23.623 87.9033 23.783 87.83C23.943 87.75 24.073 87.64 24.173 87.5C24.273 87.36 24.343 87.1933 24.383 87C24.423 86.8067 24.443 86.5933 24.443 86.36V84.64C24.443 84.42 24.4197 84.2133 24.373 84.02C24.3264 83.8267 24.253 83.66 24.153 83.52C24.053 83.38 23.923 83.27 23.763 83.19C23.603 83.1033 23.4097 83.06 23.183 83.06C22.9564 83.06 22.763 83.1033 22.603 83.19C22.443 83.27 22.313 83.38 22.213 83.52C22.113 83.66 22.0397 83.8267 21.993 84.02C21.9464 84.2133 21.923 84.42 21.923 84.64V86.36C21.923 86.5933 21.943 86.8067 21.983 87C22.023 87.1933 22.093 87.36 22.193 87.5C22.293 87.64 22.423 87.75 22.583 87.83C22.743 87.9033 22.943 87.94 23.183 87.94Z"
    fill="#5078E1"
  /><path
    d="M28.8861 87.74V82.66H28.7061L28.0461 85.06H26.7861V84.94L27.6261 82H30.2061V87.74H31.9461V89H26.9061V87.74H28.8861Z"
    fill="#5078E1"
  /><path
    d="M34.5891 85.5C34.5891 85.2733 34.6725 85.0767 34.8391 84.91C35.0058 84.7433 35.2025 84.66 35.4291 84.66C35.6558 84.66 35.8525 84.7433 36.0191 84.91C36.1858 85.0767 36.2691 85.2733 36.2691 85.5C36.2691 85.7267 36.1858 85.9233 36.0191 86.09C35.8525 86.2567 35.6558 86.34 35.4291 86.34C35.2025 86.34 35.0058 86.2567 34.8391 86.09C34.6725 85.9233 34.5891 85.7267 34.5891 85.5ZM32.8491 84.72C32.8491 83.8133 33.0758 83.11 33.5291 82.61C33.9891 82.11 34.6225 81.86 35.4291 81.86C36.2358 81.86 36.8658 82.11 37.3191 82.61C37.7791 83.11 38.0091 83.8133 38.0091 84.72V86.28C38.0091 87.2133 37.7791 87.9233 37.3191 88.41C36.8658 88.8967 36.2358 89.14 35.4291 89.14C34.6225 89.14 33.9891 88.8967 33.5291 88.41C33.0758 87.9233 32.8491 87.2133 32.8491 86.28V84.72ZM35.4291 87.94C35.6691 87.94 35.8691 87.9033 36.0291 87.83C36.1891 87.75 36.3191 87.64 36.4191 87.5C36.5191 87.36 36.5891 87.1933 36.6291 87C36.6691 86.8067 36.6891 86.5933 36.6891 86.36V84.64C36.6891 84.42 36.6658 84.2133 36.6191 84.02C36.5725 83.8267 36.4991 83.66 36.3991 83.52C36.2991 83.38 36.1691 83.27 36.0091 83.19C35.8491 83.1033 35.6558 83.06 35.4291 83.06C35.2025 83.06 35.0091 83.1033 34.8491 83.19C34.6891 83.27 34.5591 83.38 34.4591 83.52C34.3591 83.66 34.2858 83.8267 34.2391 84.02C34.1925 84.2133 34.1691 84.42 34.1691 84.64V86.36C34.1691 86.5933 34.1891 86.8067 34.2291 87C34.2691 87.1933 34.3391 87.36 34.4391 87.5C34.5391 87.64 34.6691 87.75 34.8291 87.83C34.9891 87.9033 35.1891 87.94 35.4291 87.94Z"
    fill="#5078E1"
  /><path
    d="M40.7122 85.5C40.7122 85.2733 40.7955 85.0767 40.9622 84.91C41.1288 84.7433 41.3255 84.66 41.5522 84.66C41.7788 84.66 41.9755 84.7433 42.1422 84.91C42.3089 85.0767 42.3922 85.2733 42.3922 85.5C42.3922 85.7267 42.3089 85.9233 42.1422 86.09C41.9755 86.2567 41.7788 86.34 41.5522 86.34C41.3255 86.34 41.1288 86.2567 40.9622 86.09C40.7955 85.9233 40.7122 85.7267 40.7122 85.5ZM38.9722 84.72C38.9722 83.8133 39.1989 83.11 39.6522 82.61C40.1122 82.11 40.7455 81.86 41.5522 81.86C42.3588 81.86 42.9889 82.11 43.4422 82.61C43.9022 83.11 44.1322 83.8133 44.1322 84.72V86.28C44.1322 87.2133 43.9022 87.9233 43.4422 88.41C42.9889 88.8967 42.3588 89.14 41.5522 89.14C40.7455 89.14 40.1122 88.8967 39.6522 88.41C39.1989 87.9233 38.9722 87.2133 38.9722 86.28V84.72ZM41.5522 87.94C41.7922 87.94 41.9922 87.9033 42.1522 87.83C42.3122 87.75 42.4422 87.64 42.5422 87.5C42.6422 87.36 42.7122 87.1933 42.7522 87C42.7922 86.8067 42.8122 86.5933 42.8122 86.36V84.64C42.8122 84.42 42.7889 84.2133 42.7422 84.02C42.6955 83.8267 42.6222 83.66 42.5222 83.52C42.4222 83.38 42.2922 83.27 42.1322 83.19C41.9722 83.1033 41.7788 83.06 41.5522 83.06C41.3255 83.06 41.1322 83.1033 40.9722 83.19C40.8122 83.27 40.6822 83.38 40.5822 83.52C40.4822 83.66 40.4088 83.8267 40.3622 84.02C40.3155 84.2133 40.2922 84.42 40.2922 84.64V86.36C40.2922 86.5933 40.3122 86.8067 40.3522 87C40.3922 87.1933 40.4622 87.36 40.5622 87.5C40.6622 87.64 40.7922 87.75 40.9522 87.83C41.1122 87.9033 41.3122 87.94 41.5522 87.94Z"
    fill="#5078E1"
  /><path
    d="M47.2552 87.74V82.66H47.0752L46.4152 85.06H45.1552V84.94L45.9952 82H48.5752V87.74H50.3152V89H45.2752V87.74H47.2552Z"
    fill="#5078E1"
  /><path
    d="M53.3783 87.74V82.66H53.1983L52.5383 85.06H51.2783V84.94L52.1183 82H54.6983V87.74H56.4383V89H51.3983V87.74H53.3783Z"
    fill="#5078E1"
  /><path
    d="M59.5013 87.74V82.66H59.3213L58.6613 85.06H57.4013V84.94L58.2413 82H60.8213V87.74H62.5613V89H57.5213V87.74H59.5013Z"
    fill="#5078E1"
  /><path
    d="M65.6244 87.74V82.66H65.4444L64.7844 85.06H63.5244V84.94L64.3644 82H66.9444V87.74H68.6844V89H63.6444V87.74H65.6244Z"
    fill="#5078E1"
  /><path
    d="M71.3274 85.5C71.3274 85.2733 71.4108 85.0767 71.5774 84.91C71.7441 84.7433 71.9408 84.66 72.1674 84.66C72.3941 84.66 72.5908 84.7433 72.7574 84.91C72.9241 85.0767 73.0074 85.2733 73.0074 85.5C73.0074 85.7267 72.9241 85.9233 72.7574 86.09C72.5908 86.2567 72.3941 86.34 72.1674 86.34C71.9408 86.34 71.7441 86.2567 71.5774 86.09C71.4108 85.9233 71.3274 85.7267 71.3274 85.5ZM69.5874 84.72C69.5874 83.8133 69.8141 83.11 70.2674 82.61C70.7274 82.11 71.3608 81.86 72.1674 81.86C72.9741 81.86 73.6041 82.11 74.0574 82.61C74.5174 83.11 74.7474 83.8133 74.7474 84.72V86.28C74.7474 87.2133 74.5174 87.9233 74.0574 88.41C73.6041 88.8967 72.9741 89.14 72.1674 89.14C71.3608 89.14 70.7274 88.8967 70.2674 88.41C69.8141 87.9233 69.5874 87.2133 69.5874 86.28V84.72ZM72.1674 87.94C72.4074 87.94 72.6074 87.9033 72.7674 87.83C72.9274 87.75 73.0574 87.64 73.1574 87.5C73.2574 87.36 73.3274 87.1933 73.3674 87C73.4074 86.8067 73.4274 86.5933 73.4274 86.36V84.64C73.4274 84.42 73.4041 84.2133 73.3574 84.02C73.3108 83.8267 73.2374 83.66 73.1374 83.52C73.0374 83.38 72.9074 83.27 72.7474 83.19C72.5874 83.1033 72.3941 83.06 72.1674 83.06C71.9408 83.06 71.7474 83.1033 71.5874 83.19C71.4274 83.27 71.2974 83.38 71.1974 83.52C71.0974 83.66 71.0241 83.8267 70.9774 84.02C70.9308 84.2133 70.9074 84.42 70.9074 84.64V86.36C70.9074 86.5933 70.9274 86.8067 70.9674 87C71.0074 87.1933 71.0774 87.36 71.1774 87.5C71.2774 87.64 71.4074 87.75 71.5674 87.83C71.7274 87.9033 71.9274 87.94 72.1674 87.94Z"
    fill="#5078E1"
  /><path
    d="M77.4505 85.5C77.4505 85.2733 77.5338 85.0767 77.7005 84.91C77.8671 84.7433 78.0638 84.66 78.2905 84.66C78.5171 84.66 78.7138 84.7433 78.8805 84.91C79.0471 85.0767 79.1305 85.2733 79.1305 85.5C79.1305 85.7267 79.0471 85.9233 78.8805 86.09C78.7138 86.2567 78.5171 86.34 78.2905 86.34C78.0638 86.34 77.8671 86.2567 77.7005 86.09C77.5338 85.9233 77.4505 85.7267 77.4505 85.5ZM75.7105 84.72C75.7105 83.8133 75.9371 83.11 76.3905 82.61C76.8505 82.11 77.4838 81.86 78.2905 81.86C79.0971 81.86 79.7271 82.11 80.1805 82.61C80.6405 83.11 80.8705 83.8133 80.8705 84.72V86.28C80.8705 87.2133 80.6405 87.9233 80.1805 88.41C79.7271 88.8967 79.0971 89.14 78.2905 89.14C77.4838 89.14 76.8505 88.8967 76.3905 88.41C75.9371 87.9233 75.7105 87.2133 75.7105 86.28V84.72ZM78.2905 87.94C78.5305 87.94 78.7305 87.9033 78.8905 87.83C79.0505 87.75 79.1805 87.64 79.2805 87.5C79.3805 87.36 79.4505 87.1933 79.4905 87C79.5305 86.8067 79.5505 86.5933 79.5505 86.36V84.64C79.5505 84.42 79.5271 84.2133 79.4805 84.02C79.4338 83.8267 79.3605 83.66 79.2605 83.52C79.1605 83.38 79.0305 83.27 78.8705 83.19C78.7105 83.1033 78.5171 83.06 78.2905 83.06C78.0638 83.06 77.8705 83.1033 77.7105 83.19C77.5505 83.27 77.4205 83.38 77.3205 83.52C77.2205 83.66 77.1471 83.8267 77.1005 84.02C77.0538 84.2133 77.0305 84.42 77.0305 84.64V86.36C77.0305 86.5933 77.0505 86.8067 77.0905 87C77.1305 87.1933 77.2005 87.36 77.3005 87.5C77.4005 87.64 77.5305 87.75 77.6905 87.83C77.8505 87.9033 78.0505 87.94 78.2905 87.94Z"
    fill="#5078E1"
  /><path
    d="M83.9935 87.74V82.66H83.8135L83.1535 85.06H81.8935V84.94L82.7335 82H85.3135V87.74H87.0535V89H82.0135V87.74H83.9935Z"
    fill="#5078E1"
  /><path
    d="M89.6966 85.5C89.6966 85.2733 89.7799 85.0767 89.9466 84.91C90.1132 84.7433 90.3099 84.66 90.5366 84.66C90.7632 84.66 90.9599 84.7433 91.1266 84.91C91.2932 85.0767 91.3766 85.2733 91.3766 85.5C91.3766 85.7267 91.2932 85.9233 91.1266 86.09C90.9599 86.2567 90.7632 86.34 90.5366 86.34C90.3099 86.34 90.1132 86.2567 89.9466 86.09C89.7799 85.9233 89.6966 85.7267 89.6966 85.5ZM87.9566 84.72C87.9566 83.8133 88.1832 83.11 88.6366 82.61C89.0966 82.11 89.7299 81.86 90.5366 81.86C91.3432 81.86 91.9732 82.11 92.4266 82.61C92.8866 83.11 93.1166 83.8133 93.1166 84.72V86.28C93.1166 87.2133 92.8866 87.9233 92.4266 88.41C91.9732 88.8967 91.3432 89.14 90.5366 89.14C89.7299 89.14 89.0966 88.8967 88.6366 88.41C88.1832 87.9233 87.9566 87.2133 87.9566 86.28V84.72ZM90.5366 87.94C90.7766 87.94 90.9766 87.9033 91.1366 87.83C91.2966 87.75 91.4266 87.64 91.5266 87.5C91.6266 87.36 91.6966 87.1933 91.7366 87C91.7766 86.8067 91.7966 86.5933 91.7966 86.36V84.64C91.7966 84.42 91.7732 84.2133 91.7266 84.02C91.6799 83.8267 91.6066 83.66 91.5066 83.52C91.4066 83.38 91.2766 83.27 91.1166 83.19C90.9566 83.1033 90.7632 83.06 90.5366 83.06C90.3099 83.06 90.1166 83.1033 89.9566 83.19C89.7966 83.27 89.6666 83.38 89.5666 83.52C89.4666 83.66 89.3932 83.8267 89.3466 84.02C89.2999 84.2133 89.2766 84.42 89.2766 84.64V86.36C89.2766 86.5933 89.2966 86.8067 89.3366 87C89.3766 87.1933 89.4466 87.36 89.5466 87.5C89.6466 87.64 89.7766 87.75 89.9366 87.83C90.0966 87.9033 90.2966 87.94 90.5366 87.94Z"
    fill="#5078E1"
  /><path
    d="M95.8196 85.5C95.8196 85.2733 95.9029 85.0767 96.0696 84.91C96.2363 84.7433 96.4329 84.66 96.6596 84.66C96.8863 84.66 97.0829 84.7433 97.2496 84.91C97.4163 85.0767 97.4996 85.2733 97.4996 85.5C97.4996 85.7267 97.4163 85.9233 97.2496 86.09C97.0829 86.2567 96.8863 86.34 96.6596 86.34C96.4329 86.34 96.2363 86.2567 96.0696 86.09C95.9029 85.9233 95.8196 85.7267 95.8196 85.5ZM94.0796 84.72C94.0796 83.8133 94.3063 83.11 94.7596 82.61C95.2196 82.11 95.8529 81.86 96.6596 81.86C97.4663 81.86 98.0963 82.11 98.5496 82.61C99.0096 83.11 99.2396 83.8133 99.2396 84.72V86.28C99.2396 87.2133 99.0096 87.9233 98.5496 88.41C98.0963 88.8967 97.4663 89.14 96.6596 89.14C95.8529 89.14 95.2196 88.8967 94.7596 88.41C94.3063 87.9233 94.0796 87.2133 94.0796 86.28V84.72ZM96.6596 87.94C96.8996 87.94 97.0996 87.9033 97.2596 87.83C97.4196 87.75 97.5496 87.64 97.6496 87.5C97.7496 87.36 97.8196 87.1933 97.8596 87C97.8996 86.8067 97.9196 86.5933 97.9196 86.36V84.64C97.9196 84.42 97.8963 84.2133 97.8496 84.02C97.8029 83.8267 97.7296 83.66 97.6296 83.52C97.5296 83.38 97.3996 83.27 97.2396 83.19C97.0796 83.1033 96.8863 83.06 96.6596 83.06C96.4329 83.06 96.2396 83.1033 96.0796 83.19C95.9196 83.27 95.7896 83.38 95.6896 83.52C95.5896 83.66 95.5163 83.8267 95.4696 84.02C95.4229 84.2133 95.3996 84.42 95.3996 84.64V86.36C95.3996 86.5933 95.4196 86.8067 95.4596 87C95.4996 87.1933 95.5696 87.36 95.6696 87.5C95.7696 87.64 95.8996 87.75 96.0596 87.83C96.2196 87.9033 96.4196 87.94 96.6596 87.94Z"
    fill="#5078E1"
  /><path
    d="M102.363 87.74V82.66H102.183L101.523 85.06H100.263V84.94L101.103 82H103.683V87.74H105.423V89H100.383V87.74H102.363Z"
    fill="#5078E1"
  /><path
    d="M108.066 85.5C108.066 85.2733 108.149 85.0767 108.316 84.91C108.482 84.7433 108.679 84.66 108.906 84.66C109.132 84.66 109.329 84.7433 109.496 84.91C109.662 85.0767 109.746 85.2733 109.746 85.5C109.746 85.7267 109.662 85.9233 109.496 86.09C109.329 86.2567 109.132 86.34 108.906 86.34C108.679 86.34 108.482 86.2567 108.316 86.09C108.149 85.9233 108.066 85.7267 108.066 85.5ZM106.326 84.72C106.326 83.8133 106.552 83.11 107.006 82.61C107.466 82.11 108.099 81.86 108.906 81.86C109.712 81.86 110.342 82.11 110.796 82.61C111.256 83.11 111.486 83.8133 111.486 84.72V86.28C111.486 87.2133 111.256 87.9233 110.796 88.41C110.342 88.8967 109.712 89.14 108.906 89.14C108.099 89.14 107.466 88.8967 107.006 88.41C106.552 87.9233 106.326 87.2133 106.326 86.28V84.72ZM108.906 87.94C109.146 87.94 109.346 87.9033 109.506 87.83C109.666 87.75 109.796 87.64 109.896 87.5C109.996 87.36 110.066 87.1933 110.106 87C110.146 86.8067 110.166 86.5933 110.166 86.36V84.64C110.166 84.42 110.142 84.2133 110.096 84.02C110.049 83.8267 109.976 83.66 109.876 83.52C109.776 83.38 109.646 83.27 109.486 83.19C109.326 83.1033 109.132 83.06 108.906 83.06C108.679 83.06 108.486 83.1033 108.326 83.19C108.166 83.27 108.036 83.38 107.936 83.52C107.836 83.66 107.762 83.8267 107.716 84.02C107.669 84.2133 107.646 84.42 107.646 84.64V86.36C107.646 86.5933 107.666 86.8067 107.706 87C107.746 87.1933 107.816 87.36 107.916 87.5C108.016 87.64 108.146 87.75 108.306 87.83C108.466 87.9033 108.666 87.94 108.906 87.94Z"
    fill="#5078E1"
  /><path
    d="M114.189 85.5C114.189 85.2733 114.272 85.0767 114.439 84.91C114.605 84.7433 114.802 84.66 115.029 84.66C115.255 84.66 115.452 84.7433 115.619 84.91C115.785 85.0767 115.869 85.2733 115.869 85.5C115.869 85.7267 115.785 85.9233 115.619 86.09C115.452 86.2567 115.255 86.34 115.029 86.34C114.802 86.34 114.605 86.2567 114.439 86.09C114.272 85.9233 114.189 85.7267 114.189 85.5ZM112.449 84.72C112.449 83.8133 112.675 83.11 113.129 82.61C113.589 82.11 114.222 81.86 115.029 81.86C115.835 81.86 116.465 82.11 116.919 82.61C117.379 83.11 117.609 83.8133 117.609 84.72V86.28C117.609 87.2133 117.379 87.9233 116.919 88.41C116.465 88.8967 115.835 89.14 115.029 89.14C114.222 89.14 113.589 88.8967 113.129 88.41C112.675 87.9233 112.449 87.2133 112.449 86.28V84.72ZM115.029 87.94C115.269 87.94 115.469 87.9033 115.629 87.83C115.789 87.75 115.919 87.64 116.019 87.5C116.119 87.36 116.189 87.1933 116.229 87C116.269 86.8067 116.289 86.5933 116.289 86.36V84.64C116.289 84.42 116.265 84.2133 116.219 84.02C116.172 83.8267 116.099 83.66 115.999 83.52C115.899 83.38 115.769 83.27 115.609 83.19C115.449 83.1033 115.255 83.06 115.029 83.06C114.802 83.06 114.609 83.1033 114.449 83.19C114.289 83.27 114.159 83.38 114.059 83.52C113.959 83.66 113.885 83.8267 113.839 84.02C113.792 84.2133 113.769 84.42 113.769 84.64V86.36C113.769 86.5933 113.789 86.8067 113.829 87C113.869 87.1933 113.939 87.36 114.039 87.5C114.139 87.64 114.269 87.75 114.429 87.83C114.589 87.9033 114.789 87.94 115.029 87.94Z"
    fill="#5078E1"
  /><path
    d="M120.312 85.5C120.312 85.2733 120.395 85.0767 120.562 84.91C120.728 84.7433 120.925 84.66 121.152 84.66C121.378 84.66 121.575 84.7433 121.742 84.91C121.908 85.0767 121.992 85.2733 121.992 85.5C121.992 85.7267 121.908 85.9233 121.742 86.09C121.575 86.2567 121.378 86.34 121.152 86.34C120.925 86.34 120.728 86.2567 120.562 86.09C120.395 85.9233 120.312 85.7267 120.312 85.5ZM118.572 84.72C118.572 83.8133 118.798 83.11 119.252 82.61C119.712 82.11 120.345 81.86 121.152 81.86C121.958 81.86 122.588 82.11 123.042 82.61C123.502 83.11 123.732 83.8133 123.732 84.72V86.28C123.732 87.2133 123.502 87.9233 123.042 88.41C122.588 88.8967 121.958 89.14 121.152 89.14C120.345 89.14 119.712 88.8967 119.252 88.41C118.798 87.9233 118.572 87.2133 118.572 86.28V84.72ZM121.152 87.94C121.392 87.94 121.592 87.9033 121.752 87.83C121.912 87.75 122.042 87.64 122.142 87.5C122.242 87.36 122.312 87.1933 122.352 87C122.392 86.8067 122.412 86.5933 122.412 86.36V84.64C122.412 84.42 122.388 84.2133 122.342 84.02C122.295 83.8267 122.222 83.66 122.122 83.52C122.022 83.38 121.892 83.27 121.732 83.19C121.572 83.1033 121.378 83.06 121.152 83.06C120.925 83.06 120.732 83.1033 120.572 83.19C120.412 83.27 120.282 83.38 120.182 83.52C120.082 83.66 120.008 83.8267 119.962 84.02C119.915 84.2133 119.892 84.42 119.892 84.64V86.36C119.892 86.5933 119.912 86.8067 119.952 87C119.992 87.1933 120.062 87.36 120.162 87.5C120.262 87.64 120.392 87.75 120.552 87.83C120.712 87.9033 120.912 87.94 121.152 87.94Z"
    fill="#5078E1"
  /><path
    d="M126.435 85.5C126.435 85.2733 126.518 85.0767 126.685 84.91C126.852 84.7433 127.048 84.66 127.275 84.66C127.502 84.66 127.698 84.7433 127.865 84.91C128.032 85.0767 128.115 85.2733 128.115 85.5C128.115 85.7267 128.032 85.9233 127.865 86.09C127.698 86.2567 127.502 86.34 127.275 86.34C127.048 86.34 126.852 86.2567 126.685 86.09C126.518 85.9233 126.435 85.7267 126.435 85.5ZM124.695 84.72C124.695 83.8133 124.922 83.11 125.375 82.61C125.835 82.11 126.468 81.86 127.275 81.86C128.082 81.86 128.712 82.11 129.165 82.61C129.625 83.11 129.855 83.8133 129.855 84.72V86.28C129.855 87.2133 129.625 87.9233 129.165 88.41C128.712 88.8967 128.082 89.14 127.275 89.14C126.468 89.14 125.835 88.8967 125.375 88.41C124.922 87.9233 124.695 87.2133 124.695 86.28V84.72ZM127.275 87.94C127.515 87.94 127.715 87.9033 127.875 87.83C128.035 87.75 128.165 87.64 128.265 87.5C128.365 87.36 128.435 87.1933 128.475 87C128.515 86.8067 128.535 86.5933 128.535 86.36V84.64C128.535 84.42 128.512 84.2133 128.465 84.02C128.418 83.8267 128.345 83.66 128.245 83.52C128.145 83.38 128.015 83.27 127.855 83.19C127.695 83.1033 127.502 83.06 127.275 83.06C127.048 83.06 126.855 83.1033 126.695 83.19C126.535 83.27 126.405 83.38 126.305 83.52C126.205 83.66 126.132 83.8267 126.085 84.02C126.038 84.2133 126.015 84.42 126.015 84.64V86.36C126.015 86.5933 126.035 86.8067 126.075 87C126.115 87.1933 126.185 87.36 126.285 87.5C126.385 87.64 126.515 87.75 126.675 87.83C126.835 87.9033 127.035 87.94 127.275 87.94Z"
    fill="#5078E1"
  /><path
    d="M132.558 85.5C132.558 85.2733 132.641 85.0767 132.808 84.91C132.975 84.7433 133.171 84.66 133.398 84.66C133.625 84.66 133.821 84.7433 133.988 84.91C134.155 85.0767 134.238 85.2733 134.238 85.5C134.238 85.7267 134.155 85.9233 133.988 86.09C133.821 86.2567 133.625 86.34 133.398 86.34C133.171 86.34 132.975 86.2567 132.808 86.09C132.641 85.9233 132.558 85.7267 132.558 85.5ZM130.818 84.72C130.818 83.8133 131.045 83.11 131.498 82.61C131.958 82.11 132.591 81.86 133.398 81.86C134.205 81.86 134.835 82.11 135.288 82.61C135.748 83.11 135.978 83.8133 135.978 84.72V86.28C135.978 87.2133 135.748 87.9233 135.288 88.41C134.835 88.8967 134.205 89.14 133.398 89.14C132.591 89.14 131.958 88.8967 131.498 88.41C131.045 87.9233 130.818 87.2133 130.818 86.28V84.72ZM133.398 87.94C133.638 87.94 133.838 87.9033 133.998 87.83C134.158 87.75 134.288 87.64 134.388 87.5C134.488 87.36 134.558 87.1933 134.598 87C134.638 86.8067 134.658 86.5933 134.658 86.36V84.64C134.658 84.42 134.635 84.2133 134.588 84.02C134.541 83.8267 134.468 83.66 134.368 83.52C134.268 83.38 134.138 83.27 133.978 83.19C133.818 83.1033 133.625 83.06 133.398 83.06C133.171 83.06 132.978 83.1033 132.818 83.19C132.658 83.27 132.528 83.38 132.428 83.52C132.328 83.66 132.255 83.8267 132.208 84.02C132.161 84.2133 132.138 84.42 132.138 84.64V86.36C132.138 86.5933 132.158 86.8067 132.198 87C132.238 87.1933 132.308 87.36 132.408 87.5C132.508 87.64 132.638 87.75 132.798 87.83C132.958 87.9033 133.158 87.94 133.398 87.94Z"
    fill="#5078E1"
  /><path
    d="M138.681 85.5C138.681 85.2733 138.764 85.0767 138.931 84.91C139.098 84.7433 139.294 84.66 139.521 84.66C139.748 84.66 139.944 84.7433 140.111 84.91C140.278 85.0767 140.361 85.2733 140.361 85.5C140.361 85.7267 140.278 85.9233 140.111 86.09C139.944 86.2567 139.748 86.34 139.521 86.34C139.294 86.34 139.098 86.2567 138.931 86.09C138.764 85.9233 138.681 85.7267 138.681 85.5ZM136.941 84.72C136.941 83.8133 137.168 83.11 137.621 82.61C138.081 82.11 138.714 81.86 139.521 81.86C140.328 81.86 140.958 82.11 141.411 82.61C141.871 83.11 142.101 83.8133 142.101 84.72V86.28C142.101 87.2133 141.871 87.9233 141.411 88.41C140.958 88.8967 140.328 89.14 139.521 89.14C138.714 89.14 138.081 88.8967 137.621 88.41C137.168 87.9233 136.941 87.2133 136.941 86.28V84.72ZM139.521 87.94C139.761 87.94 139.961 87.9033 140.121 87.83C140.281 87.75 140.411 87.64 140.511 87.5C140.611 87.36 140.681 87.1933 140.721 87C140.761 86.8067 140.781 86.5933 140.781 86.36V84.64C140.781 84.42 140.758 84.2133 140.711 84.02C140.664 83.8267 140.591 83.66 140.491 83.52C140.391 83.38 140.261 83.27 140.101 83.19C139.941 83.1033 139.748 83.06 139.521 83.06C139.294 83.06 139.101 83.1033 138.941 83.19C138.781 83.27 138.651 83.38 138.551 83.52C138.451 83.66 138.378 83.8267 138.331 84.02C138.284 84.2133 138.261 84.42 138.261 84.64V86.36C138.261 86.5933 138.281 86.8067 138.321 87C138.361 87.1933 138.431 87.36 138.531 87.5C138.631 87.64 138.761 87.75 138.921 87.83C139.081 87.9033 139.281 87.94 139.521 87.94Z"
    fill="#5078E1"
  /><path
    d="M145.224 87.74V82.66H145.044L144.384 85.06H143.124V84.94L143.964 82H146.544V87.74H148.284V89H143.244V87.74H145.224Z"
    fill="#5078E1"
  /><path
    d="M151.347 87.74V82.66H151.167L150.507 85.06H149.247V84.94L150.087 82H152.667V87.74H154.407V89H149.367V87.74H151.347Z"
    fill="#5078E1"
  /><path
    d="M157.47 87.74V82.66H157.29L156.63 85.06H155.37V84.94L156.21 82H158.79V87.74H160.53V89H155.49V87.74H157.47Z"
    fill="#5078E1"
  /><path
    d="M163.173 85.5C163.173 85.2733 163.256 85.0767 163.423 84.91C163.59 84.7433 163.786 84.66 164.013 84.66C164.24 84.66 164.436 84.7433 164.603 84.91C164.77 85.0767 164.853 85.2733 164.853 85.5C164.853 85.7267 164.77 85.9233 164.603 86.09C164.436 86.2567 164.24 86.34 164.013 86.34C163.786 86.34 163.59 86.2567 163.423 86.09C163.256 85.9233 163.173 85.7267 163.173 85.5ZM161.433 84.72C161.433 83.8133 161.66 83.11 162.113 82.61C162.573 82.11 163.206 81.86 164.013 81.86C164.82 81.86 165.45 82.11 165.903 82.61C166.363 83.11 166.593 83.8133 166.593 84.72V86.28C166.593 87.2133 166.363 87.9233 165.903 88.41C165.45 88.8967 164.82 89.14 164.013 89.14C163.206 89.14 162.573 88.8967 162.113 88.41C161.66 87.9233 161.433 87.2133 161.433 86.28V84.72ZM164.013 87.94C164.253 87.94 164.453 87.9033 164.613 87.83C164.773 87.75 164.903 87.64 165.003 87.5C165.103 87.36 165.173 87.1933 165.213 87C165.253 86.8067 165.273 86.5933 165.273 86.36V84.64C165.273 84.42 165.25 84.2133 165.203 84.02C165.156 83.8267 165.083 83.66 164.983 83.52C164.883 83.38 164.753 83.27 164.593 83.19C164.433 83.1033 164.24 83.06 164.013 83.06C163.786 83.06 163.593 83.1033 163.433 83.19C163.273 83.27 163.143 83.38 163.043 83.52C162.943 83.66 162.87 83.8267 162.823 84.02C162.776 84.2133 162.753 84.42 162.753 84.64V86.36C162.753 86.5933 162.773 86.8067 162.813 87C162.853 87.1933 162.923 87.36 163.023 87.5C163.123 87.64 163.253 87.75 163.413 87.83C163.573 87.9033 163.773 87.94 164.013 87.94Z"
    fill="#5078E1"
  /><path
    d="M169.716 87.74V82.66H169.536L168.876 85.06H167.616V84.94L168.456 82H171.036V87.74H172.776V89H167.736V87.74H169.716Z"
    fill="#5078E1"
  /><path
    d="M175.419 85.5C175.419 85.2733 175.503 85.0767 175.669 84.91C175.836 84.7433 176.033 84.66 176.259 84.66C176.486 84.66 176.683 84.7433 176.849 84.91C177.016 85.0767 177.099 85.2733 177.099 85.5C177.099 85.7267 177.016 85.9233 176.849 86.09C176.683 86.2567 176.486 86.34 176.259 86.34C176.033 86.34 175.836 86.2567 175.669 86.09C175.503 85.9233 175.419 85.7267 175.419 85.5ZM173.679 84.72C173.679 83.8133 173.906 83.11 174.359 82.61C174.819 82.11 175.453 81.86 176.259 81.86C177.066 81.86 177.696 82.11 178.149 82.61C178.609 83.11 178.839 83.8133 178.839 84.72V86.28C178.839 87.2133 178.609 87.9233 178.149 88.41C177.696 88.8967 177.066 89.14 176.259 89.14C175.453 89.14 174.819 88.8967 174.359 88.41C173.906 87.9233 173.679 87.2133 173.679 86.28V84.72ZM176.259 87.94C176.499 87.94 176.699 87.9033 176.859 87.83C177.019 87.75 177.149 87.64 177.249 87.5C177.349 87.36 177.419 87.1933 177.459 87C177.499 86.8067 177.519 86.5933 177.519 86.36V84.64C177.519 84.42 177.496 84.2133 177.449 84.02C177.403 83.8267 177.329 83.66 177.229 83.52C177.129 83.38 176.999 83.27 176.839 83.19C176.679 83.1033 176.486 83.06 176.259 83.06C176.033 83.06 175.839 83.1033 175.679 83.19C175.519 83.27 175.389 83.38 175.289 83.52C175.189 83.66 175.116 83.8267 175.069 84.02C175.023 84.2133 174.999 84.42 174.999 84.64V86.36C174.999 86.5933 175.019 86.8067 175.059 87C175.099 87.1933 175.169 87.36 175.269 87.5C175.369 87.64 175.499 87.75 175.659 87.83C175.819 87.9033 176.019 87.94 176.259 87.94Z"
    fill="#5078E1"
  /><path
    d="M181.542 85.5C181.542 85.2733 181.626 85.0767 181.792 84.91C181.959 84.7433 182.156 84.66 182.382 84.66C182.609 84.66 182.806 84.7433 182.972 84.91C183.139 85.0767 183.222 85.2733 183.222 85.5C183.222 85.7267 183.139 85.9233 182.972 86.09C182.806 86.2567 182.609 86.34 182.382 86.34C182.156 86.34 181.959 86.2567 181.792 86.09C181.626 85.9233 181.542 85.7267 181.542 85.5ZM179.802 84.72C179.802 83.8133 180.029 83.11 180.482 82.61C180.942 82.11 181.576 81.86 182.382 81.86C183.189 81.86 183.819 82.11 184.272 82.61C184.732 83.11 184.962 83.8133 184.962 84.72V86.28C184.962 87.2133 184.732 87.9233 184.272 88.41C183.819 88.8967 183.189 89.14 182.382 89.14C181.576 89.14 180.942 88.8967 180.482 88.41C180.029 87.9233 179.802 87.2133 179.802 86.28V84.72ZM182.382 87.94C182.622 87.94 182.822 87.9033 182.982 87.83C183.142 87.75 183.272 87.64 183.372 87.5C183.472 87.36 183.542 87.1933 183.582 87C183.622 86.8067 183.642 86.5933 183.642 86.36V84.64C183.642 84.42 183.619 84.2133 183.572 84.02C183.526 83.8267 183.452 83.66 183.352 83.52C183.252 83.38 183.122 83.27 182.962 83.19C182.802 83.1033 182.609 83.06 182.382 83.06C182.156 83.06 181.962 83.1033 181.802 83.19C181.642 83.27 181.512 83.38 181.412 83.52C181.312 83.66 181.239 83.8267 181.192 84.02C181.146 84.2133 181.122 84.42 181.122 84.64V86.36C181.122 86.5933 181.142 86.8067 181.182 87C181.222 87.1933 181.292 87.36 181.392 87.5C181.492 87.64 181.622 87.75 181.782 87.83C181.942 87.9033 182.142 87.94 182.382 87.94Z"
    fill="#5078E1"
  /><path
    d="M187.665 85.5C187.665 85.2733 187.749 85.0767 187.915 84.91C188.082 84.7433 188.279 84.66 188.505 84.66C188.732 84.66 188.929 84.7433 189.095 84.91C189.262 85.0767 189.345 85.2733 189.345 85.5C189.345 85.7267 189.262 85.9233 189.095 86.09C188.929 86.2567 188.732 86.34 188.505 86.34C188.279 86.34 188.082 86.2567 187.915 86.09C187.749 85.9233 187.665 85.7267 187.665 85.5ZM185.925 84.72C185.925 83.8133 186.152 83.11 186.605 82.61C187.065 82.11 187.699 81.86 188.505 81.86C189.312 81.86 189.942 82.11 190.395 82.61C190.855 83.11 191.085 83.8133 191.085 84.72V86.28C191.085 87.2133 190.855 87.9233 190.395 88.41C189.942 88.8967 189.312 89.14 188.505 89.14C187.699 89.14 187.065 88.8967 186.605 88.41C186.152 87.9233 185.925 87.2133 185.925 86.28V84.72ZM188.505 87.94C188.745 87.94 188.945 87.9033 189.105 87.83C189.265 87.75 189.395 87.64 189.495 87.5C189.595 87.36 189.665 87.1933 189.705 87C189.745 86.8067 189.765 86.5933 189.765 86.36V84.64C189.765 84.42 189.742 84.2133 189.695 84.02C189.649 83.8267 189.575 83.66 189.475 83.52C189.375 83.38 189.245 83.27 189.085 83.19C188.925 83.1033 188.732 83.06 188.505 83.06C188.279 83.06 188.085 83.1033 187.925 83.19C187.765 83.27 187.635 83.38 187.535 83.52C187.435 83.66 187.362 83.8267 187.315 84.02C187.269 84.2133 187.245 84.42 187.245 84.64V86.36C187.245 86.5933 187.265 86.8067 187.305 87C187.345 87.1933 187.415 87.36 187.515 87.5C187.615 87.64 187.745 87.75 187.905 87.83C188.065 87.9033 188.265 87.94 188.505 87.94Z"
    fill="#5078E1"
  /><path
    d="M194.208 87.74V82.66H194.028L193.368 85.06H192.108V84.94L192.948 82H195.528V87.74H197.268V89H192.228V87.74H194.208Z"
    fill="#5078E1"
  /><path
    d="M200.331 87.74V82.66H200.151L199.491 85.06H198.231V84.94L199.071 82H201.651V87.74H203.391V89H198.351V87.74H200.331Z"
    fill="#5078E1"
  /><path
    d="M206.454 87.74V82.66H206.274L205.614 85.06H204.354V84.94L205.194 82H207.774V87.74H209.514V89H204.474V87.74H206.454Z"
    fill="#5078E1"
  /><path
    d="M212.158 85.5C212.158 85.2733 212.241 85.0767 212.408 84.91C212.574 84.7433 212.771 84.66 212.997 84.66C213.224 84.66 213.421 84.7433 213.587 84.91C213.754 85.0767 213.837 85.2733 213.837 85.5C213.837 85.7267 213.754 85.9233 213.587 86.09C213.421 86.2567 213.224 86.34 212.997 86.34C212.771 86.34 212.574 86.2567 212.408 86.09C212.241 85.9233 212.158 85.7267 212.158 85.5ZM210.417 84.72C210.417 83.8133 210.644 83.11 211.097 82.61C211.557 82.11 212.191 81.86 212.997 81.86C213.804 81.86 214.434 82.11 214.887 82.61C215.347 83.11 215.577 83.8133 215.577 84.72V86.28C215.577 87.2133 215.347 87.9233 214.887 88.41C214.434 88.8967 213.804 89.14 212.997 89.14C212.191 89.14 211.557 88.8967 211.097 88.41C210.644 87.9233 210.417 87.2133 210.417 86.28V84.72ZM212.997 87.94C213.237 87.94 213.437 87.9033 213.597 87.83C213.757 87.75 213.888 87.64 213.988 87.5C214.088 87.36 214.157 87.1933 214.197 87C214.237 86.8067 214.257 86.5933 214.257 86.36V84.64C214.257 84.42 214.234 84.2133 214.188 84.02C214.141 83.8267 214.067 83.66 213.967 83.52C213.867 83.38 213.737 83.27 213.577 83.19C213.417 83.1033 213.224 83.06 212.997 83.06C212.771 83.06 212.577 83.1033 212.417 83.19C212.257 83.27 212.127 83.38 212.027 83.52C211.927 83.66 211.854 83.8267 211.807 84.02C211.761 84.2133 211.738 84.42 211.738 84.64V86.36C211.738 86.5933 211.758 86.8067 211.798 87C211.838 87.1933 211.907 87.36 212.007 87.5C212.107 87.64 212.237 87.75 212.397 87.83C212.557 87.9033 212.757 87.94 212.997 87.94Z"
    fill="#5078E1"
  /><path
    d="M218.281 85.5C218.281 85.2733 218.364 85.0767 218.531 84.91C218.697 84.7433 218.894 84.66 219.121 84.66C219.347 84.66 219.544 84.7433 219.711 84.91C219.877 85.0767 219.961 85.2733 219.961 85.5C219.961 85.7267 219.877 85.9233 219.711 86.09C219.544 86.2567 219.347 86.34 219.121 86.34C218.894 86.34 218.697 86.2567 218.531 86.09C218.364 85.9233 218.281 85.7267 218.281 85.5ZM216.541 84.72C216.541 83.8133 216.767 83.11 217.221 82.61C217.681 82.11 218.314 81.86 219.121 81.86C219.927 81.86 220.557 82.11 221.011 82.61C221.471 83.11 221.701 83.8133 221.701 84.72V86.28C221.701 87.2133 221.471 87.9233 221.011 88.41C220.557 88.8967 219.927 89.14 219.121 89.14C218.314 89.14 217.681 88.8967 217.221 88.41C216.767 87.9233 216.541 87.2133 216.541 86.28V84.72ZM219.121 87.94C219.361 87.94 219.561 87.9033 219.721 87.83C219.881 87.75 220.011 87.64 220.111 87.5C220.211 87.36 220.281 87.1933 220.321 87C220.361 86.8067 220.381 86.5933 220.381 86.36V84.64C220.381 84.42 220.357 84.2133 220.311 84.02C220.264 83.8267 220.191 83.66 220.091 83.52C219.991 83.38 219.861 83.27 219.701 83.19C219.541 83.1033 219.347 83.06 219.121 83.06C218.894 83.06 218.701 83.1033 218.541 83.19C218.381 83.27 218.251 83.38 218.151 83.52C218.051 83.66 217.977 83.8267 217.931 84.02C217.884 84.2133 217.861 84.42 217.861 84.64V86.36C217.861 86.5933 217.881 86.8067 217.921 87C217.961 87.1933 218.031 87.36 218.131 87.5C218.231 87.64 218.361 87.75 218.521 87.83C218.681 87.9033 218.881 87.94 219.121 87.94Z"
    fill="#5078E1"
  /><path
    d="M224.824 87.74V82.66H224.644L223.984 85.06H222.724V84.94L223.564 82H226.144V87.74H227.884V89H222.844V87.74H224.824Z"
    fill="#5078E1"
  /><path
    d="M230.527 85.5C230.527 85.2733 230.61 85.0767 230.777 84.91C230.943 84.7433 231.14 84.66 231.367 84.66C231.593 84.66 231.79 84.7433 231.957 84.91C232.123 85.0767 232.207 85.2733 232.207 85.5C232.207 85.7267 232.123 85.9233 231.957 86.09C231.79 86.2567 231.593 86.34 231.367 86.34C231.14 86.34 230.943 86.2567 230.777 86.09C230.61 85.9233 230.527 85.7267 230.527 85.5ZM228.787 84.72C228.787 83.8133 229.013 83.11 229.467 82.61C229.927 82.11 230.56 81.86 231.367 81.86C232.173 81.86 232.803 82.11 233.257 82.61C233.717 83.11 233.947 83.8133 233.947 84.72V86.28C233.947 87.2133 233.717 87.9233 233.257 88.41C232.803 88.8967 232.173 89.14 231.367 89.14C230.56 89.14 229.927 88.8967 229.467 88.41C229.013 87.9233 228.787 87.2133 228.787 86.28V84.72ZM231.367 87.94C231.607 87.94 231.807 87.9033 231.967 87.83C232.127 87.75 232.257 87.64 232.357 87.5C232.457 87.36 232.527 87.1933 232.567 87C232.607 86.8067 232.627 86.5933 232.627 86.36V84.64C232.627 84.42 232.603 84.2133 232.557 84.02C232.51 83.8267 232.437 83.66 232.337 83.52C232.237 83.38 232.107 83.27 231.947 83.19C231.787 83.1033 231.593 83.06 231.367 83.06C231.14 83.06 230.947 83.1033 230.787 83.19C230.627 83.27 230.497 83.38 230.397 83.52C230.297 83.66 230.223 83.8267 230.177 84.02C230.13 84.2133 230.107 84.42 230.107 84.64V86.36C230.107 86.5933 230.127 86.8067 230.167 87C230.207 87.1933 230.277 87.36 230.377 87.5C230.477 87.64 230.607 87.75 230.767 87.83C230.927 87.9033 231.127 87.94 231.367 87.94Z"
    fill="#5078E1"
  /><path
    d="M236.65 85.5C236.65 85.2733 236.733 85.0767 236.9 84.91C237.066 84.7433 237.263 84.66 237.49 84.66C237.716 84.66 237.913 84.7433 238.08 84.91C238.246 85.0767 238.33 85.2733 238.33 85.5C238.33 85.7267 238.246 85.9233 238.08 86.09C237.913 86.2567 237.716 86.34 237.49 86.34C237.263 86.34 237.066 86.2567 236.9 86.09C236.733 85.9233 236.65 85.7267 236.65 85.5ZM234.91 84.72C234.91 83.8133 235.136 83.11 235.59 82.61C236.05 82.11 236.683 81.86 237.49 81.86C238.296 81.86 238.926 82.11 239.38 82.61C239.84 83.11 240.07 83.8133 240.07 84.72V86.28C240.07 87.2133 239.84 87.9233 239.38 88.41C238.926 88.8967 238.296 89.14 237.49 89.14C236.683 89.14 236.05 88.8967 235.59 88.41C235.136 87.9233 234.91 87.2133 234.91 86.28V84.72ZM237.49 87.94C237.73 87.94 237.93 87.9033 238.09 87.83C238.25 87.75 238.38 87.64 238.48 87.5C238.58 87.36 238.65 87.1933 238.69 87C238.73 86.8067 238.75 86.5933 238.75 86.36V84.64C238.75 84.42 238.726 84.2133 238.68 84.02C238.633 83.8267 238.56 83.66 238.46 83.52C238.36 83.38 238.23 83.27 238.07 83.19C237.91 83.1033 237.716 83.06 237.49 83.06C237.263 83.06 237.07 83.1033 236.91 83.19C236.75 83.27 236.62 83.38 236.52 83.52C236.42 83.66 236.346 83.8267 236.3 84.02C236.253 84.2133 236.23 84.42 236.23 84.64V86.36C236.23 86.5933 236.25 86.8067 236.29 87C236.33 87.1933 236.4 87.36 236.5 87.5C236.6 87.64 236.73 87.75 236.89 87.83C237.05 87.9033 237.25 87.94 237.49 87.94Z"
    fill="#5078E1"
  /><path
    d="M243.193 87.74V82.66H243.013L242.353 85.06H241.093V84.94L241.933 82H244.513V87.74H246.253V89H241.213V87.74H243.193Z"
    fill="#5078E1"
  /><path
    d="M249.316 87.74V82.66H249.136L248.476 85.06H247.216V84.94L248.056 82H250.636V87.74H252.376V89H247.336V87.74H249.316Z"
    fill="#5078E1"
  /><path
    d="M255.019 85.5C255.019 85.2733 255.102 85.0767 255.269 84.91C255.435 84.7433 255.632 84.66 255.859 84.66C256.085 84.66 256.282 84.7433 256.449 84.91C256.615 85.0767 256.699 85.2733 256.699 85.5C256.699 85.7267 256.615 85.9233 256.449 86.09C256.282 86.2567 256.085 86.34 255.859 86.34C255.632 86.34 255.435 86.2567 255.269 86.09C255.102 85.9233 255.019 85.7267 255.019 85.5ZM253.279 84.72C253.279 83.8133 253.505 83.11 253.959 82.61C254.419 82.11 255.052 81.86 255.859 81.86C256.665 81.86 257.295 82.11 257.749 82.61C258.209 83.11 258.439 83.8133 258.439 84.72V86.28C258.439 87.2133 258.209 87.9233 257.749 88.41C257.295 88.8967 256.665 89.14 255.859 89.14C255.052 89.14 254.419 88.8967 253.959 88.41C253.505 87.9233 253.279 87.2133 253.279 86.28V84.72ZM255.859 87.94C256.099 87.94 256.299 87.9033 256.459 87.83C256.619 87.75 256.749 87.64 256.849 87.5C256.949 87.36 257.019 87.1933 257.059 87C257.099 86.8067 257.119 86.5933 257.119 86.36V84.64C257.119 84.42 257.095 84.2133 257.049 84.02C257.002 83.8267 256.929 83.66 256.829 83.52C256.729 83.38 256.599 83.27 256.439 83.19C256.279 83.1033 256.085 83.06 255.859 83.06C255.632 83.06 255.439 83.1033 255.279 83.19C255.119 83.27 254.989 83.38 254.889 83.52C254.789 83.66 254.715 83.8267 254.669 84.02C254.622 84.2133 254.599 84.42 254.599 84.64V86.36C254.599 86.5933 254.619 86.8067 254.659 87C254.699 87.1933 254.769 87.36 254.869 87.5C254.969 87.64 255.099 87.75 255.259 87.83C255.419 87.9033 255.619 87.94 255.859 87.94Z"
    fill="#5078E1"
  /><path
    d="M261.142 85.5C261.142 85.2733 261.225 85.0767 261.392 84.91C261.559 84.7433 261.755 84.66 261.982 84.66C262.209 84.66 262.405 84.7433 262.572 84.91C262.739 85.0767 262.822 85.2733 262.822 85.5C262.822 85.7267 262.739 85.9233 262.572 86.09C262.405 86.2567 262.209 86.34 261.982 86.34C261.755 86.34 261.559 86.2567 261.392 86.09C261.225 85.9233 261.142 85.7267 261.142 85.5ZM259.402 84.72C259.402 83.8133 259.629 83.11 260.082 82.61C260.542 82.11 261.175 81.86 261.982 81.86C262.789 81.86 263.419 82.11 263.872 82.61C264.332 83.11 264.562 83.8133 264.562 84.72V86.28C264.562 87.2133 264.332 87.9233 263.872 88.41C263.419 88.8967 262.789 89.14 261.982 89.14C261.175 89.14 260.542 88.8967 260.082 88.41C259.629 87.9233 259.402 87.2133 259.402 86.28V84.72ZM261.982 87.94C262.222 87.94 262.422 87.9033 262.582 87.83C262.742 87.75 262.872 87.64 262.972 87.5C263.072 87.36 263.142 87.1933 263.182 87C263.222 86.8067 263.242 86.5933 263.242 86.36V84.64C263.242 84.42 263.219 84.2133 263.172 84.02C263.125 83.8267 263.052 83.66 262.952 83.52C262.852 83.38 262.722 83.27 262.562 83.19C262.402 83.1033 262.209 83.06 261.982 83.06C261.755 83.06 261.562 83.1033 261.402 83.19C261.242 83.27 261.112 83.38 261.012 83.52C260.912 83.66 260.839 83.8267 260.792 84.02C260.745 84.2133 260.722 84.42 260.722 84.64V86.36C260.722 86.5933 260.742 86.8067 260.782 87C260.822 87.1933 260.892 87.36 260.992 87.5C261.092 87.64 261.222 87.75 261.382 87.83C261.542 87.9033 261.742 87.94 261.982 87.94Z"
    fill="#5078E1"
  /><path
    d="M267.265 85.5C267.265 85.2733 267.348 85.0767 267.515 84.91C267.682 84.7433 267.878 84.66 268.105 84.66C268.332 84.66 268.528 84.7433 268.695 84.91C268.862 85.0767 268.945 85.2733 268.945 85.5C268.945 85.7267 268.862 85.9233 268.695 86.09C268.528 86.2567 268.332 86.34 268.105 86.34C267.878 86.34 267.682 86.2567 267.515 86.09C267.348 85.9233 267.265 85.7267 267.265 85.5ZM265.525 84.72C265.525 83.8133 265.752 83.11 266.205 82.61C266.665 82.11 267.298 81.86 268.105 81.86C268.912 81.86 269.542 82.11 269.995 82.61C270.455 83.11 270.685 83.8133 270.685 84.72V86.28C270.685 87.2133 270.455 87.9233 269.995 88.41C269.542 88.8967 268.912 89.14 268.105 89.14C267.298 89.14 266.665 88.8967 266.205 88.41C265.752 87.9233 265.525 87.2133 265.525 86.28V84.72ZM268.105 87.94C268.345 87.94 268.545 87.9033 268.705 87.83C268.865 87.75 268.995 87.64 269.095 87.5C269.195 87.36 269.265 87.1933 269.305 87C269.345 86.8067 269.365 86.5933 269.365 86.36V84.64C269.365 84.42 269.342 84.2133 269.295 84.02C269.248 83.8267 269.175 83.66 269.075 83.52C268.975 83.38 268.845 83.27 268.685 83.19C268.525 83.1033 268.332 83.06 268.105 83.06C267.878 83.06 267.685 83.1033 267.525 83.19C267.365 83.27 267.235 83.38 267.135 83.52C267.035 83.66 266.962 83.8267 266.915 84.02C266.868 84.2133 266.845 84.42 266.845 84.64V86.36C266.845 86.5933 266.865 86.8067 266.905 87C266.945 87.1933 267.015 87.36 267.115 87.5C267.215 87.64 267.345 87.75 267.505 87.83C267.665 87.9033 267.865 87.94 268.105 87.94Z"
    fill="#5078E1"
  /><path
    d="M273.388 85.5C273.388 85.2733 273.471 85.0767 273.638 84.91C273.805 84.7433 274.001 84.66 274.228 84.66C274.455 84.66 274.651 84.7433 274.818 84.91C274.985 85.0767 275.068 85.2733 275.068 85.5C275.068 85.7267 274.985 85.9233 274.818 86.09C274.651 86.2567 274.455 86.34 274.228 86.34C274.001 86.34 273.805 86.2567 273.638 86.09C273.471 85.9233 273.388 85.7267 273.388 85.5ZM271.648 84.72C271.648 83.8133 271.875 83.11 272.328 82.61C272.788 82.11 273.421 81.86 274.228 81.86C275.035 81.86 275.665 82.11 276.118 82.61C276.578 83.11 276.808 83.8133 276.808 84.72V86.28C276.808 87.2133 276.578 87.9233 276.118 88.41C275.665 88.8967 275.035 89.14 274.228 89.14C273.421 89.14 272.788 88.8967 272.328 88.41C271.875 87.9233 271.648 87.2133 271.648 86.28V84.72ZM274.228 87.94C274.468 87.94 274.668 87.9033 274.828 87.83C274.988 87.75 275.118 87.64 275.218 87.5C275.318 87.36 275.388 87.1933 275.428 87C275.468 86.8067 275.488 86.5933 275.488 86.36V84.64C275.488 84.42 275.465 84.2133 275.418 84.02C275.371 83.8267 275.298 83.66 275.198 83.52C275.098 83.38 274.968 83.27 274.808 83.19C274.648 83.1033 274.455 83.06 274.228 83.06C274.001 83.06 273.808 83.1033 273.648 83.19C273.488 83.27 273.358 83.38 273.258 83.52C273.158 83.66 273.085 83.8267 273.038 84.02C272.991 84.2133 272.968 84.42 272.968 84.64V86.36C272.968 86.5933 272.988 86.8067 273.028 87C273.068 87.1933 273.138 87.36 273.238 87.5C273.338 87.64 273.468 87.75 273.628 87.83C273.788 87.9033 273.988 87.94 274.228 87.94Z"
    fill="#5078E1"
  /><path
    d="M279.931 87.74V82.66H279.751L279.091 85.06H277.831V84.94L278.671 82H281.251V87.74H282.991V89H277.951V87.74H279.931Z"
    fill="#5078E1"
  /><path
    d="M285.634 85.5C285.634 85.2733 285.717 85.0767 285.884 84.91C286.051 84.7433 286.247 84.66 286.474 84.66C286.701 84.66 286.897 84.7433 287.064 84.91C287.231 85.0767 287.314 85.2733 287.314 85.5C287.314 85.7267 287.231 85.9233 287.064 86.09C286.897 86.2567 286.701 86.34 286.474 86.34C286.247 86.34 286.051 86.2567 285.884 86.09C285.717 85.9233 285.634 85.7267 285.634 85.5ZM283.894 84.72C283.894 83.8133 284.121 83.11 284.574 82.61C285.034 82.11 285.667 81.86 286.474 81.86C287.281 81.86 287.911 82.11 288.364 82.61C288.824 83.11 289.054 83.8133 289.054 84.72V86.28C289.054 87.2133 288.824 87.9233 288.364 88.41C287.911 88.8967 287.281 89.14 286.474 89.14C285.667 89.14 285.034 88.8967 284.574 88.41C284.121 87.9233 283.894 87.2133 283.894 86.28V84.72ZM286.474 87.94C286.714 87.94 286.914 87.9033 287.074 87.83C287.234 87.75 287.364 87.64 287.464 87.5C287.564 87.36 287.634 87.1933 287.674 87C287.714 86.8067 287.734 86.5933 287.734 86.36V84.64C287.734 84.42 287.711 84.2133 287.664 84.02C287.617 83.8267 287.544 83.66 287.444 83.52C287.344 83.38 287.214 83.27 287.054 83.19C286.894 83.1033 286.701 83.06 286.474 83.06C286.247 83.06 286.054 83.1033 285.894 83.19C285.734 83.27 285.604 83.38 285.504 83.52C285.404 83.66 285.331 83.8267 285.284 84.02C285.237 84.2133 285.214 84.42 285.214 84.64V86.36C285.214 86.5933 285.234 86.8067 285.274 87C285.314 87.1933 285.384 87.36 285.484 87.5C285.584 87.64 285.714 87.75 285.874 87.83C286.034 87.9033 286.234 87.94 286.474 87.94Z"
    fill="#5078E1"
  /><path
    d="M292.177 87.74V82.66H291.997L291.337 85.06H290.077V84.94L290.917 82H293.497V87.74H295.237V89H290.197V87.74H292.177Z"
    fill="#5078E1"
  /><path
    d="M298.3 87.74V82.66H298.12L297.46 85.06H296.2V84.94L297.04 82H299.62V87.74H301.36V89H296.32V87.74H298.3Z"
    fill="#5078E1"
  /><path
    d="M304.003 85.5C304.003 85.2733 304.087 85.0767 304.253 84.91C304.42 84.7433 304.617 84.66 304.843 84.66C305.07 84.66 305.267 84.7433 305.433 84.91C305.6 85.0767 305.683 85.2733 305.683 85.5C305.683 85.7267 305.6 85.9233 305.433 86.09C305.267 86.2567 305.07 86.34 304.843 86.34C304.617 86.34 304.42 86.2567 304.253 86.09C304.087 85.9233 304.003 85.7267 304.003 85.5ZM302.263 84.72C302.263 83.8133 302.49 83.11 302.943 82.61C303.403 82.11 304.037 81.86 304.843 81.86C305.65 81.86 306.28 82.11 306.733 82.61C307.193 83.11 307.423 83.8133 307.423 84.72V86.28C307.423 87.2133 307.193 87.9233 306.733 88.41C306.28 88.8967 305.65 89.14 304.843 89.14C304.037 89.14 303.403 88.8967 302.943 88.41C302.49 87.9233 302.263 87.2133 302.263 86.28V84.72ZM304.843 87.94C305.083 87.94 305.283 87.9033 305.443 87.83C305.603 87.75 305.733 87.64 305.833 87.5C305.933 87.36 306.003 87.1933 306.043 87C306.083 86.8067 306.103 86.5933 306.103 86.36V84.64C306.103 84.42 306.08 84.2133 306.033 84.02C305.987 83.8267 305.913 83.66 305.813 83.52C305.713 83.38 305.583 83.27 305.423 83.19C305.263 83.1033 305.07 83.06 304.843 83.06C304.617 83.06 304.423 83.1033 304.263 83.19C304.103 83.27 303.973 83.38 303.873 83.52C303.773 83.66 303.7 83.8267 303.653 84.02C303.607 84.2133 303.583 84.42 303.583 84.64V86.36C303.583 86.5933 303.603 86.8067 303.643 87C303.683 87.1933 303.753 87.36 303.853 87.5C303.953 87.64 304.083 87.75 304.243 87.83C304.403 87.9033 304.603 87.94 304.843 87.94Z"
    fill="#5078E1"
  /><path
    d="M310.546 87.74V82.66H310.366L309.706 85.06H308.446V84.94L309.286 82H311.866V87.74H313.606V89H308.566V87.74H310.546Z"
    fill="#5078E1"
  /><path
    d="M316.249 85.5C316.249 85.2733 316.333 85.0767 316.499 84.91C316.666 84.7433 316.863 84.66 317.089 84.66C317.316 84.66 317.513 84.7433 317.679 84.91C317.846 85.0767 317.929 85.2733 317.929 85.5C317.929 85.7267 317.846 85.9233 317.679 86.09C317.513 86.2567 317.316 86.34 317.089 86.34C316.863 86.34 316.666 86.2567 316.499 86.09C316.333 85.9233 316.249 85.7267 316.249 85.5ZM314.509 84.72C314.509 83.8133 314.736 83.11 315.189 82.61C315.649 82.11 316.283 81.86 317.089 81.86C317.896 81.86 318.526 82.11 318.979 82.61C319.439 83.11 319.669 83.8133 319.669 84.72V86.28C319.669 87.2133 319.439 87.9233 318.979 88.41C318.526 88.8967 317.896 89.14 317.089 89.14C316.283 89.14 315.649 88.8967 315.189 88.41C314.736 87.9233 314.509 87.2133 314.509 86.28V84.72ZM317.089 87.94C317.329 87.94 317.529 87.9033 317.689 87.83C317.849 87.75 317.979 87.64 318.079 87.5C318.179 87.36 318.249 87.1933 318.289 87C318.329 86.8067 318.349 86.5933 318.349 86.36V84.64C318.349 84.42 318.326 84.2133 318.279 84.02C318.233 83.8267 318.159 83.66 318.059 83.52C317.959 83.38 317.829 83.27 317.669 83.19C317.509 83.1033 317.316 83.06 317.089 83.06C316.863 83.06 316.669 83.1033 316.509 83.19C316.349 83.27 316.219 83.38 316.119 83.52C316.019 83.66 315.946 83.8267 315.899 84.02C315.853 84.2133 315.829 84.42 315.829 84.64V86.36C315.829 86.5933 315.849 86.8067 315.889 87C315.929 87.1933 315.999 87.36 316.099 87.5C316.199 87.64 316.329 87.75 316.489 87.83C316.649 87.9033 316.849 87.94 317.089 87.94Z"
    fill="#5078E1"
  /><path
    d="M322.372 85.5C322.372 85.2733 322.456 85.0767 322.622 84.91C322.789 84.7433 322.986 84.66 323.212 84.66C323.439 84.66 323.636 84.7433 323.802 84.91C323.969 85.0767 324.052 85.2733 324.052 85.5C324.052 85.7267 323.969 85.9233 323.802 86.09C323.636 86.2567 323.439 86.34 323.212 86.34C322.986 86.34 322.789 86.2567 322.622 86.09C322.456 85.9233 322.372 85.7267 322.372 85.5ZM320.632 84.72C320.632 83.8133 320.859 83.11 321.312 82.61C321.772 82.11 322.406 81.86 323.212 81.86C324.019 81.86 324.649 82.11 325.102 82.61C325.562 83.11 325.792 83.8133 325.792 84.72V86.28C325.792 87.2133 325.562 87.9233 325.102 88.41C324.649 88.8967 324.019 89.14 323.212 89.14C322.406 89.14 321.772 88.8967 321.312 88.41C320.859 87.9233 320.632 87.2133 320.632 86.28V84.72ZM323.212 87.94C323.452 87.94 323.652 87.9033 323.812 87.83C323.972 87.75 324.102 87.64 324.202 87.5C324.302 87.36 324.372 87.1933 324.412 87C324.452 86.8067 324.472 86.5933 324.472 86.36V84.64C324.472 84.42 324.449 84.2133 324.402 84.02C324.356 83.8267 324.282 83.66 324.182 83.52C324.082 83.38 323.952 83.27 323.792 83.19C323.632 83.1033 323.439 83.06 323.212 83.06C322.986 83.06 322.792 83.1033 322.632 83.19C322.472 83.27 322.342 83.38 322.242 83.52C322.142 83.66 322.069 83.8267 322.022 84.02C321.976 84.2133 321.952 84.42 321.952 84.64V86.36C321.952 86.5933 321.972 86.8067 322.012 87C322.052 87.1933 322.122 87.36 322.222 87.5C322.322 87.64 322.452 87.75 322.612 87.83C322.772 87.9033 322.972 87.94 323.212 87.94Z"
    fill="#5078E1"
  /><path
    d="M328.915 87.74V82.66H328.735L328.075 85.06H326.815V84.94L327.655 82H330.235V87.74H331.975V89H326.935V87.74H328.915Z"
    fill="#5078E1"
  /><path
    d="M334.618 85.5C334.618 85.2733 334.702 85.0767 334.868 84.91C335.035 84.7433 335.232 84.66 335.458 84.66C335.685 84.66 335.882 84.7433 336.048 84.91C336.215 85.0767 336.298 85.2733 336.298 85.5C336.298 85.7267 336.215 85.9233 336.048 86.09C335.882 86.2567 335.685 86.34 335.458 86.34C335.232 86.34 335.035 86.2567 334.868 86.09C334.702 85.9233 334.618 85.7267 334.618 85.5ZM332.878 84.72C332.878 83.8133 333.105 83.11 333.558 82.61C334.018 82.11 334.652 81.86 335.458 81.86C336.265 81.86 336.895 82.11 337.348 82.61C337.808 83.11 338.038 83.8133 338.038 84.72V86.28C338.038 87.2133 337.808 87.9233 337.348 88.41C336.895 88.8967 336.265 89.14 335.458 89.14C334.652 89.14 334.018 88.8967 333.558 88.41C333.105 87.9233 332.878 87.2133 332.878 86.28V84.72ZM335.458 87.94C335.698 87.94 335.898 87.9033 336.058 87.83C336.218 87.75 336.348 87.64 336.448 87.5C336.548 87.36 336.618 87.1933 336.658 87C336.698 86.8067 336.718 86.5933 336.718 86.36V84.64C336.718 84.42 336.695 84.2133 336.648 84.02C336.602 83.8267 336.528 83.66 336.428 83.52C336.328 83.38 336.198 83.27 336.038 83.19C335.878 83.1033 335.685 83.06 335.458 83.06C335.232 83.06 335.038 83.1033 334.878 83.19C334.718 83.27 334.588 83.38 334.488 83.52C334.388 83.66 334.315 83.8267 334.268 84.02C334.222 84.2133 334.198 84.42 334.198 84.64V86.36C334.198 86.5933 334.218 86.8067 334.258 87C334.298 87.1933 334.368 87.36 334.468 87.5C334.568 87.64 334.698 87.75 334.858 87.83C335.018 87.9033 335.218 87.94 335.458 87.94Z"
    fill="#5078E1"
  /><path
    d="M341.161 87.74V82.66H340.981L340.321 85.06H339.061V84.94L339.901 82H342.481V87.74H344.221V89H339.181V87.74H341.161Z"
    fill="#5078E1"
  /><path
    d="M347.285 87.74V82.66H347.105L346.445 85.06H345.185V84.94L346.025 82H348.605V87.74H350.345V89H345.305V87.74H347.285Z"
    fill="#5078E1"
  /><path
    d="M352.988 85.5C352.988 85.2733 353.071 85.0767 353.238 84.91C353.404 84.7433 353.601 84.66 353.828 84.66C354.054 84.66 354.251 84.7433 354.418 84.91C354.584 85.0767 354.668 85.2733 354.668 85.5C354.668 85.7267 354.584 85.9233 354.418 86.09C354.251 86.2567 354.054 86.34 353.828 86.34C353.601 86.34 353.404 86.2567 353.238 86.09C353.071 85.9233 352.988 85.7267 352.988 85.5ZM351.248 84.72C351.248 83.8133 351.474 83.11 351.928 82.61C352.388 82.11 353.021 81.86 353.828 81.86C354.634 81.86 355.264 82.11 355.718 82.61C356.178 83.11 356.408 83.8133 356.408 84.72V86.28C356.408 87.2133 356.178 87.9233 355.718 88.41C355.264 88.8967 354.634 89.14 353.828 89.14C353.021 89.14 352.388 88.8967 351.928 88.41C351.474 87.9233 351.248 87.2133 351.248 86.28V84.72ZM353.828 87.94C354.068 87.94 354.268 87.9033 354.428 87.83C354.588 87.75 354.718 87.64 354.818 87.5C354.918 87.36 354.988 87.1933 355.028 87C355.068 86.8067 355.088 86.5933 355.088 86.36V84.64C355.088 84.42 355.064 84.2133 355.018 84.02C354.971 83.8267 354.898 83.66 354.798 83.52C354.698 83.38 354.568 83.27 354.408 83.19C354.248 83.1033 354.054 83.06 353.828 83.06C353.601 83.06 353.408 83.1033 353.248 83.19C353.088 83.27 352.958 83.38 352.858 83.52C352.758 83.66 352.684 83.8267 352.638 84.02C352.591 84.2133 352.568 84.42 352.568 84.64V86.36C352.568 86.5933 352.588 86.8067 352.628 87C352.668 87.1933 352.738 87.36 352.838 87.5C352.938 87.64 353.068 87.75 353.228 87.83C353.388 87.9033 353.588 87.94 353.828 87.94Z"
    fill="#5078E1"
  /><path
    d="M359.531 87.74V82.66H359.351L358.691 85.06H357.431V84.94L358.271 82H360.851V87.74H362.591V89H357.551V87.74H359.531Z"
    fill="#5078E1"
  /><path
    d="M365.654 87.74V82.66H365.474L364.814 85.06H363.554V84.94L364.394 82H366.974V87.74H368.714V89H363.674V87.74H365.654Z"
    fill="#5078E1"
  /><path
    d="M371.777 87.74V82.66H371.597L370.937 85.06H369.677V84.94L370.517 82H373.097V87.74H374.837V89H369.797V87.74H371.777Z"
    fill="#5078E1"
  /><path
    d="M377.48 85.5C377.48 85.2733 377.563 85.0767 377.73 84.91C377.896 84.7433 378.093 84.66 378.32 84.66C378.546 84.66 378.743 84.7433 378.91 84.91C379.076 85.0767 379.16 85.2733 379.16 85.5C379.16 85.7267 379.076 85.9233 378.91 86.09C378.743 86.2567 378.546 86.34 378.32 86.34C378.093 86.34 377.896 86.2567 377.73 86.09C377.563 85.9233 377.48 85.7267 377.48 85.5ZM375.74 84.72C375.74 83.8133 375.966 83.11 376.42 82.61C376.88 82.11 377.513 81.86 378.32 81.86C379.126 81.86 379.756 82.11 380.21 82.61C380.67 83.11 380.9 83.8133 380.9 84.72V86.28C380.9 87.2133 380.67 87.9233 380.21 88.41C379.756 88.8967 379.126 89.14 378.32 89.14C377.513 89.14 376.88 88.8967 376.42 88.41C375.966 87.9233 375.74 87.2133 375.74 86.28V84.72ZM378.32 87.94C378.56 87.94 378.76 87.9033 378.92 87.83C379.08 87.75 379.21 87.64 379.31 87.5C379.41 87.36 379.48 87.1933 379.52 87C379.56 86.8067 379.58 86.5933 379.58 86.36V84.64C379.58 84.42 379.556 84.2133 379.51 84.02C379.463 83.8267 379.39 83.66 379.29 83.52C379.19 83.38 379.06 83.27 378.9 83.19C378.74 83.1033 378.546 83.06 378.32 83.06C378.093 83.06 377.9 83.1033 377.74 83.19C377.58 83.27 377.45 83.38 377.35 83.52C377.25 83.66 377.176 83.8267 377.13 84.02C377.083 84.2133 377.06 84.42 377.06 84.64V86.36C377.06 86.5933 377.08 86.8067 377.12 87C377.16 87.1933 377.23 87.36 377.33 87.5C377.43 87.64 377.56 87.75 377.72 87.83C377.88 87.9033 378.08 87.94 378.32 87.94Z"
    fill="#5078E1"
  /><path
    d="M383.603 85.5C383.603 85.2733 383.686 85.0767 383.853 84.91C384.019 84.7433 384.216 84.66 384.443 84.66C384.669 84.66 384.866 84.7433 385.033 84.91C385.199 85.0767 385.283 85.2733 385.283 85.5C385.283 85.7267 385.199 85.9233 385.033 86.09C384.866 86.2567 384.669 86.34 384.443 86.34C384.216 86.34 384.019 86.2567 383.853 86.09C383.686 85.9233 383.603 85.7267 383.603 85.5ZM381.863 84.72C381.863 83.8133 382.089 83.11 382.543 82.61C383.003 82.11 383.636 81.86 384.443 81.86C385.249 81.86 385.879 82.11 386.333 82.61C386.793 83.11 387.023 83.8133 387.023 84.72V86.28C387.023 87.2133 386.793 87.9233 386.333 88.41C385.879 88.8967 385.249 89.14 384.443 89.14C383.636 89.14 383.003 88.8967 382.543 88.41C382.089 87.9233 381.863 87.2133 381.863 86.28V84.72ZM384.443 87.94C384.683 87.94 384.883 87.9033 385.043 87.83C385.203 87.75 385.333 87.64 385.433 87.5C385.533 87.36 385.603 87.1933 385.643 87C385.683 86.8067 385.703 86.5933 385.703 86.36V84.64C385.703 84.42 385.679 84.2133 385.633 84.02C385.586 83.8267 385.513 83.66 385.413 83.52C385.313 83.38 385.183 83.27 385.023 83.19C384.863 83.1033 384.669 83.06 384.443 83.06C384.216 83.06 384.023 83.1033 383.863 83.19C383.703 83.27 383.573 83.38 383.473 83.52C383.373 83.66 383.299 83.8267 383.253 84.02C383.206 84.2133 383.183 84.42 383.183 84.64V86.36C383.183 86.5933 383.203 86.8067 383.243 87C383.283 87.1933 383.353 87.36 383.453 87.5C383.553 87.64 383.683 87.75 383.843 87.83C384.003 87.9033 384.203 87.94 384.443 87.94Z"
    fill="#5078E1"
  /><path
    d="M390.146 87.74V82.66H389.966L389.306 85.06H388.046V84.94L388.886 82H391.466V87.74H393.206V89H388.166V87.74H390.146Z"
    fill="#5078E1"
  /><path
    d="M396.269 87.74V82.66H396.089L395.429 85.06H394.169V84.94L395.009 82H397.589V87.74H399.329V89H394.289V87.74H396.269Z"
    fill="#5078E1"
  /><path
    d="M401.972 85.5C401.972 85.2733 402.055 85.0767 402.222 84.91C402.389 84.7433 402.585 84.66 402.812 84.66C403.039 84.66 403.235 84.7433 403.402 84.91C403.569 85.0767 403.652 85.2733 403.652 85.5C403.652 85.7267 403.569 85.9233 403.402 86.09C403.235 86.2567 403.039 86.34 402.812 86.34C402.585 86.34 402.389 86.2567 402.222 86.09C402.055 85.9233 401.972 85.7267 401.972 85.5ZM400.232 84.72C400.232 83.8133 400.459 83.11 400.912 82.61C401.372 82.11 402.005 81.86 402.812 81.86C403.619 81.86 404.249 82.11 404.702 82.61C405.162 83.11 405.392 83.8133 405.392 84.72V86.28C405.392 87.2133 405.162 87.9233 404.702 88.41C404.249 88.8967 403.619 89.14 402.812 89.14C402.005 89.14 401.372 88.8967 400.912 88.41C400.459 87.9233 400.232 87.2133 400.232 86.28V84.72ZM402.812 87.94C403.052 87.94 403.252 87.9033 403.412 87.83C403.572 87.75 403.702 87.64 403.802 87.5C403.902 87.36 403.972 87.1933 404.012 87C404.052 86.8067 404.072 86.5933 404.072 86.36V84.64C404.072 84.42 404.049 84.2133 404.002 84.02C403.955 83.8267 403.882 83.66 403.782 83.52C403.682 83.38 403.552 83.27 403.392 83.19C403.232 83.1033 403.039 83.06 402.812 83.06C402.585 83.06 402.392 83.1033 402.232 83.19C402.072 83.27 401.942 83.38 401.842 83.52C401.742 83.66 401.669 83.8267 401.622 84.02C401.575 84.2133 401.552 84.42 401.552 84.64V86.36C401.552 86.5933 401.572 86.8067 401.612 87C401.652 87.1933 401.722 87.36 401.822 87.5C401.922 87.64 402.052 87.75 402.212 87.83C402.372 87.9033 402.572 87.94 402.812 87.94Z"
    fill="#5078E1"
  /><path
    d="M408.515 87.74V82.66H408.335L407.675 85.06H406.415V84.94L407.255 82H409.835V87.74H411.575V89H406.535V87.74H408.515Z"
    fill="#5078E1"
  /><path
    d="M414.218 85.5C414.218 85.2733 414.301 85.0767 414.468 84.91C414.635 84.7433 414.831 84.66 415.058 84.66C415.285 84.66 415.481 84.7433 415.648 84.91C415.815 85.0767 415.898 85.2733 415.898 85.5C415.898 85.7267 415.815 85.9233 415.648 86.09C415.481 86.2567 415.285 86.34 415.058 86.34C414.831 86.34 414.635 86.2567 414.468 86.09C414.301 85.9233 414.218 85.7267 414.218 85.5ZM412.478 84.72C412.478 83.8133 412.705 83.11 413.158 82.61C413.618 82.11 414.251 81.86 415.058 81.86C415.865 81.86 416.495 82.11 416.948 82.61C417.408 83.11 417.638 83.8133 417.638 84.72V86.28C417.638 87.2133 417.408 87.9233 416.948 88.41C416.495 88.8967 415.865 89.14 415.058 89.14C414.251 89.14 413.618 88.8967 413.158 88.41C412.705 87.9233 412.478 87.2133 412.478 86.28V84.72ZM415.058 87.94C415.298 87.94 415.498 87.9033 415.658 87.83C415.818 87.75 415.948 87.64 416.048 87.5C416.148 87.36 416.218 87.1933 416.258 87C416.298 86.8067 416.318 86.5933 416.318 86.36V84.64C416.318 84.42 416.295 84.2133 416.248 84.02C416.201 83.8267 416.128 83.66 416.028 83.52C415.928 83.38 415.798 83.27 415.638 83.19C415.478 83.1033 415.285 83.06 415.058 83.06C414.831 83.06 414.638 83.1033 414.478 83.19C414.318 83.27 414.188 83.38 414.088 83.52C413.988 83.66 413.915 83.8267 413.868 84.02C413.821 84.2133 413.798 84.42 413.798 84.64V86.36C413.798 86.5933 413.818 86.8067 413.858 87C413.898 87.1933 413.968 87.36 414.068 87.5C414.168 87.64 414.298 87.75 414.458 87.83C414.618 87.9033 414.818 87.94 415.058 87.94Z"
    fill="#5078E1"
  /><path
    d="M420.341 85.5C420.341 85.2733 420.424 85.0767 420.591 84.91C420.758 84.7433 420.954 84.66 421.181 84.66C421.408 84.66 421.604 84.7433 421.771 84.91C421.938 85.0767 422.021 85.2733 422.021 85.5C422.021 85.7267 421.938 85.9233 421.771 86.09C421.604 86.2567 421.408 86.34 421.181 86.34C420.954 86.34 420.758 86.2567 420.591 86.09C420.424 85.9233 420.341 85.7267 420.341 85.5ZM418.601 84.72C418.601 83.8133 418.828 83.11 419.281 82.61C419.741 82.11 420.374 81.86 421.181 81.86C421.988 81.86 422.618 82.11 423.071 82.61C423.531 83.11 423.761 83.8133 423.761 84.72V86.28C423.761 87.2133 423.531 87.9233 423.071 88.41C422.618 88.8967 421.988 89.14 421.181 89.14C420.374 89.14 419.741 88.8967 419.281 88.41C418.828 87.9233 418.601 87.2133 418.601 86.28V84.72ZM421.181 87.94C421.421 87.94 421.621 87.9033 421.781 87.83C421.941 87.75 422.071 87.64 422.171 87.5C422.271 87.36 422.341 87.1933 422.381 87C422.421 86.8067 422.441 86.5933 422.441 86.36V84.64C422.441 84.42 422.418 84.2133 422.371 84.02C422.324 83.8267 422.251 83.66 422.151 83.52C422.051 83.38 421.921 83.27 421.761 83.19C421.601 83.1033 421.408 83.06 421.181 83.06C420.954 83.06 420.761 83.1033 420.601 83.19C420.441 83.27 420.311 83.38 420.211 83.52C420.111 83.66 420.038 83.8267 419.991 84.02C419.944 84.2133 419.921 84.42 419.921 84.64V86.36C419.921 86.5933 419.941 86.8067 419.981 87C420.021 87.1933 420.091 87.36 420.191 87.5C420.291 87.64 420.421 87.75 420.581 87.83C420.741 87.9033 420.941 87.94 421.181 87.94Z"
    fill="#5078E1"
  /><path
    d="M426.884 87.74V82.66H426.704L426.044 85.06H424.784V84.94L425.624 82H428.204V87.74H429.944V89H424.904V87.74H426.884Z"
    fill="#5078E1"
  /><path
    d="M432.587 85.5C432.587 85.2733 432.671 85.0767 432.837 84.91C433.004 84.7433 433.201 84.66 433.427 84.66C433.654 84.66 433.851 84.7433 434.017 84.91C434.184 85.0767 434.267 85.2733 434.267 85.5C434.267 85.7267 434.184 85.9233 434.017 86.09C433.851 86.2567 433.654 86.34 433.427 86.34C433.201 86.34 433.004 86.2567 432.837 86.09C432.671 85.9233 432.587 85.7267 432.587 85.5ZM430.847 84.72C430.847 83.8133 431.074 83.11 431.527 82.61C431.987 82.11 432.621 81.86 433.427 81.86C434.234 81.86 434.864 82.11 435.317 82.61C435.777 83.11 436.007 83.8133 436.007 84.72V86.28C436.007 87.2133 435.777 87.9233 435.317 88.41C434.864 88.8967 434.234 89.14 433.427 89.14C432.621 89.14 431.987 88.8967 431.527 88.41C431.074 87.9233 430.847 87.2133 430.847 86.28V84.72ZM433.427 87.94C433.667 87.94 433.867 87.9033 434.027 87.83C434.187 87.75 434.317 87.64 434.417 87.5C434.517 87.36 434.587 87.1933 434.627 87C434.667 86.8067 434.687 86.5933 434.687 86.36V84.64C434.687 84.42 434.664 84.2133 434.617 84.02C434.571 83.8267 434.497 83.66 434.397 83.52C434.297 83.38 434.167 83.27 434.007 83.19C433.847 83.1033 433.654 83.06 433.427 83.06C433.201 83.06 433.007 83.1033 432.847 83.19C432.687 83.27 432.557 83.38 432.457 83.52C432.357 83.66 432.284 83.8267 432.237 84.02C432.191 84.2133 432.167 84.42 432.167 84.64V86.36C432.167 86.5933 432.187 86.8067 432.227 87C432.267 87.1933 432.337 87.36 432.437 87.5C432.537 87.64 432.667 87.75 432.827 87.83C432.987 87.9033 433.187 87.94 433.427 87.94Z"
    fill="#5078E1"
  /><path
    d="M439.13 87.74V82.66H438.95L438.29 85.06H437.03V84.94L437.87 82H440.45V87.74H442.19V89H437.15V87.74H439.13Z"
    fill="#5078E1"
  /><path
    d="M445.253 87.74V82.66H445.073L444.413 85.06H443.153V84.94L443.993 82H446.573V87.74H448.313V89H443.273V87.74H445.253Z"
    fill="#5078E1"
  /><path
    d="M450.956 85.5C450.956 85.2733 451.04 85.0767 451.206 84.91C451.373 84.7433 451.57 84.66 451.796 84.66C452.023 84.66 452.22 84.7433 452.386 84.91C452.553 85.0767 452.636 85.2733 452.636 85.5C452.636 85.7267 452.553 85.9233 452.386 86.09C452.22 86.2567 452.023 86.34 451.796 86.34C451.57 86.34 451.373 86.2567 451.206 86.09C451.04 85.9233 450.956 85.7267 450.956 85.5ZM449.216 84.72C449.216 83.8133 449.443 83.11 449.896 82.61C450.356 82.11 450.99 81.86 451.796 81.86C452.603 81.86 453.233 82.11 453.686 82.61C454.146 83.11 454.376 83.8133 454.376 84.72V86.28C454.376 87.2133 454.146 87.9233 453.686 88.41C453.233 88.8967 452.603 89.14 451.796 89.14C450.99 89.14 450.356 88.8967 449.896 88.41C449.443 87.9233 449.216 87.2133 449.216 86.28V84.72ZM451.796 87.94C452.036 87.94 452.236 87.9033 452.396 87.83C452.556 87.75 452.686 87.64 452.786 87.5C452.886 87.36 452.956 87.1933 452.996 87C453.036 86.8067 453.056 86.5933 453.056 86.36V84.64C453.056 84.42 453.033 84.2133 452.986 84.02C452.94 83.8267 452.866 83.66 452.766 83.52C452.666 83.38 452.536 83.27 452.376 83.19C452.216 83.1033 452.023 83.06 451.796 83.06C451.57 83.06 451.376 83.1033 451.216 83.19C451.056 83.27 450.926 83.38 450.826 83.52C450.726 83.66 450.653 83.8267 450.606 84.02C450.56 84.2133 450.536 84.42 450.536 84.64V86.36C450.536 86.5933 450.556 86.8067 450.596 87C450.636 87.1933 450.706 87.36 450.806 87.5C450.906 87.64 451.036 87.75 451.196 87.83C451.356 87.9033 451.556 87.94 451.796 87.94Z"
    fill="#5078E1"
  /><path
    d="M457.499 87.74V82.66H457.319L456.659 85.06H455.399V84.94L456.239 82H458.819V87.74H460.559V89H455.519V87.74H457.499Z"
    fill="#5078E1"
  /><path
    d="M463.622 87.74V82.66H463.442L462.782 85.06H461.522V84.94L462.362 82H464.942V87.74H466.682V89H461.642V87.74H463.622Z"
    fill="#5078E1"
  /><path
    d="M469.745 87.74V82.66H469.565L468.905 85.06H467.645V84.94L468.485 82H471.065V87.74H472.805V89H467.765V87.74H469.745Z"
    fill="#5078E1"
  /><path
    d="M475.449 85.5C475.449 85.2733 475.532 85.0767 475.699 84.91C475.865 84.7433 476.062 84.66 476.288 84.66C476.515 84.66 476.712 84.7433 476.879 84.91C477.045 85.0767 477.129 85.2733 477.129 85.5C477.129 85.7267 477.045 85.9233 476.879 86.09C476.712 86.2567 476.515 86.34 476.288 86.34C476.062 86.34 475.865 86.2567 475.699 86.09C475.532 85.9233 475.449 85.7267 475.449 85.5ZM473.709 84.72C473.709 83.8133 473.935 83.11 474.389 82.61C474.849 82.11 475.482 81.86 476.288 81.86C477.095 81.86 477.725 82.11 478.179 82.61C478.639 83.11 478.869 83.8133 478.869 84.72V86.28C478.869 87.2133 478.639 87.9233 478.179 88.41C477.725 88.8967 477.095 89.14 476.288 89.14C475.482 89.14 474.849 88.8967 474.389 88.41C473.935 87.9233 473.709 87.2133 473.709 86.28V84.72ZM476.288 87.94C476.528 87.94 476.729 87.9033 476.889 87.83C477.049 87.75 477.179 87.64 477.279 87.5C477.379 87.36 477.449 87.1933 477.489 87C477.529 86.8067 477.549 86.5933 477.549 86.36V84.64C477.549 84.42 477.525 84.2133 477.479 84.02C477.432 83.8267 477.358 83.66 477.258 83.52C477.158 83.38 477.029 83.27 476.869 83.19C476.709 83.1033 476.515 83.06 476.288 83.06C476.062 83.06 475.869 83.1033 475.709 83.19C475.549 83.27 475.418 83.38 475.318 83.52C475.218 83.66 475.145 83.8267 475.099 84.02C475.052 84.2133 475.029 84.42 475.029 84.64V86.36C475.029 86.5933 475.049 86.8067 475.089 87C475.129 87.1933 475.199 87.36 475.299 87.5C475.399 87.64 475.529 87.75 475.689 87.83C475.849 87.9033 476.048 87.94 476.288 87.94Z"
    fill="#5078E1"
  /><path
    d="M481.572 85.5C481.572 85.2733 481.655 85.0767 481.822 84.91C481.988 84.7433 482.185 84.66 482.412 84.66C482.638 84.66 482.835 84.7433 483.002 84.91C483.168 85.0767 483.252 85.2733 483.252 85.5C483.252 85.7267 483.168 85.9233 483.002 86.09C482.835 86.2567 482.638 86.34 482.412 86.34C482.185 86.34 481.988 86.2567 481.822 86.09C481.655 85.9233 481.572 85.7267 481.572 85.5ZM479.832 84.72C479.832 83.8133 480.058 83.11 480.512 82.61C480.972 82.11 481.605 81.86 482.412 81.86C483.218 81.86 483.848 82.11 484.302 82.61C484.762 83.11 484.992 83.8133 484.992 84.72V86.28C484.992 87.2133 484.762 87.9233 484.302 88.41C483.848 88.8967 483.218 89.14 482.412 89.14C481.605 89.14 480.972 88.8967 480.512 88.41C480.058 87.9233 479.832 87.2133 479.832 86.28V84.72ZM482.412 87.94C482.652 87.94 482.852 87.9033 483.012 87.83C483.172 87.75 483.302 87.64 483.402 87.5C483.502 87.36 483.572 87.1933 483.612 87C483.652 86.8067 483.672 86.5933 483.672 86.36V84.64C483.672 84.42 483.648 84.2133 483.602 84.02C483.555 83.8267 483.482 83.66 483.382 83.52C483.282 83.38 483.152 83.27 482.992 83.19C482.832 83.1033 482.638 83.06 482.412 83.06C482.185 83.06 481.992 83.1033 481.832 83.19C481.672 83.27 481.542 83.38 481.442 83.52C481.342 83.66 481.268 83.8267 481.222 84.02C481.175 84.2133 481.152 84.42 481.152 84.64V86.36C481.152 86.5933 481.172 86.8067 481.212 87C481.252 87.1933 481.322 87.36 481.422 87.5C481.522 87.64 481.652 87.75 481.812 87.83C481.972 87.9033 482.172 87.94 482.412 87.94Z"
    fill="#5078E1"
  /><path
    d="M488.115 87.74V82.66H487.935L487.275 85.06H486.015V84.94L486.855 82H489.435V87.74H491.175V89H486.135V87.74H488.115Z"
    fill="#5078E1"
  /><path
    d="M494.238 87.74V82.66H494.058L493.398 85.06H492.138V84.94L492.978 82H495.558V87.74H497.298V89H492.258V87.74H494.238Z"
    fill="#5078E1"
  /><path
    d="M499.941 85.5C499.941 85.2733 500.024 85.0767 500.191 84.91C500.357 84.7433 500.554 84.66 500.781 84.66C501.007 84.66 501.204 84.7433 501.371 84.91C501.537 85.0767 501.621 85.2733 501.621 85.5C501.621 85.7267 501.537 85.9233 501.371 86.09C501.204 86.2567 501.007 86.34 500.781 86.34C500.554 86.34 500.357 86.2567 500.191 86.09C500.024 85.9233 499.941 85.7267 499.941 85.5ZM498.201 84.72C498.201 83.8133 498.427 83.11 498.881 82.61C499.341 82.11 499.974 81.86 500.781 81.86C501.587 81.86 502.217 82.11 502.671 82.61C503.131 83.11 503.361 83.8133 503.361 84.72V86.28C503.361 87.2133 503.131 87.9233 502.671 88.41C502.217 88.8967 501.587 89.14 500.781 89.14C499.974 89.14 499.341 88.8967 498.881 88.41C498.427 87.9233 498.201 87.2133 498.201 86.28V84.72ZM500.781 87.94C501.021 87.94 501.221 87.9033 501.381 87.83C501.541 87.75 501.671 87.64 501.771 87.5C501.871 87.36 501.941 87.1933 501.981 87C502.021 86.8067 502.041 86.5933 502.041 86.36V84.64C502.041 84.42 502.017 84.2133 501.971 84.02C501.924 83.8267 501.851 83.66 501.751 83.52C501.651 83.38 501.521 83.27 501.361 83.19C501.201 83.1033 501.007 83.06 500.781 83.06C500.554 83.06 500.361 83.1033 500.201 83.19C500.041 83.27 499.911 83.38 499.811 83.52C499.711 83.66 499.637 83.8267 499.591 84.02C499.544 84.2133 499.521 84.42 499.521 84.64V86.36C499.521 86.5933 499.541 86.8067 499.581 87C499.621 87.1933 499.691 87.36 499.791 87.5C499.891 87.64 500.021 87.75 500.181 87.83C500.341 87.9033 500.541 87.94 500.781 87.94Z"
    fill="#5078E1"
  /><path
    d="M506.064 85.5C506.064 85.2733 506.147 85.0767 506.314 84.91C506.48 84.7433 506.677 84.66 506.904 84.66C507.13 84.66 507.327 84.7433 507.494 84.91C507.66 85.0767 507.744 85.2733 507.744 85.5C507.744 85.7267 507.66 85.9233 507.494 86.09C507.327 86.2567 507.13 86.34 506.904 86.34C506.677 86.34 506.48 86.2567 506.314 86.09C506.147 85.9233 506.064 85.7267 506.064 85.5ZM504.324 84.72C504.324 83.8133 504.55 83.11 505.004 82.61C505.464 82.11 506.097 81.86 506.904 81.86C507.71 81.86 508.34 82.11 508.794 82.61C509.254 83.11 509.484 83.8133 509.484 84.72V86.28C509.484 87.2133 509.254 87.9233 508.794 88.41C508.34 88.8967 507.71 89.14 506.904 89.14C506.097 89.14 505.464 88.8967 505.004 88.41C504.55 87.9233 504.324 87.2133 504.324 86.28V84.72ZM506.904 87.94C507.144 87.94 507.344 87.9033 507.504 87.83C507.664 87.75 507.794 87.64 507.894 87.5C507.994 87.36 508.064 87.1933 508.104 87C508.144 86.8067 508.164 86.5933 508.164 86.36V84.64C508.164 84.42 508.14 84.2133 508.094 84.02C508.047 83.8267 507.974 83.66 507.874 83.52C507.774 83.38 507.644 83.27 507.484 83.19C507.324 83.1033 507.13 83.06 506.904 83.06C506.677 83.06 506.484 83.1033 506.324 83.19C506.164 83.27 506.034 83.38 505.934 83.52C505.834 83.66 505.76 83.8267 505.714 84.02C505.667 84.2133 505.644 84.42 505.644 84.64V86.36C505.644 86.5933 505.664 86.8067 505.704 87C505.744 87.1933 505.814 87.36 505.914 87.5C506.014 87.64 506.144 87.75 506.304 87.83C506.464 87.9033 506.664 87.94 506.904 87.94Z"
    fill="#5078E1"
  /><path
    d="M512.607 87.74V82.66H512.427L511.767 85.06H510.507V84.94L511.347 82H513.927V87.74H515.667V89H510.627V87.74H512.607Z"
    fill="#5078E1"
  /><path
    d="M518.73 87.74V82.66H518.55L517.89 85.06H516.63V84.94L517.47 82H520.05V87.74H521.79V89H516.75V87.74H518.73Z"
    fill="#5078E1"
  /><path
    d="M524.853 87.74V82.66H524.673L524.013 85.06H522.753V84.94L523.593 82H526.173V87.74H527.913V89H522.873V87.74H524.853Z"
    fill="#5078E1"
  /><path
    d="M530.556 85.5C530.556 85.2733 530.639 85.0767 530.806 84.91C530.973 84.7433 531.169 84.66 531.396 84.66C531.623 84.66 531.819 84.7433 531.986 84.91C532.153 85.0767 532.236 85.2733 532.236 85.5C532.236 85.7267 532.153 85.9233 531.986 86.09C531.819 86.2567 531.623 86.34 531.396 86.34C531.169 86.34 530.973 86.2567 530.806 86.09C530.639 85.9233 530.556 85.7267 530.556 85.5ZM528.816 84.72C528.816 83.8133 529.043 83.11 529.496 82.61C529.956 82.11 530.589 81.86 531.396 81.86C532.203 81.86 532.833 82.11 533.286 82.61C533.746 83.11 533.976 83.8133 533.976 84.72V86.28C533.976 87.2133 533.746 87.9233 533.286 88.41C532.833 88.8967 532.203 89.14 531.396 89.14C530.589 89.14 529.956 88.8967 529.496 88.41C529.043 87.9233 528.816 87.2133 528.816 86.28V84.72ZM531.396 87.94C531.636 87.94 531.836 87.9033 531.996 87.83C532.156 87.75 532.286 87.64 532.386 87.5C532.486 87.36 532.556 87.1933 532.596 87C532.636 86.8067 532.656 86.5933 532.656 86.36V84.64C532.656 84.42 532.633 84.2133 532.586 84.02C532.539 83.8267 532.466 83.66 532.366 83.52C532.266 83.38 532.136 83.27 531.976 83.19C531.816 83.1033 531.623 83.06 531.396 83.06C531.169 83.06 530.976 83.1033 530.816 83.19C530.656 83.27 530.526 83.38 530.426 83.52C530.326 83.66 530.253 83.8267 530.206 84.02C530.159 84.2133 530.136 84.42 530.136 84.64V86.36C530.136 86.5933 530.156 86.8067 530.196 87C530.236 87.1933 530.306 87.36 530.406 87.5C530.506 87.64 530.636 87.75 530.796 87.83C530.956 87.9033 531.156 87.94 531.396 87.94Z"
    fill="#5078E1"
  /><path
    d="M536.679 85.5C536.679 85.2733 536.762 85.0767 536.929 84.91C537.096 84.7433 537.292 84.66 537.519 84.66C537.746 84.66 537.942 84.7433 538.109 84.91C538.276 85.0767 538.359 85.2733 538.359 85.5C538.359 85.7267 538.276 85.9233 538.109 86.09C537.942 86.2567 537.746 86.34 537.519 86.34C537.292 86.34 537.096 86.2567 536.929 86.09C536.762 85.9233 536.679 85.7267 536.679 85.5ZM534.939 84.72C534.939 83.8133 535.166 83.11 535.619 82.61C536.079 82.11 536.712 81.86 537.519 81.86C538.326 81.86 538.956 82.11 539.409 82.61C539.869 83.11 540.099 83.8133 540.099 84.72V86.28C540.099 87.2133 539.869 87.9233 539.409 88.41C538.956 88.8967 538.326 89.14 537.519 89.14C536.712 89.14 536.079 88.8967 535.619 88.41C535.166 87.9233 534.939 87.2133 534.939 86.28V84.72ZM537.519 87.94C537.759 87.94 537.959 87.9033 538.119 87.83C538.279 87.75 538.409 87.64 538.509 87.5C538.609 87.36 538.679 87.1933 538.719 87C538.759 86.8067 538.779 86.5933 538.779 86.36V84.64C538.779 84.42 538.756 84.2133 538.709 84.02C538.662 83.8267 538.589 83.66 538.489 83.52C538.389 83.38 538.259 83.27 538.099 83.19C537.939 83.1033 537.746 83.06 537.519 83.06C537.292 83.06 537.099 83.1033 536.939 83.19C536.779 83.27 536.649 83.38 536.549 83.52C536.449 83.66 536.376 83.8267 536.329 84.02C536.282 84.2133 536.259 84.42 536.259 84.64V86.36C536.259 86.5933 536.279 86.8067 536.319 87C536.359 87.1933 536.429 87.36 536.529 87.5C536.629 87.64 536.759 87.75 536.919 87.83C537.079 87.9033 537.279 87.94 537.519 87.94Z"
    fill="#5078E1"
  /><path
    d="M543.222 87.74V82.66H543.042L542.382 85.06H541.122V84.94L541.962 82H544.542V87.74H546.282V89H541.242V87.74H543.222Z"
    fill="#5078E1"
  /><path
    d="M548.925 85.5C548.925 85.2733 549.008 85.0767 549.175 84.91C549.342 84.7433 549.538 84.66 549.765 84.66C549.992 84.66 550.188 84.7433 550.355 84.91C550.522 85.0767 550.605 85.2733 550.605 85.5C550.605 85.7267 550.522 85.9233 550.355 86.09C550.188 86.2567 549.992 86.34 549.765 86.34C549.538 86.34 549.342 86.2567 549.175 86.09C549.008 85.9233 548.925 85.7267 548.925 85.5ZM547.185 84.72C547.185 83.8133 547.412 83.11 547.865 82.61C548.325 82.11 548.958 81.86 549.765 81.86C550.572 81.86 551.202 82.11 551.655 82.61C552.115 83.11 552.345 83.8133 552.345 84.72V86.28C552.345 87.2133 552.115 87.9233 551.655 88.41C551.202 88.8967 550.572 89.14 549.765 89.14C548.958 89.14 548.325 88.8967 547.865 88.41C547.412 87.9233 547.185 87.2133 547.185 86.28V84.72ZM549.765 87.94C550.005 87.94 550.205 87.9033 550.365 87.83C550.525 87.75 550.655 87.64 550.755 87.5C550.855 87.36 550.925 87.1933 550.965 87C551.005 86.8067 551.025 86.5933 551.025 86.36V84.64C551.025 84.42 551.002 84.2133 550.955 84.02C550.908 83.8267 550.835 83.66 550.735 83.52C550.635 83.38 550.505 83.27 550.345 83.19C550.185 83.1033 549.992 83.06 549.765 83.06C549.538 83.06 549.345 83.1033 549.185 83.19C549.025 83.27 548.895 83.38 548.795 83.52C548.695 83.66 548.622 83.8267 548.575 84.02C548.528 84.2133 548.505 84.42 548.505 84.64V86.36C548.505 86.5933 548.525 86.8067 548.565 87C548.605 87.1933 548.675 87.36 548.775 87.5C548.875 87.64 549.005 87.75 549.165 87.83C549.325 87.9033 549.525 87.94 549.765 87.94Z"
    fill="#5078E1"
  /><path
    d="M555.048 85.5C555.048 85.2733 555.131 85.0767 555.298 84.91C555.465 84.7433 555.661 84.66 555.888 84.66C556.115 84.66 556.311 84.7433 556.478 84.91C556.645 85.0767 556.728 85.2733 556.728 85.5C556.728 85.7267 556.645 85.9233 556.478 86.09C556.311 86.2567 556.115 86.34 555.888 86.34C555.661 86.34 555.465 86.2567 555.298 86.09C555.131 85.9233 555.048 85.7267 555.048 85.5ZM553.308 84.72C553.308 83.8133 553.535 83.11 553.988 82.61C554.448 82.11 555.081 81.86 555.888 81.86C556.695 81.86 557.325 82.11 557.778 82.61C558.238 83.11 558.468 83.8133 558.468 84.72V86.28C558.468 87.2133 558.238 87.9233 557.778 88.41C557.325 88.8967 556.695 89.14 555.888 89.14C555.081 89.14 554.448 88.8967 553.988 88.41C553.535 87.9233 553.308 87.2133 553.308 86.28V84.72ZM555.888 87.94C556.128 87.94 556.328 87.9033 556.488 87.83C556.648 87.75 556.778 87.64 556.878 87.5C556.978 87.36 557.048 87.1933 557.088 87C557.128 86.8067 557.148 86.5933 557.148 86.36V84.64C557.148 84.42 557.125 84.2133 557.078 84.02C557.031 83.8267 556.958 83.66 556.858 83.52C556.758 83.38 556.628 83.27 556.468 83.19C556.308 83.1033 556.115 83.06 555.888 83.06C555.661 83.06 555.468 83.1033 555.308 83.19C555.148 83.27 555.018 83.38 554.918 83.52C554.818 83.66 554.745 83.8267 554.698 84.02C554.651 84.2133 554.628 84.42 554.628 84.64V86.36C554.628 86.5933 554.648 86.8067 554.688 87C554.728 87.1933 554.798 87.36 554.898 87.5C554.998 87.64 555.128 87.75 555.288 87.83C555.448 87.9033 555.648 87.94 555.888 87.94Z"
    fill="#5078E1"
  /><path
    d="M561.171 85.5C561.171 85.2733 561.254 85.0767 561.421 84.91C561.588 84.7433 561.785 84.66 562.011 84.66C562.238 84.66 562.434 84.7433 562.601 84.91C562.768 85.0767 562.851 85.2733 562.851 85.5C562.851 85.7267 562.768 85.9233 562.601 86.09C562.434 86.2567 562.238 86.34 562.011 86.34C561.785 86.34 561.588 86.2567 561.421 86.09C561.254 85.9233 561.171 85.7267 561.171 85.5ZM559.431 84.72C559.431 83.8133 559.658 83.11 560.111 82.61C560.571 82.11 561.205 81.86 562.011 81.86C562.818 81.86 563.448 82.11 563.901 82.61C564.361 83.11 564.591 83.8133 564.591 84.72V86.28C564.591 87.2133 564.361 87.9233 563.901 88.41C563.448 88.8967 562.818 89.14 562.011 89.14C561.205 89.14 560.571 88.8967 560.111 88.41C559.658 87.9233 559.431 87.2133 559.431 86.28V84.72ZM562.011 87.94C562.251 87.94 562.451 87.9033 562.611 87.83C562.771 87.75 562.901 87.64 563.001 87.5C563.101 87.36 563.171 87.1933 563.211 87C563.251 86.8067 563.271 86.5933 563.271 86.36V84.64C563.271 84.42 563.248 84.2133 563.201 84.02C563.155 83.8267 563.081 83.66 562.981 83.52C562.881 83.38 562.751 83.27 562.591 83.19C562.431 83.1033 562.238 83.06 562.011 83.06C561.785 83.06 561.591 83.1033 561.431 83.19C561.271 83.27 561.141 83.38 561.041 83.52C560.941 83.66 560.868 83.8267 560.821 84.02C560.775 84.2133 560.751 84.42 560.751 84.64V86.36C560.751 86.5933 560.771 86.8067 560.811 87C560.851 87.1933 560.921 87.36 561.021 87.5C561.121 87.64 561.251 87.75 561.411 87.83C561.571 87.9033 561.771 87.94 562.011 87.94Z"
    fill="#5078E1"
  /><path
    d="M567.294 85.5C567.294 85.2733 567.378 85.0767 567.544 84.91C567.711 84.7433 567.908 84.66 568.134 84.66C568.361 84.66 568.558 84.7433 568.724 84.91C568.891 85.0767 568.974 85.2733 568.974 85.5C568.974 85.7267 568.891 85.9233 568.724 86.09C568.558 86.2567 568.361 86.34 568.134 86.34C567.908 86.34 567.711 86.2567 567.544 86.09C567.378 85.9233 567.294 85.7267 567.294 85.5ZM565.554 84.72C565.554 83.8133 565.781 83.11 566.234 82.61C566.694 82.11 567.328 81.86 568.134 81.86C568.941 81.86 569.571 82.11 570.024 82.61C570.484 83.11 570.714 83.8133 570.714 84.72V86.28C570.714 87.2133 570.484 87.9233 570.024 88.41C569.571 88.8967 568.941 89.14 568.134 89.14C567.328 89.14 566.694 88.8967 566.234 88.41C565.781 87.9233 565.554 87.2133 565.554 86.28V84.72ZM568.134 87.94C568.374 87.94 568.574 87.9033 568.734 87.83C568.894 87.75 569.024 87.64 569.124 87.5C569.224 87.36 569.294 87.1933 569.334 87C569.374 86.8067 569.394 86.5933 569.394 86.36V84.64C569.394 84.42 569.371 84.2133 569.324 84.02C569.278 83.8267 569.204 83.66 569.104 83.52C569.004 83.38 568.874 83.27 568.714 83.19C568.554 83.1033 568.361 83.06 568.134 83.06C567.908 83.06 567.714 83.1033 567.554 83.19C567.394 83.27 567.264 83.38 567.164 83.52C567.064 83.66 566.991 83.8267 566.944 84.02C566.898 84.2133 566.874 84.42 566.874 84.64V86.36C566.874 86.5933 566.894 86.8067 566.934 87C566.974 87.1933 567.044 87.36 567.144 87.5C567.244 87.64 567.374 87.75 567.534 87.83C567.694 87.9033 567.894 87.94 568.134 87.94Z"
    fill="#5078E1"
  /><path
    d="M573.417 85.5C573.417 85.2733 573.501 85.0767 573.667 84.91C573.834 84.7433 574.031 84.66 574.257 84.66C574.484 84.66 574.681 84.7433 574.847 84.91C575.014 85.0767 575.097 85.2733 575.097 85.5C575.097 85.7267 575.014 85.9233 574.847 86.09C574.681 86.2567 574.484 86.34 574.257 86.34C574.031 86.34 573.834 86.2567 573.667 86.09C573.501 85.9233 573.417 85.7267 573.417 85.5ZM571.677 84.72C571.677 83.8133 571.904 83.11 572.357 82.61C572.817 82.11 573.451 81.86 574.257 81.86C575.064 81.86 575.694 82.11 576.147 82.61C576.607 83.11 576.837 83.8133 576.837 84.72V86.28C576.837 87.2133 576.607 87.9233 576.147 88.41C575.694 88.8967 575.064 89.14 574.257 89.14C573.451 89.14 572.817 88.8967 572.357 88.41C571.904 87.9233 571.677 87.2133 571.677 86.28V84.72ZM574.257 87.94C574.497 87.94 574.697 87.9033 574.857 87.83C575.017 87.75 575.147 87.64 575.247 87.5C575.347 87.36 575.417 87.1933 575.457 87C575.497 86.8067 575.517 86.5933 575.517 86.36V84.64C575.517 84.42 575.494 84.2133 575.447 84.02C575.401 83.8267 575.327 83.66 575.227 83.52C575.127 83.38 574.997 83.27 574.837 83.19C574.677 83.1033 574.484 83.06 574.257 83.06C574.031 83.06 573.837 83.1033 573.677 83.19C573.517 83.27 573.387 83.38 573.287 83.52C573.187 83.66 573.114 83.8267 573.067 84.02C573.021 84.2133 572.997 84.42 572.997 84.64V86.36C572.997 86.5933 573.017 86.8067 573.057 87C573.097 87.1933 573.167 87.36 573.267 87.5C573.367 87.64 573.497 87.75 573.657 87.83C573.817 87.9033 574.017 87.94 574.257 87.94Z"
    fill="#5078E1"
  /><path
    d="M579.54 85.5C579.54 85.2733 579.624 85.0767 579.79 84.91C579.957 84.7433 580.154 84.66 580.38 84.66C580.607 84.66 580.804 84.7433 580.97 84.91C581.137 85.0767 581.22 85.2733 581.22 85.5C581.22 85.7267 581.137 85.9233 580.97 86.09C580.804 86.2567 580.607 86.34 580.38 86.34C580.154 86.34 579.957 86.2567 579.79 86.09C579.624 85.9233 579.54 85.7267 579.54 85.5ZM577.8 84.72C577.8 83.8133 578.027 83.11 578.48 82.61C578.94 82.11 579.574 81.86 580.38 81.86C581.187 81.86 581.817 82.11 582.27 82.61C582.73 83.11 582.96 83.8133 582.96 84.72V86.28C582.96 87.2133 582.73 87.9233 582.27 88.41C581.817 88.8967 581.187 89.14 580.38 89.14C579.574 89.14 578.94 88.8967 578.48 88.41C578.027 87.9233 577.8 87.2133 577.8 86.28V84.72ZM580.38 87.94C580.62 87.94 580.82 87.9033 580.98 87.83C581.14 87.75 581.27 87.64 581.37 87.5C581.47 87.36 581.54 87.1933 581.58 87C581.62 86.8067 581.64 86.5933 581.64 86.36V84.64C581.64 84.42 581.617 84.2133 581.57 84.02C581.524 83.8267 581.45 83.66 581.35 83.52C581.25 83.38 581.12 83.27 580.96 83.19C580.8 83.1033 580.607 83.06 580.38 83.06C580.154 83.06 579.96 83.1033 579.8 83.19C579.64 83.27 579.51 83.38 579.41 83.52C579.31 83.66 579.237 83.8267 579.19 84.02C579.144 84.2133 579.12 84.42 579.12 84.64V86.36C579.12 86.5933 579.14 86.8067 579.18 87C579.22 87.1933 579.29 87.36 579.39 87.5C579.49 87.64 579.62 87.75 579.78 87.83C579.94 87.9033 580.14 87.94 580.38 87.94Z"
    fill="#5078E1"
  /><path
    d="M586.083 87.74V82.66H585.903L585.243 85.06H583.983V84.94L584.823 82H587.403V87.74H589.143V89H584.103V87.74H586.083Z"
    fill="#5078E1"
  /><path
    d="M592.206 87.74V82.66H592.026L591.366 85.06H590.106V84.94L590.946 82H593.526V87.74H595.266V89H590.226V87.74H592.206Z"
    fill="#5078E1"
  /><path
    d="M598.329 87.74V82.66H598.149L597.489 85.06H596.229V84.94L597.069 82H599.649V87.74H601.389V89H596.349V87.74H598.329Z"
    fill="#5078E1"
  /><path
    d="M604.452 87.74V82.66H604.272L603.613 85.06H602.352V84.94L603.193 82H605.772V87.74H607.512V89H602.472V87.74H604.452Z"
    fill="#5078E1"
  /><path
    d="M610.156 85.5C610.156 85.2733 610.239 85.0767 610.406 84.91C610.572 84.7433 610.769 84.66 610.996 84.66C611.222 84.66 611.419 84.7433 611.586 84.91C611.752 85.0767 611.836 85.2733 611.836 85.5C611.836 85.7267 611.752 85.9233 611.586 86.09C611.419 86.2567 611.222 86.34 610.996 86.34C610.769 86.34 610.572 86.2567 610.406 86.09C610.239 85.9233 610.156 85.7267 610.156 85.5ZM608.416 84.72C608.416 83.8133 608.642 83.11 609.096 82.61C609.556 82.11 610.189 81.86 610.996 81.86C611.802 81.86 612.432 82.11 612.886 82.61C613.346 83.11 613.576 83.8133 613.576 84.72V86.28C613.576 87.2133 613.346 87.9233 612.886 88.41C612.432 88.8967 611.802 89.14 610.996 89.14C610.189 89.14 609.556 88.8967 609.096 88.41C608.642 87.9233 608.416 87.2133 608.416 86.28V84.72ZM610.996 87.94C611.236 87.94 611.436 87.9033 611.596 87.83C611.756 87.75 611.886 87.64 611.986 87.5C612.086 87.36 612.156 87.1933 612.196 87C612.236 86.8067 612.256 86.5933 612.256 86.36V84.64C612.256 84.42 612.232 84.2133 612.186 84.02C612.139 83.8267 612.066 83.66 611.966 83.52C611.866 83.38 611.736 83.27 611.576 83.19C611.416 83.1033 611.222 83.06 610.996 83.06C610.769 83.06 610.576 83.1033 610.416 83.19C610.256 83.27 610.126 83.38 610.026 83.52C609.926 83.66 609.852 83.8267 609.806 84.02C609.759 84.2133 609.736 84.42 609.736 84.64V86.36C609.736 86.5933 609.756 86.8067 609.796 87C609.836 87.1933 609.906 87.36 610.006 87.5C610.106 87.64 610.236 87.75 610.396 87.83C610.556 87.9033 610.756 87.94 610.996 87.94Z"
    fill="#5078E1"
  /><path
    d="M616.279 85.5C616.279 85.2733 616.362 85.0767 616.529 84.91C616.695 84.7433 616.892 84.66 617.119 84.66C617.345 84.66 617.542 84.7433 617.709 84.91C617.875 85.0767 617.959 85.2733 617.959 85.5C617.959 85.7267 617.875 85.9233 617.709 86.09C617.542 86.2567 617.345 86.34 617.119 86.34C616.892 86.34 616.695 86.2567 616.529 86.09C616.362 85.9233 616.279 85.7267 616.279 85.5ZM614.539 84.72C614.539 83.8133 614.765 83.11 615.219 82.61C615.679 82.11 616.312 81.86 617.119 81.86C617.925 81.86 618.555 82.11 619.009 82.61C619.469 83.11 619.699 83.8133 619.699 84.72V86.28C619.699 87.2133 619.469 87.9233 619.009 88.41C618.555 88.8967 617.925 89.14 617.119 89.14C616.312 89.14 615.679 88.8967 615.219 88.41C614.765 87.9233 614.539 87.2133 614.539 86.28V84.72ZM617.119 87.94C617.359 87.94 617.559 87.9033 617.719 87.83C617.879 87.75 618.009 87.64 618.109 87.5C618.209 87.36 618.279 87.1933 618.319 87C618.359 86.8067 618.379 86.5933 618.379 86.36V84.64C618.379 84.42 618.355 84.2133 618.309 84.02C618.262 83.8267 618.189 83.66 618.089 83.52C617.989 83.38 617.859 83.27 617.699 83.19C617.539 83.1033 617.345 83.06 617.119 83.06C616.892 83.06 616.699 83.1033 616.539 83.19C616.379 83.27 616.249 83.38 616.149 83.52C616.049 83.66 615.975 83.8267 615.929 84.02C615.882 84.2133 615.859 84.42 615.859 84.64V86.36C615.859 86.5933 615.879 86.8067 615.919 87C615.959 87.1933 616.029 87.36 616.129 87.5C616.229 87.64 616.359 87.75 616.519 87.83C616.679 87.9033 616.879 87.94 617.119 87.94Z"
    fill="#5078E1"
  /><path
    d="M622.822 87.74V82.66H622.642L621.982 85.06H620.722V84.94L621.562 82H624.142V87.74H625.882V89H620.842V87.74H622.822Z"
    fill="#5078E1"
  /><path
    d="M628.525 85.5C628.525 85.2733 628.608 85.0767 628.775 84.91C628.941 84.7433 629.138 84.66 629.365 84.66C629.591 84.66 629.788 84.7433 629.955 84.91C630.121 85.0767 630.205 85.2733 630.205 85.5C630.205 85.7267 630.121 85.9233 629.955 86.09C629.788 86.2567 629.591 86.34 629.365 86.34C629.138 86.34 628.941 86.2567 628.775 86.09C628.608 85.9233 628.525 85.7267 628.525 85.5ZM626.785 84.72C626.785 83.8133 627.011 83.11 627.465 82.61C627.925 82.11 628.558 81.86 629.365 81.86C630.171 81.86 630.801 82.11 631.255 82.61C631.715 83.11 631.945 83.8133 631.945 84.72V86.28C631.945 87.2133 631.715 87.9233 631.255 88.41C630.801 88.8967 630.171 89.14 629.365 89.14C628.558 89.14 627.925 88.8967 627.465 88.41C627.011 87.9233 626.785 87.2133 626.785 86.28V84.72ZM629.365 87.94C629.605 87.94 629.805 87.9033 629.965 87.83C630.125 87.75 630.255 87.64 630.355 87.5C630.455 87.36 630.525 87.1933 630.565 87C630.605 86.8067 630.625 86.5933 630.625 86.36V84.64C630.625 84.42 630.601 84.2133 630.555 84.02C630.508 83.8267 630.435 83.66 630.335 83.52C630.235 83.38 630.105 83.27 629.945 83.19C629.785 83.1033 629.591 83.06 629.365 83.06C629.138 83.06 628.945 83.1033 628.785 83.19C628.625 83.27 628.495 83.38 628.395 83.52C628.295 83.66 628.221 83.8267 628.175 84.02C628.128 84.2133 628.105 84.42 628.105 84.64V86.36C628.105 86.5933 628.125 86.8067 628.165 87C628.205 87.1933 628.275 87.36 628.375 87.5C628.475 87.64 628.605 87.75 628.765 87.83C628.925 87.9033 629.125 87.94 629.365 87.94Z"
    fill="#5078E1"
  /><path
    d="M635.068 87.74V82.66H634.888L634.228 85.06H632.968V84.94L633.808 82H636.388V87.74H638.128V89H633.088V87.74H635.068Z"
    fill="#5078E1"
  /><path
    d="M641.191 87.74V82.66H641.011L640.351 85.06H639.091V84.94L639.931 82H642.511V87.74H644.251V89H639.211V87.74H641.191Z"
    fill="#5078E1"
  /><path
    d="M646.894 85.5C646.894 85.2733 646.977 85.0767 647.144 84.91C647.31 84.7433 647.507 84.66 647.734 84.66C647.961 84.66 648.157 84.7433 648.324 84.91C648.49 85.0767 648.574 85.2733 648.574 85.5C648.574 85.7267 648.49 85.9233 648.324 86.09C648.157 86.2567 647.961 86.34 647.734 86.34C647.507 86.34 647.31 86.2567 647.144 86.09C646.977 85.9233 646.894 85.7267 646.894 85.5ZM645.154 84.72C645.154 83.8133 645.38 83.11 645.834 82.61C646.294 82.11 646.927 81.86 647.734 81.86C648.541 81.86 649.17 82.11 649.624 82.61C650.084 83.11 650.314 83.8133 650.314 84.72V86.28C650.314 87.2133 650.084 87.9233 649.624 88.41C649.17 88.8967 648.541 89.14 647.734 89.14C646.927 89.14 646.294 88.8967 645.834 88.41C645.38 87.9233 645.154 87.2133 645.154 86.28V84.72ZM647.734 87.94C647.974 87.94 648.174 87.9033 648.334 87.83C648.494 87.75 648.624 87.64 648.724 87.5C648.824 87.36 648.894 87.1933 648.934 87C648.974 86.8067 648.994 86.5933 648.994 86.36V84.64C648.994 84.42 648.971 84.2133 648.924 84.02C648.877 83.8267 648.804 83.66 648.704 83.52C648.604 83.38 648.474 83.27 648.314 83.19C648.154 83.1033 647.961 83.06 647.734 83.06C647.507 83.06 647.314 83.1033 647.154 83.19C646.994 83.27 646.864 83.38 646.764 83.52C646.664 83.66 646.591 83.8267 646.544 84.02C646.497 84.2133 646.474 84.42 646.474 84.64V86.36C646.474 86.5933 646.494 86.8067 646.534 87C646.574 87.1933 646.644 87.36 646.744 87.5C646.844 87.64 646.974 87.75 647.134 87.83C647.294 87.9033 647.494 87.94 647.734 87.94Z"
    fill="#5078E1"
  /><path
    d="M653.437 87.74V82.66H653.257L652.597 85.06H651.337V84.94L652.177 82H654.757V87.74H656.497V89H651.457V87.74H653.437Z"
    fill="#5078E1"
  /><path
    d="M659.56 87.74V82.66H659.38L658.72 85.06H657.46V84.94L658.3 82H660.88V87.74H662.62V89H657.58V87.74H659.56Z"
    fill="#5078E1"
  /><path
    d="M665.683 87.74V82.66H665.503L664.843 85.06H663.583V84.94L664.423 82H667.003V87.74H668.743V89H663.703V87.74H665.683Z"
    fill="#5078E1"
  /><path
    d="M16.64 104.74V99.66H16.46L15.8 102.06H14.54V101.94L15.38 99H17.96V104.74H19.7V106H14.66V104.74H16.64Z"
    fill="#5078E1"
  /><path
    d="M22.343 102.5C22.343 102.273 22.4264 102.077 22.593 101.91C22.7597 101.743 22.9564 101.66 23.183 101.66C23.4097 101.66 23.6064 101.743 23.773 101.91C23.9397 102.077 24.023 102.273 24.023 102.5C24.023 102.727 23.9397 102.923 23.773 103.09C23.6064 103.257 23.4097 103.34 23.183 103.34C22.9564 103.34 22.7597 103.257 22.593 103.09C22.4264 102.923 22.343 102.727 22.343 102.5ZM20.603 101.72C20.603 100.813 20.8297 100.11 21.283 99.61C21.743 99.11 22.3764 98.86 23.183 98.86C23.9897 98.86 24.6197 99.11 25.073 99.61C25.533 100.11 25.763 100.813 25.763 101.72V103.28C25.763 104.213 25.533 104.923 25.073 105.41C24.6197 105.897 23.9897 106.14 23.183 106.14C22.3764 106.14 21.743 105.897 21.283 105.41C20.8297 104.923 20.603 104.213 20.603 103.28V101.72ZM23.183 104.94C23.423 104.94 23.623 104.903 23.783 104.83C23.943 104.75 24.073 104.64 24.173 104.5C24.273 104.36 24.343 104.193 24.383 104C24.423 103.807 24.443 103.593 24.443 103.36V101.64C24.443 101.42 24.4197 101.213 24.373 101.02C24.3264 100.827 24.253 100.66 24.153 100.52C24.053 100.38 23.923 100.27 23.763 100.19C23.603 100.103 23.4097 100.06 23.183 100.06C22.9564 100.06 22.763 100.103 22.603 100.19C22.443 100.27 22.313 100.38 22.213 100.52C22.113 100.66 22.0397 100.827 21.993 101.02C21.9464 101.213 21.923 101.42 21.923 101.64V103.36C21.923 103.593 21.943 103.807 21.983 104C22.023 104.193 22.093 104.36 22.193 104.5C22.293 104.64 22.423 104.75 22.583 104.83C22.743 104.903 22.943 104.94 23.183 104.94Z"
    fill="#5078E1"
  /><path
    d="M28.8861 104.74V99.66H28.7061L28.0461 102.06H26.7861V101.94L27.6261 99H30.2061V104.74H31.9461V106H26.9061V104.74H28.8861Z"
    fill="#5078E1"
  /><path
    d="M35.0091 104.74V99.66H34.8291L34.1691 102.06H32.9091V101.94L33.7491 99H36.3291V104.74H38.0691V106H33.0291V104.74H35.0091Z"
    fill="#5078E1"
  /><path
    d="M41.1322 104.74V99.66H40.9522L40.2922 102.06H39.0322V101.94L39.8722 99H42.4522V104.74H44.1922V106H39.1522V104.74H41.1322Z"
    fill="#5078E1"
  /><path
    d="M46.8352 102.5C46.8352 102.273 46.9186 102.077 47.0852 101.91C47.2519 101.743 47.4486 101.66 47.6752 101.66C47.9019 101.66 48.0986 101.743 48.2652 101.91C48.4319 102.077 48.5152 102.273 48.5152 102.5C48.5152 102.727 48.4319 102.923 48.2652 103.09C48.0986 103.257 47.9019 103.34 47.6752 103.34C47.4486 103.34 47.2519 103.257 47.0852 103.09C46.9186 102.923 46.8352 102.727 46.8352 102.5ZM45.0952 101.72C45.0952 100.813 45.3219 100.11 45.7752 99.61C46.2352 99.11 46.8686 98.86 47.6752 98.86C48.4819 98.86 49.1119 99.11 49.5652 99.61C50.0252 100.11 50.2552 100.813 50.2552 101.72V103.28C50.2552 104.213 50.0252 104.923 49.5652 105.41C49.1119 105.897 48.4819 106.14 47.6752 106.14C46.8686 106.14 46.2352 105.897 45.7752 105.41C45.3219 104.923 45.0952 104.213 45.0952 103.28V101.72ZM47.6752 104.94C47.9152 104.94 48.1152 104.903 48.2752 104.83C48.4352 104.75 48.5652 104.64 48.6652 104.5C48.7652 104.36 48.8352 104.193 48.8752 104C48.9152 103.807 48.9352 103.593 48.9352 103.36V101.64C48.9352 101.42 48.9119 101.213 48.8652 101.02C48.8186 100.827 48.7452 100.66 48.6452 100.52C48.5452 100.38 48.4152 100.27 48.2552 100.19C48.0952 100.103 47.9019 100.06 47.6752 100.06C47.4486 100.06 47.2552 100.103 47.0952 100.19C46.9352 100.27 46.8052 100.38 46.7052 100.52C46.6052 100.66 46.5319 100.827 46.4852 101.02C46.4386 101.213 46.4152 101.42 46.4152 101.64V103.36C46.4152 103.593 46.4352 103.807 46.4752 104C46.5152 104.193 46.5852 104.36 46.6852 104.5C46.7852 104.64 46.9152 104.75 47.0752 104.83C47.2352 104.903 47.4352 104.94 47.6752 104.94Z"
    fill="#5078E1"
  /><path
    d="M53.3783 104.74V99.66H53.1983L52.5383 102.06H51.2783V101.94L52.1183 99H54.6983V104.74H56.4383V106H51.3983V104.74H53.3783Z"
    fill="#5078E1"
  /><path
    d="M59.0813 102.5C59.0813 102.273 59.1647 102.077 59.3313 101.91C59.498 101.743 59.6947 101.66 59.9213 101.66C60.148 101.66 60.3447 101.743 60.5113 101.91C60.678 102.077 60.7613 102.273 60.7613 102.5C60.7613 102.727 60.678 102.923 60.5113 103.09C60.3447 103.257 60.148 103.34 59.9213 103.34C59.6947 103.34 59.498 103.257 59.3313 103.09C59.1647 102.923 59.0813 102.727 59.0813 102.5ZM57.3413 101.72C57.3413 100.813 57.568 100.11 58.0213 99.61C58.4813 99.11 59.1147 98.86 59.9213 98.86C60.728 98.86 61.358 99.11 61.8113 99.61C62.2713 100.11 62.5013 100.813 62.5013 101.72V103.28C62.5013 104.213 62.2713 104.923 61.8113 105.41C61.358 105.897 60.728 106.14 59.9213 106.14C59.1147 106.14 58.4813 105.897 58.0213 105.41C57.568 104.923 57.3413 104.213 57.3413 103.28V101.72ZM59.9213 104.94C60.1613 104.94 60.3613 104.903 60.5213 104.83C60.6813 104.75 60.8113 104.64 60.9113 104.5C61.0113 104.36 61.0813 104.193 61.1213 104C61.1613 103.807 61.1813 103.593 61.1813 103.36V101.64C61.1813 101.42 61.158 101.213 61.1113 101.02C61.0647 100.827 60.9913 100.66 60.8913 100.52C60.7913 100.38 60.6613 100.27 60.5013 100.19C60.3413 100.103 60.148 100.06 59.9213 100.06C59.6947 100.06 59.5013 100.103 59.3413 100.19C59.1813 100.27 59.0513 100.38 58.9513 100.52C58.8513 100.66 58.778 100.827 58.7313 101.02C58.6847 101.213 58.6613 101.42 58.6613 101.64V103.36C58.6613 103.593 58.6813 103.807 58.7213 104C58.7613 104.193 58.8313 104.36 58.9313 104.5C59.0313 104.64 59.1613 104.75 59.3213 104.83C59.4813 104.903 59.6813 104.94 59.9213 104.94Z"
    fill="#5078E1"
  /><path
    d="M65.6244 104.74V99.66H65.4444L64.7844 102.06H63.5244V101.94L64.3644 99H66.9444V104.74H68.6844V106H63.6444V104.74H65.6244Z"
    fill="#5078E1"
  /><path
    d="M71.3274 102.5C71.3274 102.273 71.4108 102.077 71.5774 101.91C71.7441 101.743 71.9408 101.66 72.1674 101.66C72.3941 101.66 72.5908 101.743 72.7574 101.91C72.9241 102.077 73.0074 102.273 73.0074 102.5C73.0074 102.727 72.9241 102.923 72.7574 103.09C72.5908 103.257 72.3941 103.34 72.1674 103.34C71.9408 103.34 71.7441 103.257 71.5774 103.09C71.4108 102.923 71.3274 102.727 71.3274 102.5ZM69.5874 101.72C69.5874 100.813 69.8141 100.11 70.2674 99.61C70.7274 99.11 71.3608 98.86 72.1674 98.86C72.9741 98.86 73.6041 99.11 74.0574 99.61C74.5174 100.11 74.7474 100.813 74.7474 101.72V103.28C74.7474 104.213 74.5174 104.923 74.0574 105.41C73.6041 105.897 72.9741 106.14 72.1674 106.14C71.3608 106.14 70.7274 105.897 70.2674 105.41C69.8141 104.923 69.5874 104.213 69.5874 103.28V101.72ZM72.1674 104.94C72.4074 104.94 72.6074 104.903 72.7674 104.83C72.9274 104.75 73.0574 104.64 73.1574 104.5C73.2574 104.36 73.3274 104.193 73.3674 104C73.4074 103.807 73.4274 103.593 73.4274 103.36V101.64C73.4274 101.42 73.4041 101.213 73.3574 101.02C73.3108 100.827 73.2374 100.66 73.1374 100.52C73.0374 100.38 72.9074 100.27 72.7474 100.19C72.5874 100.103 72.3941 100.06 72.1674 100.06C71.9408 100.06 71.7474 100.103 71.5874 100.19C71.4274 100.27 71.2974 100.38 71.1974 100.52C71.0974 100.66 71.0241 100.827 70.9774 101.02C70.9308 101.213 70.9074 101.42 70.9074 101.64V103.36C70.9074 103.593 70.9274 103.807 70.9674 104C71.0074 104.193 71.0774 104.36 71.1774 104.5C71.2774 104.64 71.4074 104.75 71.5674 104.83C71.7274 104.903 71.9274 104.94 72.1674 104.94Z"
    fill="#5078E1"
  /><path
    d="M77.8705 104.74V99.66H77.6905L77.0305 102.06H75.7705V101.94L76.6105 99H79.1905V104.74H80.9305V106H75.8905V104.74H77.8705Z"
    fill="#5078E1"
  /><path
    d="M83.9935 104.74V99.66H83.8135L83.1535 102.06H81.8935V101.94L82.7335 99H85.3135V104.74H87.0535V106H82.0135V104.74H83.9935Z"
    fill="#5078E1"
  /><path
    d="M90.1166 104.74V99.66H89.9366L89.2766 102.06H88.0166V101.94L88.8566 99H91.4366V104.74H93.1766V106H88.1366V104.74H90.1166Z"
    fill="#5078E1"
  /><path
    d="M95.8196 102.5C95.8196 102.273 95.9029 102.077 96.0696 101.91C96.2363 101.743 96.4329 101.66 96.6596 101.66C96.8863 101.66 97.0829 101.743 97.2496 101.91C97.4163 102.077 97.4996 102.273 97.4996 102.5C97.4996 102.727 97.4163 102.923 97.2496 103.09C97.0829 103.257 96.8863 103.34 96.6596 103.34C96.4329 103.34 96.2363 103.257 96.0696 103.09C95.9029 102.923 95.8196 102.727 95.8196 102.5ZM94.0796 101.72C94.0796 100.813 94.3063 100.11 94.7596 99.61C95.2196 99.11 95.8529 98.86 96.6596 98.86C97.4663 98.86 98.0963 99.11 98.5496 99.61C99.0096 100.11 99.2396 100.813 99.2396 101.72V103.28C99.2396 104.213 99.0096 104.923 98.5496 105.41C98.0963 105.897 97.4663 106.14 96.6596 106.14C95.8529 106.14 95.2196 105.897 94.7596 105.41C94.3063 104.923 94.0796 104.213 94.0796 103.28V101.72ZM96.6596 104.94C96.8996 104.94 97.0996 104.903 97.2596 104.83C97.4196 104.75 97.5496 104.64 97.6496 104.5C97.7496 104.36 97.8196 104.193 97.8596 104C97.8996 103.807 97.9196 103.593 97.9196 103.36V101.64C97.9196 101.42 97.8963 101.213 97.8496 101.02C97.8029 100.827 97.7296 100.66 97.6296 100.52C97.5296 100.38 97.3996 100.27 97.2396 100.19C97.0796 100.103 96.8863 100.06 96.6596 100.06C96.4329 100.06 96.2396 100.103 96.0796 100.19C95.9196 100.27 95.7896 100.38 95.6896 100.52C95.5896 100.66 95.5163 100.827 95.4696 101.02C95.4229 101.213 95.3996 101.42 95.3996 101.64V103.36C95.3996 103.593 95.4196 103.807 95.4596 104C95.4996 104.193 95.5696 104.36 95.6696 104.5C95.7696 104.64 95.8996 104.75 96.0596 104.83C96.2196 104.903 96.4196 104.94 96.6596 104.94Z"
    fill="#5078E1"
  /><path
    d="M101.943 102.5C101.943 102.273 102.026 102.077 102.193 101.91C102.359 101.743 102.556 101.66 102.783 101.66C103.009 101.66 103.206 101.743 103.373 101.91C103.539 102.077 103.623 102.273 103.623 102.5C103.623 102.727 103.539 102.923 103.373 103.09C103.206 103.257 103.009 103.34 102.783 103.34C102.556 103.34 102.359 103.257 102.193 103.09C102.026 102.923 101.943 102.727 101.943 102.5ZM100.203 101.72C100.203 100.813 100.429 100.11 100.883 99.61C101.343 99.11 101.976 98.86 102.783 98.86C103.589 98.86 104.219 99.11 104.673 99.61C105.133 100.11 105.363 100.813 105.363 101.72V103.28C105.363 104.213 105.133 104.923 104.673 105.41C104.219 105.897 103.589 106.14 102.783 106.14C101.976 106.14 101.343 105.897 100.883 105.41C100.429 104.923 100.203 104.213 100.203 103.28V101.72ZM102.783 104.94C103.023 104.94 103.223 104.903 103.383 104.83C103.543 104.75 103.673 104.64 103.773 104.5C103.873 104.36 103.943 104.193 103.983 104C104.023 103.807 104.043 103.593 104.043 103.36V101.64C104.043 101.42 104.019 101.213 103.973 101.02C103.926 100.827 103.853 100.66 103.753 100.52C103.653 100.38 103.523 100.27 103.363 100.19C103.203 100.103 103.009 100.06 102.783 100.06C102.556 100.06 102.363 100.103 102.203 100.19C102.043 100.27 101.913 100.38 101.813 100.52C101.713 100.66 101.639 100.827 101.593 101.02C101.546 101.213 101.523 101.42 101.523 101.64V103.36C101.523 103.593 101.543 103.807 101.583 104C101.623 104.193 101.693 104.36 101.793 104.5C101.893 104.64 102.023 104.75 102.183 104.83C102.343 104.903 102.543 104.94 102.783 104.94Z"
    fill="#5078E1"
  /><path
    d="M108.486 104.74V99.66H108.306L107.646 102.06H106.386V101.94L107.226 99H109.806V104.74H111.546V106H106.506V104.74H108.486Z"
    fill="#5078E1"
  /><path
    d="M114.189 102.5C114.189 102.273 114.272 102.077 114.439 101.91C114.605 101.743 114.802 101.66 115.029 101.66C115.255 101.66 115.452 101.743 115.619 101.91C115.785 102.077 115.869 102.273 115.869 102.5C115.869 102.727 115.785 102.923 115.619 103.09C115.452 103.257 115.255 103.34 115.029 103.34C114.802 103.34 114.605 103.257 114.439 103.09C114.272 102.923 114.189 102.727 114.189 102.5ZM112.449 101.72C112.449 100.813 112.675 100.11 113.129 99.61C113.589 99.11 114.222 98.86 115.029 98.86C115.835 98.86 116.465 99.11 116.919 99.61C117.379 100.11 117.609 100.813 117.609 101.72V103.28C117.609 104.213 117.379 104.923 116.919 105.41C116.465 105.897 115.835 106.14 115.029 106.14C114.222 106.14 113.589 105.897 113.129 105.41C112.675 104.923 112.449 104.213 112.449 103.28V101.72ZM115.029 104.94C115.269 104.94 115.469 104.903 115.629 104.83C115.789 104.75 115.919 104.64 116.019 104.5C116.119 104.36 116.189 104.193 116.229 104C116.269 103.807 116.289 103.593 116.289 103.36V101.64C116.289 101.42 116.265 101.213 116.219 101.02C116.172 100.827 116.099 100.66 115.999 100.52C115.899 100.38 115.769 100.27 115.609 100.19C115.449 100.103 115.255 100.06 115.029 100.06C114.802 100.06 114.609 100.103 114.449 100.19C114.289 100.27 114.159 100.38 114.059 100.52C113.959 100.66 113.885 100.827 113.839 101.02C113.792 101.213 113.769 101.42 113.769 101.64V103.36C113.769 103.593 113.789 103.807 113.829 104C113.869 104.193 113.939 104.36 114.039 104.5C114.139 104.64 114.269 104.75 114.429 104.83C114.589 104.903 114.789 104.94 115.029 104.94Z"
    fill="#5078E1"
  /><path
    d="M120.312 102.5C120.312 102.273 120.395 102.077 120.562 101.91C120.728 101.743 120.925 101.66 121.152 101.66C121.378 101.66 121.575 101.743 121.742 101.91C121.908 102.077 121.992 102.273 121.992 102.5C121.992 102.727 121.908 102.923 121.742 103.09C121.575 103.257 121.378 103.34 121.152 103.34C120.925 103.34 120.728 103.257 120.562 103.09C120.395 102.923 120.312 102.727 120.312 102.5ZM118.572 101.72C118.572 100.813 118.798 100.11 119.252 99.61C119.712 99.11 120.345 98.86 121.152 98.86C121.958 98.86 122.588 99.11 123.042 99.61C123.502 100.11 123.732 100.813 123.732 101.72V103.28C123.732 104.213 123.502 104.923 123.042 105.41C122.588 105.897 121.958 106.14 121.152 106.14C120.345 106.14 119.712 105.897 119.252 105.41C118.798 104.923 118.572 104.213 118.572 103.28V101.72ZM121.152 104.94C121.392 104.94 121.592 104.903 121.752 104.83C121.912 104.75 122.042 104.64 122.142 104.5C122.242 104.36 122.312 104.193 122.352 104C122.392 103.807 122.412 103.593 122.412 103.36V101.64C122.412 101.42 122.388 101.213 122.342 101.02C122.295 100.827 122.222 100.66 122.122 100.52C122.022 100.38 121.892 100.27 121.732 100.19C121.572 100.103 121.378 100.06 121.152 100.06C120.925 100.06 120.732 100.103 120.572 100.19C120.412 100.27 120.282 100.38 120.182 100.52C120.082 100.66 120.008 100.827 119.962 101.02C119.915 101.213 119.892 101.42 119.892 101.64V103.36C119.892 103.593 119.912 103.807 119.952 104C119.992 104.193 120.062 104.36 120.162 104.5C120.262 104.64 120.392 104.75 120.552 104.83C120.712 104.903 120.912 104.94 121.152 104.94Z"
    fill="#5078E1"
  /><path
    d="M126.435 102.5C126.435 102.273 126.518 102.077 126.685 101.91C126.852 101.743 127.048 101.66 127.275 101.66C127.502 101.66 127.698 101.743 127.865 101.91C128.032 102.077 128.115 102.273 128.115 102.5C128.115 102.727 128.032 102.923 127.865 103.09C127.698 103.257 127.502 103.34 127.275 103.34C127.048 103.34 126.852 103.257 126.685 103.09C126.518 102.923 126.435 102.727 126.435 102.5ZM124.695 101.72C124.695 100.813 124.922 100.11 125.375 99.61C125.835 99.11 126.468 98.86 127.275 98.86C128.082 98.86 128.712 99.11 129.165 99.61C129.625 100.11 129.855 100.813 129.855 101.72V103.28C129.855 104.213 129.625 104.923 129.165 105.41C128.712 105.897 128.082 106.14 127.275 106.14C126.468 106.14 125.835 105.897 125.375 105.41C124.922 104.923 124.695 104.213 124.695 103.28V101.72ZM127.275 104.94C127.515 104.94 127.715 104.903 127.875 104.83C128.035 104.75 128.165 104.64 128.265 104.5C128.365 104.36 128.435 104.193 128.475 104C128.515 103.807 128.535 103.593 128.535 103.36V101.64C128.535 101.42 128.512 101.213 128.465 101.02C128.418 100.827 128.345 100.66 128.245 100.52C128.145 100.38 128.015 100.27 127.855 100.19C127.695 100.103 127.502 100.06 127.275 100.06C127.048 100.06 126.855 100.103 126.695 100.19C126.535 100.27 126.405 100.38 126.305 100.52C126.205 100.66 126.132 100.827 126.085 101.02C126.038 101.213 126.015 101.42 126.015 101.64V103.36C126.015 103.593 126.035 103.807 126.075 104C126.115 104.193 126.185 104.36 126.285 104.5C126.385 104.64 126.515 104.75 126.675 104.83C126.835 104.903 127.035 104.94 127.275 104.94Z"
    fill="#5078E1"
  /><path
    d="M132.978 104.74V99.66H132.798L132.138 102.06H130.878V101.94L131.718 99H134.298V104.74H136.038V106H130.998V104.74H132.978Z"
    fill="#5078E1"
  /><path
    d="M138.681 102.5C138.681 102.273 138.764 102.077 138.931 101.91C139.098 101.743 139.294 101.66 139.521 101.66C139.748 101.66 139.944 101.743 140.111 101.91C140.278 102.077 140.361 102.273 140.361 102.5C140.361 102.727 140.278 102.923 140.111 103.09C139.944 103.257 139.748 103.34 139.521 103.34C139.294 103.34 139.098 103.257 138.931 103.09C138.764 102.923 138.681 102.727 138.681 102.5ZM136.941 101.72C136.941 100.813 137.168 100.11 137.621 99.61C138.081 99.11 138.714 98.86 139.521 98.86C140.328 98.86 140.958 99.11 141.411 99.61C141.871 100.11 142.101 100.813 142.101 101.72V103.28C142.101 104.213 141.871 104.923 141.411 105.41C140.958 105.897 140.328 106.14 139.521 106.14C138.714 106.14 138.081 105.897 137.621 105.41C137.168 104.923 136.941 104.213 136.941 103.28V101.72ZM139.521 104.94C139.761 104.94 139.961 104.903 140.121 104.83C140.281 104.75 140.411 104.64 140.511 104.5C140.611 104.36 140.681 104.193 140.721 104C140.761 103.807 140.781 103.593 140.781 103.36V101.64C140.781 101.42 140.758 101.213 140.711 101.02C140.664 100.827 140.591 100.66 140.491 100.52C140.391 100.38 140.261 100.27 140.101 100.19C139.941 100.103 139.748 100.06 139.521 100.06C139.294 100.06 139.101 100.103 138.941 100.19C138.781 100.27 138.651 100.38 138.551 100.52C138.451 100.66 138.378 100.827 138.331 101.02C138.284 101.213 138.261 101.42 138.261 101.64V103.36C138.261 103.593 138.281 103.807 138.321 104C138.361 104.193 138.431 104.36 138.531 104.5C138.631 104.64 138.761 104.75 138.921 104.83C139.081 104.903 139.281 104.94 139.521 104.94Z"
    fill="#5078E1"
  /><path
    d="M144.804 102.5C144.804 102.273 144.887 102.077 145.054 101.91C145.221 101.743 145.417 101.66 145.644 101.66C145.871 101.66 146.067 101.743 146.234 101.91C146.401 102.077 146.484 102.273 146.484 102.5C146.484 102.727 146.401 102.923 146.234 103.09C146.067 103.257 145.871 103.34 145.644 103.34C145.417 103.34 145.221 103.257 145.054 103.09C144.887 102.923 144.804 102.727 144.804 102.5ZM143.064 101.72C143.064 100.813 143.291 100.11 143.744 99.61C144.204 99.11 144.837 98.86 145.644 98.86C146.451 98.86 147.081 99.11 147.534 99.61C147.994 100.11 148.224 100.813 148.224 101.72V103.28C148.224 104.213 147.994 104.923 147.534 105.41C147.081 105.897 146.451 106.14 145.644 106.14C144.837 106.14 144.204 105.897 143.744 105.41C143.291 104.923 143.064 104.213 143.064 103.28V101.72ZM145.644 104.94C145.884 104.94 146.084 104.903 146.244 104.83C146.404 104.75 146.534 104.64 146.634 104.5C146.734 104.36 146.804 104.193 146.844 104C146.884 103.807 146.904 103.593 146.904 103.36V101.64C146.904 101.42 146.881 101.213 146.834 101.02C146.787 100.827 146.714 100.66 146.614 100.52C146.514 100.38 146.384 100.27 146.224 100.19C146.064 100.103 145.871 100.06 145.644 100.06C145.417 100.06 145.224 100.103 145.064 100.19C144.904 100.27 144.774 100.38 144.674 100.52C144.574 100.66 144.501 100.827 144.454 101.02C144.407 101.213 144.384 101.42 144.384 101.64V103.36C144.384 103.593 144.404 103.807 144.444 104C144.484 104.193 144.554 104.36 144.654 104.5C144.754 104.64 144.884 104.75 145.044 104.83C145.204 104.903 145.404 104.94 145.644 104.94Z"
    fill="#5078E1"
  /><path
    d="M150.927 102.5C150.927 102.273 151.01 102.077 151.177 101.91C151.344 101.743 151.54 101.66 151.767 101.66C151.994 101.66 152.19 101.743 152.357 101.91C152.524 102.077 152.607 102.273 152.607 102.5C152.607 102.727 152.524 102.923 152.357 103.09C152.19 103.257 151.994 103.34 151.767 103.34C151.54 103.34 151.344 103.257 151.177 103.09C151.01 102.923 150.927 102.727 150.927 102.5ZM149.187 101.72C149.187 100.813 149.414 100.11 149.867 99.61C150.327 99.11 150.96 98.86 151.767 98.86C152.574 98.86 153.204 99.11 153.657 99.61C154.117 100.11 154.347 100.813 154.347 101.72V103.28C154.347 104.213 154.117 104.923 153.657 105.41C153.204 105.897 152.574 106.14 151.767 106.14C150.96 106.14 150.327 105.897 149.867 105.41C149.414 104.923 149.187 104.213 149.187 103.28V101.72ZM151.767 104.94C152.007 104.94 152.207 104.903 152.367 104.83C152.527 104.75 152.657 104.64 152.757 104.5C152.857 104.36 152.927 104.193 152.967 104C153.007 103.807 153.027 103.593 153.027 103.36V101.64C153.027 101.42 153.004 101.213 152.957 101.02C152.91 100.827 152.837 100.66 152.737 100.52C152.637 100.38 152.507 100.27 152.347 100.19C152.187 100.103 151.994 100.06 151.767 100.06C151.54 100.06 151.347 100.103 151.187 100.19C151.027 100.27 150.897 100.38 150.797 100.52C150.697 100.66 150.624 100.827 150.577 101.02C150.53 101.213 150.507 101.42 150.507 101.64V103.36C150.507 103.593 150.527 103.807 150.567 104C150.607 104.193 150.677 104.36 150.777 104.5C150.877 104.64 151.007 104.75 151.167 104.83C151.327 104.903 151.527 104.94 151.767 104.94Z"
    fill="#5078E1"
  /><path
    d="M157.05 102.5C157.05 102.273 157.133 102.077 157.3 101.91C157.467 101.743 157.663 101.66 157.89 101.66C158.117 101.66 158.313 101.743 158.48 101.91C158.647 102.077 158.73 102.273 158.73 102.5C158.73 102.727 158.647 102.923 158.48 103.09C158.313 103.257 158.117 103.34 157.89 103.34C157.663 103.34 157.467 103.257 157.3 103.09C157.133 102.923 157.05 102.727 157.05 102.5ZM155.31 101.72C155.31 100.813 155.537 100.11 155.99 99.61C156.45 99.11 157.083 98.86 157.89 98.86C158.697 98.86 159.327 99.11 159.78 99.61C160.24 100.11 160.47 100.813 160.47 101.72V103.28C160.47 104.213 160.24 104.923 159.78 105.41C159.327 105.897 158.697 106.14 157.89 106.14C157.083 106.14 156.45 105.897 155.99 105.41C155.537 104.923 155.31 104.213 155.31 103.28V101.72ZM157.89 104.94C158.13 104.94 158.33 104.903 158.49 104.83C158.65 104.75 158.78 104.64 158.88 104.5C158.98 104.36 159.05 104.193 159.09 104C159.13 103.807 159.15 103.593 159.15 103.36V101.64C159.15 101.42 159.127 101.213 159.08 101.02C159.033 100.827 158.96 100.66 158.86 100.52C158.76 100.38 158.63 100.27 158.47 100.19C158.31 100.103 158.117 100.06 157.89 100.06C157.663 100.06 157.47 100.103 157.31 100.19C157.15 100.27 157.02 100.38 156.92 100.52C156.82 100.66 156.747 100.827 156.7 101.02C156.653 101.213 156.63 101.42 156.63 101.64V103.36C156.63 103.593 156.65 103.807 156.69 104C156.73 104.193 156.8 104.36 156.9 104.5C157 104.64 157.13 104.75 157.29 104.83C157.45 104.903 157.65 104.94 157.89 104.94Z"
    fill="#5078E1"
  /><path
    d="M163.173 102.5C163.173 102.273 163.256 102.077 163.423 101.91C163.59 101.743 163.786 101.66 164.013 101.66C164.24 101.66 164.436 101.743 164.603 101.91C164.77 102.077 164.853 102.273 164.853 102.5C164.853 102.727 164.77 102.923 164.603 103.09C164.436 103.257 164.24 103.34 164.013 103.34C163.786 103.34 163.59 103.257 163.423 103.09C163.256 102.923 163.173 102.727 163.173 102.5ZM161.433 101.72C161.433 100.813 161.66 100.11 162.113 99.61C162.573 99.11 163.206 98.86 164.013 98.86C164.82 98.86 165.45 99.11 165.903 99.61C166.363 100.11 166.593 100.813 166.593 101.72V103.28C166.593 104.213 166.363 104.923 165.903 105.41C165.45 105.897 164.82 106.14 164.013 106.14C163.206 106.14 162.573 105.897 162.113 105.41C161.66 104.923 161.433 104.213 161.433 103.28V101.72ZM164.013 104.94C164.253 104.94 164.453 104.903 164.613 104.83C164.773 104.75 164.903 104.64 165.003 104.5C165.103 104.36 165.173 104.193 165.213 104C165.253 103.807 165.273 103.593 165.273 103.36V101.64C165.273 101.42 165.25 101.213 165.203 101.02C165.156 100.827 165.083 100.66 164.983 100.52C164.883 100.38 164.753 100.27 164.593 100.19C164.433 100.103 164.24 100.06 164.013 100.06C163.786 100.06 163.593 100.103 163.433 100.19C163.273 100.27 163.143 100.38 163.043 100.52C162.943 100.66 162.87 100.827 162.823 101.02C162.776 101.213 162.753 101.42 162.753 101.64V103.36C162.753 103.593 162.773 103.807 162.813 104C162.853 104.193 162.923 104.36 163.023 104.5C163.123 104.64 163.253 104.75 163.413 104.83C163.573 104.903 163.773 104.94 164.013 104.94Z"
    fill="#5078E1"
  /><path
    d="M169.296 102.5C169.296 102.273 169.38 102.077 169.546 101.91C169.713 101.743 169.91 101.66 170.136 101.66C170.363 101.66 170.559 101.743 170.726 101.91C170.893 102.077 170.976 102.273 170.976 102.5C170.976 102.727 170.893 102.923 170.726 103.09C170.559 103.257 170.363 103.34 170.136 103.34C169.91 103.34 169.713 103.257 169.546 103.09C169.38 102.923 169.296 102.727 169.296 102.5ZM167.556 101.72C167.556 100.813 167.783 100.11 168.236 99.61C168.696 99.11 169.33 98.86 170.136 98.86C170.943 98.86 171.573 99.11 172.026 99.61C172.486 100.11 172.716 100.813 172.716 101.72V103.28C172.716 104.213 172.486 104.923 172.026 105.41C171.573 105.897 170.943 106.14 170.136 106.14C169.33 106.14 168.696 105.897 168.236 105.41C167.783 104.923 167.556 104.213 167.556 103.28V101.72ZM170.136 104.94C170.376 104.94 170.576 104.903 170.736 104.83C170.896 104.75 171.026 104.64 171.126 104.5C171.226 104.36 171.296 104.193 171.336 104C171.376 103.807 171.396 103.593 171.396 103.36V101.64C171.396 101.42 171.373 101.213 171.326 101.02C171.28 100.827 171.206 100.66 171.106 100.52C171.006 100.38 170.876 100.27 170.716 100.19C170.556 100.103 170.363 100.06 170.136 100.06C169.91 100.06 169.716 100.103 169.556 100.19C169.396 100.27 169.266 100.38 169.166 100.52C169.066 100.66 168.993 100.827 168.946 101.02C168.9 101.213 168.876 101.42 168.876 101.64V103.36C168.876 103.593 168.896 103.807 168.936 104C168.976 104.193 169.046 104.36 169.146 104.5C169.246 104.64 169.376 104.75 169.536 104.83C169.696 104.903 169.896 104.94 170.136 104.94Z"
    fill="#5078E1"
  /><path
    d="M175.839 104.74V99.66H175.659L174.999 102.06H173.739V101.94L174.579 99H177.159V104.74H178.899V106H173.859V104.74H175.839Z"
    fill="#5078E1"
  /><path
    d="M181.962 104.74V99.66H181.782L181.122 102.06H179.862V101.94L180.702 99H183.282V104.74H185.022V106H179.982V104.74H181.962Z"
    fill="#5078E1"
  /><path
    d="M187.665 102.5C187.665 102.273 187.749 102.077 187.915 101.91C188.082 101.743 188.279 101.66 188.505 101.66C188.732 101.66 188.929 101.743 189.095 101.91C189.262 102.077 189.345 102.273 189.345 102.5C189.345 102.727 189.262 102.923 189.095 103.09C188.929 103.257 188.732 103.34 188.505 103.34C188.279 103.34 188.082 103.257 187.915 103.09C187.749 102.923 187.665 102.727 187.665 102.5ZM185.925 101.72C185.925 100.813 186.152 100.11 186.605 99.61C187.065 99.11 187.699 98.86 188.505 98.86C189.312 98.86 189.942 99.11 190.395 99.61C190.855 100.11 191.085 100.813 191.085 101.72V103.28C191.085 104.213 190.855 104.923 190.395 105.41C189.942 105.897 189.312 106.14 188.505 106.14C187.699 106.14 187.065 105.897 186.605 105.41C186.152 104.923 185.925 104.213 185.925 103.28V101.72ZM188.505 104.94C188.745 104.94 188.945 104.903 189.105 104.83C189.265 104.75 189.395 104.64 189.495 104.5C189.595 104.36 189.665 104.193 189.705 104C189.745 103.807 189.765 103.593 189.765 103.36V101.64C189.765 101.42 189.742 101.213 189.695 101.02C189.649 100.827 189.575 100.66 189.475 100.52C189.375 100.38 189.245 100.27 189.085 100.19C188.925 100.103 188.732 100.06 188.505 100.06C188.279 100.06 188.085 100.103 187.925 100.19C187.765 100.27 187.635 100.38 187.535 100.52C187.435 100.66 187.362 100.827 187.315 101.02C187.269 101.213 187.245 101.42 187.245 101.64V103.36C187.245 103.593 187.265 103.807 187.305 104C187.345 104.193 187.415 104.36 187.515 104.5C187.615 104.64 187.745 104.75 187.905 104.83C188.065 104.903 188.265 104.94 188.505 104.94Z"
    fill="#5078E1"
  /><path
    d="M193.788 102.5C193.788 102.273 193.872 102.077 194.038 101.91C194.205 101.743 194.402 101.66 194.628 101.66C194.855 101.66 195.052 101.743 195.218 101.91C195.385 102.077 195.468 102.273 195.468 102.5C195.468 102.727 195.385 102.923 195.218 103.09C195.052 103.257 194.855 103.34 194.628 103.34C194.402 103.34 194.205 103.257 194.038 103.09C193.872 102.923 193.788 102.727 193.788 102.5ZM192.048 101.72C192.048 100.813 192.275 100.11 192.728 99.61C193.188 99.11 193.822 98.86 194.628 98.86C195.435 98.86 196.065 99.11 196.518 99.61C196.978 100.11 197.208 100.813 197.208 101.72V103.28C197.208 104.213 196.978 104.923 196.518 105.41C196.065 105.897 195.435 106.14 194.628 106.14C193.822 106.14 193.188 105.897 192.728 105.41C192.275 104.923 192.048 104.213 192.048 103.28V101.72ZM194.628 104.94C194.868 104.94 195.068 104.903 195.228 104.83C195.388 104.75 195.518 104.64 195.618 104.5C195.718 104.36 195.788 104.193 195.828 104C195.868 103.807 195.888 103.593 195.888 103.36V101.64C195.888 101.42 195.865 101.213 195.818 101.02C195.772 100.827 195.698 100.66 195.598 100.52C195.498 100.38 195.368 100.27 195.208 100.19C195.048 100.103 194.855 100.06 194.628 100.06C194.402 100.06 194.208 100.103 194.048 100.19C193.888 100.27 193.758 100.38 193.658 100.52C193.558 100.66 193.485 100.827 193.438 101.02C193.392 101.213 193.368 101.42 193.368 101.64V103.36C193.368 103.593 193.388 103.807 193.428 104C193.468 104.193 193.538 104.36 193.638 104.5C193.738 104.64 193.868 104.75 194.028 104.83C194.188 104.903 194.388 104.94 194.628 104.94Z"
    fill="#5078E1"
  /><path
    d="M200.331 104.74V99.66H200.151L199.491 102.06H198.231V101.94L199.071 99H201.651V104.74H203.391V106H198.351V104.74H200.331Z"
    fill="#5078E1"
  /><path
    d="M206.034 102.5C206.034 102.273 206.118 102.077 206.284 101.91C206.451 101.743 206.648 101.66 206.874 101.66C207.101 101.66 207.298 101.743 207.464 101.91C207.631 102.077 207.714 102.273 207.714 102.5C207.714 102.727 207.631 102.923 207.464 103.09C207.298 103.257 207.101 103.34 206.874 103.34C206.648 103.34 206.451 103.257 206.284 103.09C206.118 102.923 206.034 102.727 206.034 102.5ZM204.294 101.72C204.294 100.813 204.521 100.11 204.974 99.61C205.434 99.11 206.068 98.86 206.874 98.86C207.681 98.86 208.311 99.11 208.764 99.61C209.224 100.11 209.454 100.813 209.454 101.72V103.28C209.454 104.213 209.224 104.923 208.764 105.41C208.311 105.897 207.681 106.14 206.874 106.14C206.068 106.14 205.434 105.897 204.974 105.41C204.521 104.923 204.294 104.213 204.294 103.28V101.72ZM206.874 104.94C207.114 104.94 207.314 104.903 207.474 104.83C207.634 104.75 207.764 104.64 207.864 104.5C207.964 104.36 208.034 104.193 208.074 104C208.114 103.807 208.134 103.593 208.134 103.36V101.64C208.134 101.42 208.111 101.213 208.064 101.02C208.018 100.827 207.944 100.66 207.844 100.52C207.744 100.38 207.614 100.27 207.454 100.19C207.294 100.103 207.101 100.06 206.874 100.06C206.648 100.06 206.454 100.103 206.294 100.19C206.134 100.27 206.004 100.38 205.904 100.52C205.804 100.66 205.731 100.827 205.684 101.02C205.638 101.213 205.614 101.42 205.614 101.64V103.36C205.614 103.593 205.634 103.807 205.674 104C205.714 104.193 205.784 104.36 205.884 104.5C205.984 104.64 206.114 104.75 206.274 104.83C206.434 104.903 206.634 104.94 206.874 104.94Z"
    fill="#5078E1"
  /><path
    d="M212.577 104.74V99.66H212.397L211.738 102.06H210.477V101.94L211.317 99H213.897V104.74H215.637V106H210.597V104.74H212.577Z"
    fill="#5078E1"
  /><path
    d="M218.281 102.5C218.281 102.273 218.364 102.077 218.531 101.91C218.697 101.743 218.894 101.66 219.121 101.66C219.347 101.66 219.544 101.743 219.711 101.91C219.877 102.077 219.961 102.273 219.961 102.5C219.961 102.727 219.877 102.923 219.711 103.09C219.544 103.257 219.347 103.34 219.121 103.34C218.894 103.34 218.697 103.257 218.531 103.09C218.364 102.923 218.281 102.727 218.281 102.5ZM216.541 101.72C216.541 100.813 216.767 100.11 217.221 99.61C217.681 99.11 218.314 98.86 219.121 98.86C219.927 98.86 220.557 99.11 221.011 99.61C221.471 100.11 221.701 100.813 221.701 101.72V103.28C221.701 104.213 221.471 104.923 221.011 105.41C220.557 105.897 219.927 106.14 219.121 106.14C218.314 106.14 217.681 105.897 217.221 105.41C216.767 104.923 216.541 104.213 216.541 103.28V101.72ZM219.121 104.94C219.361 104.94 219.561 104.903 219.721 104.83C219.881 104.75 220.011 104.64 220.111 104.5C220.211 104.36 220.281 104.193 220.321 104C220.361 103.807 220.381 103.593 220.381 103.36V101.64C220.381 101.42 220.357 101.213 220.311 101.02C220.264 100.827 220.191 100.66 220.091 100.52C219.991 100.38 219.861 100.27 219.701 100.19C219.541 100.103 219.347 100.06 219.121 100.06C218.894 100.06 218.701 100.103 218.541 100.19C218.381 100.27 218.251 100.38 218.151 100.52C218.051 100.66 217.977 100.827 217.931 101.02C217.884 101.213 217.861 101.42 217.861 101.64V103.36C217.861 103.593 217.881 103.807 217.921 104C217.961 104.193 218.031 104.36 218.131 104.5C218.231 104.64 218.361 104.75 218.521 104.83C218.681 104.903 218.881 104.94 219.121 104.94Z"
    fill="#5078E1"
  /><path
    d="M224.824 104.74V99.66H224.644L223.984 102.06H222.724V101.94L223.564 99H226.144V104.74H227.884V106H222.844V104.74H224.824Z"
    fill="#5078E1"
  /><path
    d="M230.947 104.74V99.66H230.767L230.107 102.06H228.847V101.94L229.687 99H232.267V104.74H234.007V106H228.967V104.74H230.947Z"
    fill="#5078E1"
  /><path
    d="M236.65 102.5C236.65 102.273 236.733 102.077 236.9 101.91C237.066 101.743 237.263 101.66 237.49 101.66C237.716 101.66 237.913 101.743 238.08 101.91C238.246 102.077 238.33 102.273 238.33 102.5C238.33 102.727 238.246 102.923 238.08 103.09C237.913 103.257 237.716 103.34 237.49 103.34C237.263 103.34 237.066 103.257 236.9 103.09C236.733 102.923 236.65 102.727 236.65 102.5ZM234.91 101.72C234.91 100.813 235.136 100.11 235.59 99.61C236.05 99.11 236.683 98.86 237.49 98.86C238.296 98.86 238.926 99.11 239.38 99.61C239.84 100.11 240.07 100.813 240.07 101.72V103.28C240.07 104.213 239.84 104.923 239.38 105.41C238.926 105.897 238.296 106.14 237.49 106.14C236.683 106.14 236.05 105.897 235.59 105.41C235.136 104.923 234.91 104.213 234.91 103.28V101.72ZM237.49 104.94C237.73 104.94 237.93 104.903 238.09 104.83C238.25 104.75 238.38 104.64 238.48 104.5C238.58 104.36 238.65 104.193 238.69 104C238.73 103.807 238.75 103.593 238.75 103.36V101.64C238.75 101.42 238.726 101.213 238.68 101.02C238.633 100.827 238.56 100.66 238.46 100.52C238.36 100.38 238.23 100.27 238.07 100.19C237.91 100.103 237.716 100.06 237.49 100.06C237.263 100.06 237.07 100.103 236.91 100.19C236.75 100.27 236.62 100.38 236.52 100.52C236.42 100.66 236.346 100.827 236.3 101.02C236.253 101.213 236.23 101.42 236.23 101.64V103.36C236.23 103.593 236.25 103.807 236.29 104C236.33 104.193 236.4 104.36 236.5 104.5C236.6 104.64 236.73 104.75 236.89 104.83C237.05 104.903 237.25 104.94 237.49 104.94Z"
    fill="#5078E1"
  /><path
    d="M243.193 104.74V99.66H243.013L242.353 102.06H241.093V101.94L241.933 99H244.513V104.74H246.253V106H241.213V104.74H243.193Z"
    fill="#5078E1"
  /><path
    d="M249.316 104.74V99.66H249.136L248.476 102.06H247.216V101.94L248.056 99H250.636V104.74H252.376V106H247.336V104.74H249.316Z"
    fill="#5078E1"
  /><path
    d="M255.019 102.5C255.019 102.273 255.102 102.077 255.269 101.91C255.435 101.743 255.632 101.66 255.859 101.66C256.085 101.66 256.282 101.743 256.449 101.91C256.615 102.077 256.699 102.273 256.699 102.5C256.699 102.727 256.615 102.923 256.449 103.09C256.282 103.257 256.085 103.34 255.859 103.34C255.632 103.34 255.435 103.257 255.269 103.09C255.102 102.923 255.019 102.727 255.019 102.5ZM253.279 101.72C253.279 100.813 253.505 100.11 253.959 99.61C254.419 99.11 255.052 98.86 255.859 98.86C256.665 98.86 257.295 99.11 257.749 99.61C258.209 100.11 258.439 100.813 258.439 101.72V103.28C258.439 104.213 258.209 104.923 257.749 105.41C257.295 105.897 256.665 106.14 255.859 106.14C255.052 106.14 254.419 105.897 253.959 105.41C253.505 104.923 253.279 104.213 253.279 103.28V101.72ZM255.859 104.94C256.099 104.94 256.299 104.903 256.459 104.83C256.619 104.75 256.749 104.64 256.849 104.5C256.949 104.36 257.019 104.193 257.059 104C257.099 103.807 257.119 103.593 257.119 103.36V101.64C257.119 101.42 257.095 101.213 257.049 101.02C257.002 100.827 256.929 100.66 256.829 100.52C256.729 100.38 256.599 100.27 256.439 100.19C256.279 100.103 256.085 100.06 255.859 100.06C255.632 100.06 255.439 100.103 255.279 100.19C255.119 100.27 254.989 100.38 254.889 100.52C254.789 100.66 254.715 100.827 254.669 101.02C254.622 101.213 254.599 101.42 254.599 101.64V103.36C254.599 103.593 254.619 103.807 254.659 104C254.699 104.193 254.769 104.36 254.869 104.5C254.969 104.64 255.099 104.75 255.259 104.83C255.419 104.903 255.619 104.94 255.859 104.94Z"
    fill="#5078E1"
  /><path
    d="M261.562 104.74V99.66H261.382L260.722 102.06H259.462V101.94L260.302 99H262.882V104.74H264.622V106H259.582V104.74H261.562Z"
    fill="#5078E1"
  /><path
    d="M267.265 102.5C267.265 102.273 267.348 102.077 267.515 101.91C267.682 101.743 267.878 101.66 268.105 101.66C268.332 101.66 268.528 101.743 268.695 101.91C268.862 102.077 268.945 102.273 268.945 102.5C268.945 102.727 268.862 102.923 268.695 103.09C268.528 103.257 268.332 103.34 268.105 103.34C267.878 103.34 267.682 103.257 267.515 103.09C267.348 102.923 267.265 102.727 267.265 102.5ZM265.525 101.72C265.525 100.813 265.752 100.11 266.205 99.61C266.665 99.11 267.298 98.86 268.105 98.86C268.912 98.86 269.542 99.11 269.995 99.61C270.455 100.11 270.685 100.813 270.685 101.72V103.28C270.685 104.213 270.455 104.923 269.995 105.41C269.542 105.897 268.912 106.14 268.105 106.14C267.298 106.14 266.665 105.897 266.205 105.41C265.752 104.923 265.525 104.213 265.525 103.28V101.72ZM268.105 104.94C268.345 104.94 268.545 104.903 268.705 104.83C268.865 104.75 268.995 104.64 269.095 104.5C269.195 104.36 269.265 104.193 269.305 104C269.345 103.807 269.365 103.593 269.365 103.36V101.64C269.365 101.42 269.342 101.213 269.295 101.02C269.248 100.827 269.175 100.66 269.075 100.52C268.975 100.38 268.845 100.27 268.685 100.19C268.525 100.103 268.332 100.06 268.105 100.06C267.878 100.06 267.685 100.103 267.525 100.19C267.365 100.27 267.235 100.38 267.135 100.52C267.035 100.66 266.962 100.827 266.915 101.02C266.868 101.213 266.845 101.42 266.845 101.64V103.36C266.845 103.593 266.865 103.807 266.905 104C266.945 104.193 267.015 104.36 267.115 104.5C267.215 104.64 267.345 104.75 267.505 104.83C267.665 104.903 267.865 104.94 268.105 104.94Z"
    fill="#5078E1"
  /><path
    d="M273.808 104.74V99.66H273.628L272.968 102.06H271.708V101.94L272.548 99H275.128V104.74H276.868V106H271.828V104.74H273.808Z"
    fill="#5078E1"
  /><path
    d="M279.931 104.74V99.66H279.751L279.091 102.06H277.831V101.94L278.671 99H281.251V104.74H282.991V106H277.951V104.74H279.931Z"
    fill="#5078E1"
  /><path
    d="M286.054 104.74V99.66H285.874L285.214 102.06H283.954V101.94L284.794 99H287.374V104.74H289.114V106H284.074V104.74H286.054Z"
    fill="#5078E1"
  /><path
    d="M291.757 102.5C291.757 102.273 291.84 102.077 292.007 101.91C292.174 101.743 292.37 101.66 292.597 101.66C292.824 101.66 293.02 101.743 293.187 101.91C293.354 102.077 293.437 102.273 293.437 102.5C293.437 102.727 293.354 102.923 293.187 103.09C293.02 103.257 292.824 103.34 292.597 103.34C292.37 103.34 292.174 103.257 292.007 103.09C291.84 102.923 291.757 102.727 291.757 102.5ZM290.017 101.72C290.017 100.813 290.244 100.11 290.697 99.61C291.157 99.11 291.79 98.86 292.597 98.86C293.404 98.86 294.034 99.11 294.487 99.61C294.947 100.11 295.177 100.813 295.177 101.72V103.28C295.177 104.213 294.947 104.923 294.487 105.41C294.034 105.897 293.404 106.14 292.597 106.14C291.79 106.14 291.157 105.897 290.697 105.41C290.244 104.923 290.017 104.213 290.017 103.28V101.72ZM292.597 104.94C292.837 104.94 293.037 104.903 293.197 104.83C293.357 104.75 293.487 104.64 293.587 104.5C293.687 104.36 293.757 104.193 293.797 104C293.837 103.807 293.857 103.593 293.857 103.36V101.64C293.857 101.42 293.834 101.213 293.787 101.02C293.74 100.827 293.667 100.66 293.567 100.52C293.467 100.38 293.337 100.27 293.177 100.19C293.017 100.103 292.824 100.06 292.597 100.06C292.37 100.06 292.177 100.103 292.017 100.19C291.857 100.27 291.727 100.38 291.627 100.52C291.527 100.66 291.454 100.827 291.407 101.02C291.36 101.213 291.337 101.42 291.337 101.64V103.36C291.337 103.593 291.357 103.807 291.397 104C291.437 104.193 291.507 104.36 291.607 104.5C291.707 104.64 291.837 104.75 291.997 104.83C292.157 104.903 292.357 104.94 292.597 104.94Z"
    fill="#5078E1"
  /><path
    d="M297.88 102.5C297.88 102.273 297.963 102.077 298.13 101.91C298.297 101.743 298.493 101.66 298.72 101.66C298.947 101.66 299.143 101.743 299.31 101.91C299.477 102.077 299.56 102.273 299.56 102.5C299.56 102.727 299.477 102.923 299.31 103.09C299.143 103.257 298.947 103.34 298.72 103.34C298.493 103.34 298.297 103.257 298.13 103.09C297.963 102.923 297.88 102.727 297.88 102.5ZM296.14 101.72C296.14 100.813 296.367 100.11 296.82 99.61C297.28 99.11 297.913 98.86 298.72 98.86C299.527 98.86 300.157 99.11 300.61 99.61C301.07 100.11 301.3 100.813 301.3 101.72V103.28C301.3 104.213 301.07 104.923 300.61 105.41C300.157 105.897 299.527 106.14 298.72 106.14C297.913 106.14 297.28 105.897 296.82 105.41C296.367 104.923 296.14 104.213 296.14 103.28V101.72ZM298.72 104.94C298.96 104.94 299.16 104.903 299.32 104.83C299.48 104.75 299.61 104.64 299.71 104.5C299.81 104.36 299.88 104.193 299.92 104C299.96 103.807 299.98 103.593 299.98 103.36V101.64C299.98 101.42 299.957 101.213 299.91 101.02C299.863 100.827 299.79 100.66 299.69 100.52C299.59 100.38 299.46 100.27 299.3 100.19C299.14 100.103 298.947 100.06 298.72 100.06C298.493 100.06 298.3 100.103 298.14 100.19C297.98 100.27 297.85 100.38 297.75 100.52C297.65 100.66 297.577 100.827 297.53 101.02C297.483 101.213 297.46 101.42 297.46 101.64V103.36C297.46 103.593 297.48 103.807 297.52 104C297.56 104.193 297.63 104.36 297.73 104.5C297.83 104.64 297.96 104.75 298.12 104.83C298.28 104.903 298.48 104.94 298.72 104.94Z"
    fill="#5078E1"
  /><path
    d="M304.003 102.5C304.003 102.273 304.087 102.077 304.253 101.91C304.42 101.743 304.617 101.66 304.843 101.66C305.07 101.66 305.267 101.743 305.433 101.91C305.6 102.077 305.683 102.273 305.683 102.5C305.683 102.727 305.6 102.923 305.433 103.09C305.267 103.257 305.07 103.34 304.843 103.34C304.617 103.34 304.42 103.257 304.253 103.09C304.087 102.923 304.003 102.727 304.003 102.5ZM302.263 101.72C302.263 100.813 302.49 100.11 302.943 99.61C303.403 99.11 304.037 98.86 304.843 98.86C305.65 98.86 306.28 99.11 306.733 99.61C307.193 100.11 307.423 100.813 307.423 101.72V103.28C307.423 104.213 307.193 104.923 306.733 105.41C306.28 105.897 305.65 106.14 304.843 106.14C304.037 106.14 303.403 105.897 302.943 105.41C302.49 104.923 302.263 104.213 302.263 103.28V101.72ZM304.843 104.94C305.083 104.94 305.283 104.903 305.443 104.83C305.603 104.75 305.733 104.64 305.833 104.5C305.933 104.36 306.003 104.193 306.043 104C306.083 103.807 306.103 103.593 306.103 103.36V101.64C306.103 101.42 306.08 101.213 306.033 101.02C305.987 100.827 305.913 100.66 305.813 100.52C305.713 100.38 305.583 100.27 305.423 100.19C305.263 100.103 305.07 100.06 304.843 100.06C304.617 100.06 304.423 100.103 304.263 100.19C304.103 100.27 303.973 100.38 303.873 100.52C303.773 100.66 303.7 100.827 303.653 101.02C303.607 101.213 303.583 101.42 303.583 101.64V103.36C303.583 103.593 303.603 103.807 303.643 104C303.683 104.193 303.753 104.36 303.853 104.5C303.953 104.64 304.083 104.75 304.243 104.83C304.403 104.903 304.603 104.94 304.843 104.94Z"
    fill="#5078E1"
  /><path
    d="M310.126 102.5C310.126 102.273 310.21 102.077 310.376 101.91C310.543 101.743 310.74 101.66 310.966 101.66C311.193 101.66 311.39 101.743 311.556 101.91C311.723 102.077 311.806 102.273 311.806 102.5C311.806 102.727 311.723 102.923 311.556 103.09C311.39 103.257 311.193 103.34 310.966 103.34C310.74 103.34 310.543 103.257 310.376 103.09C310.21 102.923 310.126 102.727 310.126 102.5ZM308.386 101.72C308.386 100.813 308.613 100.11 309.066 99.61C309.526 99.11 310.16 98.86 310.966 98.86C311.773 98.86 312.403 99.11 312.856 99.61C313.316 100.11 313.546 100.813 313.546 101.72V103.28C313.546 104.213 313.316 104.923 312.856 105.41C312.403 105.897 311.773 106.14 310.966 106.14C310.16 106.14 309.526 105.897 309.066 105.41C308.613 104.923 308.386 104.213 308.386 103.28V101.72ZM310.966 104.94C311.206 104.94 311.406 104.903 311.566 104.83C311.726 104.75 311.856 104.64 311.956 104.5C312.056 104.36 312.126 104.193 312.166 104C312.206 103.807 312.226 103.593 312.226 103.36V101.64C312.226 101.42 312.203 101.213 312.156 101.02C312.11 100.827 312.036 100.66 311.936 100.52C311.836 100.38 311.706 100.27 311.546 100.19C311.386 100.103 311.193 100.06 310.966 100.06C310.74 100.06 310.546 100.103 310.386 100.19C310.226 100.27 310.096 100.38 309.996 100.52C309.896 100.66 309.823 100.827 309.776 101.02C309.73 101.213 309.706 101.42 309.706 101.64V103.36C309.706 103.593 309.726 103.807 309.766 104C309.806 104.193 309.876 104.36 309.976 104.5C310.076 104.64 310.206 104.75 310.366 104.83C310.526 104.903 310.726 104.94 310.966 104.94Z"
    fill="#5078E1"
  /><path
    d="M316.249 102.5C316.249 102.273 316.333 102.077 316.499 101.91C316.666 101.743 316.863 101.66 317.089 101.66C317.316 101.66 317.513 101.743 317.679 101.91C317.846 102.077 317.929 102.273 317.929 102.5C317.929 102.727 317.846 102.923 317.679 103.09C317.513 103.257 317.316 103.34 317.089 103.34C316.863 103.34 316.666 103.257 316.499 103.09C316.333 102.923 316.249 102.727 316.249 102.5ZM314.509 101.72C314.509 100.813 314.736 100.11 315.189 99.61C315.649 99.11 316.283 98.86 317.089 98.86C317.896 98.86 318.526 99.11 318.979 99.61C319.439 100.11 319.669 100.813 319.669 101.72V103.28C319.669 104.213 319.439 104.923 318.979 105.41C318.526 105.897 317.896 106.14 317.089 106.14C316.283 106.14 315.649 105.897 315.189 105.41C314.736 104.923 314.509 104.213 314.509 103.28V101.72ZM317.089 104.94C317.329 104.94 317.529 104.903 317.689 104.83C317.849 104.75 317.979 104.64 318.079 104.5C318.179 104.36 318.249 104.193 318.289 104C318.329 103.807 318.349 103.593 318.349 103.36V101.64C318.349 101.42 318.326 101.213 318.279 101.02C318.233 100.827 318.159 100.66 318.059 100.52C317.959 100.38 317.829 100.27 317.669 100.19C317.509 100.103 317.316 100.06 317.089 100.06C316.863 100.06 316.669 100.103 316.509 100.19C316.349 100.27 316.219 100.38 316.119 100.52C316.019 100.66 315.946 100.827 315.899 101.02C315.853 101.213 315.829 101.42 315.829 101.64V103.36C315.829 103.593 315.849 103.807 315.889 104C315.929 104.193 315.999 104.36 316.099 104.5C316.199 104.64 316.329 104.75 316.489 104.83C316.649 104.903 316.849 104.94 317.089 104.94Z"
    fill="#5078E1"
  /><path
    d="M322.792 104.74V99.66H322.612L321.952 102.06H320.692V101.94L321.532 99H324.112V104.74H325.852V106H320.812V104.74H322.792Z"
    fill="#5078E1"
  /><path
    d="M328.915 104.74V99.66H328.735L328.075 102.06H326.815V101.94L327.655 99H330.235V104.74H331.975V106H326.935V104.74H328.915Z"
    fill="#5078E1"
  /><path
    d="M334.618 102.5C334.618 102.273 334.702 102.077 334.868 101.91C335.035 101.743 335.232 101.66 335.458 101.66C335.685 101.66 335.882 101.743 336.048 101.91C336.215 102.077 336.298 102.273 336.298 102.5C336.298 102.727 336.215 102.923 336.048 103.09C335.882 103.257 335.685 103.34 335.458 103.34C335.232 103.34 335.035 103.257 334.868 103.09C334.702 102.923 334.618 102.727 334.618 102.5ZM332.878 101.72C332.878 100.813 333.105 100.11 333.558 99.61C334.018 99.11 334.652 98.86 335.458 98.86C336.265 98.86 336.895 99.11 337.348 99.61C337.808 100.11 338.038 100.813 338.038 101.72V103.28C338.038 104.213 337.808 104.923 337.348 105.41C336.895 105.897 336.265 106.14 335.458 106.14C334.652 106.14 334.018 105.897 333.558 105.41C333.105 104.923 332.878 104.213 332.878 103.28V101.72ZM335.458 104.94C335.698 104.94 335.898 104.903 336.058 104.83C336.218 104.75 336.348 104.64 336.448 104.5C336.548 104.36 336.618 104.193 336.658 104C336.698 103.807 336.718 103.593 336.718 103.36V101.64C336.718 101.42 336.695 101.213 336.648 101.02C336.602 100.827 336.528 100.66 336.428 100.52C336.328 100.38 336.198 100.27 336.038 100.19C335.878 100.103 335.685 100.06 335.458 100.06C335.232 100.06 335.038 100.103 334.878 100.19C334.718 100.27 334.588 100.38 334.488 100.52C334.388 100.66 334.315 100.827 334.268 101.02C334.222 101.213 334.198 101.42 334.198 101.64V103.36C334.198 103.593 334.218 103.807 334.258 104C334.298 104.193 334.368 104.36 334.468 104.5C334.568 104.64 334.698 104.75 334.858 104.83C335.018 104.903 335.218 104.94 335.458 104.94Z"
    fill="#5078E1"
  /><path
    d="M341.161 104.74V99.66H340.981L340.321 102.06H339.061V101.94L339.901 99H342.481V104.74H344.221V106H339.181V104.74H341.161Z"
    fill="#5078E1"
  /><path
    d="M347.285 104.74V99.66H347.105L346.445 102.06H345.185V101.94L346.025 99H348.605V104.74H350.345V106H345.305V104.74H347.285Z"
    fill="#5078E1"
  /><path
    d="M352.988 102.5C352.988 102.273 353.071 102.077 353.238 101.91C353.404 101.743 353.601 101.66 353.828 101.66C354.054 101.66 354.251 101.743 354.418 101.91C354.584 102.077 354.668 102.273 354.668 102.5C354.668 102.727 354.584 102.923 354.418 103.09C354.251 103.257 354.054 103.34 353.828 103.34C353.601 103.34 353.404 103.257 353.238 103.09C353.071 102.923 352.988 102.727 352.988 102.5ZM351.248 101.72C351.248 100.813 351.474 100.11 351.928 99.61C352.388 99.11 353.021 98.86 353.828 98.86C354.634 98.86 355.264 99.11 355.718 99.61C356.178 100.11 356.408 100.813 356.408 101.72V103.28C356.408 104.213 356.178 104.923 355.718 105.41C355.264 105.897 354.634 106.14 353.828 106.14C353.021 106.14 352.388 105.897 351.928 105.41C351.474 104.923 351.248 104.213 351.248 103.28V101.72ZM353.828 104.94C354.068 104.94 354.268 104.903 354.428 104.83C354.588 104.75 354.718 104.64 354.818 104.5C354.918 104.36 354.988 104.193 355.028 104C355.068 103.807 355.088 103.593 355.088 103.36V101.64C355.088 101.42 355.064 101.213 355.018 101.02C354.971 100.827 354.898 100.66 354.798 100.52C354.698 100.38 354.568 100.27 354.408 100.19C354.248 100.103 354.054 100.06 353.828 100.06C353.601 100.06 353.408 100.103 353.248 100.19C353.088 100.27 352.958 100.38 352.858 100.52C352.758 100.66 352.684 100.827 352.638 101.02C352.591 101.213 352.568 101.42 352.568 101.64V103.36C352.568 103.593 352.588 103.807 352.628 104C352.668 104.193 352.738 104.36 352.838 104.5C352.938 104.64 353.068 104.75 353.228 104.83C353.388 104.903 353.588 104.94 353.828 104.94Z"
    fill="#5078E1"
  /><path
    d="M359.111 102.5C359.111 102.273 359.194 102.077 359.361 101.91C359.527 101.743 359.724 101.66 359.951 101.66C360.177 101.66 360.374 101.743 360.541 101.91C360.707 102.077 360.791 102.273 360.791 102.5C360.791 102.727 360.707 102.923 360.541 103.09C360.374 103.257 360.177 103.34 359.951 103.34C359.724 103.34 359.527 103.257 359.361 103.09C359.194 102.923 359.111 102.727 359.111 102.5ZM357.371 101.72C357.371 100.813 357.597 100.11 358.051 99.61C358.511 99.11 359.144 98.86 359.951 98.86C360.757 98.86 361.387 99.11 361.841 99.61C362.301 100.11 362.531 100.813 362.531 101.72V103.28C362.531 104.213 362.301 104.923 361.841 105.41C361.387 105.897 360.757 106.14 359.951 106.14C359.144 106.14 358.511 105.897 358.051 105.41C357.597 104.923 357.371 104.213 357.371 103.28V101.72ZM359.951 104.94C360.191 104.94 360.391 104.903 360.551 104.83C360.711 104.75 360.841 104.64 360.941 104.5C361.041 104.36 361.111 104.193 361.151 104C361.191 103.807 361.211 103.593 361.211 103.36V101.64C361.211 101.42 361.187 101.213 361.141 101.02C361.094 100.827 361.021 100.66 360.921 100.52C360.821 100.38 360.691 100.27 360.531 100.19C360.371 100.103 360.177 100.06 359.951 100.06C359.724 100.06 359.531 100.103 359.371 100.19C359.211 100.27 359.081 100.38 358.981 100.52C358.881 100.66 358.807 100.827 358.761 101.02C358.714 101.213 358.691 101.42 358.691 101.64V103.36C358.691 103.593 358.711 103.807 358.751 104C358.791 104.193 358.861 104.36 358.961 104.5C359.061 104.64 359.191 104.75 359.351 104.83C359.511 104.903 359.711 104.94 359.951 104.94Z"
    fill="#5078E1"
  /><path
    d="M365.234 102.5C365.234 102.273 365.317 102.077 365.484 101.91C365.65 101.743 365.847 101.66 366.074 101.66C366.3 101.66 366.497 101.743 366.664 101.91C366.83 102.077 366.914 102.273 366.914 102.5C366.914 102.727 366.83 102.923 366.664 103.09C366.497 103.257 366.3 103.34 366.074 103.34C365.847 103.34 365.65 103.257 365.484 103.09C365.317 102.923 365.234 102.727 365.234 102.5ZM363.494 101.72C363.494 100.813 363.72 100.11 364.174 99.61C364.634 99.11 365.267 98.86 366.074 98.86C366.88 98.86 367.51 99.11 367.964 99.61C368.424 100.11 368.654 100.813 368.654 101.72V103.28C368.654 104.213 368.424 104.923 367.964 105.41C367.51 105.897 366.88 106.14 366.074 106.14C365.267 106.14 364.634 105.897 364.174 105.41C363.72 104.923 363.494 104.213 363.494 103.28V101.72ZM366.074 104.94C366.314 104.94 366.514 104.903 366.674 104.83C366.834 104.75 366.964 104.64 367.064 104.5C367.164 104.36 367.234 104.193 367.274 104C367.314 103.807 367.334 103.593 367.334 103.36V101.64C367.334 101.42 367.31 101.213 367.264 101.02C367.217 100.827 367.144 100.66 367.044 100.52C366.944 100.38 366.814 100.27 366.654 100.19C366.494 100.103 366.3 100.06 366.074 100.06C365.847 100.06 365.654 100.103 365.494 100.19C365.334 100.27 365.204 100.38 365.104 100.52C365.004 100.66 364.93 100.827 364.884 101.02C364.837 101.213 364.814 101.42 364.814 101.64V103.36C364.814 103.593 364.834 103.807 364.874 104C364.914 104.193 364.984 104.36 365.084 104.5C365.184 104.64 365.314 104.75 365.474 104.83C365.634 104.903 365.834 104.94 366.074 104.94Z"
    fill="#5078E1"
  /><path
    d="M371.777 104.74V99.66H371.597L370.937 102.06H369.677V101.94L370.517 99H373.097V104.74H374.837V106H369.797V104.74H371.777Z"
    fill="#5078E1"
  /><path
    d="M377.9 104.74V99.66H377.72L377.06 102.06H375.8V101.94L376.64 99H379.22V104.74H380.96V106H375.92V104.74H377.9Z"
    fill="#5078E1"
  /><path
    d="M383.603 102.5C383.603 102.273 383.686 102.077 383.853 101.91C384.019 101.743 384.216 101.66 384.443 101.66C384.669 101.66 384.866 101.743 385.033 101.91C385.199 102.077 385.283 102.273 385.283 102.5C385.283 102.727 385.199 102.923 385.033 103.09C384.866 103.257 384.669 103.34 384.443 103.34C384.216 103.34 384.019 103.257 383.853 103.09C383.686 102.923 383.603 102.727 383.603 102.5ZM381.863 101.72C381.863 100.813 382.089 100.11 382.543 99.61C383.003 99.11 383.636 98.86 384.443 98.86C385.249 98.86 385.879 99.11 386.333 99.61C386.793 100.11 387.023 100.813 387.023 101.72V103.28C387.023 104.213 386.793 104.923 386.333 105.41C385.879 105.897 385.249 106.14 384.443 106.14C383.636 106.14 383.003 105.897 382.543 105.41C382.089 104.923 381.863 104.213 381.863 103.28V101.72ZM384.443 104.94C384.683 104.94 384.883 104.903 385.043 104.83C385.203 104.75 385.333 104.64 385.433 104.5C385.533 104.36 385.603 104.193 385.643 104C385.683 103.807 385.703 103.593 385.703 103.36V101.64C385.703 101.42 385.679 101.213 385.633 101.02C385.586 100.827 385.513 100.66 385.413 100.52C385.313 100.38 385.183 100.27 385.023 100.19C384.863 100.103 384.669 100.06 384.443 100.06C384.216 100.06 384.023 100.103 383.863 100.19C383.703 100.27 383.573 100.38 383.473 100.52C383.373 100.66 383.299 100.827 383.253 101.02C383.206 101.213 383.183 101.42 383.183 101.64V103.36C383.183 103.593 383.203 103.807 383.243 104C383.283 104.193 383.353 104.36 383.453 104.5C383.553 104.64 383.683 104.75 383.843 104.83C384.003 104.903 384.203 104.94 384.443 104.94Z"
    fill="#5078E1"
  /><path
    d="M390.146 104.74V99.66H389.966L389.306 102.06H388.046V101.94L388.886 99H391.466V104.74H393.206V106H388.166V104.74H390.146Z"
    fill="#5078E1"
  /><path
    d="M396.269 104.74V99.66H396.089L395.429 102.06H394.169V101.94L395.009 99H397.589V104.74H399.329V106H394.289V104.74H396.269Z"
    fill="#5078E1"
  /><path
    d="M402.392 104.74V99.66H402.212L401.552 102.06H400.292V101.94L401.132 99H403.712V104.74H405.452V106H400.412V104.74H402.392Z"
    fill="#5078E1"
  /><path
    d="M408.515 104.74V99.66H408.335L407.675 102.06H406.415V101.94L407.255 99H409.835V104.74H411.575V106H406.535V104.74H408.515Z"
    fill="#5078E1"
  /><path
    d="M414.218 102.5C414.218 102.273 414.301 102.077 414.468 101.91C414.635 101.743 414.831 101.66 415.058 101.66C415.285 101.66 415.481 101.743 415.648 101.91C415.815 102.077 415.898 102.273 415.898 102.5C415.898 102.727 415.815 102.923 415.648 103.09C415.481 103.257 415.285 103.34 415.058 103.34C414.831 103.34 414.635 103.257 414.468 103.09C414.301 102.923 414.218 102.727 414.218 102.5ZM412.478 101.72C412.478 100.813 412.705 100.11 413.158 99.61C413.618 99.11 414.251 98.86 415.058 98.86C415.865 98.86 416.495 99.11 416.948 99.61C417.408 100.11 417.638 100.813 417.638 101.72V103.28C417.638 104.213 417.408 104.923 416.948 105.41C416.495 105.897 415.865 106.14 415.058 106.14C414.251 106.14 413.618 105.897 413.158 105.41C412.705 104.923 412.478 104.213 412.478 103.28V101.72ZM415.058 104.94C415.298 104.94 415.498 104.903 415.658 104.83C415.818 104.75 415.948 104.64 416.048 104.5C416.148 104.36 416.218 104.193 416.258 104C416.298 103.807 416.318 103.593 416.318 103.36V101.64C416.318 101.42 416.295 101.213 416.248 101.02C416.201 100.827 416.128 100.66 416.028 100.52C415.928 100.38 415.798 100.27 415.638 100.19C415.478 100.103 415.285 100.06 415.058 100.06C414.831 100.06 414.638 100.103 414.478 100.19C414.318 100.27 414.188 100.38 414.088 100.52C413.988 100.66 413.915 100.827 413.868 101.02C413.821 101.213 413.798 101.42 413.798 101.64V103.36C413.798 103.593 413.818 103.807 413.858 104C413.898 104.193 413.968 104.36 414.068 104.5C414.168 104.64 414.298 104.75 414.458 104.83C414.618 104.903 414.818 104.94 415.058 104.94Z"
    fill="#5078E1"
  /><path
    d="M420.761 104.74V99.66H420.581L419.921 102.06H418.661V101.94L419.501 99H422.081V104.74H423.821V106H418.781V104.74H420.761Z"
    fill="#5078E1"
  /><path
    d="M426.884 104.74V99.66H426.704L426.044 102.06H424.784V101.94L425.624 99H428.204V104.74H429.944V106H424.904V104.74H426.884Z"
    fill="#5078E1"
  /><path
    d="M433.007 104.74V99.66H432.827L432.167 102.06H430.907V101.94L431.747 99H434.327V104.74H436.067V106H431.027V104.74H433.007Z"
    fill="#5078E1"
  /><path
    d="M439.13 104.74V99.66H438.95L438.29 102.06H437.03V101.94L437.87 99H440.45V104.74H442.19V106H437.15V104.74H439.13Z"
    fill="#5078E1"
  /><path
    d="M444.833 102.5C444.833 102.273 444.917 102.077 445.083 101.91C445.25 101.743 445.447 101.66 445.673 101.66C445.9 101.66 446.097 101.743 446.263 101.91C446.43 102.077 446.513 102.273 446.513 102.5C446.513 102.727 446.43 102.923 446.263 103.09C446.097 103.257 445.9 103.34 445.673 103.34C445.447 103.34 445.25 103.257 445.083 103.09C444.917 102.923 444.833 102.727 444.833 102.5ZM443.093 101.72C443.093 100.813 443.32 100.11 443.773 99.61C444.233 99.11 444.867 98.86 445.673 98.86C446.48 98.86 447.11 99.11 447.563 99.61C448.023 100.11 448.253 100.813 448.253 101.72V103.28C448.253 104.213 448.023 104.923 447.563 105.41C447.11 105.897 446.48 106.14 445.673 106.14C444.867 106.14 444.233 105.897 443.773 105.41C443.32 104.923 443.093 104.213 443.093 103.28V101.72ZM445.673 104.94C445.913 104.94 446.113 104.903 446.273 104.83C446.433 104.75 446.563 104.64 446.663 104.5C446.763 104.36 446.833 104.193 446.873 104C446.913 103.807 446.933 103.593 446.933 103.36V101.64C446.933 101.42 446.91 101.213 446.863 101.02C446.817 100.827 446.743 100.66 446.643 100.52C446.543 100.38 446.413 100.27 446.253 100.19C446.093 100.103 445.9 100.06 445.673 100.06C445.447 100.06 445.253 100.103 445.093 100.19C444.933 100.27 444.803 100.38 444.703 100.52C444.603 100.66 444.53 100.827 444.483 101.02C444.437 101.213 444.413 101.42 444.413 101.64V103.36C444.413 103.593 444.433 103.807 444.473 104C444.513 104.193 444.583 104.36 444.683 104.5C444.783 104.64 444.913 104.75 445.073 104.83C445.233 104.903 445.433 104.94 445.673 104.94Z"
    fill="#5078E1"
  /><path
    d="M450.956 102.5C450.956 102.273 451.04 102.077 451.206 101.91C451.373 101.743 451.57 101.66 451.796 101.66C452.023 101.66 452.22 101.743 452.386 101.91C452.553 102.077 452.636 102.273 452.636 102.5C452.636 102.727 452.553 102.923 452.386 103.09C452.22 103.257 452.023 103.34 451.796 103.34C451.57 103.34 451.373 103.257 451.206 103.09C451.04 102.923 450.956 102.727 450.956 102.5ZM449.216 101.72C449.216 100.813 449.443 100.11 449.896 99.61C450.356 99.11 450.99 98.86 451.796 98.86C452.603 98.86 453.233 99.11 453.686 99.61C454.146 100.11 454.376 100.813 454.376 101.72V103.28C454.376 104.213 454.146 104.923 453.686 105.41C453.233 105.897 452.603 106.14 451.796 106.14C450.99 106.14 450.356 105.897 449.896 105.41C449.443 104.923 449.216 104.213 449.216 103.28V101.72ZM451.796 104.94C452.036 104.94 452.236 104.903 452.396 104.83C452.556 104.75 452.686 104.64 452.786 104.5C452.886 104.36 452.956 104.193 452.996 104C453.036 103.807 453.056 103.593 453.056 103.36V101.64C453.056 101.42 453.033 101.213 452.986 101.02C452.94 100.827 452.866 100.66 452.766 100.52C452.666 100.38 452.536 100.27 452.376 100.19C452.216 100.103 452.023 100.06 451.796 100.06C451.57 100.06 451.376 100.103 451.216 100.19C451.056 100.27 450.926 100.38 450.826 100.52C450.726 100.66 450.653 100.827 450.606 101.02C450.56 101.213 450.536 101.42 450.536 101.64V103.36C450.536 103.593 450.556 103.807 450.596 104C450.636 104.193 450.706 104.36 450.806 104.5C450.906 104.64 451.036 104.75 451.196 104.83C451.356 104.903 451.556 104.94 451.796 104.94Z"
    fill="#5078E1"
  /><path
    d="M457.499 104.74V99.66H457.319L456.659 102.06H455.399V101.94L456.239 99H458.819V104.74H460.559V106H455.519V104.74H457.499Z"
    fill="#5078E1"
  /><path
    d="M463.202 102.5C463.202 102.273 463.286 102.077 463.452 101.91C463.619 101.743 463.816 101.66 464.042 101.66C464.269 101.66 464.466 101.743 464.632 101.91C464.799 102.077 464.882 102.273 464.882 102.5C464.882 102.727 464.799 102.923 464.632 103.09C464.466 103.257 464.269 103.34 464.042 103.34C463.816 103.34 463.619 103.257 463.452 103.09C463.286 102.923 463.202 102.727 463.202 102.5ZM461.462 101.72C461.462 100.813 461.689 100.11 462.142 99.61C462.602 99.11 463.236 98.86 464.042 98.86C464.849 98.86 465.479 99.11 465.932 99.61C466.392 100.11 466.622 100.813 466.622 101.72V103.28C466.622 104.213 466.392 104.923 465.932 105.41C465.479 105.897 464.849 106.14 464.042 106.14C463.236 106.14 462.602 105.897 462.142 105.41C461.689 104.923 461.462 104.213 461.462 103.28V101.72ZM464.042 104.94C464.282 104.94 464.482 104.903 464.642 104.83C464.802 104.75 464.932 104.64 465.032 104.5C465.132 104.36 465.202 104.193 465.242 104C465.282 103.807 465.302 103.593 465.302 103.36V101.64C465.302 101.42 465.279 101.213 465.232 101.02C465.186 100.827 465.112 100.66 465.012 100.52C464.912 100.38 464.782 100.27 464.622 100.19C464.462 100.103 464.269 100.06 464.042 100.06C463.816 100.06 463.622 100.103 463.462 100.19C463.302 100.27 463.172 100.38 463.072 100.52C462.972 100.66 462.899 100.827 462.852 101.02C462.806 101.213 462.782 101.42 462.782 101.64V103.36C462.782 103.593 462.802 103.807 462.842 104C462.882 104.193 462.952 104.36 463.052 104.5C463.152 104.64 463.282 104.75 463.442 104.83C463.602 104.903 463.802 104.94 464.042 104.94Z"
    fill="#5078E1"
  /><path
    d="M469.745 104.74V99.66H469.565L468.905 102.06H467.645V101.94L468.485 99H471.065V104.74H472.805V106H467.765V104.74H469.745Z"
    fill="#5078E1"
  /><path
    d="M475.869 104.74V99.66H475.689L475.029 102.06H473.769V101.94L474.609 99H477.189V104.74H478.929V106H473.889V104.74H475.869Z"
    fill="#5078E1"
  /><path
    d="M481.992 104.74V99.66H481.812L481.152 102.06H479.892V101.94L480.732 99H483.312V104.74H485.052V106H480.012V104.74H481.992Z"
    fill="#5078E1"
  /><path
    d="M488.115 104.74V99.66H487.935L487.275 102.06H486.015V101.94L486.855 99H489.435V104.74H491.175V106H486.135V104.74H488.115Z"
    fill="#5078E1"
  /><path
    d="M493.818 102.5C493.818 102.273 493.901 102.077 494.068 101.91C494.234 101.743 494.431 101.66 494.658 101.66C494.884 101.66 495.081 101.743 495.248 101.91C495.414 102.077 495.498 102.273 495.498 102.5C495.498 102.727 495.414 102.923 495.248 103.09C495.081 103.257 494.884 103.34 494.658 103.34C494.431 103.34 494.234 103.257 494.068 103.09C493.901 102.923 493.818 102.727 493.818 102.5ZM492.078 101.72C492.078 100.813 492.304 100.11 492.758 99.61C493.218 99.11 493.851 98.86 494.658 98.86C495.464 98.86 496.094 99.11 496.548 99.61C497.008 100.11 497.238 100.813 497.238 101.72V103.28C497.238 104.213 497.008 104.923 496.548 105.41C496.094 105.897 495.464 106.14 494.658 106.14C493.851 106.14 493.218 105.897 492.758 105.41C492.304 104.923 492.078 104.213 492.078 103.28V101.72ZM494.658 104.94C494.898 104.94 495.098 104.903 495.258 104.83C495.418 104.75 495.548 104.64 495.648 104.5C495.748 104.36 495.818 104.193 495.858 104C495.898 103.807 495.918 103.593 495.918 103.36V101.64C495.918 101.42 495.894 101.213 495.848 101.02C495.801 100.827 495.728 100.66 495.628 100.52C495.528 100.38 495.398 100.27 495.238 100.19C495.078 100.103 494.884 100.06 494.658 100.06C494.431 100.06 494.238 100.103 494.078 100.19C493.918 100.27 493.788 100.38 493.688 100.52C493.588 100.66 493.514 100.827 493.468 101.02C493.421 101.213 493.398 101.42 493.398 101.64V103.36C493.398 103.593 493.418 103.807 493.458 104C493.498 104.193 493.568 104.36 493.668 104.5C493.768 104.64 493.898 104.75 494.058 104.83C494.218 104.903 494.418 104.94 494.658 104.94Z"
    fill="#5078E1"
  /><path
    d="M499.941 102.5C499.941 102.273 500.024 102.077 500.191 101.91C500.357 101.743 500.554 101.66 500.781 101.66C501.007 101.66 501.204 101.743 501.371 101.91C501.537 102.077 501.621 102.273 501.621 102.5C501.621 102.727 501.537 102.923 501.371 103.09C501.204 103.257 501.007 103.34 500.781 103.34C500.554 103.34 500.357 103.257 500.191 103.09C500.024 102.923 499.941 102.727 499.941 102.5ZM498.201 101.72C498.201 100.813 498.427 100.11 498.881 99.61C499.341 99.11 499.974 98.86 500.781 98.86C501.587 98.86 502.217 99.11 502.671 99.61C503.131 100.11 503.361 100.813 503.361 101.72V103.28C503.361 104.213 503.131 104.923 502.671 105.41C502.217 105.897 501.587 106.14 500.781 106.14C499.974 106.14 499.341 105.897 498.881 105.41C498.427 104.923 498.201 104.213 498.201 103.28V101.72ZM500.781 104.94C501.021 104.94 501.221 104.903 501.381 104.83C501.541 104.75 501.671 104.64 501.771 104.5C501.871 104.36 501.941 104.193 501.981 104C502.021 103.807 502.041 103.593 502.041 103.36V101.64C502.041 101.42 502.017 101.213 501.971 101.02C501.924 100.827 501.851 100.66 501.751 100.52C501.651 100.38 501.521 100.27 501.361 100.19C501.201 100.103 501.007 100.06 500.781 100.06C500.554 100.06 500.361 100.103 500.201 100.19C500.041 100.27 499.911 100.38 499.811 100.52C499.711 100.66 499.637 100.827 499.591 101.02C499.544 101.213 499.521 101.42 499.521 101.64V103.36C499.521 103.593 499.541 103.807 499.581 104C499.621 104.193 499.691 104.36 499.791 104.5C499.891 104.64 500.021 104.75 500.181 104.83C500.341 104.903 500.541 104.94 500.781 104.94Z"
    fill="#5078E1"
  /><path
    d="M506.484 104.74V99.66H506.304L505.644 102.06H504.384V101.94L505.224 99H507.804V104.74H509.544V106H504.504V104.74H506.484Z"
    fill="#5078E1"
  /><path
    d="M512.187 102.5C512.187 102.273 512.27 102.077 512.437 101.91C512.603 101.743 512.8 101.66 513.027 101.66C513.253 101.66 513.45 101.743 513.617 101.91C513.783 102.077 513.867 102.273 513.867 102.5C513.867 102.727 513.783 102.923 513.617 103.09C513.45 103.257 513.253 103.34 513.027 103.34C512.8 103.34 512.603 103.257 512.437 103.09C512.27 102.923 512.187 102.727 512.187 102.5ZM510.447 101.72C510.447 100.813 510.673 100.11 511.127 99.61C511.587 99.11 512.22 98.86 513.027 98.86C513.833 98.86 514.463 99.11 514.917 99.61C515.377 100.11 515.607 100.813 515.607 101.72V103.28C515.607 104.213 515.377 104.923 514.917 105.41C514.463 105.897 513.833 106.14 513.027 106.14C512.22 106.14 511.587 105.897 511.127 105.41C510.673 104.923 510.447 104.213 510.447 103.28V101.72ZM513.027 104.94C513.267 104.94 513.467 104.903 513.627 104.83C513.787 104.75 513.917 104.64 514.017 104.5C514.117 104.36 514.187 104.193 514.227 104C514.267 103.807 514.287 103.593 514.287 103.36V101.64C514.287 101.42 514.263 101.213 514.217 101.02C514.17 100.827 514.097 100.66 513.997 100.52C513.897 100.38 513.767 100.27 513.607 100.19C513.447 100.103 513.253 100.06 513.027 100.06C512.8 100.06 512.607 100.103 512.447 100.19C512.287 100.27 512.157 100.38 512.057 100.52C511.957 100.66 511.883 100.827 511.837 101.02C511.79 101.213 511.767 101.42 511.767 101.64V103.36C511.767 103.593 511.787 103.807 511.827 104C511.867 104.193 511.937 104.36 512.037 104.5C512.137 104.64 512.267 104.75 512.427 104.83C512.587 104.903 512.787 104.94 513.027 104.94Z"
    fill="#5078E1"
  /><path
    d="M518.73 104.74V99.66H518.55L517.89 102.06H516.63V101.94L517.47 99H520.05V104.74H521.79V106H516.75V104.74H518.73Z"
    fill="#5078E1"
  /><path
    d="M524.853 104.74V99.66H524.673L524.013 102.06H522.753V101.94L523.593 99H526.173V104.74H527.913V106H522.873V104.74H524.853Z"
    fill="#5078E1"
  /><path
    d="M530.556 102.5C530.556 102.273 530.639 102.077 530.806 101.91C530.973 101.743 531.169 101.66 531.396 101.66C531.623 101.66 531.819 101.743 531.986 101.91C532.153 102.077 532.236 102.273 532.236 102.5C532.236 102.727 532.153 102.923 531.986 103.09C531.819 103.257 531.623 103.34 531.396 103.34C531.169 103.34 530.973 103.257 530.806 103.09C530.639 102.923 530.556 102.727 530.556 102.5ZM528.816 101.72C528.816 100.813 529.043 100.11 529.496 99.61C529.956 99.11 530.589 98.86 531.396 98.86C532.203 98.86 532.833 99.11 533.286 99.61C533.746 100.11 533.976 100.813 533.976 101.72V103.28C533.976 104.213 533.746 104.923 533.286 105.41C532.833 105.897 532.203 106.14 531.396 106.14C530.589 106.14 529.956 105.897 529.496 105.41C529.043 104.923 528.816 104.213 528.816 103.28V101.72ZM531.396 104.94C531.636 104.94 531.836 104.903 531.996 104.83C532.156 104.75 532.286 104.64 532.386 104.5C532.486 104.36 532.556 104.193 532.596 104C532.636 103.807 532.656 103.593 532.656 103.36V101.64C532.656 101.42 532.633 101.213 532.586 101.02C532.539 100.827 532.466 100.66 532.366 100.52C532.266 100.38 532.136 100.27 531.976 100.19C531.816 100.103 531.623 100.06 531.396 100.06C531.169 100.06 530.976 100.103 530.816 100.19C530.656 100.27 530.526 100.38 530.426 100.52C530.326 100.66 530.253 100.827 530.206 101.02C530.159 101.213 530.136 101.42 530.136 101.64V103.36C530.136 103.593 530.156 103.807 530.196 104C530.236 104.193 530.306 104.36 530.406 104.5C530.506 104.64 530.636 104.75 530.796 104.83C530.956 104.903 531.156 104.94 531.396 104.94Z"
    fill="#5078E1"
  /><path
    d="M536.679 102.5C536.679 102.273 536.762 102.077 536.929 101.91C537.096 101.743 537.292 101.66 537.519 101.66C537.746 101.66 537.942 101.743 538.109 101.91C538.276 102.077 538.359 102.273 538.359 102.5C538.359 102.727 538.276 102.923 538.109 103.09C537.942 103.257 537.746 103.34 537.519 103.34C537.292 103.34 537.096 103.257 536.929 103.09C536.762 102.923 536.679 102.727 536.679 102.5ZM534.939 101.72C534.939 100.813 535.166 100.11 535.619 99.61C536.079 99.11 536.712 98.86 537.519 98.86C538.326 98.86 538.956 99.11 539.409 99.61C539.869 100.11 540.099 100.813 540.099 101.72V103.28C540.099 104.213 539.869 104.923 539.409 105.41C538.956 105.897 538.326 106.14 537.519 106.14C536.712 106.14 536.079 105.897 535.619 105.41C535.166 104.923 534.939 104.213 534.939 103.28V101.72ZM537.519 104.94C537.759 104.94 537.959 104.903 538.119 104.83C538.279 104.75 538.409 104.64 538.509 104.5C538.609 104.36 538.679 104.193 538.719 104C538.759 103.807 538.779 103.593 538.779 103.36V101.64C538.779 101.42 538.756 101.213 538.709 101.02C538.662 100.827 538.589 100.66 538.489 100.52C538.389 100.38 538.259 100.27 538.099 100.19C537.939 100.103 537.746 100.06 537.519 100.06C537.292 100.06 537.099 100.103 536.939 100.19C536.779 100.27 536.649 100.38 536.549 100.52C536.449 100.66 536.376 100.827 536.329 101.02C536.282 101.213 536.259 101.42 536.259 101.64V103.36C536.259 103.593 536.279 103.807 536.319 104C536.359 104.193 536.429 104.36 536.529 104.5C536.629 104.64 536.759 104.75 536.919 104.83C537.079 104.903 537.279 104.94 537.519 104.94Z"
    fill="#5078E1"
  /><path
    d="M543.222 104.74V99.66H543.042L542.382 102.06H541.122V101.94L541.962 99H544.542V104.74H546.282V106H541.242V104.74H543.222Z"
    fill="#5078E1"
  /><path
    d="M548.925 102.5C548.925 102.273 549.008 102.077 549.175 101.91C549.342 101.743 549.538 101.66 549.765 101.66C549.992 101.66 550.188 101.743 550.355 101.91C550.522 102.077 550.605 102.273 550.605 102.5C550.605 102.727 550.522 102.923 550.355 103.09C550.188 103.257 549.992 103.34 549.765 103.34C549.538 103.34 549.342 103.257 549.175 103.09C549.008 102.923 548.925 102.727 548.925 102.5ZM547.185 101.72C547.185 100.813 547.412 100.11 547.865 99.61C548.325 99.11 548.958 98.86 549.765 98.86C550.572 98.86 551.202 99.11 551.655 99.61C552.115 100.11 552.345 100.813 552.345 101.72V103.28C552.345 104.213 552.115 104.923 551.655 105.41C551.202 105.897 550.572 106.14 549.765 106.14C548.958 106.14 548.325 105.897 547.865 105.41C547.412 104.923 547.185 104.213 547.185 103.28V101.72ZM549.765 104.94C550.005 104.94 550.205 104.903 550.365 104.83C550.525 104.75 550.655 104.64 550.755 104.5C550.855 104.36 550.925 104.193 550.965 104C551.005 103.807 551.025 103.593 551.025 103.36V101.64C551.025 101.42 551.002 101.213 550.955 101.02C550.908 100.827 550.835 100.66 550.735 100.52C550.635 100.38 550.505 100.27 550.345 100.19C550.185 100.103 549.992 100.06 549.765 100.06C549.538 100.06 549.345 100.103 549.185 100.19C549.025 100.27 548.895 100.38 548.795 100.52C548.695 100.66 548.622 100.827 548.575 101.02C548.528 101.213 548.505 101.42 548.505 101.64V103.36C548.505 103.593 548.525 103.807 548.565 104C548.605 104.193 548.675 104.36 548.775 104.5C548.875 104.64 549.005 104.75 549.165 104.83C549.325 104.903 549.525 104.94 549.765 104.94Z"
    fill="#5078E1"
  /><path
    d="M555.468 104.74V99.66H555.288L554.628 102.06H553.368V101.94L554.208 99H556.788V104.74H558.528V106H553.488V104.74H555.468Z"
    fill="#5078E1"
  /><path
    d="M561.171 102.5C561.171 102.273 561.254 102.077 561.421 101.91C561.588 101.743 561.785 101.66 562.011 101.66C562.238 101.66 562.434 101.743 562.601 101.91C562.768 102.077 562.851 102.273 562.851 102.5C562.851 102.727 562.768 102.923 562.601 103.09C562.434 103.257 562.238 103.34 562.011 103.34C561.785 103.34 561.588 103.257 561.421 103.09C561.254 102.923 561.171 102.727 561.171 102.5ZM559.431 101.72C559.431 100.813 559.658 100.11 560.111 99.61C560.571 99.11 561.205 98.86 562.011 98.86C562.818 98.86 563.448 99.11 563.901 99.61C564.361 100.11 564.591 100.813 564.591 101.72V103.28C564.591 104.213 564.361 104.923 563.901 105.41C563.448 105.897 562.818 106.14 562.011 106.14C561.205 106.14 560.571 105.897 560.111 105.41C559.658 104.923 559.431 104.213 559.431 103.28V101.72ZM562.011 104.94C562.251 104.94 562.451 104.903 562.611 104.83C562.771 104.75 562.901 104.64 563.001 104.5C563.101 104.36 563.171 104.193 563.211 104C563.251 103.807 563.271 103.593 563.271 103.36V101.64C563.271 101.42 563.248 101.213 563.201 101.02C563.155 100.827 563.081 100.66 562.981 100.52C562.881 100.38 562.751 100.27 562.591 100.19C562.431 100.103 562.238 100.06 562.011 100.06C561.785 100.06 561.591 100.103 561.431 100.19C561.271 100.27 561.141 100.38 561.041 100.52C560.941 100.66 560.868 100.827 560.821 101.02C560.775 101.213 560.751 101.42 560.751 101.64V103.36C560.751 103.593 560.771 103.807 560.811 104C560.851 104.193 560.921 104.36 561.021 104.5C561.121 104.64 561.251 104.75 561.411 104.83C561.571 104.903 561.771 104.94 562.011 104.94Z"
    fill="#5078E1"
  /><path
    d="M567.714 104.74V99.66H567.534L566.874 102.06H565.614V101.94L566.454 99H569.034V104.74H570.774V106H565.734V104.74H567.714Z"
    fill="#5078E1"
  /><path
    d="M573.837 104.74V99.66H573.657L572.997 102.06H571.737V101.94L572.577 99H575.157V104.74H576.897V106H571.857V104.74H573.837Z"
    fill="#5078E1"
  /><path
    d="M579.96 104.74V99.66H579.78L579.12 102.06H577.86V101.94L578.7 99H581.28V104.74H583.02V106H577.98V104.74H579.96Z"
    fill="#5078E1"
  /><path
    d="M585.663 102.5C585.663 102.273 585.747 102.077 585.913 101.91C586.08 101.743 586.277 101.66 586.503 101.66C586.73 101.66 586.927 101.743 587.093 101.91C587.26 102.077 587.343 102.273 587.343 102.5C587.343 102.727 587.26 102.923 587.093 103.09C586.927 103.257 586.73 103.34 586.503 103.34C586.277 103.34 586.08 103.257 585.913 103.09C585.747 102.923 585.663 102.727 585.663 102.5ZM583.923 101.72C583.923 100.813 584.15 100.11 584.603 99.61C585.063 99.11 585.697 98.86 586.503 98.86C587.31 98.86 587.94 99.11 588.393 99.61C588.853 100.11 589.083 100.813 589.083 101.72V103.28C589.083 104.213 588.853 104.923 588.393 105.41C587.94 105.897 587.31 106.14 586.503 106.14C585.697 106.14 585.063 105.897 584.603 105.41C584.15 104.923 583.923 104.213 583.923 103.28V101.72ZM586.503 104.94C586.743 104.94 586.943 104.903 587.103 104.83C587.263 104.75 587.393 104.64 587.493 104.5C587.593 104.36 587.663 104.193 587.703 104C587.743 103.807 587.763 103.593 587.763 103.36V101.64C587.763 101.42 587.74 101.213 587.693 101.02C587.647 100.827 587.573 100.66 587.473 100.52C587.373 100.38 587.243 100.27 587.083 100.19C586.923 100.103 586.73 100.06 586.503 100.06C586.277 100.06 586.083 100.103 585.923 100.19C585.763 100.27 585.633 100.38 585.533 100.52C585.433 100.66 585.36 100.827 585.313 101.02C585.267 101.213 585.243 101.42 585.243 101.64V103.36C585.243 103.593 585.263 103.807 585.303 104C585.343 104.193 585.413 104.36 585.513 104.5C585.613 104.64 585.743 104.75 585.903 104.83C586.063 104.903 586.263 104.94 586.503 104.94Z"
    fill="#5078E1"
  /><path
    d="M591.786 102.5C591.786 102.273 591.87 102.077 592.036 101.91C592.203 101.743 592.4 101.66 592.626 101.66C592.853 101.66 593.05 101.743 593.216 101.91C593.383 102.077 593.466 102.273 593.466 102.5C593.466 102.727 593.383 102.923 593.216 103.09C593.05 103.257 592.853 103.34 592.626 103.34C592.4 103.34 592.203 103.257 592.036 103.09C591.87 102.923 591.786 102.727 591.786 102.5ZM590.046 101.72C590.046 100.813 590.273 100.11 590.726 99.61C591.186 99.11 591.82 98.86 592.626 98.86C593.433 98.86 594.063 99.11 594.516 99.61C594.976 100.11 595.206 100.813 595.206 101.72V103.28C595.206 104.213 594.976 104.923 594.516 105.41C594.063 105.897 593.433 106.14 592.626 106.14C591.82 106.14 591.186 105.897 590.726 105.41C590.273 104.923 590.046 104.213 590.046 103.28V101.72ZM592.626 104.94C592.866 104.94 593.066 104.903 593.226 104.83C593.386 104.75 593.516 104.64 593.616 104.5C593.716 104.36 593.786 104.193 593.826 104C593.866 103.807 593.886 103.593 593.886 103.36V101.64C593.886 101.42 593.863 101.213 593.816 101.02C593.77 100.827 593.696 100.66 593.596 100.52C593.496 100.38 593.366 100.27 593.206 100.19C593.046 100.103 592.853 100.06 592.626 100.06C592.4 100.06 592.206 100.103 592.046 100.19C591.886 100.27 591.756 100.38 591.656 100.52C591.556 100.66 591.483 100.827 591.436 101.02C591.39 101.213 591.366 101.42 591.366 101.64V103.36C591.366 103.593 591.386 103.807 591.426 104C591.466 104.193 591.536 104.36 591.636 104.5C591.736 104.64 591.866 104.75 592.026 104.83C592.186 104.903 592.386 104.94 592.626 104.94Z"
    fill="#5078E1"
  /><path
    d="M598.329 104.74V99.66H598.149L597.489 102.06H596.229V101.94L597.069 99H599.649V104.74H601.389V106H596.349V104.74H598.329Z"
    fill="#5078E1"
  /><path
    d="M604.452 104.74V99.66H604.272L603.613 102.06H602.352V101.94L603.193 99H605.772V104.74H607.512V106H602.472V104.74H604.452Z"
    fill="#5078E1"
  /><path
    d="M610.156 102.5C610.156 102.273 610.239 102.077 610.406 101.91C610.572 101.743 610.769 101.66 610.996 101.66C611.222 101.66 611.419 101.743 611.586 101.91C611.752 102.077 611.836 102.273 611.836 102.5C611.836 102.727 611.752 102.923 611.586 103.09C611.419 103.257 611.222 103.34 610.996 103.34C610.769 103.34 610.572 103.257 610.406 103.09C610.239 102.923 610.156 102.727 610.156 102.5ZM608.416 101.72C608.416 100.813 608.642 100.11 609.096 99.61C609.556 99.11 610.189 98.86 610.996 98.86C611.802 98.86 612.432 99.11 612.886 99.61C613.346 100.11 613.576 100.813 613.576 101.72V103.28C613.576 104.213 613.346 104.923 612.886 105.41C612.432 105.897 611.802 106.14 610.996 106.14C610.189 106.14 609.556 105.897 609.096 105.41C608.642 104.923 608.416 104.213 608.416 103.28V101.72ZM610.996 104.94C611.236 104.94 611.436 104.903 611.596 104.83C611.756 104.75 611.886 104.64 611.986 104.5C612.086 104.36 612.156 104.193 612.196 104C612.236 103.807 612.256 103.593 612.256 103.36V101.64C612.256 101.42 612.232 101.213 612.186 101.02C612.139 100.827 612.066 100.66 611.966 100.52C611.866 100.38 611.736 100.27 611.576 100.19C611.416 100.103 611.222 100.06 610.996 100.06C610.769 100.06 610.576 100.103 610.416 100.19C610.256 100.27 610.126 100.38 610.026 100.52C609.926 100.66 609.852 100.827 609.806 101.02C609.759 101.213 609.736 101.42 609.736 101.64V103.36C609.736 103.593 609.756 103.807 609.796 104C609.836 104.193 609.906 104.36 610.006 104.5C610.106 104.64 610.236 104.75 610.396 104.83C610.556 104.903 610.756 104.94 610.996 104.94Z"
    fill="#5078E1"
  /><path
    d="M616.279 102.5C616.279 102.273 616.362 102.077 616.529 101.91C616.695 101.743 616.892 101.66 617.119 101.66C617.345 101.66 617.542 101.743 617.709 101.91C617.875 102.077 617.959 102.273 617.959 102.5C617.959 102.727 617.875 102.923 617.709 103.09C617.542 103.257 617.345 103.34 617.119 103.34C616.892 103.34 616.695 103.257 616.529 103.09C616.362 102.923 616.279 102.727 616.279 102.5ZM614.539 101.72C614.539 100.813 614.765 100.11 615.219 99.61C615.679 99.11 616.312 98.86 617.119 98.86C617.925 98.86 618.555 99.11 619.009 99.61C619.469 100.11 619.699 100.813 619.699 101.72V103.28C619.699 104.213 619.469 104.923 619.009 105.41C618.555 105.897 617.925 106.14 617.119 106.14C616.312 106.14 615.679 105.897 615.219 105.41C614.765 104.923 614.539 104.213 614.539 103.28V101.72ZM617.119 104.94C617.359 104.94 617.559 104.903 617.719 104.83C617.879 104.75 618.009 104.64 618.109 104.5C618.209 104.36 618.279 104.193 618.319 104C618.359 103.807 618.379 103.593 618.379 103.36V101.64C618.379 101.42 618.355 101.213 618.309 101.02C618.262 100.827 618.189 100.66 618.089 100.52C617.989 100.38 617.859 100.27 617.699 100.19C617.539 100.103 617.345 100.06 617.119 100.06C616.892 100.06 616.699 100.103 616.539 100.19C616.379 100.27 616.249 100.38 616.149 100.52C616.049 100.66 615.975 100.827 615.929 101.02C615.882 101.213 615.859 101.42 615.859 101.64V103.36C615.859 103.593 615.879 103.807 615.919 104C615.959 104.193 616.029 104.36 616.129 104.5C616.229 104.64 616.359 104.75 616.519 104.83C616.679 104.903 616.879 104.94 617.119 104.94Z"
    fill="#5078E1"
  /><path
    d="M622.822 104.74V99.66H622.642L621.982 102.06H620.722V101.94L621.562 99H624.142V104.74H625.882V106H620.842V104.74H622.822Z"
    fill="#5078E1"
  /><path
    d="M628.525 102.5C628.525 102.273 628.608 102.077 628.775 101.91C628.941 101.743 629.138 101.66 629.365 101.66C629.591 101.66 629.788 101.743 629.955 101.91C630.121 102.077 630.205 102.273 630.205 102.5C630.205 102.727 630.121 102.923 629.955 103.09C629.788 103.257 629.591 103.34 629.365 103.34C629.138 103.34 628.941 103.257 628.775 103.09C628.608 102.923 628.525 102.727 628.525 102.5ZM626.785 101.72C626.785 100.813 627.011 100.11 627.465 99.61C627.925 99.11 628.558 98.86 629.365 98.86C630.171 98.86 630.801 99.11 631.255 99.61C631.715 100.11 631.945 100.813 631.945 101.72V103.28C631.945 104.213 631.715 104.923 631.255 105.41C630.801 105.897 630.171 106.14 629.365 106.14C628.558 106.14 627.925 105.897 627.465 105.41C627.011 104.923 626.785 104.213 626.785 103.28V101.72ZM629.365 104.94C629.605 104.94 629.805 104.903 629.965 104.83C630.125 104.75 630.255 104.64 630.355 104.5C630.455 104.36 630.525 104.193 630.565 104C630.605 103.807 630.625 103.593 630.625 103.36V101.64C630.625 101.42 630.601 101.213 630.555 101.02C630.508 100.827 630.435 100.66 630.335 100.52C630.235 100.38 630.105 100.27 629.945 100.19C629.785 100.103 629.591 100.06 629.365 100.06C629.138 100.06 628.945 100.103 628.785 100.19C628.625 100.27 628.495 100.38 628.395 100.52C628.295 100.66 628.221 100.827 628.175 101.02C628.128 101.213 628.105 101.42 628.105 101.64V103.36C628.105 103.593 628.125 103.807 628.165 104C628.205 104.193 628.275 104.36 628.375 104.5C628.475 104.64 628.605 104.75 628.765 104.83C628.925 104.903 629.125 104.94 629.365 104.94Z"
    fill="#5078E1"
  /><path
    d="M635.068 104.74V99.66H634.888L634.228 102.06H632.968V101.94L633.808 99H636.388V104.74H638.128V106H633.088V104.74H635.068Z"
    fill="#5078E1"
  /><path
    d="M641.191 104.74V99.66H641.011L640.351 102.06H639.091V101.94L639.931 99H642.511V104.74H644.251V106H639.211V104.74H641.191Z"
    fill="#5078E1"
  /><path
    d="M647.314 104.74V99.66H647.134L646.474 102.06H645.214V101.94L646.054 99H648.634V104.74H650.374V106H645.334V104.74H647.314Z"
    fill="#5078E1"
  /><path
    d="M653.017 102.5C653.017 102.273 653.1 102.077 653.267 101.91C653.434 101.743 653.63 101.66 653.857 101.66C654.084 101.66 654.28 101.743 654.447 101.91C654.614 102.077 654.697 102.273 654.697 102.5C654.697 102.727 654.614 102.923 654.447 103.09C654.28 103.257 654.084 103.34 653.857 103.34C653.63 103.34 653.434 103.257 653.267 103.09C653.1 102.923 653.017 102.727 653.017 102.5ZM651.277 101.72C651.277 100.813 651.504 100.11 651.957 99.61C652.417 99.11 653.05 98.86 653.857 98.86C654.664 98.86 655.294 99.11 655.747 99.61C656.207 100.11 656.437 100.813 656.437 101.72V103.28C656.437 104.213 656.207 104.923 655.747 105.41C655.294 105.897 654.664 106.14 653.857 106.14C653.05 106.14 652.417 105.897 651.957 105.41C651.504 104.923 651.277 104.213 651.277 103.28V101.72ZM653.857 104.94C654.097 104.94 654.297 104.903 654.457 104.83C654.617 104.75 654.747 104.64 654.847 104.5C654.947 104.36 655.017 104.193 655.057 104C655.097 103.807 655.117 103.593 655.117 103.36V101.64C655.117 101.42 655.094 101.213 655.047 101.02C655 100.827 654.927 100.66 654.827 100.52C654.727 100.38 654.597 100.27 654.437 100.19C654.277 100.103 654.084 100.06 653.857 100.06C653.63 100.06 653.437 100.103 653.277 100.19C653.117 100.27 652.987 100.38 652.887 100.52C652.787 100.66 652.714 100.827 652.667 101.02C652.62 101.213 652.597 101.42 652.597 101.64V103.36C652.597 103.593 652.617 103.807 652.657 104C652.697 104.193 652.767 104.36 652.867 104.5C652.967 104.64 653.097 104.75 653.257 104.83C653.417 104.903 653.617 104.94 653.857 104.94Z"
    fill="#5078E1"
  /><path
    d="M659.14 102.5C659.14 102.273 659.223 102.077 659.39 101.91C659.557 101.743 659.753 101.66 659.98 101.66C660.207 101.66 660.403 101.743 660.57 101.91C660.737 102.077 660.82 102.273 660.82 102.5C660.82 102.727 660.737 102.923 660.57 103.09C660.403 103.257 660.207 103.34 659.98 103.34C659.753 103.34 659.557 103.257 659.39 103.09C659.223 102.923 659.14 102.727 659.14 102.5ZM657.4 101.72C657.4 100.813 657.627 100.11 658.08 99.61C658.54 99.11 659.173 98.86 659.98 98.86C660.787 98.86 661.417 99.11 661.87 99.61C662.33 100.11 662.56 100.813 662.56 101.72V103.28C662.56 104.213 662.33 104.923 661.87 105.41C661.417 105.897 660.787 106.14 659.98 106.14C659.173 106.14 658.54 105.897 658.08 105.41C657.627 104.923 657.4 104.213 657.4 103.28V101.72ZM659.98 104.94C660.22 104.94 660.42 104.903 660.58 104.83C660.74 104.75 660.87 104.64 660.97 104.5C661.07 104.36 661.14 104.193 661.18 104C661.22 103.807 661.24 103.593 661.24 103.36V101.64C661.24 101.42 661.217 101.213 661.17 101.02C661.123 100.827 661.05 100.66 660.95 100.52C660.85 100.38 660.72 100.27 660.56 100.19C660.4 100.103 660.207 100.06 659.98 100.06C659.753 100.06 659.56 100.103 659.4 100.19C659.24 100.27 659.11 100.38 659.01 100.52C658.91 100.66 658.837 100.827 658.79 101.02C658.743 101.213 658.72 101.42 658.72 101.64V103.36C658.72 103.593 658.74 103.807 658.78 104C658.82 104.193 658.89 104.36 658.99 104.5C659.09 104.64 659.22 104.75 659.38 104.83C659.54 104.903 659.74 104.94 659.98 104.94Z"
    fill="#5078E1"
  /><path
    d="M665.263 102.5C665.263 102.273 665.346 102.077 665.513 101.91C665.68 101.743 665.876 101.66 666.103 101.66C666.33 101.66 666.526 101.743 666.693 101.91C666.86 102.077 666.943 102.273 666.943 102.5C666.943 102.727 666.86 102.923 666.693 103.09C666.526 103.257 666.33 103.34 666.103 103.34C665.876 103.34 665.68 103.257 665.513 103.09C665.346 102.923 665.263 102.727 665.263 102.5ZM663.523 101.72C663.523 100.813 663.75 100.11 664.203 99.61C664.663 99.11 665.296 98.86 666.103 98.86C666.91 98.86 667.54 99.11 667.993 99.61C668.453 100.11 668.683 100.813 668.683 101.72V103.28C668.683 104.213 668.453 104.923 667.993 105.41C667.54 105.897 666.91 106.14 666.103 106.14C665.296 106.14 664.663 105.897 664.203 105.41C663.75 104.923 663.523 104.213 663.523 103.28V101.72ZM666.103 104.94C666.343 104.94 666.543 104.903 666.703 104.83C666.863 104.75 666.993 104.64 667.093 104.5C667.193 104.36 667.263 104.193 667.303 104C667.343 103.807 667.363 103.593 667.363 103.36V101.64C667.363 101.42 667.34 101.213 667.293 101.02C667.246 100.827 667.173 100.66 667.073 100.52C666.973 100.38 666.843 100.27 666.683 100.19C666.523 100.103 666.33 100.06 666.103 100.06C665.876 100.06 665.683 100.103 665.523 100.19C665.363 100.27 665.233 100.38 665.133 100.52C665.033 100.66 664.96 100.827 664.913 101.02C664.866 101.213 664.843 101.42 664.843 101.64V103.36C664.843 103.593 664.863 103.807 664.903 104C664.943 104.193 665.013 104.36 665.113 104.5C665.213 104.64 665.343 104.75 665.503 104.83C665.663 104.903 665.863 104.94 666.103 104.94Z"
    fill="#5078E1"
  /></g></g><path
    id="lock"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M47.7534 54.7785H44.1489C41.9416 54.7785 40.1532 56.5674 40.1532 58.7736V81.7659C40.1532 83.972 41.9416 85.7615 44.1489 85.7615H76.0049C78.2116 85.7615 80 83.972 80 81.7659V58.7736C79.9999 56.5674 78.2116 54.7785 76.0049 54.7785H72.4002V47.2473V46.1996C72.4002 44.1763 71.8804 42.2157 70.9614 40.4212C69.2821 37.1427 65.8818 34.6544 62.2426 34.0796C60.3813 33.7857 58.5201 33.8302 56.6965 34.3456C55.0106 34.822 53.5501 35.6719 52.1879 36.7485C51.015 37.6755 50.0283 38.9074 49.305 40.2103C48.4387 41.7707 47.9935 43.4268 47.805 45.1893C47.7063 46.1124 47.7234 47.0603 47.7405 47.9985C47.7469 48.3563 47.7534 48.7126 47.7534 49.0657V54.723V54.7785ZM52.8428 54.7785H67.3108V54.6777V49.0107C67.3108 48.6717 67.3162 48.3297 67.3216 47.9864C67.3361 47.0675 67.3508 46.1389 67.2623 45.2308C67.1531 44.5996 66.9849 43.9826 66.758 43.3834C66.4935 42.8167 66.1782 42.2779 65.814 41.7696C65.4182 41.2946 64.9818 40.8582 64.5068 40.4624C63.9988 40.0984 63.4602 39.7832 62.8939 39.5187C62.2955 39.2922 61.6795 39.124 61.0492 39.0147C60.4001 38.9527 59.7487 38.9526 59.0999 39.0151C58.4724 39.1239 57.8586 39.2916 57.263 39.5171C56.6967 39.7808 56.1578 40.0959 55.6499 40.4598C55.1725 40.8567 54.7339 41.2954 54.337 41.7727C53.9731 42.2806 53.6581 42.8195 53.3944 43.3858C53.1706 43.9766 53.0039 44.5853 52.8949 45.2075C52.8402 45.801 52.8413 46.395 52.8425 46.991C52.8427 47.0764 52.8428 47.1619 52.8428 47.2474V54.7785ZM61.5143 76.27V72.1343C62.617 71.6001 63.3776 70.4697 63.3777 69.1619C63.3777 67.3386 61.8994 65.8608 60.0772 65.8608C58.2539 65.8608 56.7761 67.3385 56.7761 69.1619C56.7761 70.468 57.5345 71.5967 58.6345 72.132V76.27C58.6345 76.6676 58.7959 77.0278 59.0563 77.2881C59.3166 77.5484 59.6768 77.7099 60.0744 77.7099C60.8694 77.7099 61.5143 77.065 61.5143 76.27Z"
    fill="#2A2A2A"
  /><path
    d="M102.431 17.5687C79.0064 -5.85622 40.9936 -5.85622 17.5687 17.5687C-5.85623 40.9936 -5.85623 79.0064 17.5687 102.431C40.9936 125.856 79.0064 125.856 102.431 102.431C125.856 78.8662 125.856 40.9936 102.431 17.5687ZM25.9848 94.0152C9.01228 77.0427 7.32905 50.5319 20.9351 31.7358C21.4962 31.0345 22.6184 30.8942 23.3197 31.5956L88.4044 96.6803C89.1058 97.3816 88.9655 98.5038 88.2642 99.0649C69.4681 112.671 42.9573 110.988 25.9848 94.0152ZM96.8206 88.2642L31.7358 23.1794C31.0345 22.4781 31.1747 21.3559 31.8761 20.7949C50.6721 7.18878 77.1829 8.87201 94.1555 25.8445C111.128 42.8171 112.811 69.3279 99.2051 88.1239C98.6441 88.8252 97.5219 88.9655 96.8206 88.2642Z"
    fill="#3D73FF"
  /></svg>
</template>

<style scoped>
svg #binary {
    animation: scroll 10s infinite alternate linear;
}
@keyframes scroll {
    100% {
        transform: translateX(-100%) scale(1.1);
    }
}

/* dark mode */
@media (prefers-color-scheme: dark) {
    svg #lock {
        fill: #fff;
    }
}
</style>
