<template>
  <Notification :messages="props.flash.message" />
  <div class="flex h-full">
    <Navigation
      :mobile-menu-opened="mobileMenuOpen"
      :user="user"
      @close="mobileMenuOpen = false"
    />
    <div class="flex flex-1 flex-col">
      <div class="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
        <button
          type="button"
          class="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          @click="mobileMenuOpen = true"
        >
          <span class="sr-only">{{ __("Open sidebar") }}</span>
          <Bars3Icon
            class="h-6 w-6"
            aria-hidden="true"
          />
        </button>
      </div>
      <main class="flex-1 flex flex-col overflow-auto">
        <div class="my-6 px-4 sm:px-6 md:px-8 space-y-32 flex-1 flex flex-col">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import Notification from "@/Shared/Ui/Notification.vue";
import Navigation from "@/Shared/Ui/Navigation.vue";
import User from "@/Models/User.js";

import { Bars3Icon } from '@heroicons/vue/24/outline'

import {computed, ref} from "vue";

const mobileMenuOpen = ref(false)

const props = defineProps({
    auth: Object,
    flash: Object,
})

const user = computed(() => new User(props.auth?.user));
</script>
