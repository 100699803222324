<template>
    <img v-if="user.avatar" class="w-full shadow-soft-xl rounded-full" :src="`/img/avatars/${user.avatar}`" :alt="user.name" />
    <div v-else :class="[fontSize, bg, size]" class="rounded-full bg-blue-500 dark:bg-indigo-600 text-white flex items-center justify-center font-medium select-none"> {{ initials }}</div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    user: Object,
    fontSize: {
        type: String,
        default: "text-xl"
    },
    size: {
        type: String,
        default: "w-8 h-8"
    },
    bg: String
})

const initials = computed(() => {
    if (!props.user.name) {
        return "";
    }
    
    const [first_name, last_name] = props.user.name.split(" ");
    if (first_name && last_name) {
        return (first_name[0]+last_name[0]).toUpperCase();
    }
    if (first_name) {
        return first_name.slice(0, 2).toUpperCase();
    }
    if (last_name) {
        return last_name.slice(0, 2).toUpperCase();
    }
    return "";

})
</script>
